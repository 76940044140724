import { render, staticRenderFns } from "./FafSmallChart.vue?vue&type=template&id=b07a5a72&scoped=true&"
import script from "./FafSmallChart.vue?vue&type=script&lang=js&"
export * from "./FafSmallChart.vue?vue&type=script&lang=js&"
import style0 from "./FafSmallChart.vue?vue&type=style&index=0&id=b07a5a72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07a5a72",
  null
  
)

export default component.exports