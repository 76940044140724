<template>
  <b-button
    v-b-tooltip.hover
    v-can="['action', 'CopyTrackingLink']"
    style="padding: 0; font-size: 18px;"
    class="icon-button text-danger ml-2"
    title="Kopeeri tellimuse jälgimise link"
    @click="copyLink()"
  >
    <font-awesome-icon icon="link" />
  </b-button>
</template>

<script>
  export default {
    name: 'faf-tracking-link',
    props: {
      trackingId: String,
      host: {
        type: String,
        default: 'https://tracking.faf.sh/lapi/'
      }
    },
    methods: {
      copyLink () {
        this.$copyText(this.host + this.trackingId)
        this.$notify.show('Link on kopeeritud')
      }
    }
  }
</script>
