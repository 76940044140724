<template>
  <b-pagination
    v-bind="$attrs"
    class="my-2"
    align="center"
    v-on="$listeners"
  >
    <template v-slot:first-text>
      <font-awesome-icon icon="angle-double-left" />
    </template>
    <template v-slot:prev-text>
      <font-awesome-icon icon="angle-left" />
    </template>
    <template v-slot:next-text>
      <font-awesome-icon icon="angle-right" />
    </template>
    <template v-slot:last-text>
      <font-awesome-icon icon="angle-double-right" />
    </template>
  </b-pagination>
</template>
<script>
  export default {
    name: 'faf-pagination'
  }
</script>
