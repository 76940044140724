import cloneDeep from 'lodash.clonedeep'
import set from 'lodash.set'
import get from 'lodash.get'
import debounce from 'lodash.debounce'
import compact from 'lodash.compact'
import isequal from 'lodash.isequal'
import without from 'lodash.without'
import differenceWith from 'lodash.differencewith'
import throttle from 'lodash.throttle'
import uniq from 'lodash.uniq'

export default function installLodashHelperMethods (Vue) {
  if (Vue) {
    Vue.prototype.$_debounce = debounce
    Vue.prototype.$_cloneDeep = cloneDeep
    Vue.prototype.$_get = get
    Vue.prototype.$_set = set
    Vue.prototype.$_compact = compact
    Vue.prototype.$_isEqual = isequal
    Vue.prototype.$_without = without
    Vue.prototype.$_differenceWith = differenceWith
    Vue.prototype.$_throttle = throttle
    Vue.prototype.$_uniq = uniq
  } else {
    console.error('Vue not found')
  }
}
