export default function installSettings (Vue, settings) {
  if (Vue) {
    Vue.prototype.$unblockMainThread = unblockMainThread
  } else {
    console.error('Vue not found')
  }
}

async function unblockMainThread () {
  return await new Promise(resolve => { setTimeout(() => { resolve() }, 0) })
}

export {
  unblockMainThread
}
