export default {
  1000: 'Kasutajanimi on juba kasutusel',
  1001: 'Sisestatud andmed on puudulikud',
  1002: 'Kasutajaõigused puuduvad või olete väljalogitud süsteemist. Palun proovige uuesti sisse logida',
  1003: 'Ainult avatud töid on võimalik lõpetada',
  1004: 'Valitud tööd ei eksisteeri',
  1005: 'Ainult avatuid töid saab annulleerida',
  1006: 'Kasutajal on juba avatud töö',
  1007: 'Antud tellimusega töötab keegi',
  1008: 'Kõik tooted pole tehtud',
  1009: 'Tarne kuupäev ei saa tellimuse loomisel olla minevikus',
  1055: 'Tellimust on muudetud. Laen alla viimased andmed ja palun sisestage andmed uuesti!'
}
