<template>
  <div>
    <faf-filters
      ref="filters"
      :fields="filterFields"
      :url-cache="true"
      :initial-query="initialQuery"
      @filter="getOrders(pagination.page, $_cloneDeep($event), true)"
      @search-change="searchChange"
    >
      <template slot="slotFields">
      </template>
    </faf-filters>
    <b-row class="pl-2 pb-2">
      <b-col
        v-can="['action', 'Filter']"
        class="px-1 py-1"
        cols="auto"
      >
        <b-form-group class="button-checkboxes">
          <b-form-radio-group
            v-if="$refs.filters"
            v-model="$refs.filters.query.status"
            :options="statusOptions"
            name="filter-status"
            buttons
            @input="$refs.filters.query.subStatus = null; $refs.filters.filter()"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="$refs.filters && $refs.filters.query.status && $refs.filters.query.status === 'OFFER'"
        v-can="['action', 'FilterSubStatus']"
        class="px-1 py-1 sub-status"
        cols="auto"
      >
        <b-form-group class="button-checkboxes">
          <b-form-radio-group
            v-model="$refs.filters.query.subStatus"
            :options="offerSubStatusOptions"
            name="filter-sub-status"
            buttons
            @input="$refs.filters.filter"
          />
        </b-form-group>
        <b-button
          v-if="$refs.filters.query.subStatus"
          v-b-tooltip.hover
          class="icon-button text-warning"
          title="Eemalda filter"
          @click="$refs.filters.query.subStatus = null"
        >
          <font-awesome-icon icon="times-circle" />
        </b-button>
      </b-col>
      <b-col
        v-if="$refs.filters && $refs.filters.query.status && $refs.filters.query.status === 'ORDER'"
        v-can="['action', 'FilterSubStatus']"
        class="px-1 py-1 sub-status"
      >
        <b-form-group class="button-checkboxes">
          <b-form-radio-group
            v-model="$refs.filters.query.subStatus"
            :options="orderSubStatusOptions"
            name="filter-sub-status"
            buttons
            @input="$refs.filters.filter"
          />
        </b-form-group>
        <b-button
          v-if="$refs.filters.query.subStatus"
          v-b-tooltip.hover
          class="icon-button text-warning"
          title="Eemalda filter"
          @click="$refs.filters.query.subStatus = null"
        >
          <font-awesome-icon icon="times-circle" />
        </b-button>
      </b-col>
      <b-col
        v-if="$refs.filters && $refs.filters.query.status && $refs.filters.query.status === 'PRODUCTION_ORDER'"
        v-can="['action', 'FilterSubStatus']"
        class="px-1 py-1 sub-status"
      >
        <b-form-group class="button-checkboxes">
          <b-form-radio-group
            v-model="$refs.filters.query.subStatus"
            :options="productionOrderSubStatusOptions"
            name="filter-sub-status"
            buttons
            @input="$refs.filters.filter"
          />
        </b-form-group>
        <b-button
          v-if="$refs.filters.query.subStatus"
          v-b-tooltip.hover
          class="icon-button text-warning"
          title="Eemalda filter"
          @click="$refs.filters.query.subStatus = null"
        >
          <font-awesome-icon icon="times-circle" />
        </b-button>
      </b-col>
    </b-row>
    <div class="table-responsive sales-table">
      <b-table
        :items="items"
        :fields="fields"
        :busy="loading"
        no-local-sorting
        striped
        show-empty
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
        @sort-changed="sortBy"
        @row-contextmenu="$contextMenu.item = $event"
      >
        <template #row-details="row">
          <faf-child-orders-table v-if="$_get(row.item, 'linkedOrders.childOrders', []).length" :order-id="row.item._id" />
        </template>
        <template v-slot:cell(status)="data">
          <faf-tag v-if="data.item.status === 'COMPLETE'" :text="(getOrderStatus(data.item)).label" color="success" />
          <faf-tag v-else-if="data.item.status === 'ANNULLED'" :text="(getOrderStatus(data.item)).label" color="danger" />
          <span v-else>{{ (getOrderStatus(data.item)).label }}</span>
        </template>
        <template v-slot:cell(createdDate)="data">
          {{ $dayjs(data.item.createdDate).format('DD.MM.YYYY') }}
        </template>
        <template v-slot:cell(deliveryDate)="data">
          {{ $dayjs(data.item.deliveryDate).format('DD.MM.YYYY') }}
        </template>
        <template v-slot:cell(readyProducts)="data">
          <faf-tag :color="getReadyProductsColor(data.item.products)" :text="getReadyProducts(data.item.products)" />
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(expand)="data">
          <b-button
            v-show="$_get(data.item, 'linkedOrders.childOrders', []).length"
            class="icon-button text-info pt-0 pb-0"
            @click="data.toggleDetails()"
          >
            <font-awesome-icon
              :icon="data.detailsShowing ? 'angle-double-down' : 'angle-double-right'"
            />
          </b-button>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            class="icon-button text-info ml-2"
            title="Muuda projekti"
            style="font-size: 20px; padding: 0;"
            @click="editId = data.item._id"
          >
            <font-awesome-icon
              icon="pencil-alt"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-2"
            title="Detailne vaade"
            @click="detailedOrderId = data.item.id"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
          <faf-tracking-link :host="trackingHost" :tracking-id="data.item.id" />
          <b-button
            v-if="$can(['orderSubStatus', 'PRODUCTION_ORDER'])"
            v-b-tooltip.hover
            style="font-size: 18px;"
            class="icon-button text-success"
            title="Loo tootmistellimus"
            @click="addChildOrder = true; parentOrder = data.item"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </b-table>
    </div>

    <b-pagination
      :key="pagination.page"
      v-model="pagination.page"
      :total-rows="pagination.totalDocs"
      :per-page="pagination.limit"
      class="mt-2 mb-3"
      align="center"
      @change="getOrders($event, cachedFilters, false, cachedSort)"
    >
      <template v-slot:first-text>
        <font-awesome-icon icon="angle-double-left" />
      </template>
      <template v-slot:prev-text>
        <font-awesome-icon icon="angle-left" />
      </template>
      <template v-slot:next-text>
        <font-awesome-icon icon="angle-right" />
      </template>
      <template v-slot:last-text>
        <font-awesome-icon icon="angle-double-right" />
      </template>
    </b-pagination>
    <faf-detailed-sale
      v-if="!!detailedOrderId"
      :id="detailedOrderId"
      @close="detailedOrderId = null; getOrders($route.query.page || 1, cachedFilters, false, cachedSort)"
    />
    <faf-add-edit-sale
      v-if="!!editId || addChildOrder"
      :parent-order="parentOrder"
      :edit-id="editId"
      @hide="editId = null; getOrders($route.query.page || 1, cachedFilters, false, cachedSort); addChildOrder = false; parentOrder = null"
    />
  </div>
</template>
<script>
  import { getOrderStatus } from './../../helperMethods/getOrderStatus'

  export default {
    name: 'order-table',
    components: {
    },
    data () {
      return {
        cachedSort: null,
        initialQuery: { status: this.$can(['orderFields', 'statusOffer']) ? 'OFFER' : 'ORDER', subStatus: null},
        statusOptions: [
          (this.$can(['orderFields', 'statusOffer']) && { value: 'OFFER', text: 'Hinnapakkumine' }),
          { value: 'ORDER', text: 'Tellimus' },
          { value: 'COMPLETE', text: 'Tehtud' },
          { value: 'ANNULLED', text: 'Tühistatud' },
          { value: '', text: 'Kõik' }
        ].filter(Boolean),
        offerSubStatusOptions: [
          (this.$can(['orderSubStatus', 'OFFER_PREPARED']) && { value: 'OFFER_PREPARED', text: 'Ettevalmistatud' }),
          (this.$can(['orderSubStatus', 'OFFER_EMAIL_SENT']) && { value: 'OFFER_EMAIL_SENT', text: 'Saadetud' }),
          (this.$can(['orderSubStatus', 'PRODUCTION_ORDER']) && {text: 'Tootmistellimus', value: 'PRODUCTION_ORDER'})
        ].filter(Boolean),
        orderSubStatusOptions: [
          (this.$can(['orderSubStatus', 'ORDER_PACKED']) && { value: 'ORDER_PACKED', text: 'Komplekteeritud' }),
          (this.$can(['orderSubStatus', 'ORDER_EMAIL_SENT']) && { value: 'ORDER_EMAIL_SENT', text: 'Klient teavitatud' }),
          (this.$can(['orderSubStatus', 'ORDER_SENT']) && { value: 'ORDER_SENT', text: 'Kaup väljastatud' }),
          (this.$can(['orderSubStatus', 'PRODUCTION_ORDER']) && {text: 'Tootmistellimus', value: 'PRODUCTION_ORDER'})
        ].filter(Boolean),
        productionOrderSubStatusOptions: [
          (this.$can(['orderSubStatus', 'IN_PURCHASE']) && { value: 'IN_PURCHASE', text: 'Ostusoovituses' }),
          (this.$can(['orderSubStatus', 'IN_PRODUCTION']) && { value: 'IN_PRODUCTION', text: 'Tootmises' }),
          (this.$can(['orderSubStatus', 'PRODUCTION_ORDER_COMPLETE']) && { value: 'PRODUCTION_ORDER_COMPLETE', text: 'Tehtud' })
        ].filter(Boolean),
        detailedOrderId: null,
        addChildOrder: false,
        parentOrder: null,
        editId: null,
        trackingHost: `https://tracking.produxio.io/${ this.$company.name.toLowerCase() }/`,
        loading: true,
        cachedFilters: {},
        items: [],
        fields: [
          { key: 'expand', label: '', width: '10px' },
          (this.$can(['orderFields', 'salesSystemNumber']) && { key: 'salesSystemNumber', label: 'ID', sortable: true }),
          (this.$can(['orderFields', 'orderId']) && { key: 'orderId', label: 'Projekti nr' }),
          (this.$can(['orderFields', 'projectName']) && { key: 'projectName', label: 'Projekti nimi' }),
          (this.$can(['orderFields', 'catalogue']) && { key: 'catalogue', label: 'Kataloog' }),
          (this.$can(['orderFields', 'object']) && { key: 'object', label: 'Objekt', tdClass: 'faf-max-width-300' }),
          { key: 'client', label: 'Klient', sortable: true },
          { key: 'createdDate', label: 'Sisestatud kuupäeval', sortable: true },
          { key: 'deliveryDate', label: 'Tarne kuupäeval', sortable: true },
          { key: 'status', label: 'Staatus' },
          (this.$can(['orderFields', 'readyProducts']) && { key: 'readyProducts', label: 'Valmistooted' }),
          { key: 'actions', label: 'Tegevused' }
        ],
        pageOptions: [25, 50, 100],
        pagination: {
          totalPages: 1,
          page: this.$route.query.page || 1,
          currentPage: +this.$route.query.page,
          limit: 25,
          totalDocs: 0
        },
        filterFields: [
          { type: 'multiselect', name: 'salesSystemNumber', label: 'ID', options: [], can: ['orderFields', 'salesSystemNumber'] },
          { type: 'multiselect', name: 'orderId', label: 'Projekti nr', options: [], can: ['orderFields', 'orderId'] },
          { type: 'multiselect', name: 'projectName', label: 'Projekti nimi', options: [], can: ['orderFields', 'projectName'] },
          { type: 'multiselect', name: 'catalogue', label: 'Kataloog', options: [], can: ['orderFields', 'catalogue'] },
          { type: 'multiselect', name: 'object', label: 'Objekt', options: [], can: ['orderFields', 'object'] },
          { type: 'multiselect', name: 'client', label: 'Klient', options: [] },
          { type: 'multiselect', name: 'products.name', label: 'Tootenimi', options: [] },
          { type: 'datepicker', name: 'startDate', label: 'Alates kuupäev' },
          { type: 'datepicker', name: 'endDate', label: 'Kuni kuupäev', info: 'Kuni kuupäeva kasutamiseks sisesta ka alguse kuupäev!' }
        ]
      }
    },
    async created () {
      await this.initialize()
      this.loading = false
    },
    methods: {
      sortBy (event) {
        const sort = event.sortDesc ? 1 : -1
        if (event.sortBy === 'salesSystemNumber') event.sortBy = '_id'
        this.cachedSort = {[event.sortBy]: sort}
        this.getOrders(this.pagination.page, this.cachedFilters, false, this.cachedSort)
      },
      async initialize () {
        if (!this.$route.query.filter) {
          this.$router.replace({query: {
            filter: JSON.stringify(this.initialQuery),
            ...this.$route.query.page && { page: this.$route.query.page }
          }}).catch(() => {})
        }
        try {
          await this.getOrders(this.pagination.page, JSON.parse(this.$route.query.filter), false)
        } catch (error) {
          await this.getOrders(this.pagination.page, {}, false)
        }
      },
      getOrderStatus,
      // TODO: Make better solution (map in BE or smth)
      getReadyProducts (products) {
        const ready = products.map(product => product.quantity.doneAmount >= product.quantity.amount).filter(Boolean)
        return `${ready.length}/${products.length}`
      },
      getReadyProductsColor (products) {
        const ready = products.map(product => product.quantity.doneAmount >= product.quantity.amount).filter(Boolean)
        return ready.length >= products.length ? 'success' : ready.length > 0 ? 'warning' : 'danger'
      },
      async getOrders (page, filters, resetPagination, sort) {

        this.loading = true
        if (resetPagination) {
          this.updatePagination()
        } else {
          page && (this.pagination.page = page)
        }

        let filterQuery
        let dateQuery

        if (filters) {
          filterQuery = {
            ...filters.salesSystemNumber && { salesSystemNumber: filters.salesSystemNumber },
            ...filters.catalogue && { catalogue: filters.catalogue },
            ...filters.object && { object: filters.object },
            ...filters.orderId && { orderId: filters.orderId },
            ...filters.client && { client: filters.client },
            ...filters.projectName && { projectName: filters.projectName },
            ...filters['products.name'] && { 'products.name': filters['products.name'] }
          }
          dateQuery = {
            ...filters.startDate && { startDate: filters.startDate },
            ...filters.endDate && { endDate: filters.endDate }
          }
        }
        this.cachedFilters = filters || {}

        // TODO More elegant way to filter from all orders
        if (!filters.status && !Object.keys({...filterQuery, ...dateQuery}).length) {
          filters.status = 'null'
        }
        await this.$orders.getOrders(filterQuery, this.pagination, dateQuery, filters && filters.status, filters && filters.subStatus, sort).then(rs => {
          this.items = rs.data.docs
          this.$router.replace({ query: { filter: this.$route.query.filter, page: rs.data.page }}).catch(() => {})
          this.updatePagination(rs.data)
          this.loading = false
        }).catch(err => this.$err.show(err))
      },
      updatePagination (data) {
        if (data) {
          this.pagination = {
            totalPages: data.totalPages || this.pagination.totalPages,
            page: data.page || this.$route.query.page || this.pagination.page,
            limit: data.limit || this.pagination.limit,
            totalDocs: data.totalDocs || this.pagination.totalDocs
          }
        } else {
          this.pagination = {
            totalPages: 1,
            page: 1,
            limit: 25,
            totalDocs: 0
          }
        }
      },
      async searchChange (e) {
        const options = await this.$typeahead.getArchiveSuggestions(e.name, e.event)
        const currentField = this.filterFields.find(field => field.name === e.name)
        currentField.options = options.data
      }
    }
  }
</script>
<style lang="scss">
  .button-checkboxes .btn.btn-secondary {
    padding: 7px 20px;
  }

  .sub-status .button-checkboxes,
  .sub-status .icon-button {
    display: inline-block;
  }

  .sub-status .icon-button {
    margin: 0;
    padding: 5px 5px 0;
  }
</style>
