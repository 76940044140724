import Ajv from 'ajv'
import { _getProductWorkTime } from '.'
import { unblockMainThread } from './../plugins/unblockMainThread'

// Kõikide toodete töökeskuste arvutus

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'array',
  required: ['materials'],
  properties: {
    materials: {
      type: 'array'
    }
  }
}

const test = ajv.compile(schema)

export default async function _getWorkTimeTotal (products) {
  if (!test(products)) {
    throw { function: '_getWorkTimeTotal', error: test.errors }
  }

  const calculations = []
  let otherUnits = false

  for await (const product of products) {
    calculations.push(await _getProductWorkTime(product))
  }

  const calc = calculations.reduce((a, workcenter) => {
    if (workcenter.otherUnits) {
      otherUnits = workcenter.otherUnits
    }
    return {
      amount: a.amount + +workcenter.amount,
      total: a.total + +workcenter.total
    }
  }, { amount: 0, price: 0, total: 0 })

  return {
    amount: calc.amount.toFixed(2),
    price: calc.amount > 0 ? (calc.total / calc.amount).toFixed(2) : '0.00',
    total: calc.total.toFixed(2),
    otherUnits
  }
}
