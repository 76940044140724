<template>
  <div>
    <h4 class="mb-0 text-info">
      TÖÖKESKUSED
    </h4>
    <div v-click-outside="disableEditMode">
      <b-table
        :class="currentTableId"
        :items="workcenters"
        :fields="workcenterFields"
        striped
        show-empty
        hover
      >
        <template v-slot:empty>
          <b-button
            :disabled="actionsDisabled"
            class="icon-button text-info"
            @click="addWorkcenter(null, true)"
          >
            <span class="icon-button-text">Lisa uus töökeskus</span><font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>

        <template v-slot:head(leadTime)="data">
          <span>{{ data.label }}</span>
          <b-button v-b-tooltip.hover :title="data.field.info" class="icon-tooltip icon-tooltip-table icon-button text-info">
            <font-awesome-icon icon="info-circle" />
          </b-button>
        </template>

        <template v-slot:head(setupTime)="data">
          <span>{{ data.label }}</span>
          <b-button v-b-tooltip.hover :title="data.field.info" class="icon-tooltip icon-tooltip-table icon-button text-info">
            <font-awesome-icon icon="info-circle" />
          </b-button>
        </template>

        <template v-slot:cell(queue)="data">
          <faf-input
            v-model="formData[data.index].queue"
            :active="editIndex === data.index"
            :name="'workcenter-queue-' + data.index + '-' + currentTableId"
            max-width="50"
            number
            @edit-row="editRow(data.index)"
            @input="onInput"
          />
        </template>

        <template v-slot:cell(name)="data">
          <faf-multiselect
            v-model="formData[data.index].name"
            v-validate="'required'"
            :active="editIndex === data.index"
            :options="nameSuggestions"
            :name="'workcenter-name-' + data.index + '-' + currentTableId"
            min-width="150"
            @input="prefillWorkcenter($event, data.index); onInput($event)"
            @search-change="searchChange('nameSuggestions', 'getWorkcenterNames', $event)"
            @add-option="addOption('nameSuggestions', $event)"
            @edit-row="editRow(data.index)"
          />
        </template>

        <template v-slot:cell(team)="data">
          <faf-multiselect
            v-model="formData[data.index].team"
            v-validate="'required'"
            :active="editIndex === data.index"
            :options="teamSuggestions"
            :name="'workcenter-team-' + data.index + '-' + currentTableId"
            min-width="150"
            @input="prefillWorkcenter($event, data.index); onInput($event)"
            @search-change="searchChange('teamSuggestions', 'getTeamNames', $event)"
            @add-option="addOption('teamSuggestions', $event)"
            @edit-row="editRow(data.index)"
          />
        </template>

        <template v-slot:cell(workTime)="data">
          <div style="min-width: 100px;">
            <faf-input
              v-model="formData[data.index].workTime"
              v-validate="'required'"
              :active="editIndex === data.index"
              :unit="data.item.unit"
              :name="'workcenter-workTime-' + data.index + '-' + currentTableId"
              max-width="50"
              number
              @edit-row="editRow(data.index)"
              @input="onInput"
            />
            <faf-select
              v-model="formData[data.index].unit"
              v-validate="'required'"
              :active="editIndex === data.index"
              :options="unitOptions"
              :name="'workcenter-unit-' + data.index + '-' + currentTableId"
              max-width="50"
              text-hidden
              @edit-row="editRow(data.index)"
              @input="onInput"
            />
          </div>
        </template>

        <template v-slot:cell(leadTime)="data">
          <faf-input
            v-model="formData[data.index].leadTime"
            :active="editIndex === data.index"
            :name="'workcenter-leadTime-' + data.index + '-' + currentTableId"
            max-width="50"
            number
            unit="päev"
            @edit-row="editRow(data.index)"
            @input="onInput"
          />
        </template>

        <template v-slot:cell(setupTime)="data">
          <faf-input
            v-model="formData[data.index].setupTime"
            :active="editIndex === data.index"
            :name="'workcenter-setupTime-' + data.index + '-' + currentTableId"
            max-width="50"
            number
            unit="min"
            @edit-row="editRow(data.index)"
            @input="onInput"
          />
        </template>

        <template v-slot:cell(price)="data">
          <faf-input
            v-model="formData[data.index].price"
            :active="editIndex === data.index"
            :unit="'€/' + data.item.unit"
            :name="'workcenter-price-' + data.index + '-' + currentTableId"
            max-width="80"
            number
            @edit-row="editRow(data.index)"
            @input="onInput"
          />
        </template>

        <template v-slot:cell(notes)="data">
          <faf-input
            v-model="formData[data.index].notes"
            v-validate="'max:55'"
            :active="editIndex === data.index"
            :name="'workcenter-notes-' + data.index + '-' + currentTableId"
            error-text="Märkmetel saab olla kuni 55 tähemärki!"
            @edit-row="editRow(data.index)"
            @input="onInput"
          />
        </template>

        <template v-slot:cell(linkedMaterials)="data">
          <faf-multiselect
            v-if="linkedMaterialsSuggestions.length > 0 && $can(['orderFields', 'linkedMaterials'])"
            v-model="formData[data.index].linkedMaterials"
            :active="editIndex === data.index"
            :taggable="false"
            :close-on-select="false"
            :clear-on-select="false"
            :options="linkedMaterialsSuggestions"
            :name="'workcenter-linkedMaterials-' + data.index"
            label="text"
            track-by="value"
            multiple
            min-width="200"
            max-width="200"
            @edit-row="editRow(data.index)"
            @input="onInput"
          >
            <template v-slot:custom-text>
              <span class="overflow-ellipsis" style="line-height: 18px;">
                {{ formData[data.index].linkedMaterials ? getLinkedMaterialText(data.index) : '' }}
              </span>
            </template>
          </faf-multiselect>
        </template>

        <template v-slot:cell(action)="data">
          <b-button
            v-b-tooltip.hover
            :disabled="actionsDisabled"
            class="text-info icon-button"
            title="Värskenda töökeskust"
            @click="prefillWorkcenter(formData[data.index].name, data.index); onInput()"
          >
            <font-awesome-icon icon="sync" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            :disabled="actionsDisabled"
            class="icon-button text-info"
            title="Lisa töökeskus"
            @click="addWorkcenter(data, true)"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            :disabled="actionsDisabled"
            class="icon-button text-info"
            title="Loo duplikaat"
            @click="addWorkcenter(data)"
          >
            <font-awesome-icon icon="copy" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            :disabled="actionsDisabled || formData.length <= 1"
            class="icon-button text-danger"
            title="Kustuta töökeskus"
            @click="deleteWorkcenter(data)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
  import { emptyWorkcenter } from './productHelpers'

  export default {
    name: 'faf-workcenters-edit',
    inject: ['$validator'],
    props: {
      workcenters: {
        type: Array,
        default: () => []
      },
      linkedMaterialsSuggestions: {
        type: Array,
        default: () => []
      },
      actionsDisabled: Boolean
    },
    data () {
      return {
        currentTableId: Math.random().toString(16).slice(-4),
        editIndex: null,
        formData: this.workcenters,
        nameSuggestions: [],
        teamSuggestions: [],
        workcenterFields: [
          { key: 'queue', label: 'Nr' },
          { key: 'name', label: 'Nimi' },
          { key: 'team', label: 'Tiim' },
          { key: 'workTime', label: 'Töömaht' },
          { key: 'leadTime', label: 'LTP', formatter: value => `${value} päev`, info: 'Läbilase tööpäevades' },
          { key: 'setupTime', label: 'SA', info: 'Ühekorde seadistusaeg (min)' },
          { key: 'price', label: 'Hind', formatter: (value, key, item) => `${value} €/${item.unit}` },
          { key: 'notes', label: 'Märkmed' },
          (this.$can(['orderFields', 'linkedMaterials']) && { key: 'linkedMaterials', label: 'Materjal' }),
          { key: 'action', label: 'Tegevused' }
        ],
        unitOptions: ['h', 'min', 'tk', 'kg', 'm2', 'm']
      }
    },
    watch: {
      workcenters (newVal, oldVal) {
        this.formData = newVal
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 300)
      this.resetWorkCenterQueue = this.$_debounce(this.resetWorkCenterQueue, 200)
    },
    methods: {
      // Keep in sync
      onInput () {
        this.$emit('input', this.formData)
      },

      // Multiselect
      async searchChange (suggestions, method, e) {
        this[suggestions] = (await this.$suggestions[method](e)).data
      },
      addOption (suggestions, value) {
        this[suggestions].push(value)
      },

      // Editable logic
      editRow (index) {
        !this.editDisabled && (this.editIndex = index)
      },
      disableEditMode (e) {
        const isCurrentTable = e.path && e.path.find(i => (i.className && typeof i.className === 'string' && i.className.includes(this.currentTableId)))
        !isCurrentTable && (this.editIndex = null)
      },

      // Field specific methods
      async prefillWorkcenter (event, index) {
        const workcenterData = (await this.$workcenters.getWorkcenters({filters: {name: event}})).data
        if (workcenterData.docs.length) {
          this.workcenters[index].setupTime = workcenterData.docs[0].setupTime || 0
          this.workcenters[index].leadTime = workcenterData.docs[0].leadTime
          this.workcenters[index].price = workcenterData.docs[0].price
          this.workcenters[index].team = workcenterData.docs[0].team
        }
      },
      getLinkedMaterialText (index) {
        if (!this.formData[index].linkedMaterials.includes(null)) {
          if (this.formData[index].linkedMaterials.length > 0) {
            return this.formData[index].linkedMaterials.map((m, i) => {
              if (m && m.text) {
                return m.text
              } else {
                this.formData[index].linkedMaterials[i] = this.linkedMaterialsSuggestions.find(s => s.value === m)
                return m ? (m.text || '') : ''
              }
            }).join(', ')
          }
        } else {
          return ''
        }

      },
      resetWorkCenterQueue () {
        this.formData.map((wc, i) => wc.queue = i + 1)
        this.onInput()
      },

      // Add, duplicate, delete
      addWorkcenter (data, newItem) {
        const index = data ? +data.index + 1 : 0
        const item = newItem ? this.$_cloneDeep({ ...emptyWorkcenter }) : {
          queue: +data.item.queue + 1,
          name: data.item.name,
          team: data.item.team,
          workTime: data.item.workTime,
          unit: data.item.unit,
          leadTime: data.item.leadTime,
          setupTime: data.item.setupTime,
          price: data.item.price,
          linkedMaterials: data.item.linkedMaterials || []
        }
        this.resetWorkCenterQueue()
        this.$emit('add-workcenter', { index, item })
      },
      deleteWorkcenter (data) {
        if (data.item._id) {
          this.$confirm.show('Töökeskus kustutatakse koheselt ära.', 'Olete kindel, et soovite töökeskust kustutada?', answer => {
            if (answer) {
              data.item._id && this.$orders.deleteWorkcenter(data.item._id)
              this.$emit('delete-workcenter', { index: +data.index })
              this.resetWorkCenterQueue()
            }
          })
        } else {
          this.$emit('delete-workcenter', { index: +data.index })
          this.resetWorkCenterQueue()
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .table-responsive {
    overflow: initial;
    overflow-x: auto;
  }

  .table-input {
    border: none;
    padding: 0;
    line-height: 1.9;
    font-size: 0.875rem;
    font-weight: 400;
    height: auto;

    .multiselect__tags {
      border: none;
      padding: 0;
      line-height: 1.9;
      font-size: 0.875rem;
      font-weight: 400;
      height: 26px;
    }
  }

  .icon-button {
    font-size: 20px;
    padding: 0;
    margin-right: 5px;
  }

  .icon-tooltip.icon-tooltip-table {
    position: relative;
    bottom: 0;
    margin-left: 5px;
  }
</style>
