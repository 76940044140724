<template>
  <div class="dataTables_wrapper">
    <faf-filters :fields="filterFields" :url-cache="true" no-options-label="Laen..." @getOptions="getAllSuggestions" @filter="filterTable({query: $event, page: 1})" />
    <b-row>
      <b-col class="ml-2">
        <div v-for="(pages, index) in selectAll" :key="index + '_selectAllIndex'">
          <b-form-checkbox
            v-if="(currentPage - 1) === index"
            id="select-all"
            v-model="selectAll[currentPage - 1]"
            class="pb-3"
            name="select-all"
            value="true"
            unchecked-value="false"
            @input="setSelected"
          >
            Vali kõik
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col v-if="selectedRowsPlannedTime">
        <b-badge class="pl-2 pr-2" pill style="font-size: 15px;" variant="success">
          Planeeritud aeg: {{ selectedRowsPlannedTime }}
        </b-badge>
      </b-col>
      <b-col
        v-can="['action', 'FilterPerPage']"
        class="px-1 py-1 mr-2"
        cols="auto"
      >
        <b-form-group class="button-checkboxes mb-3">
          <b-form-radio-group
            v-model="perPage"
            :options="[50, 100, 200]"
            name="filter-per-page"
            buttons
            @input="currentPage = 1; filterTable({query: cachedQuery, page: 1})"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="table-responsive production-table">
      <b-table
        :per-page="perPage"
        :sort-compare="$sortDate"
        :items="items"
        :fields="fields"
        :busy="$loader.val('production-table')"
        :tbody-tr-class="rowClass"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :borderless="$isMobile"
        :hover="!$isMobile"
        :show-empty="!$isMobile"
        stacked="xl"
        empty-text="Andmed puuduvad"
        @row-clicked="rowClicked"
        @row-contextmenu="$contextMenu.item = $event"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(forceProduction)="data">
          <div class="pl-1">
            <b-button
              v-if="isForceProduction(data.item.product)"
              v-b-tooltip.hover
              :class="'icon-button text-danger'"
              :title="'Kõiki materjale ei pruugi olla laos'"
              style="font-size: 20px; padding: 0;"
            >
              <font-awesome-icon icon="exclamation" />
            </b-button>
          </div>
        </template>
        <template v-slot:cell(material)="data">
          <div v-if="data.item.product.workcenters.linkedMaterials && data.item.product.workcenters.linkedMaterials.length > 0">
            {{ data.value }}
            <b-button :id="'name-tooltip-' + data.index" class="icon-button text-info">
              <font-awesome-icon icon="info-circle" />
            </b-button>
            <b-tooltip :target="'name-tooltip-' + data.index" triggers="hover" placement="right">
              <span v-for="(item, index) in getLinkedMaterial(data.item)" :key="index">
                <strong>{{ item.name }}</strong> - {{ item.width }} - {{ item.size }}{{ item.unit }} - {{ (item.size && +item.width ? (+item.size / +item.width / (+item.multiplier || 8)).toFixed(2) : '') }}m<sup>2</sup><br />
              </span>
            </b-tooltip>
          </div>
        </template>
        <template v-slot:cell(product.workcenters.startingTime)="data">
          <faf-tag :text="$dayjs(data.item.product.workcenters.startingTime).format('DD.MM.YYYY')" :color="$getDateColor(data.item.product.workcenters.startingTime)" />
        </template>
        <template v-slot:cell(product.workcenters.deliveryDate)="data">
          <faf-tag :text="$dayjs(data.item.product.workcenters.deliveryDate).format('DD.MM.YYYY')" :color="$getDateColor(data.item.product.workcenters.deliveryDate)" />
        </template>
        <template v-slot:cell(product.quantity)="data">
          <faf-tag v-if="data.item.product.workcenters.doneProducts >= data.item.product.quantity.amount" :text="data.item.product.workcenters.doneProducts + '/' + data.item.product.quantity.amount" color="success" />
          <div v-else>
            {{ data.item.product.workcenters.doneProducts + '/' + data.item.product.quantity.amount }}
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-modal.modal-add-note
            v-b-tooltip.hover
            v-b-tooltip.left
            :class="isNoteExcisting(data) ? 'icon-button text-info' : 'icon-button text-warning'"
            :title="isNoteExcisting(data) ? data.item.product.workcenters.notes : 'Lisa märkus'"
            style="font-size: 20px; padding: 0;"
            @click="setNotesToEdit(data.item)"
          >
            <font-awesome-icon
              :icon="isNoteExcisting(data) ? 'comment-dots' : 'comment-alt'"
            />
          </b-button>
          <production-stopped-modal
            :id="data.item.product.workcenters._id"
            v-can="['action', 'ProductStopped']"
            :is-stopped="data.item.product.subStatus.includes('STOPPED')"
            :reasons="data.item.product.stoppedAudit || []"
            @submitted="initialize()"
          />
          <b-button
            v-b-tooltip.focus
            v-b-tooltip.left
            tabindex="0"
            style="font-size: 20px; padding: 0 0 0 10px;"
            class="icon-button text-info"
            title="Detailne vaade"
            @click="detailedWorkcenterId = data.item.product.workcenters._id"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>

    <faf-pagination
      v-if="totalRows > perPage"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="mt-2 mb-3"
      align="center"
      @change="filterTable({query: cachedQuery, page: $event})"
    />

    <faf-detailed-production v-if="!!detailedWorkcenterId" :id="detailedWorkcenterId" @close="detailedWorkcenterId = null; initialize()" />
    <faf-add-note :visible="!!activeOrderAction" :loading="noteLoading" :note-to-edit="activeOrderAction" @submit="updateNote" @close="activeOrderAction = null" />
    <prepare-proof
      :key="endAndEditJobModalIndex"
      :my-jobs="cachedSelectedRows"
      @hide="endAndEditJobModalIndex++"
    />
    <complete-workcenter
      :key="completeWorkcenterIndex"
      :my-jobs="cachedSelectedRows"
      @hide="completeWorkcenterIndex++"
    />
    <faf-get-product-sheet-pdf
      :data="cachedSelectedRows"
      type="PRODUCTION"
    />
  </div>
</template>
<script>
  import PrepareProof from './../MyJobs/PrepareProof'
  import CompleteWorkcenter from './../MyJobs/CompleteWorkcenter'
  import FafDetailedProduction from './../DetailedViews/DetailedProduction'
  import FafGetProductSheetPdf from './../../components/GetPdf/templates/GetProductSheetPdf'
  import productionTableMixin from './productionTableMixin'
  import ProductionStoppedModal from './ProductionStoppedModal'

  import { _getWorkcenterProductivity } from './../../Calculations/index'
  import { formatHoursAndMinutes } from './../../helperMethods/dateHelpers'

  export default {
    name: 'production-table',
    components: {
      PrepareProof,
      CompleteWorkcenter,
      FafDetailedProduction,
      FafGetProductSheetPdf,
      ProductionStoppedModal
    },
    mixins: [ productionTableMixin ],
    data () {
      return {
        selectAll: ['false'],
        detailedWorkcenterId: null,
        cachedSelectedRows: [],
        selectedDocuments: {},
        selectedRowsPlannedTime: 0,
        endAndEditJobModalIndex: 1,
        completeWorkcenterIndex: 100,
        activeOrderAction: null,
        noteLoading: false,
        totalRows: 0,
        currentPage: +this.$route.query.page,
        perPage: this.$can(['action', 'FilterPerPage']) ? 50 : 20,
        cachedQuery: {},
        sortBy: 'product.workcenters.deliveryDate',
        sortDesc: true,
        filterFields: [
          { type: 'multiselect', name: 'salesSystemNumber', placeholder: 'ID', options: [], can: ['orderFields', 'salesSystemNumber'] },
          { type: 'multiselect', name: 'orderId', placeholder: 'Projekti nr', options: [], can: ['orderFields', 'orderId'] },
          { type: 'multiselect', name: 'catalogue', placeholder: 'Kataloog', options: [], can: ['orderFields', 'catalogue'] },
          { type: 'multiselect', name: 'projectName', placeholder: 'Projekti nimi', options: [], can: ['orderFields', 'projectName'] },
          { type: 'multiselect', name: 'client', placeholder: 'Klient', options: [] },
          { type: 'multiselect', name: 'products.workcenters.name', placeholder: 'Töökeskus', options: [] },
          { type: 'multiselect', name: 'products.name', placeholder: 'Toote nimetus', options: [] },
          { type: 'multiselect', name: 'products.workcenters.team', placeholder: 'Tiim', options: [] },
          { type: 'multiselect', name: 'products.workcenters.proof.workSheet', placeholder: 'Tööleht', options: [], can: ['action', 'FilterWorksheet'] },
          { type: 'multiselect', name: 'products.materials.name', placeholder: 'Materjali nimi', options: [], can: ['action', 'FilterWorkcenterWithLinkedMaterialsParameters'] },
          { type: 'multiselect', name: 'products.materials.diagram', placeholder: 'Joonis', options: [], can: ['action', 'FilterWorkcenterWithLinkedMaterialsParameters'] },
          { type: 'multiselect', name: 'products.materials.width', placeholder: 'Paksus', options: [], can: ['action', 'FilterWorkcenterWithLinkedMaterialsParameters'] },
          { type: 'b-form-checkbox', name: 'doneProducts', placeholder: 'Näita tehtud töid', can: ['action', 'FilterDoneProducts'] },
          { type: 'b-form-checkbox', name: 'inWorks', placeholder: 'Töös' },
          { type: 'b-form-checkbox', name: 'withWorkSheet', placeholder: 'Töölehe numbriga', can: ['orderFields', 'salesSystemNumber'] },
          { type: 'b-form-checkbox', name: 'withoutWorkSheet', placeholder: 'Töölehe numbrita', can: ['orderFields', 'salesSystemNumber'] },
          { type: 'b-form-checkbox', name: 'products.subStatus', value: 'STOPPED', label: null, uncheckedValue: null, placeholder: 'Seisak', can: ['action', 'ProductStopped'] }
        ],
        items: []
      }
    },
    computed: {
      clearedCachedQuery () {
        if (this.cachedQuery) {
          const query = this.$_cloneDeep(this.cachedQuery)
          return query
        }
        return null
      },
      selectedRows () {
        return this.items.filter(item => item.selected)
      }
    },
    watch: {
      cachedSelectedRows (val) {
        const plannedTimeSum = val.reduce((a, b) => {
          const workcenterUnit = this.$_get(b, 'product.workcenters.unit', null)
          const doneAmount = this.$_get(b, 'product.workcenters.doneProducts', 0)
          const totalAmount = this.$_get(b, 'product.quantity.amount', 1)
          const notDoneAmount = totalAmount - doneAmount
          if (workcenterUnit === 'min') return a + (+this.calculateWorkTime(b) / totalAmount * notDoneAmount)
          if (workcenterUnit === 'h') return a + (+this.calculateWorkTime(b) * 60 / totalAmount * notDoneAmount)
          return a + 0
        }, 0)
        this.selectedRowsPlannedTime = plannedTimeSum ? formatHoursAndMinutes(plannedTimeSum, 'min', true) : 0

        this.$emit('rows-selected', val.length > 0)
      }
    },
    beforeDestroy () {
      this.$root.$off('start-workcenter-job')
    },
    async created () {
      this.$root.$on('start-workcenter-job', () => {
        this.startJob()
      })
      this.$emit('rows-selected', this.cachedSelectedRows.length > 0)
      await this.initialize()
    },
    methods: {
      isForceProduction (product) {
        return product.forceProduction || (product.subStatus && product.subStatus.includes('FORCE_PRODUCTION') && !product.subStatus.includes('PURCHASE_DONE'))
      },
      getLinkedMaterial (item, type) {
        const linkedMaterials = item.product.materials.map(material => this.$_get(item, 'product.workcenters.linkedMaterials', []).includes(material._id) && material).filter(Boolean)
        return type ? linkedMaterials.map(material => material[type]).filter(Boolean) : linkedMaterials
      },
      rowClass (item, type) {
        if (item && item.selected) {
          return ["b-table-row-selected", "table-success", "cursor-pointer"]
        } else {
          if (!item || type !== 'row') return
          if (item.product.subStatus && item.product.subStatus.includes('STOPPED')) return 'table-warning-opacity'
          return ["cursor-pointer"]
        }
      },
      isNoteExcisting (data) {
        const notes = this.$_get(data, 'item.product.workcenters.notes', null)
        return ![' ', '', null, void 0, false].includes(notes)
      },
      setNotesToEdit (item) {
        const workcenter = this.$_get(item, 'product.workcenters', {})
        this.activeOrderAction = {
          id: workcenter._id,
          notes: workcenter.notes
        }
      },
      updateNote (notes) {
        this.noteLoading = true
        this.$orders.updateWorkcenter(this.activeOrderAction.id, { notes: notes || ' '}).then(() => {
          this.activeOrderAction = null
          this.initialize()
          this.noteLoading = false
        }).catch(err => {
          this.$err.show(err)
          this.noteLoading = false
        })
      },
      calculateWorkTime (item) {
        const unit = this.$_get(item, 'product.workcenters.unit', null)
        const doneAmount = this.$_get(item, 'product.workcenters.doneProducts', 0)
        const totalAmount = this.$_get(item, 'product.quantity.amount', 1)
        const notDoneAmount = totalAmount - doneAmount
        const setupTime = unit === 'min' ? this.$_get(item, 'product.workcenters.setupTime', 0) : this.$_get(item, 'product.workcenters.setupTime', 0.0001) / 60
        const totalTime = (this.$_get(item, 'product.workcenters.workTime', 0) * this.$_get(item, 'product.quantity.amount')) + setupTime
        const notDoneTotalTime = totalTime / totalAmount * notDoneAmount
        return totalTime
      },
      getLoad (item) {
        const unit = this.$_get(item, 'product.workcenters.unit', null)
        if (unit === 'min' || unit === 'h') {
          const workTime = this.$_get(item, 'product.workcenters.workTime', [])
          const amount = this.$_get(item, 'product.quantity.amount')
          const divider = 7.5 * (unit === 'min' ? 60 : 1)
          return `${((workTime * amount) / divider).toFixed(1)}`
        }
        return '-'
      },
      getMissingProofCount (proof, amount) {
        const doneProof = proof.reduce((a, b) => {
          if (b.workSheet !== '' && b.workSheet !== null && b.workSheet !== ' ') {
            return +a + +b.producedProductsAmount
          }
          return +a
        }, 0)
        return amount - doneProof
      },
      async initialize () {
        try {
          this.filterTable({query: JSON.parse(this.$route.query.filter), page: this.$route.query.page || 1})
        } catch (error) {
          this.filterTable({query: {}, page: this.$route.query.page || 1})
        }
      },
      async getAllSuggestions (event) {
        await this.filterFields.map(async (field) => {
          if (field.type !== 'datepicker' && field.name !== 'products.status') {
            if (field.options && !field.options.length && event === field.name) {
              field.options = (await this.$typeahead.getSuggestions(field.name)).data.filter(n => n)
            }
          }
        })
      },
      async filterTable ({query = 'empty', page = this.$route.query.page || 1}) {
        if (this.selectAll[page - 1] !== 'true') this.selectAll[page - 1] = 'false'
        if (!page) this.currentPage = 1
        if (query !== 'empty') this.cachedQuery = this.$_cloneDeep(query)
        this.$loader.start('production-table')
        this.$orders.getAllWorkcenterWithParent({filter: this.cachedQuery, page: page || this.currentPage, limit: this.perPage}).then(async rs => {
          this.$loader.stop('production-table')

          for await (const order of rs.data.docs) {
            const productivity = await _getWorkcenterProductivity(order)
            order.productivity = productivity ? productivity + ' %' : '-'
          }
          this.items = rs.data.docs
          this.totalRows = rs.data.totalDocs
          this.setCachedSelectedRows()
          this.$router.replace({ query: { filter: this.$route.query.filter, page: rs.data.page }}).catch(() => {})
          this.currentPage = rs.data.page

        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('production-table')
        })
      },
      clearSelected () {
        this.items.forEach((item) => {
          this.$delete(item, 'selected')
        })
      },
      setCachedSelectedRows () {
        this.items.forEach(item => {
          const match = this.cachedSelectedRows.find(r => r.product.workcenters._id === item.product.workcenters._id)
          if (match) this.$set(item, 'selected', true)
        })
      },
      setSelected () {
        this.items.forEach((item, index) => {
          const isCached = this.cachedSelectedRows.filter(r => r.product.workcenters._id === item.product.workcenters._id)
          if (this.selectAll[this.currentPage - 1] === 'true') {
            this.$set(item, 'selected', true)
            !isCached.length && this.cachedSelectedRows.push(item)
          } else if (isCached.length) {
            this.$set(item, 'selected', false)
            this.removeSelected(this.cachedSelectedRows, 'product.workcenters._id', item.product.workcenters._id)
          }
          this.$set(item, 'selected', this.selectAll[this.currentPage - 1] === 'true' ? true : false)
        })
      },
      removeSelected (array, key, value) {
        const index = array.findIndex(obj => this.$_get(obj, key) === value);
        array.splice(index, 1)
      },
      rowClicked (item) {
        this.$contextMenu.item = item
        if (item.selected) {
          this.$set(item, 'selected', false)
          this.removeSelected(this.cachedSelectedRows, 'product.workcenters._id', item.product.workcenters._id)

        } else {
          this.$set(item, 'selected', true)
          const isCached = this.cachedSelectedRows.filter(r => r.product.workcenters._id === item.product.workcenters._id)
          !isCached.length && this.cachedSelectedRows.push(item)
        }
      },
      async startJob () {
        this.$loader.start('production-table')
        const payload = {
          operatorName: `${this.$user.getFirstName()} ${this.$user.getLastName()}`,
          operatorId: this.$user.getId(),
          jobs: this.cachedSelectedRows.map(job => {
            return {
              orderId: job._id,
              productId: job.product._id,
              workcenterId: job.product.workcenters._id,
              workcenterName: job.product.workcenters.name,
              teamName: job.product.workcenters.team,
              status: 'ORDER_WORK'
            }
          })
        }
        if (payload.jobs.length) {
          await this.$work.startJob(payload).then(() => {
            this.$cardReset('my-jobs')
            this.$cardReset('production-table')
            this.clearSelected()
          }).catch(err => this.$err.show(err))
        } else {
          if (this.$can(['action', 'StartSingleJob'])) {
            this.$bvModal.show('starting-single-workcenter-job-modal')
          } else {
            this.$err.show('Vali vähemalt üks töökeskus, et alustada tööd!')
          }
        }
        this.$loader.stop('production-table')
      }
    }
  }
</script>
<style lang="scss">
  .table-responsive table .faf-table-row-fixed {
    position: sticky;
    right: 0;
    background: #27293d;
    padding-left: 10px;
  }
  .white-content .table-responsive table .faf-table-row-fixed {
    background: #fafafa;
  }
  .production-table .table > tbody > tr > td:nth-child(2) {
    padding-right: 10px;
  }
</style>
