<template>
  <div>
    <faf-card name="my-jobs" title="Tööaja tabel">
      <b-table
        v-if="operators.length"
        :fields="fields"
        :items="operators"
        stacked="xl"
        striped
        bordered
        no-collapse
        head-variant="light"
        hover
      />
      <p v-else>
        Pole ühtegi aktiivset tööd
      </p>
    </faf-card>
  </div>
</template>
<script>
  export default {
    name: 'worktime-table',
    data () {
      return {
        operators: [],
        fields: []
      }
    },
    async created () {
      this.operators = (await this.$work.workTimeTable(this.$user.getId())).data
    }
  }
</script>
