const documents = [
  // SALES
  "WORKORDER",
  "PRODUCT_WORKORDER",
  "SALES_PREPAYMENT",
  "SALES_PRICE_INQUIRY",
  "SALES_BILL_OF_DELIVERY",
  "SALES_ORDER_CONFIRMATION",
  // PURCHASE
  "PURCHASE_PRICE_INQUIRY",
  "PURCHASE_ORDER",
  // PRODUCTION
  "PRODUCT_SHEET",
];

const orderFields = [
  "material-width",
  "salesSystemNumber",
  "catalogue",
  "object",
  "setup-time",
  "linkedMaterials",
  "linkedMaterialsInProduction",
  "statusOffer",
  "readyProducts",
  "WorkSheetMissing",
  "recommendedPrice",
];

const action = [
  "ProductStopped",
  "Filter",
  "FilterDoneProducts",
  "FilterSuppliers",
  "FilterWorksheet",
  "StartSingleJob",
  "FilterClients",
  "FilterSubStatus",
  "FilterWorkcenterWithLinkedMaterialsParameters",
  "FilterPerPage",
  "CopyTrackingLink",
  "chooseDeliveryTimeInDays",
  "chooseValidTimeInDays",
  "DownloadSalesExcel",
  "leftoverPercentage",
  "automatedEmailSending",
];

const orderSubStatus = [
  "OFFER_PREPARED",
  "OFFER_EMAIL_SENT",
  "ORDER_PACKED",
  "ORDER_EMAIL_SENT",
  "ORDER_SENT",
  "PRODUCTION_ORDER",
];

const statistics = [
  "WORK_TIME",
  "PRODUCTIVITY_DETAILED",
  "SALES_CAPACITY",
  "PLAN_EXECUTION",
];

export const plasmaProPrivileges = {
  admin: {
    see: [
      "Tootmine",
      "Müük",
      "Sätted",
      "Kasutajad",
      "Minu asjad",
      "Ost",
      "Tellimused",
      "Juhtimissüsteem",
      "detailedProject",
      "Supplier",
      "Client",
      "FileManager",
      "Material",
      "Workcenter",
      "documentSubTypes",
      "AjanImport",
      "Kvaliteediaruanded",
      "Statistics",
      "documentAccessTypeManagement",
      "EmailSending",
      "Reports"
    ],
    add: [
      "Kasutaja",
      "Müük",
      "Ost",
      "Supplier",
      "WorksheetCertificate",
      "Client",
      "Material",
      "Workcenter",
    ],
    action,
    orderFields,
    documents,
    orderSubStatus,
    statistics,
  },
  manager: {
    see: [
      "Tootmine",
      "Müük",
      "Sätted",
      "Minu asjad",
      "Ost",
      "Tellimused",
      "Juhtimissüsteem",
      "detailedProject",
      "Supplier",
      "Client",
      "FileManager",
      "Material",
      "Workcenter",
      "documentSubTypes",
      "AjanImport",
      "Kvaliteediaruanded",
      "Statistics",
      "documentAccessTypeManagement",
      "EmailSending",
      "Reports",
    ],
    add: [
      "Müük",
      "Ost",
      "Supplier",
      "WorksheetCertificate",
      "Client",
      "Material",
      "Workcenter",
    ],
    action,
    orderFields,
    documents,
    orderSubStatus,
    statistics,
  },
  employee: {
    see: [
      "Tootmine",
      "Minu asjad",
      "FileManager",
      "documentSubTypes",
      "AjanImport",
      "Juhtimissüsteem",
      "Statistics",
    ],
    add: ["WorksheetCertificate"],
    action,
    orderFields,
    documents,
    orderSubStatus,
    statistics: ["PRODUCTIVITY_DETAILED", "PLAN_EXECUTION"],
  },
  BASIC: {},
};
