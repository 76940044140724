<template>
  <div>
    <b-table
      v-if="items.length"
      small
      responsive
      hover
      striped
      sticky-header="600px"
      :items="items"
      :fields="fields"
      :tbody-tr-class="rowClass"
      class="reports-table"
      :class="{ 'table-sticky-dark': this.$user.getTheme() === 'dark' }"
    >
      <template #cell(materialNetoWeight)="data">
        {{ data.value ? data.value.toFixed(2) : 0 }} kg ({{ data.item.materialNetoWeightSquare ? data.item.materialNetoWeightSquare.toFixed(2) : ' - ' }} m<sup>2</sup>)
      </template>
      <template #cell(materialBrutoWeight)="data">
        {{ data.value ? data.value.toFixed(2) : 0 }} kg ({{ data.item.materialBrutoWeightSquare ? data.item.materialBrutoWeightSquare.toFixed(2) : ' - ' }} m<sup>2</sup>)
      </template>
    </b-table>
    <b-skeleton-table
      v-else
      :rows="25"
      :columns="9"
      :table-props="{ striped: true }"
      animation="wave"
    />
  </div>
</template>
<script>
  export default {
    name: 'product-report-table',
    components: {
    },
    props: {
      filters: Object,
      companyPlasmaPro: Boolean
    },
    data () {
      return {
        fields: [
          {
            key: 'salesSystemNumber',
            label: 'ID',
            tdClass: "text-nowrap"
          },
          {
            key: 'products.materials.name',
            label: 'Materjali nimetus',
            tdClass: "text-nowrap"
          },
          {
            key: 'materialNetoWeight',
            label: 'Neto kogus',
            tdClass: "text-nowrap"
          },
          {
            key: 'materialBrutoWeight',
            label: 'Bruto kogus',
            tdClass: "text-nowrap"
          },
          {
            key: 'materialMedianPrice',
            label: 'Hind',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' €/kg' : '0 €/kg'
          },
          {
            key: 'materialTotalPrice',
            label: 'Summa',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' €' : '0 €'
          },
          {
            key: 'materialRemains',
            label: 'Jääk',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' kg' : '0 kg'
          },
          {
            key: 'remainsPrice',
            label: 'Hind',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' €/kg' : '0 €/kg'
          },
          {
            key: 'materialRemainsTotalPrice',
            label: 'Summa',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' €' : '0 €'
          }
        ],
        items: []
      }
    },
    async created () {
      await this.generateReport()
    },

    methods: {
      rowClass (item, type) {
        if (!item || type !== 'row') return
        if (item.accent) return 'row-accent'
      },
      async generateReport () {
        const filters = {
          ...this.filters.status && { status: this.filters.status },
          ...this.filters.startDate && { startDate: this.filters.startDate },
          ...this.filters.endDate && { endDate: this.filters.endDate },
          ...this.filters.salesSystemNumber && { salesSystemNumber: this.filters.salesSystemNumber },
          ...this.filters.orderId && { orderId: this.filters.orderId },
          ...this.filters.projectName && { projectName: this.filters.projectName },
          ...this.filters.client && { client: this.filters.client },
          ...this.filters.productName && { 'products.name': this.filters.productName },
          ...this.filters.materialName && { 'products.materials.name': this.filters.materialName },
          ...this.filters.catalogue && { client: this.filters.catalogue },
          ...this.filters.object && { client: this.filters.object },
        }

        this.items = (await this.$reports.getMaterialReport(filters)).data
      }
    }
  }
</script>
<style lang="scss">
.row-accent {
  font-weight: 800;
  & + tr td {
    border-color: rgba(255, 255, 255, 0.5)!important;
    padding-top: 10px!important;
  }
}
</style>
