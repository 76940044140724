<template>
  <div>
    <b-modal
      :visible="!!noteToEdit"
      title="Lisa või muuda"
      @hide="close"
    >
      <b-form-group
        :state="noteState"
        label="Märkmed"
        label-for="notes"
      >
        <b-form-textarea
          v-validate="'max:55'"
          id="notes"
          v-bind="$requiredInputProps('notes')"
          v-model.trim="notes"
          :state="noteState"
          name="notes"
          required
        />
        <b-form-invalid-feedback v-if="!$validateField('notes')">
          Väljal võib olla kuni 55 tähemärki
        </b-form-invalid-feedback>
      </b-form-group>

      <div slot="modal-footer" class="container">
        <div class="row justify-content-end">
          <div class="col col-md-4 pl-0 pr-1">
            <b-button
              block
              variant="text-danger"
              size="m"
              class="w-100"
              @click="close"
            >
              Tagasi
            </b-button>
          </div>
          <div class="col col-md-4 pr-0 pl-1">
            <b-button
              :disabled="loading"
              block
              variant="primary"
              size="m"
              class="w-100"
              @click="submit"
            >
              <b-spinner v-if="loading" tag="div" small />
              <span v-else>Lisa</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  export default {
    name: 'faf-add-note',
    props: {
      loading: Boolean,
      noteToEdit: Object
    },
    data () {
      return {
        notes: '',
        noteState: null
      }
    },
    watch: {
      noteToEdit (val) {
        val && (this.notes = val.notes)
      }
    },
    methods: {
      submit () {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return
          }
          this.$emit('submit', this.notes)
        })
      },
      close () {
        this.$emit('close')
      }
    }
  }

</script>
