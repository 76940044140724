<template>
  <div>
    <faf-card name="management-system-reports" title="Juhtimissüsteem">
      <faf-management-system-reports-table />
      <template slot="buttons">
        <b-button
          id="add-new-management-system-report"
          v-can="['add', 'Supplier']"
          v-b-modal.add-edit-management-system-reports
          v-b-tooltip.hover
          class="icon-button text-success"
          title="Lisa uus juhtimissüsteemi dokument"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-button>
      </template>
    </faf-card>
  </div>
</template>
<script>
  import { FafManagementSystemReportsTable } from '@faf/faf-ui'
  export default {
    name: 'management-system-reports',
    components: {
      FafManagementSystemReportsTable
    },
    methods: {

    }
  }
</script>
<style lang="scss" scoped>
  .with-bottom-bar {
    margin-bottom: 80px;
  }
</style>
