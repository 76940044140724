import errorTexts from './../../../Globals/errorTexts'
export default function installErr (Vue) {
  if (Vue) {
    Vue.prototype.$err = {
      _vm: new Vue({ data: {
        notificationMessage: null,
        title: '',
        callback: () => ''
      }}),
      show (message = 'System failure - please try again', callback = () => '', title = '') {
        try {
          const isString = typeof message === 'string'
          this._vm.$data.notificationMessage = isString ? message : getErrorText(message.response.data)
          this._vm.$data.title = title
          this._vm.$data.callback = callback
        } catch (error) {
          console.error(message)
          console.error(error)
        }
      },
      hide () {
        this._vm.$data.notificationMessage = null
        this._vm.$data.callback()
      },
      isActive () {
        return this._vm.$data.notificationMessage ? true : false
      },
      getMessage () {
        return this._vm.$data.notificationMessage
      },
      getTitle () {
        return this._vm.$data.title
      }
    }
  } else {
    console.error('Vue not found')
  }
}

function getErrorText (errorObj) {
  return errorTexts[errorObj.code] || errorObj.message || 'System failure - please try again: ' + JSON.stringify(errorObj)
}
