export default function installNotify (Vue) {
  if (Vue) {
    Vue.prototype.$notify = {
      _vm: new Vue({ data: {
        notificationMessages: [],
        callback: () => ''
      }}),
      show (message, callback = () => '') {
        const m = message || 'Tegevus oli edukas'
        const id = Math.random().toString(16).slice(-4)
        this._vm.$data.notificationMessages.push({ id, m, class: '' })
        const found = this._vm.$data.notificationMessages.find(n => n.id === id)
        setTimeout(() => {
          found.class = 'enter-active'
        }, 100)
        this._vm.$data.callback = callback
        setTimeout(() => {
          found.class = 'leave-active'
        }, 5500)
        setTimeout(() => {
          found && this._vm.$data.notificationMessages.splice(this._vm.$data.notificationMessages.indexOf(found), 1)
        }, 6000)
      },
      hide (id) {
        const found = this._vm.$data.notificationMessages.find(n => n.id === id)
        found && this._vm.$data.notificationMessages.splice(this._vm.$data.notificationMessages.indexOf(found), 1)
        this._vm.$data.callback()
      },
      getMessages () {
        return this._vm.$data.notificationMessages
      }
    }
  } else {
    console.error('Vue not found')
  }
}
