import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const getQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `${field}=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getClient (query) {
  return axios({
    url: `/api/client?${getQuery(query)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getAllClients (query) {
  return axios({
    url: `/api/client/find?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getClientsNames (filters) {
  return axios({
    url: `/api/client/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addClient (data) {
  return axios({
    url: `/api/client`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateClient (id, data) {
  return axios({
    url: `/api/client/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$clients = {
    getClient,
    getAllClients,
    addClient,
    getClientsNames,
    updateClient
  }
}

export {
  getClient,
  getAllClients,
  addClient,
  getClientsNames,
  updateClient
}
