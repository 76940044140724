<template>
  <div>
    <faf-filters :fields="filterFields" :cols="4" @search-change="searchChange" @filter="getProducts({ filters: $event })" />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('products-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button v-b-tooltip.hover v-b-tooltip.left class="icon-button text-danger" title="Kustuta" @click="deleteProduct(data.item._id)">
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getProducts({ page: $event })"
    />
  </div>
</template>
<script>
  export default {
    name: 'products-table',
    data () {
      return {
        addProductModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Nimi'
          },
          {
            key: 'price',
            label: 'Hind',
            formatter: (value, key, item) => value + ' €'
          },
          {
            key: 'surfaceTreatment',
            label: 'Viimistlus'
          },
          {
            key: 'materials',
            label: 'Materjalid',
            formatter: (value, key, item) => this.getNames(value)
          },
          {
            key: 'workcenters',
            label: 'Töökeskused',
            formatter: (value, key, item) => this.getNames(value)
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Toote nimi', options: [] }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getProducts()
    },
    methods: {
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getProductNames({ [e.name]: e.event })).data
      },
      async getProducts (event) {
        this.$loader.start('products-table')
        const page = (event && event.page) || this.currentPage
        const { data } = await this.$products.getAllProducts({ page, limit: this.perPage, filters: (event && event.filters) })
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('products-table')
      },
      async deleteProduct (_id) {
        await this.$products.deleteProduct(_id).then(() => {
          this.getProducts()
        }).catch(err => this.$err.show('Midagi läks valesti.'))
      },
      getNames (items) {
        return items.map(item => item.name).join(', ')
      }
    }
  }
</script>
