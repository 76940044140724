var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-button",class:{
      'text-dark disabled': !_vm.product.name,
      'text-info': !_vm.prefilled && _vm.product.name,
      'text-success': _vm.prefilled
    },attrs:{"title":"Eeltäida toode"},on:{"click":_vm.prefillProduct}},[_c('font-awesome-icon',{attrs:{"icon":_vm.prefilled ? 'check-circle' : 'file-signature'}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-button",class:{
      'text-info': !_vm.favourite,
      'text-primary': _vm.favourite
    },attrs:{"disabled":_vm.disabled,"title":"Salvesta toode"},on:{"click":_vm.saveFavouriteProduct}},[_c('font-awesome-icon',{attrs:{"icon":"heart"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }