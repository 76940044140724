<template>
  <faf-sales-table />
</template>
<script>
  import { FafSalesTable } from '@faf/faf-ui'

  export default {
    name: 'sales',
    components: {
      FafSalesTable
    }
  }
</script>
