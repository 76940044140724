<template>
  <b-modal :id="modalName" title="Alusta tööd" @hide="$emit('hide')">
    <b-container class="single-workshop-modal">
      <b-row class="justify-content-md-center">
        <b-form-group>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
          />
        </b-form-group>
      </b-row>
      <zoom-center-transition key="workcenterName" :duration="400" :delay="30">
        <b-row v-if="selected.includes('WORKCENTER')" align-h="center">
          <b-col class="justify-content-md-center mt-2" lg="7">
            <label for="workcenter">Vali töökeskus</label>
            <multiselect
              v-validate="'required'"
              v-model="selectedWorkcenter"
              :options="workcenterSuggestions"
              v-bind="$multiselectProps('workcenterName')"
              @input="onWorkcenterSelect"
            />
            <b-form-invalid-feedback v-if="!$validateField('workcenterName')">
              Väli on nõutud
            </b-form-invalid-feedback>
          </b-col>
          <b-col class="justify-content-md-center mt-2" lg="7">
            <label for="workcenter">Vali tiim</label>
            <multiselect
              v-validate="'required'"
              v-model="selectedTeam"
              :options="teamNameSuggestions"
              v-bind="$multiselectProps('teamName')"
            />
            <b-form-invalid-feedback v-if="!$validateField('workcenterName')">
              Väli on nõutud
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </zoom-center-transition>
      <zoom-center-transition key="otherWork" :duration="400" :delay="30">
        <b-row v-if="selected.includes('OTHER_WORK')" align-h="center">
          <b-col class="justify-content-md-center mt-2" lg="7">
            <label for="otherWork">Vali abitöö</label>
            <multiselect
              v-validate="'required'"
              v-model="selectedOtherWork"
              :options="otherWorkSuggestions"
              v-bind="$multiselectProps('otherWork')"
              label="name"
            />
            <b-form-invalid-feedback v-if="!$validateField('otherWork')">
              Väli on nõutud
            </b-form-invalid-feedback>
          </b-col>
          <b-col :cols="12">
            <b-alert :show="selectedOtherWork && selectedOtherWork.value === 'PROOF_CORRECTIONS'" class="mt-3">
              Raporteerimise parandustega soovitame valida ka töökeskuse, et õige töökeskuse alla parandus läheks. Vastasel juhul liigitub parandus raportites "Muu" all.
            </b-alert>
          </b-col>
        </b-row>

      </zoom-center-transition>
    </b-container>
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide(modalName)">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover class="icon-button text-success" title="Alusta tööd" @click="startJob">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import { ZoomCenterTransition } from 'vue2-transitions'
  import otherWorkSuggestions from './helpers/otherWorkSuggestions'

  export default {
    name: 'starting-single-workcenter-job',
    components: { ZoomCenterTransition },
    props: {
      modalName: {
        type: String,
        default: 'starting-single-workcenter-job-modal'
      }
    },
    data () {
      return {
        selectedWorkcenter: null,
        selectedTeam: null,
        selectedOtherWork: null,
        workcenterSuggestions: [],
        teamNameSuggestions: [],
        otherWorkSuggestions: otherWorkSuggestions(),
        selected: [],
        options: [
          { text: 'Töökeskus', value: 'WORKCENTER' },
          { text: 'Abitöö', value: 'OTHER_WORK' }
        ]
      }
    },
    watch: {
      selected (value) {
        if (!value.includes('WORKCENTER')) this.selectedWorkcenter = null
        if (!value.includes('OTHER_WORK')) this.selectedOtherWork = null
      }
    },
    async created () {
      this.workcenterSuggestions = (await this.$suggestions.getWorkcenterNames()).data
      this.teamNameSuggestions = (await this.$suggestions.getTeamNames()).data
    },
    methods: {
      async onWorkcenterSelect (event) {
        const { data } = await this.$workcenters.getWorkcenters({filters: {name: event}})
        this.selectedTeam = this.$_get(data, 'docs[0].team', null)
      },
      async startJob () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('production-table')
          const payload = {
            operatorName: `${this.$user.getFirstName()} ${this.$user.getLastName()}`,
            operatorId: this.$user.getId(),
            jobs: this.selected.length ? [{
              workcenterName: this.selectedWorkcenter || null,
              status: this.$_get(this.selectedOtherWork, 'value', 'WORK'),
              teamName: this.selectedTeam
            }] : []
          }
          if (payload.jobs.length) {
            await this.$work.startJob(payload).then(() => {
              this.$cardReset('my-jobs')
              this.$cardReset('production-table')
            }).catch(err => this.$err.show(err))
          } else {
            this.$err.show('Vali vähemalt üks, et alustada tööd!')
          }
          this.$loader.stop('production-table')
        }
      }
    }
  }
</script>
