<template>
  <div>
    <faf-filters :fields="filterFields" :cols="4" @search-change="searchChange" @filter="getAllClients({ filters: $event })" />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('clients-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4"
            title="Vaata/Muuda"
            @click="$refs['add-edit-client'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getAllClients({ page: $event })"
    />
    <add-edit-client ref="add-edit-client" :key="addClientModal" @client-added="getAllClients()" @hide="addClientModal = !addClientModal" />
  </div>
</template>
<script>
  import AddEditClient from './AddEditClient'

  export default {
    name: 'supplies-table',
    components: {
      AddEditClient
    },
    data () {
      return {
        addClientModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Firma'
          },
          {
            key: 'address',
            label: 'Aadress'
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Firma nimi', options: [] }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getAllClients()
    },
    methods: {
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getClientNames({ [e.name]: e.event })).data
      },
      async getAllClients (event) {
        this.$loader.start('clients-table')
        const page = (event && event.page) || this.currentPage
        const { data } = await this.$clients.getAllClients({page, limit: this.perPage, filters: (event && event.filters)})
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('clients-table')
      }
    }
  }
</script>
