import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function send (data) {
  return axios({
    url: `/mailing-service/v1/send`,
    method: 'POST',
    data,
    headers: {
      Authorization: token()
    }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$email = {
    send
  }
}

export {
  send
}
