<template>
  <div class="p-3 faf-big-chart stats-title">
    <div class="stats-title-wrapper">
      <h5 class="mb-0">
        <strong><span class="text-uppercase">{{ title }}</span><span v-if="subTitle">{{ subTitle }}</span></strong>
      </h5>
      <h2 :class="'mb-2 text-' + color">
        <strong>{{ formattedTotal }} {{ afterLabel }}</strong>
      </h2>
    </div>
    <div v-if="multipleCharts" class="stats-title-wrapper">
      <h5 class="mb-0">
        <strong><span class="text-uppercase">{{ label[1] || title }}</span><span v-if="subTitle">{{ subTitle }}</span></strong>
      </h5>
      <h2 :class="'mb-2 text-' + secondaryColor">
        <strong>{{ formattedSecondaryTotal }} {{ afterLabel }}</strong>
      </h2>
    </div>
    <div class="chart-area">
      <bar-chart
        v-if="chartData.datasets[0].data && !loading && !toBeUpdated && !error"
        :chart-data="chartData"
        :extra-options="extraOptions"
        style="height: 100%"
      />
      <!-- <line-chart
        v-if="chartData.datasets[0].data && !loading && !toBeUpdated && multipleCharts"
        :chart-data="chartData"
        :extra-options="extraOptions"
        style="height: 100%"
      /> -->
      <b-spinner v-if="loading && !toBeUpdated && !error" variant="primary" label="Laeb..." style="margin: auto;" />
      <p v-if="toBeUpdated" class="to-be-updated">
        Varsti tulekul...
      </p>
      <p v-if="error" class="to-be-updated">
        Midagi läks valesti. Proovi mõne aja pärast uuesti.
      </p>
    </div>
  </div>
</template>

<script>
  import config from '../../config';
  import BarChart from './components/BarChart'
  //import LineChart from './components/LineChart'
  import { days, extraOptions } from './components/utils'

  export default {
    name: 'faf-big-chart',
    components: {
      BarChart
      //LineChart
    },
    props: {
      title: String,
      subTitle: String,
      total: [String, Number],
      secondaryTotal: [String, Number],
      data: Array,
      color: {
        type: String,
        default: 'primary',
        validator: (val) => ['default', 'primary', 'success', 'info', 'warning', 'danger', 'black', 'primaryGradient'].includes(val)
      },
      secondaryColor: {
        type: String,
        default: 'primary',
        validator: (val) => ['default', 'primary', 'success', 'info', 'warning', 'danger', 'black', 'primaryGradient'].includes(val)
      },
      label: [String, Array],
      afterLabel: {
        type: String,
        default: ''
      },
      labels: {
        type: Array,
        default: () => days
      },
      additionalLabels: {
        type: Array,
        default: () => []
      },
      loading: Boolean,
      toBeUpdated: Boolean,
      error: Boolean,
      type: {
        type: String,
        default: 'default',
        validator: (val) => ['default', 'time', 'money'].includes(val)
      }
    },
    data () {
      return {
        extraOptions: {
          ...extraOptions,
          tooltips: {
            callbacks : {
              label: tooltipItem => {
                this.multipleCharts
                const start = `${ this.multipleCharts ? (this.label[tooltipItem.datasetIndex] || this.title) : (this.label || this.title) }: `
                if (this.type === 'time') {
                  return `${ start }${ tooltipItem.value.replace('.', ' tundi ') }${ this.afterLabel }`
                } else if (this.type === 'money') {
                  return `${ start }${(+tooltipItem.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') }${ this.afterLabel }`
                } else {
                  return `${ start }${ tooltipItem.value }${ this.afterLabel }`
                }
              },
              footer: tooltipItem => {
                return `${ this.additionalLabels[tooltipItem[0].index] || '' }`
              }
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false
              }
            }]
          }
        },
        emptyDataSet: {
          label: this.label,
          fill: true,
          borderColor: config.colors[this.secondaryColor],
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors[this.secondaryColor],
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: config.colors[this.secondaryColor],
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [0, 0, 0, 0, 0, 0, 0]
        },
        chartData: {
          labels: days,
          datasets: [{
            label: this.label,
            fill: true,
            borderColor: config.colors[this.color],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors[this.color],
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors[this.color],
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [0, 0, 0, 0, 0, 0, 0]
          }]
        }
      }
    },
    computed: {
      formattedTotal () {
        if (this.type === 'time') {
          return (this.total + '').replace('.', ' tundi ')
        } else if (this.type === 'money') {
          return (+this.total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        } else {
          return this.total
        }
      },
      formattedSecondaryTotal () {
        if (this.type === 'time') {
          return (this.secondaryTotal + '').replace('.', ' tundi ')
        } else if (this.type === 'money') {
          return (+this.secondaryTotal).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        } else {
          return this.secondaryTotal
        }
      },
      multipleCharts () {
        return this.data && Array.isArray(this.data[0])
      }
    },
    watch: {
      data (val) {
        this.chartData.datasets = [ this.$_cloneDeep(this.emptyDataSet) ]
        let numberArray = null
        if (this.multipleCharts) {
          this.chartData.datasets.push(this.$_cloneDeep(this.emptyDataSet))

          numberArray = [...this.data[0].map(Number), ...this.data[1].map(Number)]
        } else {
          numberArray = this.data.map(Number)
        }

        this.chartData.datasets[0].data = null

        this.updateColor()

        this.extraOptions.scales.yAxes[0].ticks.max = Math.max(...numberArray) + 0.5
        this.extraOptions.scales.yAxes[0].ticks.min = Math.min(...numberArray) - 0.5

        if (Math.max(...numberArray) > 100) {
          this.extraOptions.scales.yAxes[0].ticks.max = Math.max(...numberArray)
        }
        if (this.type === 'default' && Math.max(...numberArray) < 100) {
          this.extraOptions.scales.yAxes[0].ticks.max = 100
        }
        this.updateChart()
      }
    },
    created () {
      this.updateChart = this.$_debounce(this.updateChart, 100)
    },
    methods: {
      updateColor () {
        this.chartData.datasets[0].borderColor = config.colors[this.color]
        this.chartData.datasets[0].pointBackgroundColor = config.colors[this.color]
        this.chartData.datasets[0].pointHoverBackgroundColor = config.colors[this.color]
      },
      updateChart () {
        if (this.multipleCharts) {
          this.data.map((d, i) => {
            this.chartData.datasets[i].data = d
          })
        } else {
          this.chartData.datasets[0].data = this.data
        }
        this.chartData.labels = this.labels
      }
    }
  }
</script>
<style lang="scss" scoped>
  .faf-big-chart {
    border: 1px solid rgba(240, 248, 255, 0.2);
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);

    .stats-title-wrapper {
      width: 50%;
      display: inline-block;

      h2 {
        margin-bottom: 0 !important;
      }
    }

    .chart-area {
      height: 325px;
      margin: 20px 5px 5px;
      position: relative;

      .spinner-border {
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        bottom: 0;
      }

      .to-be-updated {
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        line-height: 325px;
        background-color: rgba(30, 30, 47, 0.3);
      }
    }
  }
</style>
