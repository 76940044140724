import get from 'lodash/get'
export {
  cleanUpOrder,
  cleanUpProduct,
  cleanUpWorkcenters,
  cleanUpMaterials
}

async function cleanUpOrder (order) {
  return {
    client: order.client,
    projectName: order.projectName,
    deliveryDate: order.deliveryDate,
    notes: order.notes,
    products: order.products.map(p => cleanUpProduct(p))
  }
}

function cleanUpProduct (p) {
  return {
    name: p.name,
    price: p.price,
    surfaceTreatment: p.surfaceTreatment,
    notes: p.notes,
    quantity: {
      amount: get(p, 'quantity.amount')
    },
    materials: cleanUpMaterials(p.materials),
    workcenters: cleanUpWorkcenters (p.workcenters)
  }
}

function cleanUpWorkcenters (workcenters) {
  return workcenters.map(w => ({
    name: w.name,
    queue: w.queue,
    team: w.team,
    workTime: w.workTime,
    setupTime: w.setupTime || 0,
    unit: w.unit || 'min',
    leadTime: w.leadTime,
    price: w.price,
    notes: w.notes
  }))
}

function cleanUpMaterials (materials) {
  return materials.map(m => ({
    name: m.name,
    description: m.description,
    width: m.width,
    diagram: m.diagram,
    diagramNumber: m.diagramNumber,
    detailName: m.detailName,
    size: m.size,
    actualSize: m.actualSize,
    unit: m.unit,
    specialWeight: m.specialWeight,
    amount: m.amount,
    price: m.price,
    notes: m.notes
  }))
}
