import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const getQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `${field}=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getSupplier (query) {
  return axios({
    url: `/api/supplier?${getQuery(query)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getSuppliers (query) {
  return axios({
    url: `/api/supplier/find?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getSuppliersNames (filters) {
  return axios({
    url: `/api/supplier/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addSupplier (data) {
  return axios({
    url: `/api/supplier`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateSupplier (id, data) {
  return axios({
    url: `/api/supplier/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$suppliers = {
    getSupplier,
    getSuppliers,
    addSupplier,
    getSuppliersNames,
    updateSupplier
  }
}

export {
  getSupplier,
  getSuppliers,
  addSupplier,
  getSuppliersNames,
  updateSupplier
}
