<template>
  <div class="flex-page wrapper">
    <div class="side-nav">
      <side-bar>
        <template slot="links">
          <sidebar-link v-for="(link , index) in $sideMenuLinks" :key="index" :to="link.path" :name="link.name" :icon="link.icon"/>
        </template>
      </side-bar>
    </div>
    <div :class="{ loading : $loader.val() }" class="loader" />
    <top-navbar/>
    <dashboard-content/>
  </div>

</template>
<style lang="scss">
</style>
<script>
  import TopNavbar from "./Navbar.vue";
  import DashboardContent from "./Content.vue";

  export default {
    components: {
      TopNavbar,
      DashboardContent
    },
    methods: {
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      }
    }
  };
</script>
<style lang="scss">
  // Animate loading
  @import './../assets/sass/black-dashboard/custom/variables';

  .loader {
    height: 2px;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 99999;
  }

  .loader:before {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: $primary;
  }

  .white-content {
    .loader {
      height: 3px;
    }

    .loader:before {
      height: 3px;
      background-color: $success;
    }
  }

  .loader.loading:before {
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    from {
      left: -200px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    80% {
      left: 50%;
    }
    to {
      left: 100%;
    }
  }

  .custom-control-label:after {
    border: 1px solid #2b3553;
    border-radius: 5px;
  }

  .custom-control.custom-radio .custom-control-label:after {
    border-radius: 50%;
  }

  .flex-page {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }

  // Side Navigation
  .side-nav {
    padding-top: 90px;
  }

  // Content
  .content {
    flex-grow: 1;
    padding: 2rem;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  html,
  body {
    -webkit-overflow-scrolling: touch !important;
    overflow: hidden !important;
  }

  body.fixed-nav {
    padding-top: 9px;
  }

  .content-wrapper {
    width: 100%;
    height: auto !important;
    min-height: calc(100vh - 56px);
    padding-top: 1rem;
  }

  .sidenav-toggled #mainNav.fixed-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }

  #mainNav.static-top .sidenav-toggler {
    display: none;
  }

  @media (min-width: 992px) {
    #mainNav.static-top .sidenav-toggler {
      display: flex;
    }
  }

  .sidenav-toggled #mainNav.static-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }

  .content-wrapper {
    overflow: scroll !important;
    margin-top: 60px;
    padding-bottom: 100px;
  }

  @media (min-width: 992px) {
    .content-wrapper {
      padding-bottom: 0px;
      margin-left: 75px;
    }
  }
</style>
