export default {
  data () {
    return {
      statusTranslations: {
        ORDER: 'Tootmises',
        IN_PRODUCTION: 'Tootmises',
        COMPLETE: 'Tehtud',
        ANNULLED: 'Tühistatud',
        PRODUCTION_READY: 'Valmis tootmiseks',
        INCOMPLETE: 'Ootab ostu taga'
      },
      materialFields: [
        {
          key: 'name',
          label: 'Nimi',
          sortable: true
        },
        {
          key: 'diagram',
          label: 'Joonis',
          sortable: true
        },
        (this.$can(['orderFields', 'material-width']) && {
          key: 'width',
          label: 'Paksus',
          sortable: true
        }),
        {
          key: 'description',
          label: 'Kirjeldus',
          sortable: true
        },
        {
          key: 'size',
          label: 'Mõõt/kaal',
          sortable: true,
          formatter: (value, key, item) => `${(+value).toFixed(2)} ${item.unit}`
        },
        {
          key: 'actualSize',
          label: 'Tegelik mõõt/kaal',
          sortable: true,
          formatter: (value, key, item) => `${(+value).toFixed(2)} ${item.unit}`
        },
        {
          key: 'specialWeight',
          label: 'Erikaal',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Kogus',
          sortable: true,
          formatter: (value, key, item) => value + ' tk'
        },
        {
          key: 'notes',
          label: 'Märkmed'
        }
      ]
    }
  }
}
