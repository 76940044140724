import Ajv from 'ajv'
import get from 'lodash/get'

// Ühe toote materjalide arvutus

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['product', 'type'],
  properties: {
    product: {
      type: 'object',
      required: ['materials', 'quantity'],
      properties: {
        materials: {
          type: 'array'
        },
        quantity: {
          type: 'object',
          required: ['amount'],
          properties: {
            amount: { type: 'number' }
          }
        }
      }
    },
    type: {
      type: 'string',
      enum: [
        'size', 'actualSize'
      ]
    }
  }
}

const test = ajv.compile(schema)

export default async function _getProductMaterialCost (product, type = 'actualSize') {
  if (!test({product, type})) {
    throw { function: '_getProductMaterialCost', error: test.errors }
  }
  if (product.materials.length <= 0) {
    return {
      amount: '0.00',
      price: '0.00',
      total: '0.00'
    }
  }

  const materials = product.materials

  const calc = materials.reduce((a, material) => {
    const sum = get(product, 'quantity.amount', 1) * +material.amount
    const special = get(material, 'specialWeight', 1)
    return {
      amount: a.amount + (+material[type] * +special * sum),
      total: a.total + (material[type] * +special * sum * (material.price || 0.00001))
    }
  }, { amount: 0, price: 0, total: 0 })

  const price = +calc.total / +calc.amount

  return {
    amount: calc.amount.toFixed(2),
    price: price.toFixed(2),
    total: calc.total.toFixed(2)
  }
}
