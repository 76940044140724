export default function installValidation (Vue) {
  if (Vue) {

    // To use validation, when writing on input add these values:
    // v-validate="'required|min:6'"
    // :state="$validateField('field-name')"
    // data-vv-delay="500" <- optional
    // and add <b-form-invalid-feedback>Error</b-form-invalid-feedback> after input

    Vue.prototype.$validateField = function (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return true
    }
  } else {
    console.error('Vue not found')
  }
}
