import Ajv from 'ajv'
import get from 'lodash/get'

const ajv = new Ajv({ allErrors: true })

// Kõikide toodete seadistusaeg

const schema = {
  type: 'object',
  required: ['order'],
  properties: {
    order: {
      type: 'object',
      required: ['product'],
      properties: {
        product: {
          type: 'object',
          required: ['workcenters', 'quantity'],
          properties: {
            workcenters: {
              type: 'object',
              required: ['workTime']
            },
            quantity: {
              type: 'object',
              required: ['amount']
            }
          }
        }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getWorkcenterProductivity (order) {
  if (!test({order})) {
    throw { function: '_getWorkcenterProductivity', error: test.errors }
  }

  const workTime = get(order, 'product.workcenters.workTime', 0.00001)
  const amount = get(order, 'product.quantity.amount', 1)
  const doneProducts = get(order, 'product.workcenters.doneProducts', 0)
  const activeTime = get(order, 'product.workcenters.activeTime', 0)
  const setupTime = get(order, 'product.workcenters.setupTime', 0)
  const totalWorkTime = ((+workTime * +amount) + setupTime)
  const result = ( (+totalWorkTime / +amount * +doneProducts) / +activeTime ) * 100

  return Number.isFinite(result) ? result.toFixed(2) : null
}
