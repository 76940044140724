<template>
  <b-row class="my-4 display-flex">
    <b-col cols="6">
      <h5 class="text-gray mb-0">
        <strong>OMAHINNA KALKULATSIOON</strong>
      </h5>
      <b-table
        v-if="projectCalculation.length"
        :items="projectCalculation"
        class="hide-header"
        striped
        hover
      />
      <b-skeleton-table
        v-else
        :rows="7"
        :columns="1"
        :table-props="{ striped: true }"
      />
      <b-table
        :fields="projectCostsFields"
        :items="projectCosts"
        striped
        hover
      />
    </b-col>
    <b-col cols="6">
      <h5 class="text-gray mb-0">
        <strong>JÄRELKALKULATSIOON</strong>
      </h5>
      <b-table
        v-if="afterCalculation.length"
        :items="afterCalculation"
        class="hide-header"
        striped
        hover
      />
      <div v-else-if="afterCalculationImpossible">
        <p class="to-be-updated">
          Hetkel pole tööd alustatud. Alusta tööd, et näha järelkalkulatsiooni.
        </p>
      </div>
      <b-skeleton-table
        v-else
        :rows="7"
        :columns="1"
        :table-props="{ striped: true }"
      />
      <b-alert :variant="someProductsAreNotFinished ? 'danger' : 'success'" :show="!afterCalculationImpossible && calculated">
        {{ someProductsAreNotFinished ? 'Osad tooted on lõpetamata!' : 'Tooted on tehtud!' }}
      </b-alert>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    name: 'faf-detailed-project',
    inject: ['$validator'],
    components: {
    },
    props: {
      order: Object
    },
    data () {
      return {
        calc: [],
        projectCalculation: [],
        projectCosts: [],
        afterCalculation: [],
        afterCalculationImpossible: false,
        someProductsAreNotFinished: false,
        calculated: false,
        projectCostsFields: [
          { key: 'Kulu nimetus', label: 'Projekti kaal' },
          'Kogus',
          'Hind',
          'Summa'
        ]
      }
    },
    async created () {
      await this.calculateAllOrders(this.order)
      await this.setProjectAndAfterCalculation()
      this.calculated = true
    },
    methods: {
      async calculateAllOrders (order) {
        await this.$unblockMainThread()
        this.calc.push(await this.calculateOrder(order._id))

        if (order.products.find(p => ['INCOMPLETE', 'PRODUCTION_READY', 'IN_PRODUCTION'].includes(p.status))) {
          this.someProductsAreNotFinished = true
        }

        const childOrders = this.$_get(order, 'linkedOrders.childOrders', [])
        for await (const o of childOrders) {
          await this.calculateAllOrders(o)
        }
      },
      async calculateOrder (id) {
        const orderCalc = (await this.$CalculationController.calculateAfterOrder(id)).data

        const calc = orderCalc.calc

        return calc
      },
      async setProjectAndAfterCalculation (order) {
        // First table
        const materialNetoTotalAmount = await this.getTotalNetoMaterialAmount()
        const materialBrutoTotalAmount = await this.getTotalBrutoMaterialAmount()
        const materialBrutoTotal = await this.getTotalBrutoMaterial()
        const workTotal = await this.getTotalWork()
        const transportTotal = await this.getTotalTransport()
        const netoPriceTotal = await this.getTotalNetoPrice()
        const salePriceTotal = await this.getTotalSalePrice()
        const marginalTotal = (+salePriceTotal - +netoPriceTotal).toFixed(2)

        const materialPercentage = (+materialBrutoTotal / +salePriceTotal * 100).toFixed(2)
        const workPercentage = (+workTotal.total / +salePriceTotal * 100).toFixed(2)
        const transportPercentage = (+transportTotal / +salePriceTotal * 100).toFixed(2)
        const netoPricePercentage = (+netoPriceTotal / +salePriceTotal * 100).toFixed(2)
        const marginalPercentage = (+marginalTotal / +salePriceTotal * 100).toFixed(2)
        const salePricePercentage = (+salePriceTotal / +salePriceTotal * 100).toFixed(2)

        const tkPrice = (workTotal.total / workTotal.time * 60).toFixed(2)

        this.projectCalculation = [
          { title: 'Materjal', data: materialBrutoTotal + ' €' || '-', percentage: isFinite(materialPercentage) ? materialPercentage + '%' : '-' },
          { title: 'Töö', data: (workTotal.total).toFixed(2) + ' €' || '-', percentage: isFinite(workPercentage) ? workPercentage + '%' : '-' },
          { title: 'Transport', data: transportTotal + ' €' || '-', percentage: isFinite(transportPercentage) ? transportPercentage + '%' : '-' },
          { title: 'Omahind', data: netoPriceTotal + ' €' || '-', percentage: isFinite(netoPricePercentage) ? netoPricePercentage + '%' : '-' },
          { title: 'Marginaal', data: marginalTotal + ' €' || '-', percentage: isFinite(marginalPercentage) ? marginalPercentage + '%' : '-' },
          { title: 'Müügihind', data: salePriceTotal + ' €' || '-', percentage: isFinite(salePricePercentage) ? salePricePercentage + '%' : '-' },
          { title: 'Töökeskuse keskmine hind (€/h)', data: tkPrice + ' €/h' || '-' }
        ]

        // Second table
        const remainsPrice = await this.getTotalRemainsPrice()
        this.projectCosts = [
          {
            'Kulu nimetus': 'Netokaal',
            Kogus: materialNetoTotalAmount + ' kg',
            Hind: '',
            Summa: ''
          },
          {
            'Kulu nimetus': 'Brutokaal',
            Kogus: materialBrutoTotalAmount + ' kg',
            Hind: '',
            Summa: ''
          },
          {
            'Kulu nimetus': 'Jääk',
            Kogus: remainsPrice.amount.toFixed(2) + ' kg',
            Hind: (remainsPrice.price / remainsPrice.items).toFixed(2) + ' €/kg',
            Summa: remainsPrice.total.toFixed(2) + ' €'
          }
        ]

        // Third table
        const totalActualTime = await this.getTotalActualTime()

        if (+totalActualTime > 0) {
          const afterEffectivity = ((+workTotal.time / +totalActualTime) * 100).toFixed(2) || '-'
          const afterworkTotal = ((+workTotal.total / +afterEffectivity) * 100).toFixed(2) || '-'
          const afterNetoPriceTotal = (+materialBrutoTotal + +afterworkTotal + +transportTotal).toFixed(2)
          const afterMarginalTotal = (+salePriceTotal - +afterNetoPriceTotal).toFixed(2)

          const afterTkPrice = (+tkPrice / +afterEffectivity * 100).toFixed(2)

          const afterWorkPercentage = (+afterworkTotal / +salePriceTotal * 100).toFixed(2)
          const afterNetoPricePercentage = (+afterNetoPriceTotal / +salePriceTotal * 100).toFixed(2)
          const afterMarginalPercentage = (+afterMarginalTotal / +salePriceTotal * 100).toFixed(2)
          const afterSalePricePercentage = (+salePriceTotal / +salePriceTotal * 100).toFixed(2)

          this.afterCalculation = [
            { title: 'Materjal', data: materialBrutoTotal + ' €' || '-', percentage: isFinite(materialPercentage) ? materialPercentage + '%' : '-' },
            { title: 'Töö', data: afterworkTotal + ' €' || '-', percentage: isFinite(afterWorkPercentage) ? afterWorkPercentage + '%' : '-' },
            { title: 'Transport', data: transportTotal + ' €' || '-', percentage: isFinite(transportPercentage) ? transportPercentage + '%' : '-' },
            { title: 'Omahind', data: afterNetoPriceTotal + ' €' || '-', percentage: isFinite(afterNetoPricePercentage) ? afterNetoPricePercentage + '%' : '-' },
            { title: 'Marginaal', data: afterMarginalTotal + ' €' || '-', percentage: isFinite(afterMarginalPercentage) ? afterMarginalPercentage + '%' : '-' },
            { title: 'Müügihind', data: salePriceTotal + ' €' || '-', percentage: isFinite(afterSalePricePercentage) ? afterSalePricePercentage + '%' : '-' },
            { title: 'Töökeskuse keskmine hind (€/h)', data: afterTkPrice + ' €/h' || '-' },
            { title: 'Efektiivsus', data: afterEffectivity + ' %' || '-' }
          ]
        } else {
          this.afterCalculationImpossible = true
        }
      },
      async getTotalRemainsPrice () {
        return this.calc.reduce((a, b) => {
          return {
            amount: a.amount + +b.remainsPrice.amount,
            price: a.price + +b.remainsPrice.price,
            total: a.total + +b.remainsPrice.total,
            items: a.items + 1
          }
        }, {amount: 0, price: 0, total:0, items: 0})
      },
      async getTotalNetoMaterialAmount () {
        return (this.calc.reduce((a, b) => {
          return a + +b.materialNetoCostTotal.amount
        }, 0)).toFixed(2)
      },
      async getTotalBrutoMaterialAmount () {
        return (this.calc.reduce((a, b) => {
          return a + +b.materialBrutoCostTotal.amount
        }, 0)).toFixed(2)
      },
      async getTotalBrutoMaterial () {
        return (this.calc.reduce((a, b) => {
          return a + +b.materialBrutoCostTotal.total
        }, 0)).toFixed(2)
      },
      async getTotalWork () {
        return (this.calc.reduce((a, b) => {
          return { total: +a.total + +b.workTimeTotal.total + +b.setupTime.total, time: +a.time + +b.workTimeTotal.amount + +b.setupTime.time }
        }, { total: 0, time: 0 }))
      },
      async getTotalTransport () {
        return (this.calc.reduce((a, b) => {
          return a + +b.transportPrice
        }, 0)).toFixed(2)
      },
      async getTotalNetoPrice () {
        return (this.calc.reduce((a, b) => {
          return a + +b.netCostTotal
        }, 0)).toFixed(2)
      },
      async getTotalMarginal () {
        // TODO: PROBABLY NOT RIGHT
        return (this.calc.reduce((a, b) => {
          return a + +b.marginal
        }, 0)).toFixed(2)
      },
      async getTotalSalePrice () {
        return (this.calc.reduce((a, b) => {
          return a + +b.saleTotal
        }, 0)).toFixed(2)
      },
      getTotalActualTime  () {
        return (this.calc.reduce((a, b) => {
          return a + +b.actualTime
        }, 0)).toFixed(2)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .to-be-updated {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(30, 30, 47, 0.1);
  }
</style>
