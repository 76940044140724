<template>
  <div>
    <b-row class="pl-3 pr-3">
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Veebileht</label>
        <input
          v-model.trim="formData.global.homepage"
          :class="{ 'form-control': true }"
          name="homepage"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Ettevõtte täis nimi</label>
        <input
          v-model.trim="formData.global.fullName"
          :class="{ 'form-control': true }"
          name="fullName"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Registri kood</label>
        <input
          v-model.trim="formData.global.regCode"
          :class="{ 'form-control': true }"
          name="regCode"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">KMKR</label>
        <input
          v-model.trim="formData.global.KMKR"
          :class="{ 'form-control': true }"
          name="KMKR"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Kontakt telefon</label>
        <input
          v-model.trim="formData.global.phone"
          :class="{ 'form-control': true }"
          name="phone"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Email</label>
        <input
          v-model.trim="formData.global.email"
          :class="{ 'form-control': true }"
          name="email"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Pank</label>
        <input
          v-model.trim="formData.global.bank"
          :class="{ 'form-control': true }"
          name="bank"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">IBAN</label>
        <input
          v-model.trim="formData.global.IBAN"
          :class="{ 'form-control': true }"
          name="IBAN"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">SWIFT</label>
        <input
          v-model.trim="formData.global.SWIFT"
          :class="{ 'form-control': true }"
          name="SWIFT"
        />
      </b-col>
      <b-col md="12" class="px-1 pb-2">
        <img :src="logoImage" />
        <b-form-file accept="image/jpeg, image/png" class="mt-3" @input="handleFile" />
      </b-col>
    </b-row>
    <b-row class="justify-content-end mt-3">
      <b-col cols="5">
        <b-button
          id="add-new-user"
          :disabled="this.$loader.val()"
          class="w-100"
          type="submit"
          variant="primary"
          size="m"
          @click="upsert"
        >
          Salvesta
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    name: 'global-company-settings',
    data () {
      return {
        formData: {
          global: {
            homepage: null,
            fullName: null,
            name: null,
            regCode: null,
            KMKR: null,
            phone: null,
            email: null,
            bank: null,
            IBAN: null,
            SWIFT: null,
            logoImg: null
          }
        }
      }
    },
    computed: {
      logoImage () {
        return this.formData.global.logoImg
      }
    },
    async created () {
      const { data } = await this.$settings.get()
      if (data.global) this.formData.global = { ...this.formData.global, ...data.global }
      this.$validator.reset()
    },
    methods: {
      async handleFile (file) {
        const base64Img = await this.$fileService.convertImageToBase64(file)
        this.formData.global.logoImg = base64Img
      },
      async upsert () {
        this.$loader.start('edit-global-company-settings')
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            this.$loader.stop('edit-global-company-settings')

          } else {
            this.$settings.upsert(this.formData).then(() => {
              this.$loader.stop('edit-global-company-settings')
              this.$notify.show('Salvestatud')
            }).catch(err => {
              this.$loader.stop('edit-global-company-settings')
              this.$err.show(err)
            })
          }
        })
      }
    }
  }
</script>
