import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function generateDocument (type, data) {
  return axios({
    url: `/document-service/document?type=` + type,
    method: 'POST',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    data,
    responseType: 'arraybuffer'
  })
}

function generateWorkTimeReportExcel (startDate, endDate) {
  return axios({
    url: `/document-service/report/work-time?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    responseType: 'arraybuffer'
  })
}

function generateInventoryReportExcel (data) {
  return axios({
    url: `/document-service/report/inventory`,
    method: 'POST',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    data,
    responseType: 'arraybuffer'
  })
}

function generateSalesExcel (data, type) {
  return axios({
    url: `/document-service/report/sales-excel?type=${type}`,
    method: 'POST',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    data,
    responseType: 'arraybuffer'
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$DocumentController = {
    generateDocument,
    generateWorkTimeReportExcel,
    generateInventoryReportExcel,
    generateSalesExcel
  }
}

export {
  generateDocument,
  generateWorkTimeReportExcel,
  generateInventoryReportExcel,
  generateSalesExcel
}
