<template>
  <div>
    <order-table-card />
  </div>
</template>
<script>
  import OrderTableCard from './../../Components/Order/OrderTableCard'

  export default {
    name: 'order',
    components: {
      OrderTableCard
    },
    data () {
      return {
        addNewSale: false,
        duplicate: null
      }
    },
    created () {
      this.$root.$on('duplicate-order', order => {
        this.duplicate = order
        this.addNewSale = true
      })
    },
    beforeDestroy () {
      this.$root.$off('duplicate-order')
    }
  }
</script>
