import Ajv from 'ajv'
import get from 'lodash/get'

const ajv = new Ajv({ allErrors: true })

// Kõikide toodete seadistusaeg

const schema = {
  type: 'object',
  required: ['order'],
  properties: {
    order: {
      type: 'object',
      required: ['products'],
      properties: {
        products: {
          type: 'array'
        }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getAllWorkcentersActualTime (order) {
  if (!test({order})) {
    throw { function: '_getAllWorkcentersActualTime', error: test.errors }
  }

  let total = 0

  for await (const product of order.products) {
    for await (const workcenter of product.workcenters) {
      total += +workcenter.activeTime
    }
  }

  return (Number.isFinite(total) && total) ? total.toFixed(2) : 0
}
