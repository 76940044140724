<template>
  <div class="dataTables_wrapper">
    <div class="table-responsive">
      <b-table
        :per-page="perPage"
        :sort-compare="$sortDate"
        :items="items"
        :fields="fields"
        :busy="loading"
        striped
        show-empty
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button v-b-tooltip.hover class="icon-button text-info" title="Detailne vaade" @click="selectedDetailedItem = row.item">
            <font-awesome-icon v-b-modal.modal-detailed-purchase-order icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPurchaseOrdersHistory"
    />
    <faf-detailed-purchase-modal v-if="selectedDetailedItem" :id="selectedDetailedItem.id" history @close="selectedDetailedItem = null" />
  </div>
</template>
<script>
  import FafDetailedPurchaseModal from './DetailedPurchaseModal'

  export default {
    name: 'faf-purchase-history',
    components: {
      FafDetailedPurchaseModal
    },
    data () {
      return {
        totalRows: 0,
        loading: false,
        currentPage: 1,
        perPage: 10,
        selectedDetailedItem: null,
        pageOptions: [5, 10, 15],
        fields: [
          {
            key: 'orderNumber',
            label: 'Tellimuse Number',
            sortable: true
          },
          {
            key: 'deliveryDate',
            label: 'Tarneaeg',
            sortable: true,
            formatter: (value, key, item) => this.$dayjs(value).format('DD.MM.YYYY'),
            sortByFormatted: true
          },
          {
            key: 'supplier.name',
            label: 'Tarnija',
            sortable: true
          },
          {
            key: 'supplier.name',
            label: 'Tarnija',
            sortable: true
          },
          {
            key: 'supplier.contact',
            label: 'Kontakt',
            formatter: (value, key, item) => `${value.name}, ${value.email}, ${value.phone}`
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        items: [],
        selected: [],
        tags: []
      }
    },
    async created () {
      await this.getPurchaseOrdersHistory()
    },
    methods: {
      async getPurchaseOrdersHistory (e) {
        this.$loader.start()
        this.loading = true
        await this.$purchase.getPurchaseOrdersHistory({page: e || this.currentPage}).then(rs => {
          this.totalRows = rs.data.totalDocs
          this.items = rs.data.docs
        })
        this.loading = false
        this.$loader.stop()
      }
    }
  }
</script>
