<template>
  <file-pond
    ref="pond"
    :allow-multiple="true"
    :files="files"
    :server="serverConf"
    name="file"
    label-idle="Lae fail/failid üles"
    label-file-loading="Laen"
    label-file-added="Üles laadimine lõpetatud"
    label-file-processing="Laen"
    label-file-processing-complete="Tehtud"
    label-file-processing-error="Midagi läks valesti"
    label-file-type-not-allowed="Faili tüüp pole lubatud"
    label-tap-to-retry="Proovi uuesti"
    label-tap-to-undo="Sulge"
    label-tap-to-cancel="Katkesta"
    @init="handleFilePondInit"
    @processfiles="$emit('processfiles')"
  />
</template>

<script>
  // Import Vue FilePond
  import vueFilePond from 'vue-filepond';

  // Import FilePond styles
  import 'filepond/dist/filepond.min.css';

  // Import FilePond plugins
  // Please note that you need to install these plugins separately

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

  // Create component
  const FilePond = vueFilePond(FilePondPluginFileValidateType);

  export default {
    name: 'faf-file-pond',
    components: {
      FilePond
    },
    props: {
      meta: {
        default: () => null,
        type: Object
      }
    },
    data () {
      return {
        files: []
      }
    },
    computed: {
      serverConf () {
        return {
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const formData = new FormData();
            this.meta && formData.append('meta', JSON.stringify(this.meta))
            formData.append(fieldName, file, file.name)
            const companyHeader = localStorage.company ? localStorage.company.toUpperCase() : 'FAF'

            const request = new XMLHttpRequest();
            request.open('POST', '/file-service/v1/upload');
            request.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.user).token}`)
            request.setRequestHeader('X-FAF-Organization', companyHeader)

            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total)
            };

            request.onload = () => {
              if (request.status >= 200 && request.status < 300) {
                this.$emit('uploaded', JSON.parse(request.responseText))
                load(request.responseText)
              }
              else {
                if (request.status === 403) {
                  this.$err.show('Vale Faili tüüp või Fail on liiga suur!')
                } else {
                  this.$err.show(request.responseText)
                }
                error('oh no');
              }
            };

            request.send(formData);

          },
          revert: (uniqueFileId, load, error) => {
            error('oh my goodness')
            load();
          }
        }
      }
    },
    methods: {
      handleFilePondInit () {
        console.log('FilePond has initialized')
      }

    }
  }
</script>

<style>
</style>
