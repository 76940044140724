<template>
  <proof-management
    :my-jobs="myJobs"
    :modal-name="modalName"
    :disable-certificate="true"
    :bulk-worksheet="true"
    submit-always-enabled
    @editProof="editProof"
    @complete="complete"
  />
</template>
<script>
  import startupCheck from './mixins/startupCheck'
  import ProofManagement from './templates/ProofManagement'

  export default {
    name: 'prepare-proof-modal',
    components: {
      ProofManagement
    },
    mixins: [startupCheck],
    props: {
      modalName: {
        type: String,
        default: 'prepare-proof-modal'
      },
      myJobs: Array,
      workId: String,
      editMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        overTime: 0,
        closingModal: {},
        workcenters: [{proof: [{}]}]
      }
    },
    methods: {
      async editProof ({ workcenters, bulkCertificate = null }, promise) {
        await this.$orders.setProof(
          {
            workcenters,
            bulkCertificate,
            calculateDoneAmount: false,
            setEndDate: false
          }
        ).catch(err => {
          promise.reject(err)
        })

        promise.resolve()
        // this.$cardReset('my-jobs')
        // this.$cardReset('production-table')
      },
      complete (payload, promise) {
        this.$cardReset('my-jobs')
        this.$cardReset('production-table')
        promise.resolve()
      }
    }
  }
</script>
