<template>
  <div class="faf-recursive-table">

    <b-table
      :items="testOrders"
      :fields="fields"
      :busy="loading"
      striped
      hover
      fixed
      no-local-sorting
      empty-text="Andmed puuduvad"
    >
      <template v-slot:cell(expand)="data">
        <b-button
          v-show="$_get(data.item, 'linkedOrders.childOrders', []).length"
          class="icon-button text-info pt-0 pb-0"
          @click="data.toggleDetails()"
        >
          <font-awesome-icon
            :icon="data.detailsShowing ? 'angle-double-down' : 'angle-double-right'"
          />
        </b-button>
      </template>
      <template #row-details="data">
        <faf-child-orders-table
          v-if="$_get(data.item, 'linkedOrders.childOrders', []).length && isAddNewChildOrderVisible"
          :level="level + 1"
          :order-id="data.item._id"
        />
      </template>

      <template v-slot:cell(createdDate)="data">
        {{ $dayjs(data.item.createdDate).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:cell(deliveryDate)="data">
        {{ $dayjs(data.item.deliveryDate).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:cell(readyProducts)="data">
        <faf-tag :color="getReadyProductsColor(data.item.products)" :text="getReadyProducts(data.item.products)" />
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-primary my-3">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          class="icon-button text-info ml-2"
          title="Muuda projekti"
          style="font-size: 20px; padding: 0;"
          @click="editId = data.item._id"
        >
          <font-awesome-icon
            icon="pencil-alt"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          style="padding: 0; font-size: 18px;"
          class="icon-button text-info ml-2"
          title="Detailne vaade"
          @click="detailedOrderId = data.item._id"
        >
          <font-awesome-icon icon="eye" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-if="isAddNewChildOrderVisible"
          style="font-size: 18px;"
          class="icon-button text-success"
          title="Loo tootmistellimus"
          @click="addChildOrder = true; parentOrder = data.item"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-button>
      </template>
    </b-table>
    <faf-detailed-sale v-if="!!detailedOrderId" :id="detailedOrderId" @close="detailedOrderId = null; getOrder()" />
    <faf-add-edit-sale v-if="!!editId || addChildOrder" :parent-order="parentOrder" :edit-id="editId" @hide="editId = null; getOrder(); addChildOrder = false; parentOrder = null" />
  </div>
</template>
<script>
  import { getOrderStatus } from './../../helperMethods/getOrderStatus'
  export default {
    name: 'faf-child-orders-table',
    props: {
      orderId: String,
      level: {
        type: Number,
        default: 1
      }
    },
    data () {
      return {
        order: null,
        levelLength: [],
        loading: true,
        detailedOrderId: null,
        addChildOrder: false,
        parentOrder: null,
        editId: null,
        testOrders: [],
        fields: [
          { key: 'expand', label: '', width: '10px' },
          (this.$can(['orderFields', 'salesSystemNumber']) && { key: 'salesSystemNumber', label: 'ID', sortable: true, width: '10px' }),
          { key: 'deliveryDate', label: 'Tarne kuupäeval' },
          (this.$can(['orderFields', 'readyProducts']) && { key: 'readyProducts', label: 'Valmistooted' }),
          { key: 'actions', label: 'Tegevused' }
        ]
      }
    },
    computed: {
      isAddNewChildOrderVisible () {
        return this.level < 4
      }
    },
    async created () {
      await this.getOrder()
    },
    methods: {
      getEditClasses (row) {
        if (this.activeRow === null || this.activeRow === row.index) {
          return row.detailsShowing ? 'tim-icons icon-minimal-right turn-right' : 'tim-icons icon-double-right'
        }
        return 'tim-icons icon-double-right visually-hidden'
      },
      getReadyProductsColor (products) {
        const ready = products.map(product => product.quantity.doneAmount >= product.quantity.amount).filter(Boolean)
        return ready.length >= products.length ? 'success' : ready.length > 0 ? 'warning' : 'danger'
      },
      getOrderStatus,
      getReadyProducts (products) {
        const ready = products.map(product => product.quantity.doneAmount >= product.quantity.amount).filter(Boolean)
        return `${ready.length}/${products.length}`
      },
      async getOrder () {
        this.loading = true
        const { data } = await this.$orders.getOrderById(this.orderId)
        //await this.setLevelLength(data)
        this.testOrders = data.linkedOrders.childOrders
        this.loading = false

      },
      async setLevelLength (order) {
        const childOrders = this.$_get(order, 'linkedOrders.childOrders', [])
        if (childOrders.length) {
          const index = this.levelLength.length
          for await (const childOrder of childOrders) {
            this.levelLength.push({index, order: childOrder})
            this.setLevelLength(childOrder)
          }
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .faf-recursive-table {
    padding: 10px;
    .table {
      background-color: transparent;
    }
  }
</style>
