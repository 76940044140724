<template>
  <main style="height: 100vh;" class="login-form">
    <error />
    <div class="card-wrapper">
      <div class="logo-wrapper">
        <img
          :src="logo"
          alt=""
        >
      </div>

      <form class="form-signin">
        <hr class="colorgraph"><br>

        <input
          v-model="credentials.username"
          type="text"
          class="form-control"
          name="username"
          placeholder="Kasutajanimi"
          required
        />
        <input
          v-model="credentials.password"
          type="password"
          class="form-control mt-2"
          name="password"
          placeholder="Parool"
          required
          @keyup.enter="login"
        />
        <b-button
          :disabled="loading"
          size="lg"
          block
          variant="primary"
          class="mt-4"
          @click.prevent="login"
        >
          <div class="text-center">
            <b-spinner v-if="loading" style="width: 15px; margin: auto;" type="grow" small />
            <span v-else>Sisene</span>
          </div>
        </b-button>
      </form>
    </div>
  </main>
</template>
<script>
  import { login } from './AuthService'
  import heartbeat from './../../router/heartbeat'
  import Error from './../../components/Notifications/Error'
  import produxioLogo from './../../assets/images/produxio-logo.js'
  import produxioLogoAlt from './../../assets/images/produxio-logo-alt.js'

  export default {
    name: 'login',
    components: {
      Error
    },
    data () {
      return {
        credentials: {},
        loading: false
      }
    },
    computed: {
      logo () {
        return this.$user.getTheme() === 'dark' ? produxioLogoAlt : produxioLogo
      }
    },
    methods: {
      async login () {
        this.loading = true
        login(this.credentials).then(async rs => {
          console.log('login successful')
          this.$rolePrivileges.changeRole(rs.data.userGroups)
          this.$user.setData({
            token:rs.data.token,
            _id: rs.data._id,
            firstName: rs.data.firstName,
            lastName: rs.data.lastName,
            username: rs.data.username,
            company: rs.data.company,
            userGroups: rs.data.userGroups,
            theme: rs.data.theme || 'dark'
          })
          const currentTime = new Date()
          localStorage.sessionEndTime = currentTime.getTime() + 43100000
          await heartbeat({
            timeout: localStorage.sessionEndTime,
            beatAfter: 15000
          }, () => {
            this.$rolePrivileges.changeRole(['PUBLIC'])
            this.$router.replace({ name: 'Login' })
          })
          this.$isMobile ? this.$router.push({name: 'Tootmine'}) : this.$router.push('/')
        }).catch(err => {
          this.loading = false
          this.$err.show('Sisselogimine ebaõnnestus')
        })
      }
    }
  }
</script>
<style scoped>
  .form-signin-heading {
    margin-top: 30px;
    margin-bottom: 5px;
  }
  img {
    width: 100%;
  }
  .login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .form-signin-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
  }
</style>
