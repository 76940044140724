<template>
  <faf-card name="inventory" title="Ladu" @cardReset="cardReset">
    <template slot="buttons">
      <b-button v-b-tooltip.hover class="icon-button text-success" title="Lae dokument alla" @click="downloadDocument">
        <font-awesome-icon icon="file-excel" />
      </b-button>
      <b-button v-b-tooltip.hover v-b-modal.modal-add-inventory class="icon-button text-success" title="Lisa materjalile lao seis">
        <font-awesome-icon icon="plus-circle" />
      </b-button>
    </template>
    <div class="dataTables_wrapper">
      <faf-filters :fields="filterFields" :cols="4" url-cache @addTag="addFilterTag" @filter="getMaterialsWithInventory" />
      <div
        style="max-height: 450px; overflow: scroll;"
        class="table-responsive"
      >
        <b-table
          :fields="fields"
          :items="materials"
          :busy="$loader.val('inventory')"
          striped
          hover
          empty-text="Andmed puuduvad"
        >
          <template v-slot:table-busy>
            <div class="text-center text-primary my-3">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              v-b-tooltip.hover
              v-b-tooltip.left
              v-b-modal.modal-add-inventory
              class="icon-button text-info"
              title="Muuda"
              style="font-size: 20px; padding: 0;"
              @click="prefillData(data.item)"
            >
              <font-awesome-icon
                icon="pencil-alt"
              />
            </b-button>
          </template>
        </b-table>
      </div>
      <b-modal
        id="modal-add-inventory"
        title="Lisa või muuda materjali laoseisu"
        ok-title="Lisa"
        cancel-title="Tagasi"
        @hide="clearData"
      >
        <div>
          <h4 class="mt-0 text-info">
          </h4>
          <b-row class="my-1">
            <b-col sm="6" class="pr-lg-1">
              <label class="mb-0">Materjal</label>
              <multiselect
                v-model.lazy="selectedMaterial"
                v-validate="'required'"
                v-faf-autocomplete-off
                v-bind="$multiselectProps('material-name')"
                :options="materialSuggestions.data"
                placeholder="Materjal"
                @input="addMaterialParameters(selectedMaterial)"
                @tag="addTag('materialSuggestions', `selectedMaterial`, $event)"
              />
            </b-col>
            <b-col sm="6" class="pl-lg-1">
              <label class="mb-0">Hind €/{{ selectedMaterialUnit }}</label>
              <input
                v-model="selectedMaterialCalculatedPrice"
                v-validate="'required|decimal:5'"
                v-bind="$requiredInputProps('material-inventory-calculated-price')"
                placeholder="Hind"
                type="number"
              >
            </b-col>
            <b-col sm="6" class="pr-lg-1">
              <label class="mb-0 mt-2">Kogus</label>
              <input
                v-model="selectedMaterialAmount"
                v-validate="'required|decimal:2'"
                v-bind="$requiredInputProps('material-inventory-amount')"
                placeholder="Kogus"
                type="number"
              >
            </b-col>
            <b-col sm="6" class="pl-lg-1">
              <label class="mb-0 mt-2">Ühik</label>
              <select
                v-model="selectedMaterialUnit"
                v-validate="'required'"
                v-bind="$requiredInputProps('material-inventory-unit')"
                placeholder="Ühik"
              >
                <option v-for="(option, optionIndex) in unitOptions" :key="'option-' + optionIndex" :value="option">
                  <span v-if="option === 'm2'">m&sup2;</span>
                  <span v-else>{{ option }}</span>
                </option>
              </select>
            </b-col>
          </b-row>
        </div>

        <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
          <div class="row justify-content-center">
            <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="close">
              <font-awesome-icon icon="arrow-alt-circle-left" />
            </b-button>
            <b-button v-b-tooltip.hover class="icon-button text-success" title="Salvesta" @click="addInventoryAmount">
              <font-awesome-icon icon="check-circle" />
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </faf-card>
</template>
<script>
  import { downloadBinaryFile } from './../../components/GetPdf/downloadFile'

  export default {
    name: 'faf-inventory',
    data () {
      return {
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Materjali nimi', options: [], taggable: true }
        ],
        selectedMaterial: '',
        selectedMaterialAmount: null,
        selectedMaterialUnit: 'tk',
        selectedMaterialCalculatedPrice: null,
        selectedMaterialSpecialWeight: null,
        materialSuggestions: { data: [] },
        unitOptions: ['kg', 'mm', 'm2', 'tk', 'L', 'm'],
        totalRows: 0,
        materials: [],
        fields: [
          {
            key: 'name',
            label: 'Materjal',
            sortable: true
          },
          {
            key: 'inventoryAmount',
            label: 'Kogus',
            formatter: (value, key, item) => `${value.toFixed(1)} ${item.unit}`,
            sortable: true,
            sortByFormatted: true
          },
          {
            key: 'calculatedPrice',
            label: 'Hind',
            formatter: (value, key, item) => this.getCalculatedPrice(item) + ` €/${item.unit}`,
            sortable: true
          },
          {
            key: 'total',
            label: 'Summa',
            formatter: (value, key, item) => this.getTotalPrice(item) + ' €',
            sortable: true
          },
          {
            key: 'actions',
            label: 'Tegevused'
          }
        ]
      }
    },
    async created () {
      this.$loader.start('inventory')
      this.materialSuggestions = await this.$suggestions.getMaterialNames()
      this.filterFields[0].options = this.materialSuggestions.data
      await this.getMaterialsWithInventory()
      this.$loader.stop('inventory')
    },
    methods: {
      close () {
        this.$cardReset('inventory')
      },
      async cardReset () {
        await this.getMaterialsWithInventory()
      },
      addFilterTag (fieldName, val) {
        this.filterFields[0].options.push(val)
      },
      addTag (type, model, val) {
        this.$_set(this, model, val)
      },
      addMaterialParameters () {
        this.$loader.start()
        this.$materials.getMaterials({filters: {name: this.selectedMaterial}}).then(rs => {
          const current = rs.data.docs[0]
          if (current) {
            current.inventoryAmount && (this.selectedMaterialAmount = current.inventoryAmount)
            this.selectedMaterialUnit = current.unit
            this.selectedMaterialSpecialWeight = current.specialWeight || 1
            this.selectedMaterialCalculatedPrice = +((current.calculatedPrice || current.price) * this.selectedMaterialSpecialWeight).toFixed(5)
          }
          this.$loader.stop()
        })
      },
      async getMaterialsWithInventory (filter = {}) {
        const finishedFilters = this.$route.query.filter ? JSON.parse(this.$route.query.filter) : filter
        const materials = await this.$materials.getMaterialsWithInventory(finishedFilters || {})
        this.totalRows = materials.data.length
        this.materials = materials.data
      },
      async addInventoryAmount () {
        this.$validator.validateAll().then(async (result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            return
          }

          await this.$materials.updateOrAddNewMaterial({
            name: this.selectedMaterial,
            inventoryAmount: this.selectedMaterialAmount,
            unit: this.selectedMaterialUnit,
            calculatedPrice: +this.selectedMaterialCalculatedPrice / this.selectedMaterialSpecialWeight
          }).then(async () => {
            await this.getMaterialsWithInventory()
            this.$notify.show('Salvestatud')
          })
        })
      },
      getCalculatedPrice (item) {
        if (typeof (item.calculatedPrice || item.price) === 'number') {
          const price = item.calculatedPrice || item.price
          return +(price * (item.specialWeight || 1)).toFixed(5)
        }
        return `-`
      },
      getTotalPrice (item) {
        const price = this.getCalculatedPrice(item)
        const total = price * +item.inventoryAmount
        return Number.isFinite(total) ? total.toFixed(2) : '-'
      },
      prefillData (item) {
        this.materialSuggestions.data.push(item.name)
        this.selectedMaterial = item.name
        this.addMaterialParameters()
      },
      clearData () {
        this.selectedMaterialAmount = null
        this.selectedMaterial = ''
      },
      async downloadDocument () {
        this.$loader.start('document-download')
        this.$DocumentController.generateInventoryReportExcel(this.materials).then(rs => {
          this.$loader.stop('document-download')
          const headers = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          downloadBinaryFile(rs.data, headers, `LADU.xlsx`)
          this.$cardReset('production-table')
        }).catch(err => {
          this.$loader.stop('document-download')
          this.$err.show(err)
        })
      }
    }
  }
</script>
<style scoped>
  .separator {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
</style>
