import Ajv from 'ajv'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'number',
  required: ['salePrice']
}

const test = ajv.compile(schema)

export default async function _getRecommendedPrice (netCostTotal) {
  if (!test(netCostTotal)) {
    throw { function: '_getRecommendedPrice', error: test.errors }
  }

  let previousMaterialPercentage = 33
  let index = 0.05085
  let previousMaterialCost = 33

  if (netCostTotal === 0) {
    return '0.00'
  } else if (netCostTotal <= 33) {
    let result = netCostTotal * 100 / 33
    if (result < 30) {
      result = 30
    }
    return Number.isFinite(result) ? result.toFixed(2) : 0

  } else if (netCostTotal >= 7900) {
    previousMaterialPercentage = 78
    index = 0.00060
    previousMaterialCost = 6240

  } else if (netCostTotal >= 6240) {
    previousMaterialPercentage = 76
    index = 0.00119
    previousMaterialCost = 4560

  } else if (netCostTotal >= 4560) {
    previousMaterialPercentage = 74
    index = 0.00233
    previousMaterialCost = 3700

  } else if (netCostTotal >= 3700) {
    previousMaterialPercentage = 72
    index = 0.00244
    previousMaterialCost = 2880

  } else if (netCostTotal >= 2880) {
    previousMaterialPercentage = 70
    index = 0.00256
    previousMaterialCost = 2100

  } else if (netCostTotal >= 2100) {
    previousMaterialPercentage = 66
    index = 0.00513
    previousMaterialCost = 1320

  } else if (netCostTotal >= 1320) {
    previousMaterialPercentage = 63
    index = 0.00435
    previousMaterialCost = 630

  } else if (netCostTotal >= 630) {
    previousMaterialPercentage = 42
    index = 0.05000
    previousMaterialCost = 210

  } else if (netCostTotal >= 33) {
    previousMaterialPercentage = 33
    index = 0.05085
    previousMaterialCost = 33

  }

  // netcost * 100 / ( eelneva-materjalipiirväärtus-protsent + ( index - ( netCostTotal - eelneva-materjalipiirväärtus ) ) )
  const result = netCostTotal * 100 / (previousMaterialPercentage + (index * (netCostTotal - previousMaterialCost)))

  return Number.isFinite(result) ? result.toFixed(1) + '0' : 0
}
