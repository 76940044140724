import Vue from 'vue'
import ContextMenu from './ContextMenu'

const state = Vue.observable({
  item: null,
  visible: false,
  reset: _reset
});

function _reset () {
  state.item = null
  state.visible = false
}

export default function install (Vue, options = {}) {
  Vue.prototype.$contextMenu = state
  Vue.component(ContextMenu.name, ContextMenu)
}
