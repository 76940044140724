<template>

  <b-button
    v-can="['action', 'ProductStopped']"
    v-b-tooltip.hover
    v-b-tooltip.left
    :class="'icon-button text-danger'"
    :title="getTooltipText"
    style="font-size: 20px; padding: 0; margin-left: 10px;"
    @click="toggle"
  >
    <font-awesome-icon
      icon="hand-paper"
    />
    <b-modal
      v-if="visible"
      v-model="visible"
      :id="'modalName'"
      :size="'s'"
      :title="'Seisak'"
      scrollable
      @hide="$emit('hide')"
    >
      <b-row>
        <b-col cols="12">
          <label for="document-type">Seisaku põhjus</label>
          <select v-validate="'required'" v-model="formData.reason" v-bind="$requiredInputProps('document-type')">
            <option v-for="(option, optionIndex) in options" :key="'document-type-option-' + optionIndex" :value="option.value">
              <span>{{ option.label }}</span>
            </option>
          </select>
        </b-col>
      </b-row>
      <div slot="modal-footer" class="container modal-buttons-wrapper">
        <div class="row justify-content-center">
          <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="visible = false">
            <font-awesome-icon icon="arrow-alt-circle-left" />
          </b-button>
          <b-button v-b-tooltip.hover :disabled="$loader.val('toggle-stopped')" class="icon-button text-success" title="Kinnita" @click="setStatus(id)">
            <font-awesome-icon icon="check-circle" />
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-button>

</template>
<script>
  export default {
    name: 'production-stopped-modal',
    props: {
      id: String,
      isStopped: Boolean,
      reasons: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        formData: {
          reason: 'NO_INFORMATION'
        },
        visible: false,
        options: [
          { label: 'Info Puudu', value: 'NO_INFORMATION' },
          { label: 'Materjal', value: 'MATERIAL'},
          { label: 'Kvaliteet', value: 'QUALITY'}
        ]
      }
    },
    computed: {
      getTooltipText () {
        if (this.isStopped) {
          return this.options.find(o => {
            return o.value === this.reasons[this.reasons.length - 1].reason
          }).label || 'Seisak'
        } else {
          return 'Seisak'
        }
      }
    },
    methods: {
      async toggle () {
        if (this.isStopped) {
          await this.setStatus(this.id)
        } else {
          this.visible = true
        }
      },
      async setStatus (id) {
        this.$loader.start('toggle-stopped')

        await this.$orders.toggleStopped(id, this.formData).catch(err => this.$err.show(err))

        this.$loader.stop('toggle-stopped')
        this.visible = false
        this.$emit('submitted')
      }
    }
  }
</script>
