<template>
  <div :class="wrapperClasses" :style="wrapperStyles" class="faf-input-wrapper">
    <div v-if="active" class="position-relative">
      <multiselect
        :ref="name"
        v-model="val"
        v-faf-autocomplete-off
        :name="name"
        v-bind="{ ...$multiselectProps(name, false), ...$attrs }"
        :options="options"
        :multiple="multiple"
        class="form-control faf-input"
        @input="onInput"
        @tag="addOption"
        @search-change="searchChange"
        @remove="$emit('remove', $event)"
      >
        <template v-if="multiple" slot="selection" slot-scope="{ values }">
          <span class="overflow-ellipsis">{{ values ? values.map(m => m.text).join(', ') : '' }}</span>
        </template>
        <template slot="noOptions">
          Alusta trükkimist...
        </template>
        <template slot="noResult">
          Tulemused puuduvad
        </template>
      </multiselect>
      <b-button v-if="isInValid(name)" v-b-tooltip.hover :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
    </div>
    <div v-else-if="!textHidden" class="faf-input-text" @click="editRow">
      <b-button v-if="isInValid(name)" v-b-tooltip.hover :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
      <slot name="custom-text" />
      <span v-if="!$slots['custom-text']">{{ val }} {{ unit.replace('m2', 'm&sup2;') }}</span>
    </div>
  </div>
</template>
<script>
  import field from './../mixins/field'

  export default {
    name: 'faf-multiselect',
    mixins: [ field ],
    props: {
      options: {
        type: Array,
        default: () => []
      },
      multiple: Boolean
    },
    data () {
      return {
      }
    },
    methods: {
      searchChange (e) {
        if (e.length > 1) {
          this.$emit('search-change', e)
        }
      },
      addOption (e) {
        this.val = e
        this.$emit('add-option', e)
        this.onInput(e)
      }
    }
  }
</script>
