import Ajv from 'ajv'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object'
}

const test = ajv.compile(schema)

export default async function _getRemainsCost (neto, bruto, remains) {
  if (!test({neto, bruto, remains})) {
    throw { function: '_getRemainsCost', error: test.errors }
  }

  const amount = +bruto.amount - +neto.amount
  const price = +remains
  const total = +amount * price

  return {
    amount: amount.toFixed(2), price: price.toFixed(2), total: total.toFixed(2)
  }
}
