<template>
  <div>
    <div v-can="['see', 'Statistics']" class="row">
      <div class="col-md-12">
        <faf-my-statistics />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <worktime-table />
      </div>
    </div>
    <div v-can="['see', 'Reports']" class="row">
      <div class="col-md-12">
        <faf-reports />
      </div>
    </div>
  </div>
</template>
<script>
  import { FafMyStatistics, FafReports } from "@faf/faf-ui";

  export default {
    name: "my-page",
    components: {
      FafMyStatistics,
      FafReports,
    },
  };
</script>
<style lang="scss" scoped>
.with-bottom-bar {
  margin-bottom: 80px;
}
</style>
