export default {
  data () {
    return {
      fields: [],
      fieldsPLASMAPRO: [
        'forceProduction',
        'actions',
        'salesSystemNumber',
        'client',
        'catalogue',
        'product.workcenters.name',
        'product.workcenters.queue',
        'product.name',
        'material',
        'width',
        'product.quantity',
        'product.workcenters.proof',
        'inProgressBy',
        'product.workcenters.startingTime',
        'product.workcenters.deliveryDate',
        'product.surfaceTreatment'
      ]
    }
  },
  created () {
    this.setFields(this.$company.name === 'PLASMAPRO' ? this.fieldsPLASMAPRO : null)
  },
  methods: {
    setFields (keys) {
      const fields =
        [{
           key: 'forceProduction',
           label: '',
           sortable: true
         },
         (this.$can(['orderFields', 'salesSystemNumber']) && {
           key: 'salesSystemNumber',
           label: 'ID',
           sortable: true
         }),
         (this.$can(['orderFields', 'orderId']) && {
           key: 'orderId',
           label: 'Projekti Nr',
           sortable: true
         }),
         (this.$can(['orderFields', 'catalogue']) && {
           key: 'catalogue',
           label: 'Kataloog',
           sortable: true
         }),
         {
           key: 'product.workcenters.queue',
           label: 'Jrk',
           sortable: true
         },
         {
           key: 'product.workcenters.name',
           label: 'Töökeskus',
           sortable: true
         },
         {
           key: 'product.name',
           label: 'Toote nimetus',
           sortable: true
         },
         (this.$can(['orderFields', 'linkedMaterialsInProduction']) && {
           key: 'material',
           label: 'Materjal',
           formatter: (value, key, item) => this.getLinkedMaterial(item, 'name')[0],
           sortable: true,
           sortByFormatted: true
         }),
         (this.$can(['orderFields', 'linkedMaterialsInProduction']) && {
           key: 'width',
           label: 'Paksus',
           formatter: (value, key, item) => this.getLinkedMaterial(item, 'width')[0],
           sortable: true,
           sortByFormatted: true
         }),
         (this.$can(['orderFields', 'WorkSheetMissing']) && {
           key: 'product.workcenters.proof',
           label: 'TL puudu',
           formatter: (value, key, item) => this.getMissingProofCount(value, item.product.quantity.amount),
           sortable: true,
           sortByFormatted: true
         }),
         {
           key: 'product.quantity',
           label: 'Tehtud',
           sortable: true
         },
         {
           key: 'inProgressBy',
           label: 'Töös',
           formatter: (value, key, item) => this.$_get(item, 'currentWorkers', []).map(operator => operator.operatorName).join(', '),
           sortable: true,
           sortByFormatted: true
         },
         {
           key: 'product.workcenters.startingTime',
           label: 'Algusaeg',
           sortable: true
         },
         {
           key: 'product.workcenters.deliveryDate',
           label: 'Tarneaeg',
           sortable: true
         },
         {
           key: 'product.workcenters.workTime',
           label: 'Aeg kokku ',
           formatter: (value, key, item) => this.$formatTime(this.calculateWorkTime(item), this.$_get(item, 'product.workcenters.unit', 'min')),
           sortable: true,
           sortByFormatted: true
         },
         {
           key: 'product.workcenters.activeTime',
           label: 'Tegelik aeg',
           formatter: (value, key, item) => this.$formatTime(this.$_get(item, 'product.workcenters.activeTime', []), 'min'),
           sortable: true,
           sortByFormatted: true
         },
         {
           key: 'productivity',
           label: 'Tootlikkus',
           sortable: true,
           sortByFormatted: true
         },
         {
           key: 'load',
           label: 'Koormus',
           formatter: (value, key, item) => this.getLoad(item),
           sortable: true,
           sortByFormatted: true
         },
         {
           key: 'product.surfaceTreatment',
           label: 'Viimistlus',
           sortable: true
         },
         {
           key: 'client',
           label: 'Klient',
           sortable: true
         },
         { key: 'actions', label: 'Tegevused', tdClass: 'faf-table-row-fixed', thClass: 'faf-table-row-fixed' }
        ]

      if (keys) {
        this.fields = keys.map(key => fields.find(field => field.key === key))
      } else {
        this.fields = fields
      }
    }
  }
}
