<template>
  <div
    ref="sidebar"
    :data="backgroundColor"
    :class="{
      'sidebar-collapsed': $sidebar.showSidebar
    }"
    class="sidebar"
  >
    <div id="style-3" class="sidebar-wrapper">
      <div class="logo" style="min-height: 62px;">
        <a
          :href="$homepage"
          aria-label="sidebar mini logo"
          class="simple-text logo-mini"
        >
          <div class="logo-img">
            <img
              :src="$companyLogo"
              alt=""
            >
          </div>
        </a>
        <a v-if="!$sidebar.showSidebar" :href="$homepage" class="simple-text logo-normal">
          Produxio
        </a>
      </div>
      <slot>
      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-link
            v-for="(link,index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :sidebar-collapsed="!$sidebar.showSidebar"
            :icon="link.icon"
          />
        </slot>
      </ul>
      <div class="collapse-button-container">
        <b-button
          v-b-tooltip.hover
          :title="$sidebar.showSidebar ? 'Ava menüü' : 'Sulge menüü'"
          class="icon-button text-white collapse-button"
          @click="$sidebar.displaySidebar(!$sidebar.showSidebar)"
        >
          <font-awesome-icon icon="chevron-circle-down" />
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
  import SidebarLink from "./SidebarLink";

  export default {
    components: {
      SidebarLink
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        default: "vue"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          const acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    data () {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: []
      };
    },
    computed: {
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx () {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle () {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      }
    },
    mounted () {
      this.$watch("$route", this.findActiveLink, {
        immediate: true
      })
    },
    methods: {
      findActiveLink () {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink (link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink (link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .collapse-button-container {
    position: absolute;
    right: 0;
    bottom: 0;

    .collapse-button {
      margin: 10px 24px;

      svg {
        transform: rotate(90deg);
        -webkit-transition: all 1s;
        transition: all 1s;
      }
    }
  }

  .sidebar {
    z-index: 999;
  }

  .sidebar .sidebar-wrapper {
    overflow: hidden;
  }

  .sidebar-collapsed {
    width: 80px;

    .collapse-button svg {
      transform: rotate(280deg);
    }
  }
</style>
