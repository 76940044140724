import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function getOrderReport (filter) {
  if (filter.status) {
    filter.status = filter.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/order`,
    method: 'POST',
    headers: { Authorization: token() },
    data: filter
  })
}

function getProductReport (filter) {
  if (filter.status) {
    filter.status = filter.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/product`,
    method: 'POST',
    headers: { Authorization: token() },
    data: filter
  })
}

function getMaterialReport (filter) {
  if (filter.status) {
    filter.status = filter.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/material`,
    method: 'POST',
    headers: { Authorization: token() },
    data: filter
  })
}

function getWorkcenterReport (filter) {
  if (filter.status) {
    filter.status = filter.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/workcenter`,
    method: 'POST',
    headers: { Authorization: token() },
    data: filter
  })
}

function getOperatorReport (filter) {
  if (filter.status) {
    filter.status = filter.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/operator`,
    method: 'POST',
    headers: { Authorization: token() },
    data: filter
  })
}

function getExcelReport (type, filter) {
  const filterClone = JSON.parse(JSON.stringify(filter))
  if (filterClone.status) {
    filterClone.status = filterClone.status.map(s => s.value)
  }
  return axios({
    url: `/api/reports/excel/operator/${type}`,
    method: 'POST',
        headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    responseType: 'arraybuffer',
    data: filterClone
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$reports = {
    getOrderReport,
    getProductReport,
    getMaterialReport,
    getWorkcenterReport,
    getOperatorReport,
    getExcelReport
  }
}

export {
  getOrderReport,
  getProductReport,
  getMaterialReport,
  getWorkcenterReport,
  getOperatorReport,
  getExcelReport
}
