import without from 'lodash.without'

export default function installLoader (Vue) {
  if (Vue) {
    Vue.prototype.$loader = {
      _vm: new Vue(
        { data: {
            loading: null,
            queue: []
          },
          watch: {
            queue (val) {
              if (val.length) {
                this.loading = true
              } else {
                this.loading = false
              }
            }
          }
      }),
      val (id = null) {
        if (id) {
          return this._vm.$data.queue.includes(id)
        } else {
          return this._vm.$data.loading
        }
      },
      start (id = 'no-id') {
        this._vm.$data.queue.push(id)
      },
      stop (id) {
        if (id) {
          this._vm.$data.queue = without(this._vm.$data.queue, id)
        } else {
          this._vm.$data.queue = without(this._vm.$data.queue, 'no-id')
        }
      }
    }
  } else {
    console.error('Vue not found')
  }
}
