<template>
  <b-modal
    visible
    size="xl"
    scrollable
    title-tag="h4"
    content-class="faf-full-modal-height"
    @hide="close"
  >
    <template v-slot:modal-header="{ close }">
      <b-container v-if="order" class="mt-3 mb-2">
        <b-row>
          <b-col cols="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>ID</strong>
              </h5>
              <font-awesome-icon icon="project-diagram" />
              <h2 class="mb-2 text-info">
                <strong>{{ order.salesSystemNumber }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>Staatus</strong>
              </h5>
              <font-awesome-icon icon="chart-line" />
              <h2 class="mb-2 text-info">
                <strong>{{ status.find(s => s.value === order.status).label }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>Tarne kp</strong>
              </h5>
              <font-awesome-icon icon="truck" />
              <h2 class="mb-2 text-info">
                <strong>{{ $dayjs(order.deliveryDate).format('DD.MM.YYYY') }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>HIND KOKKU</strong>
              </h5>
              <font-awesome-icon icon="money-bill-wave" />
              <h2 class="mb-2 text-info">
                <strong>{{ calc.saleTotal }} €</strong>
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-progress :value="progressValue" :max="progressMax" :variant="'success'" striped />
          </b-col>
        </b-row>
      </b-container>
    </template>
    <div v-if="order">
      <b-tabs class="mb-3 mt-1" align="center" no-key-nav>
        <b-tab title="Ülevaade ja omahind" active>
          <b-row class="my-4 display-flex">
            <b-col cols="7">
              <div class="p-3 bg-light-gray stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Projekti omahinna kalkulatsioon</strong>
                </h5>
                <b-button v-b-tooltip.hover :disabled="!isActive" class="icon-button m-0 p-1" title="Muuda projekti kalkulatsiooni" @click="editModal = 'CALCULATION'; prefillFormData(order)">
                  <font-awesome-icon class="text-info" icon="pencil-alt" />
                </b-button>
                <b-table :items="projectCalculation" :tbody-tr-class="rowClass" striped hover />

                <hr />
                <h5 class="text-gray mb-3">
                  <strong>Projekti tarneinfo</strong>
                </h5>
                <b-button v-b-tooltip.hover :disabled="!isActive" class="icon-button m-0 p-1" title="Muuda projekti tarneinfot" @click="editModal = 'TRANSPORT'; prefillFormData(order)">
                  <font-awesome-icon class="text-info" icon="pencil-alt" />
                </b-button>
                <div class="table-responsive sales-table">
                  <b-table :items="projectDelivery" :tbody-tr-class="rowClass" class="no-header" striped hover>
                    <template v-slot:cell(data)="data">
                      <div v-if=" data.item.title === 'Tellimuse jälgimine'">
                        {{ data.value }}
                      <!-- TRACKING  -->
                      <!-- <faf-tracking-link :host="trackingHost" :tracking-id="id" /> -->
                      </div>
                      <span v-else>{{ data.value }}</span>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-col>
            <b-col cols="5">
              <div class="p-3 bg-light-gray stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Projekti info ja müügihind</strong>
                </h5>
                <b-button v-b-tooltip.hover :disabled="!isActive" class="icon-button m-0 p-1" title="Muuda projekti kalkulatsiooni" @click="editModal = 'DISCOUNT'; prefillFormData(order)">
                  <font-awesome-icon class="text-info" icon="pencil-alt" />
                </b-button>

                <b-table
                  v-if="projectDetails.length"
                  :items="projectDetails"
                  :tbody-tr-class="rowClass"
                  class="hide-header"
                  striped
                  hover
                />
                <b-skeleton-table
                  v-else
                  :rows="11"
                  :columns="1"
                  :table-props="{ striped: true }"
                />
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Lisainfo">
          <b-row class="my-4 display-flex">
            <b-col cols="7">
              <div class="p-3 bg-light-gray stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Projekti kulud</strong>
                </h5>
                <b-button v-b-tooltip.hover :disabled="!isActive" class="icon-button m-0 p-1" title="Muuda märkmeid" @click="editModal = 'REMAINS'; prefillFormData(order)">
                  <font-awesome-icon class="text-info" icon="pencil-alt" />
                </b-button>
                <b-table :items="projectCosts" :tbody-tr-class="rowClass" striped hover />
                <hr />
                <b-table :items="materialCosts" :tbody-tr-class="rowClass" striped hover>
                  <template v-slot:head(Brutokogus)="data">
                    Bruto kogus
                  </template>
                  <template v-slot:cell(Brutokogus)="data">
                    {{ data.item['Brutokogus'] }}<sup>2</sup>)
                  </template>
                </b-table>
                <hr />
                <b-table :items="workCosts" :tbody-tr-class="rowClass" striped hover />
              </div>
            </b-col>
            <b-col cols="5">
              <div class="p-3 bg-light-gray stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Märkmed</strong>
                </h5>
                <b-button v-b-tooltip.hover :disabled="!isActive" class="icon-button m-0 p-1" title="Muuda märkmeid" @click="editModal = 'NOTES'; prefillFormData(order)">
                  <font-awesome-icon class="text-info" icon="pencil-alt" />
                </b-button>
                <h4 class="text-gray mt-3">
                  {{ order.notes }}
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab lazy show title="Tooted">
          <faf-detailed-products :order="order" />
        </b-tab>
        <b-tab
          v-if="this.$can(['see', 'detailedProject']) && !childOrder"
          :lazy="detailedProjectNotOpened"
          title="Projekti omahinna analüüs"
          @click="detailedProjectNotOpened = false"
        >
          <faf-detailed-project :order="order" :project-costs="projectCosts" />
        </b-tab>
      </b-tabs>
    </div>

    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div v-if="order" class="row justify-content-center">
        <!-- COMMON BUTTONS -->
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="close">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <span v-if="isActive" class="divider" />

        <b-button
          v-if="isActive"
          v-b-tooltip.hover
          :disabled="$loader.val()"
          class="icon-button text-danger"
          title="Tühista projekt"
          @click="annullOrder"
        >
          <font-awesome-icon icon="times" />
        </b-button>

        <!-- OFFER BUTTONS -->
        <b-button
          v-if="$can(['orderSubStatus', 'OFFER_PREPARED']) && statusOffer"
          v-b-tooltip.hover
          :disabled="$loader.val()"
          :class="{ 'text-success': order.subStatus.includes('OFFER_PREPARED'), 'text-info': !order.subStatus.includes('OFFER_PREPARED') }"
          class="icon-button"
          title="Ettevalmistatud"
          @click="updateStatus('OFFER_PREPARED')"
        >
          <font-awesome-icon icon="tasks" />
        </b-button>
        <b-button
          v-if="$can(['orderSubStatus', 'OFFER_EMAIL_SENT']) && statusOffer"
          v-b-tooltip.hover
          :disabled="$loader.val()"
          :class="{ 'text-success': order.subStatus.includes('OFFER_EMAIL_SENT'), 'text-info': !order.subStatus.includes('OFFER_EMAIL_SENT') }"
          class="icon-button"
          title="Saadetud"
          @click="updateStatus('OFFER_EMAIL_SENT')"
        >
          <font-awesome-icon icon="envelope" />
        </b-button>
        <b-button
          v-if="statusOffer"
          v-b-tooltip.hover
          :disabled="$loader.val()"
          class="icon-button text-info"
          title="Vormista tellimuseks"
          @click="updateStatus('ORDER')"
        >
          <font-awesome-icon icon="suitcase" />
        </b-button>

        <!-- ORDER BUTTONS -->
        <b-button
          v-if="$can(['orderSubStatus', 'ORDER_PACKED']) && statusOrder && !childOrder"
          v-b-tooltip.hover
          :disabled="!orderAllowed || $loader.val()"
          :class="{ 'text-success': order.subStatus.includes('ORDER_PACKED'), 'text-info': !order.subStatus.includes('ORDER_PACKED') }"
          class="icon-button"
          title="Komplekteeritud"
          @click="updateStatus('ORDER_PACKED')"
        >
          <font-awesome-icon icon="cube" />
        </b-button>
        <b-button
          v-if="$can(['orderSubStatus', 'ORDER_EMAIL_SENT']) && statusOrder && !childOrder"
          v-b-tooltip.hover
          :disabled="!orderAllowed || $loader.val()"
          :class="{ 'text-success': order.subStatus.includes('ORDER_EMAIL_SENT'), 'text-info': !order.subStatus.includes('ORDER_EMAIL_SENT') }"
          class="icon-button"
          title="Klient teavitatud"
          @click="updateStatus('ORDER_EMAIL_SENT')"
        >
          <font-awesome-icon icon="envelope" />
        </b-button>
        <b-button
          v-if="$can(['orderSubStatus', 'ORDER_SENT']) && statusOrder && !childOrder"
          v-b-tooltip.hover
          :disabled="!orderAllowed || $loader.val()"
          :class="{ 'text-success': order.subStatus.includes('ORDER_SENT'), 'text-info': !order.subStatus.includes('ORDER_SENT') }"
          class="icon-button"
          title="Kaup väljastatud"
          @click="updateStatus('ORDER_SENT')"
        >
          <font-awesome-icon icon="truck-pickup" />
        </b-button>

        <b-button
          v-if="statusOrder"
          v-b-tooltip.hover
          :disabled="!orderAllowed || $loader.val()"
          class="icon-button text-info"
          title="Märgi tellimus tehtuks"
          @click="updateStatus('COMPLETE')"
        >
          <font-awesome-icon icon="check-circle" />
        </b-button>

        <span class="divider" />

        <!-- COMMON BUTTONS -->
        <b-button v-b-tooltip.hover :disabled="$loader.val()" class="icon-button text-primary" title="Salvesta fail" @click="pdfDownloadVisible = !pdfDownloadVisible">
          <font-awesome-icon icon="file-download" />
        </b-button>
        <b-button
          v-can="['see', 'FileManager']"
          v-b-tooltip.hover
          v-b-modal.file-manager
          :disabled="$loader.val()"
          class="icon-button text-info"
          title="Dokumendid"
        >
          <font-awesome-icon icon="folder-open" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val()" class="icon-button text-dark" title="Saada email" @click="emailSendingModalVisible = true">
          <font-awesome-icon icon="mail-bulk" />
        </b-button>
      </div>
    </div>

    <faf-get-pdf
      v-if="pdfDownloadVisible"
      :visible="pdfDownloadVisible"
      :order="order"
      :client="order.client"
      type="SALES"
      @close="pdfDownloadVisible = false"
    />
    <faf-file-manager v-if="order" :key="order.documents.length" v-can="['see', 'FileManager']" :order-object-id="order._id" :pre-loaded-documents="order.documents || []" />
    <faf-detailed-edit-modal :form-data="formData" :edit-modal="editModal" @hide="editModal = false" @submit="saveProject" />
    <faf-delivery-date-modal :delivery-date="order && order.deliveryDate" :edit-modal="editDeliveryDateModal" @hide="editDeliveryDateModal = false" @submit="updateDeliveryDate" />
    <email-sending-modal
      v-if="!childOrder && $can(['see', 'EmailSending'])"
      :modal-visible="emailSendingModalVisible"
      :type="emailSendingType"
      :order="order"
      @hide="emailSendingModalVisible = false; emailSendingType = null;"
    />
  </b-modal>
</template>
<script>
  import FafDetailedProducts from './DetailedProducts'
  import FafDetailedEditModal from './DetailedEditModal'
  import FafDeliveryDateModal from './DeliveryDateModal'
  import FafDetailedProject from './DetailedProject'
  import EmailSendingModal from './../Email/EmailSendingModal'
  import { _getProductsTotalQuantity,
           _getProductMaterialCost,
           _getProductWorkTime } from './../../Calculations/index'

  export default {
    name: 'faf-detailed-sale',
    inject: ['$validator'],
    components: {
      FafDetailedProducts,
      FafDetailedEditModal,
      FafDeliveryDateModal,
      FafDetailedProject,
      EmailSendingModal
    },
    props: {
      id: String
    },
    data () {
      return {
        trackingHost: `https://tracking.produxio.io/${ this.$company.name.toLowerCase() }/`,
        pdfDownloadVisible: false,
        editModal: false,
        editDeliveryDateModal: false,
        emailSendingModalVisible: false,
        emailSendingType: null,
        order: null,
        detailedProjectNotOpened: true,

        orderStartingDates: [],

        projectCalculation: [],
        projectDetails: [],
        projectDelivery: [],

        projectCosts: [],
        materialCosts: [],
        workCosts: [],

        status: [
          { value: 'OFFER', label: 'Hinnapakkumine' },
          { value: 'ORDER', label: 'Tellimus' },
          { value: 'PRODUCTION_ORDER', label: 'Tootmistellimus' },
          { value: 'IN_PRODUCTION', label: 'Tootmises' },
          { value: 'PRODUCTION_READY', label: 'Tootmises' },
          { value: 'ANNULLED', label: 'Tühistatud' },
          { value: 'COMPLETE', label: 'Tehtud' }
        ],
        progressMax: 0,
        progressValue: 0,
        formData: { transport: {} },

        calc: {
          materialNetoCostTotal: {},
          materialBrutoCostTotal: {},
          workTimeTotal: {},
          setupTime: 0,

          netCostTotal: 0,
          netCostKgPrice: 0,

          salePrice: 0,
          saleKgPrice: 0
        }
      }
    },
    computed: {
      statusOffer () {
        return this.order && this.order.status === 'OFFER'
      },
      statusOrder () {
        return this.order && this.order.status === 'ORDER'
      },
      childOrder () {
        return this.$_get(this.order, 'subStatus', []).includes('PRODUCTION_ORDER')
      },
      statusProductionOrder () {
        return this.order && this.order.status === 'PRODUCTION_ORDER'
      },
      isActive () {
        return this.statusOffer || this.statusOrder
      },
      orderAllowed () {
        return this.order && !this.order.products.find(product => product.status != 'COMPLETE')
      }
    },
    watch: {
      id (val) {
        this.calculate(val)
      }
    },
    created () {
      this.calculate(this.id)
    },
    methods: {
      prefillFormData (order) {
        this.formData = {
          transport: {
            price: order.transport.price,
            address: order.transport.address
          },
          discount: order.discount,
          remainsPrice: order.remainsPrice,
          invoice: order.invoice,
          notes: order.notes
        }
      },
      async setProgressBar (products) {
        this.progressMax = await _getProductsTotalQuantity({ products, type: 'amount' })
        this.progressValue = await _getProductsTotalQuantity({ products, type: 'doneAmount' })
      },
      async calculate (id) {
        // BASIC CALCULATIONS
        const orderCalc = (await this.$CalculationController.calculateOrder(id)).data
        this.order = orderCalc.order
        this.calc = orderCalc.calc

        // SET CALCULATIONS
        this.setProjectDetails(this.order)
        this.setProjectCalculation(this.order)
        this.setProjectDelivery(this.order)
        this.setProjectCosts()
        this.setMaterialCosts()
        this.setWorkCosts()
        this.setProgressBar(this.order.products)
      },

      setProjectCosts () {
        this.projectCosts = [
          {
            'Kulu nimetus': 'Netokaal',
            Kogus: this.calc.materialNetoCostTotal.amount + ' kg',
            Hind: '',
            Summa: ''
          },
          {
            'Kulu nimetus': 'Brutokaal',
            Kogus: this.calc.materialBrutoCostTotal.amount + ' kg',
            Hind: '',
            Summa: ''
          },
          {
            'Kulu nimetus': 'Jääk',
            Kogus: this.calc.remainsPrice.amount + ' kg',
            Hind: this.calc.remainsPrice.price + ' €/kg',
            Summa: this.calc.remainsPrice.total + ' €'
          }
        ]
      },
      async setMaterialCosts () {
        this.materialCosts = []
        const initialMaterials = []
        const materials = []

        // Set all materials in same array
        for await (const p of this.order.products) {
          for await (const m of p.materials) {
            initialMaterials.push({...m, productAmount: p.quantity.amount})
          }
        }

        // Remove double materials
        for await (const m of initialMaterials) {
          await this.$unblockMainThread()
          const existingMaterial = materials.find(existingMaterial => existingMaterial.name === m.name && existingMaterial.price === m.price)
          const productMaterialCost = await _getProductMaterialCost({ materials: [m], quantity: { amount: m.productAmount } })
          if (existingMaterial) {
            existingMaterial.materialCost.push(productMaterialCost)
          } else {
            materials.push({
              name: m.name,
              price: m.price,
              width: m.width,
              materialCost: [ productMaterialCost ]
            })
          }
        }

        for await (const m of materials) {
          await this.$unblockMainThread()
          let amount = 0
          let total = 0
          for await (const price of m.materialCost) {
            await this.$unblockMainThread()
            amount += +price.amount
            total += +price.total
          }

          const material = (await this.$materials.getMaterials({filters: {name: m.name}})).data.docs[0]
          const multiplier = +this.$_get(material, 'multiplier', 8)

          this.materialCosts.push({
            'Materjali nimetus': m.name,
            Brutokogus: `${ amount.toFixed(2) } kg (${ ((amount && m.width) && +m.width ? (+amount / +m.width / multiplier).toFixed(2) : ' -') } m`,
            Hind: m.price + ' €/kg',
            Summa: total.toFixed(2) + ' €'
          })

        }
      },
      async setWorkCosts () {
        this.workCosts = []
        const initialWorkcenters = []
        const workcenters = []

        // Set all materials in same array
        for await (const p of this.order.products) {
          await this.$unblockMainThread()
          for await (const w of p.workcenters) {
            initialWorkcenters.push({...w, productAmount: p.quantity.amount})
          }
        }

        // Remove double workcenters
        for await (const w of initialWorkcenters) {
          await this.$unblockMainThread()
          const existingWorkcenter = workcenters.find(existingWorkcenter => existingWorkcenter.name === w.name && existingWorkcenter.price === w.price)
          const productWorkTime = await _getProductWorkTime({ workcenters: [w], quantity: { amount: w.productAmount } })
          if (existingWorkcenter) {
            existingWorkcenter.amount.push(productWorkTime)
          } else {
            workcenters.push({
              name: w.name,
              price: w.price,
              amount: [ productWorkTime ]
            })
          }
        }

        for await (const w of workcenters) {
          await this.$unblockMainThread()
          let amount = 0
          let total = 0
          for await (const a of w.amount) {
            amount += +a.amount
            total += +a.total
          }

          this.workCosts.push({
            'Töö kulu': w.name,
            Kogus: amount.toFixed(0) + ' min',
            Hind: w.price + ' €/min',
            Summa: total.toFixed(2) + ' €'
          })
        }

      },

      async setProjectDelivery (order) {
        this.projectDelivery = [
          { title: 'Tarne aadress', data: order.transport.address },
          { title: 'Arve number', data: order.invoice },
          ...(this.$can(['action', 'CopyTrackingLink']) && !this.childOrder ? [{ title: 'Tellimuse jälgimine', data: this.trackingHost + this.id }] : []),
          { title: 'Sisesed märkmed', data: order.detailedNotes }
        ]
      },
      async getParentOrders (order) {
        const parentOrder = this.$_get(order, 'linkedOrders.parentOrder', {})
        if (parentOrder && parentOrder.salesSystemNumber) {
          if (parentOrder._id !== this.order._id) {
            this.order.documents = [...this.order.documents, ...this.$_get(parentOrder, 'documents', []).map(d => {
              d.fileName = `(${parentOrder.salesSystemNumber}) ${d.fileName}`
              d.subOrder = true
              return d
            })]
          }
          const result = await this.getParentOrders(parentOrder)
          return result ? `${parentOrder.salesSystemNumber} > ${result}` : parentOrder.salesSystemNumber
        }
        return ''
      },
      async setProjectDetails (order) {
        await this.getOrderProducingStartingDate(order)
        this.projectDetails = [
          { title: 'Kuuluvus', data: await this.getParentOrders(order) || '-' },
          { title: 'Klient', data: order.client },
          { title: 'Reg kp', data: this.$dayjs(order.createdDate).format('DD.MM.YYYY') },
          { title: 'Tootmise algus kp', data: this.$dayjs(await this.getEarliestProducingDate(this.orderStartingDates)).format('DD.MM.YYYY') },
          { title: 'Tarne kp', data: this.$dayjs(order.deliveryDate).format('DD.MM.YYYY') },
          (order.catalogue && { title: 'Kataloog', data: order.catalogue }),
          (order.object && { title: 'Objekt', data: order.object }),
          { title: 'Koostas', data: this.findCreatorOfOrder(order) },
          { title: 'Viimati uuendas', data: this.findLastUpdaterOfOrder(order) },
          { title: 'Kg hind', data: this.calc.saleKgPrice + ' €/kg' },
          { title: 'Marginaal', data: this.calc.marginal + ' %' },
          { title: 'Allahindlus', data: order.discount + ' €' },
          { title: 'Müügihind kokku', data: this.calc.saleTotal + ' €', accent: true },
          (this.$can(['orderFields', 'recommendedPrice']) && { title: 'Soovitatav hind', data: this.calc.recommendedPrice + ' €', accent: true })
        ].filter(Boolean)
      },
      async getOrderProducingStartingDate (order) {

        if (order._id !== this.order._id) {
          this.order.documents = [...this.order.documents, ...this.$_get(order, 'documents', []).map(d => {
            d.fileName = `(${order.salesSystemNumber}) ${d.fileName}`
            d.subOrder = true
            return d
          })]
        }
        this.orderStartingDates.push(await this.getProducingStartingDate(order))

        const childOrders = this.$_get(order, 'linkedOrders.childOrders', [])
        for await (const o of childOrders) {
          await this.getOrderProducingStartingDate(o)
        }
      },
      async getProducingStartingDate (order) {
        let startingTime = null
        for await (const product of order.products) {
          await this.$unblockMainThread()
          for await (const workcenter of product.workcenters) {
            await this.$unblockMainThread()
            if (!startingTime) startingTime = workcenter.startingTime
            if ((new Date(workcenter.startingTime)).getTime() < (new Date(startingTime)).getTime()) {
              startingTime = workcenter.startingTime
            }
          }
        }
        return startingTime
      },
      async getEarliestProducingDate (dates) {
        let startingTime = null
        for await (const d of dates) {
          await this.$unblockMainThread()
          if (!startingTime) startingTime = d
          if ((new Date(d)).getTime() < (new Date(startingTime)).getTime()) {
            startingTime = d
          }
        }
        return startingTime
      },

      async setProjectCalculation (order) {
        this.projectCalculation = [
          {
            'Kulu nimetus': 'Materjali kulu',
            Kogus: this.calc.materialNetoCostTotal.amount + ' kg',
            Hind: (+this.calc.materialNetoCostTotal.amount > 0 ? (+this.calc.materialBrutoCostTotal.total / +this.calc.materialNetoCostTotal.amount).toFixed(2) : '0.00') + ' €/kg',
            Summa: this.calc.materialBrutoCostTotal.total + ' €'
          }, {
            'Kulu nimetus': 'Töömaht ja muud kulud',
            Kogus: this.calc.workTimeTotal.amount + ( this.calc.workTimeTotal.otherUnits ? '' : ' min' ),
            Hind: this.calc.workTimeTotal.price + ( this.calc.workTimeTotal.otherUnits ? '' : ' €/min' ),
            Summa: this.calc.workTimeTotal.total + ' €'
          }, ...(this.$can(['orderFields', 'setup-time']) ? [{
            'Kulu nimetus': 'Seadistusaeg',
            Kogus: this.calc.setupTime.time + ' min',
            Hind: this.calc.setupTime.price + ' €/min',
            Summa: this.calc.setupTime.total + ' €'
          }] : []), {
            'Kulu nimetus': 'Transport',
            Kogus: '',
            Hind: '',
            Summa: order.transport.price + ' €'
          }, {
            'Kulu nimetus': 'Kg hind',
            Kogus: '',
            Hind: '',
            Summa: this.calc.netCostKgPrice + ' €/kg'
          }, {
            'Kulu nimetus': 'Omahind Kokku',
            Kogus: '',
            Hind: '',
            Summa: this.calc.netCostTotal + ' €',
            accent: true
          }
        ]
      },
      findCreatorOfOrder (order) {
        order.audit ? order.audit.find(a => a.queryType === 'CREATE') : 'Info puudub'
        if (order.audit) {
          const operator = order.audit.find(a => a.queryType === 'CREATE')
          if (operator) {
            return `${operator.firstName} ${operator.lastName}`
          } else {
            return 'Info puudub'
          }
        }
      },
      findLastUpdaterOfOrder (order) {
        if (order.audit && order.audit.length) {
          const auditLength = order.audit.length
          const operator = order.audit[auditLength - 1]
          return `${operator.firstName} ${operator.lastName}`
        } else {
          return 'Info puudub'
        }
      },
      saveProject (formData) {
        this.$loader.start('detailed-sale')
        this.$orders.updateOrder(this.id, formData).then(() => {
          this.calculate(this.id)
          this.editModal = false
          this.detailedProjectNotOpened = true
          this.$loader.stop('detailed-sale')
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('detailed-sale')
        })
      },

      rowClass (item, type) {
        if (!item || type !== 'row') return
        if (item.accent) return 'text-bold'
      },
      annullOrder () {
        this.$confirm.show(
          'Kontrolli ka materjali koguseid ja vajadusel võta lattu arvele.',
          'Oled kindel, et soovid projekti tühistada?',
          answer => {
            if (answer) {
              this.$loader.start()
              this.$orders.updateOrder(this.order._id, {status: 'ANNULLED'}).then(() => {
                this.$loader.stop()
                this.$notify.show('Projekt edukalt tühistatud')
                this.close()
              }).catch(err => {
                this.$loader.stop()
                this.$err.show(err)
              })
            }
          }
        )
      },
      async updateStatus (status) {
        this.$loader.start('update-status')
        this.shouldSendEmail(status)

        if (status === 'ORDER') {
          this.$loader.stop('update-status')
          this.editDeliveryDateModal = true
        } else if (status === 'COMPLETE') {
          this.$orders.updateOrder(this.id, { status }).then(async rs => {
            await this.calculate(rs.data._id)
            this.$loader.stop('update-status')
            this.$notify.show('Projekt edukalt täidetud')
          }).catch(err => {
            this.$err.show(err)
            this.$loader.stop('update-status')
          })
        } else {
          const method = this.order.subStatus.includes(status) ? 'deleteSubStatus' : 'addSubStatus'
          await this.$orders[method](this.id, { status }).then(async rs => {
            await this.calculate(rs.data._id)
            this.$loader.stop('update-status')
          }).catch(err => {
            this.$err.show(err)
            this.$loader.stop('update-status')
          })
        }
      },
      async updateDeliveryDate (e) {
        this.$loader.start('update-status')
        this.$orders.updateOrder(this.id, { status: 'ORDER', deliveryDate: e }).then(async rs => {
          await this.calculate(rs.data._id)
          this.$loader.stop('update-status')
          this.editDeliveryDateModal = false
          this.$notify.show('Hinnapakkumine muudetud müügitellimuseks')
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('update-status')
        })
      },
      shouldSendEmail (status) {
        if (
          this.$can(['action', 'automatedEmailSending']) &&
          ((status === 'OFFER_EMAIL_SENT' && !this.order.subStatus.includes('OFFER_EMAIL_SENT')) ||
          (status === 'ORDER_EMAIL_SENT' && !this.order.subStatus.includes('ORDER_EMAIL_SENT')) ||
          ['ORDER', 'COMPLETE'].includes(status))
        ) {
          this.emailSendingType = status
          this.emailSendingModalVisible = true
        }
      },
      close (e) {
        e.preventDefault()
        this.$emit('close')
      }
    }
  }
</script>
<style>
  .hide-modal-scroll {
    overflow-y: hidden !important;
  }
</style>
<style lang="scss" scoped>
  .bg-blue {
    background-color: aliceblue;
    border-radius: 4px;
    height: 100%;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .bg-light-gray {
    // background-color: #f8f9fa;
    border-radius: 4px;
    height: 100%;
    // border: 0.0625rem solid #e3e3e3;
    // -webkit-box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
    // box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .stats-title {
    & > h5 {
      text-transform: uppercase;
      font-size: 12px;
      display: inline-block;
    }

    button,
    svg {
      font-size: 18px;
      float: right;
    }

    & > svg {
      font-size: 36px;
    }

    & > h2 {
      display: block;
    }
  }

  .divider {
    width: 1px;
    height: 39px;
    margin: 0 2px;
    background: #6c757d;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      #6c757d 20%,
      #6c757d 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
</style>
<style lang="scss">
  .table.stats-table > thead {
    line-height: 28px;
  }

  .table > tr:nth-child(odd) {
    background-color: transparent;
  }

  .table.hide-header > thead {
    opacity: 0;
  }

  .table.no-header > thead {
    display: none !important;
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .input-group.text-dark .form-control + .input-group-append .input-group-text {
    color: #212529 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-bold {
    font-weight: 700;
  }
</style>
