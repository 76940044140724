<template>
  <nav id="mainNav" class="navbar navbar-expand-lg fixed-top" :class="{ 'navbar-dark': this.$user.getTheme() === 'dark' }">
    <a class="navbar-brand" href="#">{{ routeName }}</a>
    <button
      class="navbar-toggler navbar-toggler-right"
      type="button"
      data-toggle="collapse"
      @click="toggleSidebar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbarResponsive" class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <faf-ribbon v-if="$mode !== 'production'" />

          <collapse-transition>
            <div v-show="showMenu" class="collapse navbar-collapse show">
              <ul class="navbar-nav ml-auto">
                <div class="search-bar input-group" @click="searchModalVisible = true">
                </div>
                <base-dropdown
                  tag="li"
                  title-tag="a"
                  class="nav-item"
                  menu-classes="dropdown-navbar"
                >
                  <span slot="title" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                    <div class="photo">
                      <img :src="avatar">
                    </div>
                    <b class="caret d-none d-lg-block d-xl-block"></b>
                    <p class="d-lg-none">
                    </p>
                  </span>
                  <li class="nav-link">
                    <span class="nav-item dropdown-item" @click="profileVisible = !profileVisible">Profiil</span>
                  </li>
                  <li v-can="['see', 'Sätted']" class="nav-link">
                    <span class="nav-item dropdown-item" @click="$router.push({name: 'Sätted'}).catch(() => {})">Sätted</span>
                  </li>
                  <li v-for="(link, index) in $topNavigationLinks" :key="index" class="nav-link">
                    <span class="nav-item dropdown-item" @click="$router.push({name: link.name}).catch(() => {})">{{ link.text }}</span>
                  </li>
                  <div class="dropdown-divider"></div>
                  <li class="nav-link">
                    <span href="#" class="nav-item dropdown-item" @click="logout">Logi välja</span>
                  </li>
                </base-dropdown>
              </ul>
            </div>
          </collapse-transition>
          <faf-profile v-if="profileVisible" :visible="profileVisible" @close="profileVisible = !profileVisible" />
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import FafProfile from './../views/Profile/Profile'
  import FafRibbon from './../components/Ribbon/Ribbon'
  import avatar from './../assets/images/avatar.js'
  import axios from 'axios'

  export default {
    components: {
      CollapseTransition,
      FafProfile,
      FafRibbon
    },
    data () {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        avatar,
        profileVisible: false
      };
    },
    computed: {
      routeName () {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown () {
        this.activeNotifications = false;
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      toggleMenu () {
        this.showMenu = !this.showMenu;
      },
      async logout () {
        localStorage.removeItem('user');
        localStorage.removeItem('sessionEndTime');
        localStorage.removeItem('company');
        if (!localStorage.company) {
          const { data } = await axios('api/info').catch(err => {
            alert('Ühenduse probleem. Proovi mõne hetke pärast lehte uuesti laadida')
            location.reload();
          })
          localStorage.company = data.company
        }
        this.$rolePrivileges.changeRole(['PUBLIC'])
        this.$router.push({ name: 'Login' })
      }
    }
  };
</script>
<style>
</style>
