export default {
  inject: ['$validator'],
  inheritAttrs: false,
  data () {
    return {
      val: this.value
    }
  },
  computed: {
    wrapperClasses () {
      return {
        active: this.active,
        'faf-input-with-addon': !!this.addOn,
        'faf-input-wrapper': true,
        'faf-invalid': this.isInValid(this.name)
      }
    },
    wrapperStyles () {
      return {
        'max-width': this.active ? this.maxWidth + 'px' : 'auto',
        'min-width': this.minWidth + 'px' || 'auto'
      }
    }
  },
  props: {
    value: {
      type: [
        String,
        Number,
        Boolean,
        Object,
        Date,
        Array
      ],
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      default: ''
    },
    textHidden: {
      type: Boolean,
      default: false
    },
    noText: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: 'Väli on nõutud!'
    },
    maxWidth: [Number, String],
    minWidth: [Number, String],
    addOn: [Number, String],
    number: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    }
  },
  methods: {
    onInput (event) {
      if (this.number) {
        const val = (event && event.target) ? event.target.value : event
        this.val = val.replace(",", ".")
      } else {
        this.val = (event && event.target) ? event.target.value : event
      }
      this.$emit('input', this.val)
    },
    isInValid (name) {
      if (this.$validator.errors.items.length > 0) {
        return this.$validator.errors.items.find(e => e.field === name)
      } else {
        return false
      }
    },
    editRow () {
      this.$emit('edit-row')
      this.$nextTick(() => {
        if (this.$refs[this.name]) {
          this.$refs[this.name].$el ? this.$refs[this.name].$el.focus() : this.$refs[this.name].focus()
        }
      })
    }
  }
}
