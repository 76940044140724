import Ajv from 'ajv';
import _getProductSalePrice from './_getProductSalePrice'

const ajv = new Ajv({ allErrors: true })

const schema = {
  required: ['products'],
  type: 'array',
  properties: {
    products: {
      type: 'object',
      required: ['quantity', 'price'],
      properties: {
        price: {
          type: 'number'
        },
        quantity: {
          type: 'object',
          required: ['amount'],
          properties: {
            amount: { type: 'number' }
          }
        }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getTotalSalePrice (products) {
  if (!test(products)) {
    throw { function: '_getTotalSalePrice', error: test.errors }
  }

  const calculations = []

  for await (const product of products) {
    calculations.push(await _getProductSalePrice(product))
  }

  return calculations.reduce((a, b) => a + +b, 0).toFixed(2)
}
