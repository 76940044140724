<template>
  <b-row class="mt-4">
    <b-col>
      <faf-pagination-right
        ref="sidePagination"
        :product-name-suggestions="order.products.map(p => p.name)"
        :total-pages="order.products.length"
        :total="order.products.length"
        name="detailed-paginate-search"
        @pagechanged="active = $event; calculate(order.products, $event)"
        @search="search"
      >
        <b-col>
          <faf-card
            :name="'product-detailed'"
            :collapse-button-visible="false"
            :scrollable="true"
            style="height: calc(100vh - 34.5rem);"
            class="slide-card mb-0 mt-3"
          >
            <div v-for="(product, index) in calculatedProducts" :key="index" style="border-bottom: 1px solid #e9e9e9;" class="mb-3">
              <b-row class="ml-1 mb-4 mt-4" style="padding-left: 40px">
                <b-badge class="badge-round text-light" variant="secondary" pill>
                  {{ active }}
                </b-badge>
                <faf-stats-title :text="product.name" title="Toote nimi" />
                <faf-stats-title :text="product.price + ' €'" title="Hind" />
                <faf-stats-title :text="`${product.quantity.amount} tk`" title="Kogus" />
                <faf-stats-title :text="statusTranslations[product.status]" title="Staatus" />
                <faf-stats-title :text="product.surfaceTreatment || '-'" title="Viimistlus" />
                <faf-stats-title :text="product.calc.materialCost + ' €'" title="Materjal" />
                <faf-stats-title :text="product.calc.workTime + ' €'" title="Töökulu" />
                <faf-stats-title :text="product.calc.netPrice + ' €'" title="Omahind" />
                <faf-stats-title :text="product.calc.salePrice + ' €'" title="Müügihind" />
                <faf-stats-title :text="product.calc.marginal + ' %'" title="Marginaal" />
              </b-row>
              <b-row v-if="product.materials.length > 0" class="mx-1 mt-0">
                <b-col xl="12">
                  <h4>Materjalid</h4>
                  <b-table :fields="materialFields" :items="product.materials" striped hover />
                </b-col>
              </b-row>
              <b-row v-if="product.workcenters.length > 0" class="mx-1 mt-0">
                <b-col xl="12">
                  <h4>Töökeskused</h4>
                  <b-table :fields="workcentersFields" :items="product.workcenters" striped hover>
                    <template v-slot:cell(workTime)="data">
                      {{ calculateWorkTime(data.item, product.quantity) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </faf-card>
        </b-col>
      </faf-pagination-right>
    </b-col>
  </b-row>
</template>
<script>
  import { _getProductMaterialCost, _getProductWorkTime, _getProductNetPrice, _getDiscountPercentage, _getProductMarginal, _getProductSalePriceWithDiscount } from './../../Calculations'
  import detailedMixin from './detailedMixin'
  export default {
    name: 'faf-detailed-products',
    mixins: [ detailedMixin ],
    props: {
      order: Object
    },
    data () {
      return {
        active: 1,
        items: [...Array(10)],
        calculatedProducts: [],
        totalRows: 200,
        currentPage: 1,
        perPage: 1,
        workcentersFields: [
          {
            key: 'name',
            label: 'Nimi'
          },
          {
            key: 'team',
            label: 'Tiim'
          },
          {
            key: 'workTime',
            label: 'Kogus'
          },
          {
            key: 'price',
            label: 'Hind',
            formatter: (value, key, item) => value + '€'
          },
          {
            key: 'notes',
            label: 'Märkmed'
          },
          (this.$can(['add', 'WorksheetCertificate']) && { key: 'proof', label: 'Sertifikaadid', formatter: (value, key, item) => this.getCertificates(value) })
        ]
      }
    },
    created () {
      this.totalRows = this.order.products.length
      this.calculate(this.order.products, this.active)
    },
    methods: {
      getCertificates (proof) {
        const certificates = proof.filter(v => v.certificate).map(v => v.certificate)
        return certificates.length ? certificates.join(', ') : ''
      },
      search (event) {
        const searchIndex = this.order.products.findIndex(p => p.name === event) + 1
        if (searchIndex !== 0) {
          this.active = searchIndex
          this.$refs.sidePagination.currentPage = searchIndex
          this.calculate(this.order.products, searchIndex)
        }

      },
      setActiveItem (index) {
        this.active = index
      },
      calculateWorkTime (item, quantity) {
        const setupTime = item.setupTime ? item.unit === 'min' ? +item.setupTime : +item.setupTime / 60 : 0
        const totalTime = (item.workTime * quantity.amount) + setupTime
        return this.$formatTime(totalTime, item.unit)
      },
      async calculate (products, page) {
        const paginatedProducts = [products[page - 1]]
        try {
          for await (const product of paginatedProducts) {
            const discountPercentage = await _getDiscountPercentage(this.order)
            product.calc = {
              materialCost: (await _getProductMaterialCost(product)).total,
              workTime: (await _getProductWorkTime(product)).total,
              netPrice: await _getProductNetPrice(product),
              salePrice: await _getProductSalePriceWithDiscount(product, discountPercentage),
              marginal: await _getProductMarginal(product)
            }
            this.calculatedProducts = [product]
          }
          this.currentPage = page
        } catch (error) {
          this.$loader.stop('detailed-sale')
          this.$err.show(JSON.stringify(error))
        }

      }
    }
  }
</script>
<style lang="scss" scoped>
  h4 {
    color: #1d253b;
    margin: 0;
  }
  .badge-round {
    font-size: 15px;
    margin-left: -40px;
    width: 40px;
    height: 40px;
    line-height: 30px;
    border-radius: 50%;
  }

  .stats-title {
    & > .text-info {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 22px;
    }

    & > .text-gray {
      font-size: 16px;
      line-height: 16px;
    }
  }
</style>
