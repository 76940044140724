<template>
  <div>
    <div class="row">
      <div class="col-md-7">
        <my-jobs-card />
      </div>
      <div class="col-md-5">
        <faf-all-active-jobs-table />
      </div>
    </div>
    <production-table-card />
  </div>
</template>
<script>
  import ProductionTableCard from './../../Components/Production/ProductionTableCard'
  import MyJobsCard from './../../Components/MyJobs/MyJobsCard'
  import { FafAllActiveJobsTable } from '@faf/faf-ui'

  export default {
    name: 'production',
    components: {
      ProductionTableCard,
      MyJobsCard,
      FafAllActiveJobsTable
    }
  }
</script>
