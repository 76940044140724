export default function installConfirm (Vue) {
  if (Vue) {
    Vue.prototype.$confirm = {
      _vm: new Vue({ data: {
        notificationMessage: null,
        notificationTitle: null,
        callback: () => ''
      }}),
      show (message, title, callback = () => '') {
        this._vm.$data.notificationMessage = message || 'Kas te olete kindel, et soovite lahkuda?'
        this._vm.$data.notificationTitle = title || 'Kinnitus'
        this._vm.$data.callback = callback
      },
      hide () {
        this._vm.$data.notificationMessage = null
        this._vm.$data.notificationTitle = null
        this._vm.$data.callback(false)
      },
      agree () {
        this._vm.$data.notificationMessage = null
        this._vm.$data.notificationTitle = null
        this._vm.$data.callback(true)
      },
      isActive () {
        return this._vm.$data.notificationMessage ? true : false
      },
      getMessage () {
        return this._vm.$data.notificationMessage
      },
      getTitle () {
        return this._vm.$data.notificationTitle
      }
    }
  } else {
    console.error('Vue not found')
  }
}
