<template>
  <div class="row">
    <div v-can="['see', 'Kasutajad']" class="col-md-7">
      <faf-card name="edit-user-table" title="Olemasolevad kasutajad" initial-collapsed>
        <edit-user-table />
      </faf-card>
    </div>
    <div v-can="['add', 'Kasutaja']" class="col-md-5">
      <faf-card initial-collapsed name="add-user" title="Lisa uus kasutaja">
        <add-user />
      </faf-card>
    </div>
    <div v-can="['see', 'Client']" class="col-md-5">
      <faf-card initial-collapsed name="clients" title="Kliendid">
        <clients-table />
        <template slot="buttons" slot-scope="data">
          <b-button
            v-if="!data.data.collapsed"
            id="add-new-sale"
            v-can="['add', 'Client']"
            v-b-modal.add-edit-client
            v-b-tooltip.hover
            class="icon-button text-success"
            title="Lisa uus klient"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </faf-card>
    </div>
    <div v-can="['see', 'Material']" class="col-md-7">
      <faf-card initial-collapsed name="materials" title="Materjalid">
        <materials-table />
        <template slot="buttons" slot-scope="data">
          <b-button
            v-if="!data.data.collapsed"
            id="add-new-sale"
            v-can="['add', 'Material']"
            v-b-modal.add-edit-material
            v-b-tooltip.hover
            class="icon-button text-success"
            title="Lisa uus materjal"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </faf-card>
    </div>
    <div v-can="['see', 'Supplier']" class="col-md-5">
      <faf-card initial-collapsed name="suppliers" title="Tarnijad">
        <suppliers-table />
        <template slot="buttons" slot-scope="data">
          <b-button
            v-if="!data.data.collapsed"
            id="add-new-sale"
            v-can="['add', 'Supplier']"
            v-b-modal.add-edit-supplier
            v-b-tooltip.hover
            class="icon-button text-success"
            title="Lisa uus tarnija"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </faf-card>
    </div>
    <div v-can="['see', 'Workcenter']" class="col-md-7">
      <faf-card initial-collapsed name="workcenters" title="Töökeskused">
        <workcenters-table />
        <template slot="buttons" slot-scope="data">
          <b-button
            v-if="!data.data.collapsed"
            id="add-new-sale"
            v-can="['add', 'Workcenter']"
            v-b-modal.add-edit-workcenter
            v-b-tooltip.hover
            class="icon-button text-success"
            title="Lisa uus töökeskus"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </faf-card>
    </div>
    <div class="col-md-3">
      <faf-card initial-collapsed name="work-time-report" title="Tööaja raport">
        <work-time-excel-report />
      </faf-card>
    </div>
    <div class="col-md-9">
      <faf-card initial-collapsed name="products" title="Eelsalvestatud tooted">
        <products-table />
      </faf-card>
    </div>
    <div class="col-md-6">
      <faf-card initial-collapsed name="order-settings" title="Tellimuste seaded">
        <order-settings />
      </faf-card>
    </div>
    <div v-can="['see', 'EmailSending']" class="col-md-3">
      <faf-card initial-collapsed name="email-settings" title="Emaili seaded">
        <email-settings />
      </faf-card>
    </div>
    <div class="col-md-3">
      <faf-card initial-collapsed name="global-company-settings" title="Ettevõtte seaded">
        <global-company-settings />
      </faf-card>
    </div>
  </div>
</template>
<script>
  import AddUser from './../../components/User/AddUser'
  import EditUserTable from './../../components/User/EditUserTable'
  import SuppliersTable from './../../produxio/SupplierManagement/SuppliersTable'
  import ClientsTable from './../../produxio/ClientManagement/ClientsTable'
  import MaterialsTable from './../../produxio/MaterialManagement/MaterialsTable'
  import WorkcentersTable from './../../produxio/WorkcentersManagement/WorkcentersTable'
  import ProductsTable from './../../produxio/ProductManagement/ProductsTable'
  import WorkTimeExcelReport from './../../produxio/Statistics/WorkTimeExcelReport/WorkTimeExcelReport'
  import EmailSettings from './../../produxio/Email/EmailSettings'
  import GlobalCompanySettings from './../../produxio/CompanySettings/GlobalCompanySettings'
  import OrderSettings from './../../produxio/CompanySettings/OrderSettings'

  export default {
    name: 'settings',
    components: {
      AddUser,
      EditUserTable,
      SuppliersTable,
      ClientsTable,
      MaterialsTable,
      WorkcentersTable,
      ProductsTable,
      WorkTimeExcelReport,
      EmailSettings,
      GlobalCompanySettings,
      OrderSettings
    }
  }
</script>
