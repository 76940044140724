<template>
  <div>
    <b-button
      v-b-tooltip.hover
      :disabled="disabled"
      class="icon-button text-info"
      title="Loo duplikaat"
      @click="modalVisible = true"
    >
      <font-awesome-icon icon="copy" />
    </b-button>
    <b-modal
      id="email-sending-modal"
      :visible="modalVisible"
      title="Klooni tellimus"
      title-tag="h4"
      @hide="modalVisible = false"
    >
      <b-row>
        <b-col v-if="$can(['orderFields', 'statusOffer'])" md="12" class="px-1 pb-2">
          <label for="status">Staatus</label>
          <b-select
            v-model="formData.status"
            v-validate="'required'"
            v-bind="$requiredInputProps('status')"
            placeholder="Staatus"
          >
            <option v-for="(option, optionIndex) in statusOptions" :key="'option-' + optionIndex" :value="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-col>
        <b-col md="12" class="px-1 pb-2">
          <label for="add-delivery-date">Tarneaeg</label>
          <datepicker
            v-model="formData.deliveryDate"
            v-validate="'required'"
            :disabled-dates="{to: new Date(disableToDate())}"
            v-bind="$datepickerProps('add-delivery-date')"
            name="add-delivery-date"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-delivery-date')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
        <b-col :class="{ 'field-with-tooltip': newClient }" md="12" class="px-1 pb-2">
          <label for="add-client">Klient</label>
          <multiselect
            v-model="formData.client"
            v-validate="'required'"
            v-faf-autocomplete-off
            :options="clientSuggestions"
            v-bind="$multiselectProps('add-client')"
            name="add-client"
            placeholder="Vali klient"
            @tag="addNewClient"
            @input="newClient = false"
            @search-change="searchChangeClient"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
          <b-button
            v-if="newClient && $can(['action', 'FilterClients'])"
            v-b-tooltip.hover
            title="Lisa uus klient"
            class="icon-tooltip icon-button text-success"
            style="cursor: pointer"
            @click="$refs['add-edit-client'].formData = $_cloneDeep({ ...clientFormData, name: formData.client })"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
          <div v-if="!$validateField('add-client')" class="invalid-feedback">
            Väli on nõutud
          </div>
        </b-col>
        <b-col v-if="$can(['orderFields', 'orderId']) || $can(['orderFields', 'orderIdMultiselect'])" md="12" class="px-1 pb-2">
          <label for="add-order-id">Projekti nr</label>
          <input
            v-model.trim="formData.orderId"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-order-id') }"
            :type="!$can(['orderFields', 'salesSystemNumber']) && 'number'"
            name="add-order-id"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-order-id')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
        <b-col v-if="$can(['orderFields', 'projectName'])" md="12" class="px-1 pb-2">
          <label for="add-project-name">Projekti nimi</label>
          <input
            v-model.trim="formData.projectName"
            :class="{ 'form-control': true }"
            name="add-project-name"
          />
        </b-col>
        <b-col v-if="$can(['orderFields', 'catalogue'])" md="12" class="px-1 pb-2">
          <label for="add-catalogue">Kataloog</label>
          <input
            v-model.trim="formData.catalogue"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-catalogue') }"
            name="add-catalogue"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-catalogue')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>

        <b-col v-if="$can(['orderFields', 'object'])" md="12" class="px-1 pb-2">
          <label for="add-object">Objekt</label>
          <input
            v-model.trim="formData.object"
            class="form-control"
            name="add-object"
          />
        </b-col>
      </b-row>
      <add-edit-client
        ref="add-edit-client"
        :key="addClientModal"
        @hide="addClientModal = !addClientModal"
        @client-added="newClient = false"
      />
      <div slot="modal-footer" class="container modal-buttons-wrapper">
        <div class="row justify-content-center">
          <b-button v-b-tooltip.hover :disabled="$loader.val()" :title="'Tagasi'" class="icon-button" @click="modalVisible = false">
            <font-awesome-icon :icon="'times-circle'" />
          </b-button>
          <b-button v-b-tooltip.hover class="icon-button text-success" title="Klooni" @click="cloneOrder">
            <font-awesome-icon icon="copy" />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import AddEditClient from './../ClientManagement/AddEditClient'
  export default {
    name: 'clone-order-modal',
    components: {
      AddEditClient
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: ''
      },
      client: {
        type: String,
        default: ''
      },
      catalogue: {
        type: String,
        default: ''
      },
      object: {
        type: String,
        default: ''
      },
      orderId: {
        type: String,
        default: ''
      },
      projectName: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        modalVisible: false,
        formData: {
          deliveryDate: new Date(),
          status: this.$can(['orderFields', 'statusOffer']) ? 'OFFER' : 'ORDER',
          client: ''
        },
        clientSuggestions: [],
        addClientModal: false,
        newClient: false,
        clientFormData: {
          name: '',
          address: '',
          contacts: [{
            name: '',
            email: '',
            phone: ''
          }]
        },
        statusOptions: [
          { label: 'Tellimus', value: 'ORDER'},
          { label: 'Hinnapakkumine', value: 'OFFER' }
        ]
      }
    },
    watch: {
      id: {
        immediate: true,
        handler () {
          this.formData.client = this.client
          if (this.$can(['orderFields', 'object'])) this.formData.object = this.object
          if (this.$can(['orderFields', 'catalogue'])) this.formData.catalogue = this.catalogue
          if (this.$can(['orderFields', 'orderId']) && this.$can(['orderFields', 'salesSystemNumber'])) this.formData.orderId = this.orderId
          if (this.$can(['orderFields', 'projectName'])) this.formData.projectName = this.projectName
        }
      }
    },
    created () {
      this.searchChangeClient = this.$_debounce(this.searchChangeClient, 300)

    },
    methods: {
      async cloneOrder () {
        this.$confirm.show(
          'Kas soovite kopeerida ka dokumente ?',
          'Kas soovite kopeerida ka dokumente ?',
          async answer => {
            this.$loader.start('duplicate-order')
            await this.$orders.cloneOrder(this.id, {...this.formData, cloneDocuments: answer})
              .then(rs => {
                const id = this.$can(['orderFields', 'salesSystemNumber']) ? rs.data.salesSystemNumber : rs.data.orderId
                this.$router.replace({name: 'Tellimused', query: { filter: JSON.stringify({[this.$can(['orderFields', 'salesSystemNumber']) ? 'salesSystemNumber' : 'orderId']: id}) }})
                this.$notify.show(`${id} lisatud!`)
                this.$cardReset('archive-table')
              })
              .catch(err => this.$err.show(err))
            this.$loader.stop('duplicate-order')
          }
        )

      },
      disableToDate () {
        const d = new Date()
        return d.setDate(d.getDate() - 1)
      },
      // Client
      addNewClient (value) {
        this.clientSuggestions.push(value)
        this.formData.client = value
        this.newClient = true
      },
      async searchChangeClient (e) {
        if (e.length > 1) {
          this.clientSuggestions = (await this.$suggestions.getClientNames(e)).data
        }
      }
    }
  }
</script>
