<template>
  <faf-card name="purchase-order" title="Ostutellimused">
    <faf-purchase />
    <template slot="buttons">
      <b-button v-b-tooltip.hover v-b-modal.modal-add-new-purchase class="icon-button text-success" title="Lisa uus ostutellimus">
        <font-awesome-icon icon="plus-circle" />
      </b-button>
    </template>
  </faf-card>
</template>
<script>
  import { FafPurchase } from '@faf/faf-ui'
  export default {
    name: 'purchase-card',
    components: {
      FafPurchase
    }
  }
</script>
