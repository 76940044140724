<template>
  <b-modal
    visible
    size="xl"
    style="z-index: 9999;"
    scrollable
    title-tag="h4"
    @hide="close"
  >
    <template v-slot:modal-header="{ close }">
      <b-container v-if="order" fluid class="mt-3 mb-2">
        <b-row class="faf-mobile-hide">
          <b-col lg="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>ID</strong>
              </h5>
              <font-awesome-icon icon="project-diagram" />
              <h2 class="mb-2 text-info">
                <strong>{{ order.salesSystemNumber }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>Töökeskus</strong>
              </h5>
              <font-awesome-icon icon="cube" />
              <h2 class="mb-2 text-info">
                <strong>{{ order.product.workcenters.name }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>Tehtud</strong>
              </h5>
              <font-awesome-icon icon="chart-line" />
              <h2 class="mb-2 text-info">
                <strong>{{ order.product.workcenters.doneProducts + ' / ' + order.product.quantity.amount }}</strong>
              </h2>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="p-3 bg-blue stats-title">
              <h5 class="text-gray mb-0">
                <strong>Tarne kp</strong>
              </h5>
              <font-awesome-icon icon="truck" />
              <h2 class="mb-2 text-info">
                <strong>{{ $dayjs(order.deliveryDate).format('DD.MM.YYYY') }}</strong>
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <b-progress :value="order.product.workcenters.doneProducts" :max="order.product.quantity.amount" :variant="'success'" striped />
          </b-col>
        </b-row>
      </b-container>
    </template>

    <div v-if="order">
      <b-tabs class="mb-3 mt-1" nav-class="nowrap" align="center">
        <b-tab title="Ülevaade">
          <b-container fluid>
            <b-row class="my-4">
              <b-col lg="5">
                <div class="p-3 bg-light-gray stats-title">
                  <h5 class="text-gray mb-0">
                    <strong>Toote ja tellimuse info</strong>
                  </h5>
                  <b-table :items="productInfo" class="no-header" striped hover />
                </div>
              </b-col>
              <b-col lg="7">
                <div class="p-3 bg-light-gray stats-title">
                  <h5 class="text-gray mb-0">
                    <strong>Töökeskuse info</strong>
                  </h5>
                  <b-table :items="workcenterInfo" class="no-header" striped hover />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>

        <b-tab active title="Materjalid">
          <b-container fluid>
            <b-row class="my-4">
              <b-col lg="12">
                <b-form-checkbox v-if="$can(['orderFields', 'linkedMaterials'])" v-model="allMaterials" name="all-materials" class="ml-2 mb-2">
                  Näita kõiki materjale
                </b-form-checkbox>
                <b-table
                  v-if="materials.length > 0"
                  :fields="materialFields"
                  :items="materials"
                  stacked="xl"
                  striped
                  hover
                />
                <b-alert v-else class="mx-2 my-3" variant="info" show>
                  {{ allMaterials ? 'Materjalid puuduvad!' : 'Lingitud materjalid puuduvad!' }}
                </b-alert>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab v-if="$can(['action', 'ProductStopped'])" title="Seisakud">
          <b-row class="my-4">
            <b-col cols="12">
              <b-table :fields="stoppedAuditFields" :items="order.product.stoppedAudit" striped hover />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div v-if="order" class="row justify-content-center">
        <!-- COMMON BUTTONS -->
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="close">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>

        <!-- COMMON BUTTONS -->
        <b-button
          v-b-tooltip.hover
          :disabled="$loader.val()"
          class="icon-button text-primary"
          title="Salvesta fail"
          @click="getOrderDetails()"
        >
          <font-awesome-icon icon="file-download" />
        </b-button>
        <b-button
          v-can="['see', 'FileManager']"
          v-b-tooltip.hover
          v-b-modal.file-manager
          :disabled="$loader.val()"
          class="icon-button text-info"
          title="Dokumendid"
        >
          <font-awesome-icon icon="folder-open" />
        </b-button>
      </div>
    </div>

    <faf-get-pdf
      v-if="pdfDownloadVisible"
      :visible="pdfDownloadVisible"
      :order="pdfData"
      :client="order.client"
      type="PRODUCTION"
      @close="pdfDownloadVisible = false"
    />
    <faf-file-manager v-if="order" :key="order.documents.length" v-can="['see', 'FileManager']" :order-object-id="order._id" :pre-loaded-documents="order.documents || []" />
  </b-modal>
</template>
<script>
  import detailedMixin from './detailedMixin'
  import { _getWorkcenterProductivity } from './../../Calculations/index'
  const TRANSLATIONS = {
    'NO_INFORMATION': 'Info Puudu',
    'MATERIAL': 'Materjal',
    'QUALITY': 'Kvaliteet'
  }
  export default {
    name: 'faf-detailed-production',
    inject: ['$validator'],
    mixins: [ detailedMixin ],
    props: {
      id: String
    },
    data () {
      return {
        stoppedAuditFields: [
          {
            key: 'reason',
            label: 'Põhjus',
            formatter: (value, key, item) => `${TRANSLATIONS[value]}`
          },
          {
            key: 'firstName',
            label: 'Kasutajanimi',
            formatter: (value, key, item) => `${item.firstName} ${item.lastName}`
          },
          {
            key: 'startTime',
            label: 'Algusaeg',
            formatter: (value, key, item) => this.$dayjs(value).format('DD.MM.YYYY HH:mm')
          },
          {
            key: 'endTime',
            label: 'Lõpuaeg',
            formatter: (value, key, item) => value ? this.$dayjs(value).format('DD.MM.YYYY HH:mm') : '-'
          }
        ],
        order: null,
        pdfData: null,
        workcenterInfo: [],
        productInfo: [],
        allMaterials: this.$can(['orderFields', 'linkedMaterials']) ? false : true,
        pdfDownloadVisible: false
      }
    },
    computed: {
      materials () {
        if (this.allMaterials) {
          return this.order.product.materials
        } else {
          return this.order.product.materials.filter(material => this.order.product.workcenters.linkedMaterials && this.order.product.workcenters.linkedMaterials.includes(material._id))
        }
      }
    },
    watch: {
      id (val) {
        this.getOrder(val)
      }
    },
    created () {
      this.getOrder(this.id)
    },
    methods: {
      async getOrder (id) {
        this.$loader.start('detailed-production')
        await this.$orders.getAllWorkcenterWithParent({page: 1, limit: 1, filter: { 'products.workcenters._id': this.id, doneProducts: true }}).then(async rs => {
          if (rs.data.docs[0]) {
            this.order = rs.data.docs[0]
            this.setWorkcenterInfo(this.order)
            this.setProductInfo(this.order)
            await this.getFiles(rs.data.docs[0]._id)
          } else {
            this.$err.show('Midagi läks valesti')
          }
          this.$loader.stop('detailed-production')
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('detailed-production')
        })
      },
      async getFiles (orderId) {
        try {
          if (orderId) {
            const { data } = await this.$orders.getOrderById(orderId)
            await this.getChildFiles(data)
            await this.getParentFiles(data)
          }
        } catch (err) {
          this.$err.show(err)
          this.$loader.stop('detailed-production')
        }

      },
      async getChildFiles (order) {

        if (order._id !== this.order._id) {
          this.order.documents = [...this.order.documents, ...order.documents.map(d => {
            d.fileName = `(${order.salesSystemNumber}) ${d.fileName}`
            d.subOrder = true
            return d
          })]
        }
        const childOrders = this.$_get(order, 'linkedOrders.childOrders', [])
        for await (const o of childOrders) {
          await this.getChildFiles(o)
        }
      },
      async getParentFiles (order) {

        const parentOrder = this.$_get(order, 'linkedOrders.parentOrder', {})
        if (parentOrder && parentOrder.salesSystemNumber) {
          if (parentOrder._id !== this.order._id) {
            this.order.documents = [...this.order.documents, ...parentOrder.documents.map(d => {
              d.fileName = `(${parentOrder.salesSystemNumber}) ${d.fileName}`
              d.subOrder = true
              return d
            })]
          }
          await this.getParentFiles(parentOrder)
        }
      },
      async getOrderDetails () {
        this.$loader.start('sale')
        this.$orders.getOrderById(this.order._id).then(async rs => {
          this.pdfData = rs.data
          this.pdfDownloadVisible = !this.pdfDownloadVisible
          this.$loader.stop('sale')
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('sale')
        })
      },
      async setProductInfo (order) {
        this.productInfo = [
          { title: 'Toote nimi', data: order.product.name },
          { title: 'Kogus', data: `${order.product.quantity.amount} tk` },
          { title: 'Staatus', data: this.statusTranslations[order.product.status] },
          { title: 'Viimistlus', data: order.product.surfaceTreatment || '-' },
          { title: 'Reg kp', data: this.$dayjs(order.createdDate).format('DD.MM.YYYY') },
          { title: 'Tarne kp', data: this.$dayjs(order.deliveryDate).format('DD.MM.YYYY') },
          (order.catalogue && { title: 'Kataloog', data: order.catalogue }),
          (order.object && { title: 'Objekt', data: order.object })
        ].filter(Boolean)
      },
      async setWorkcenterInfo (order) {
        const productivity = await _getWorkcenterProductivity(order)
        this.workcenterInfo = [
          { title: 'Algusaeg', data: this.$dayjs(order.product.workcenters.startingTime).format('DD.MM.YYYY') },
          { title: 'Tarneaeg', data: this.$dayjs(order.product.workcenters.deliveryDate).format('DD.MM.YYYY') },
          { title: 'Aeg kokku', data: this.$formatTime(this.$_get(order, 'product.workcenters.workTime', []) * this.$_get(order, 'product.quantity.amount'), this.$_get(order, 'product.workcenters.unit', null)) },
          { title: 'Tegelik aeg', data: this.$formatTime(this.$_get(order, 'product.workcenters.activeTime', []), this.$_get(order, 'product.workcenters.unit', null)) },
          { title: 'Tootlikkus', data: productivity ? productivity + '%' : '-' },
          { title: 'Koormus', data: this.getLoad(order) },
          { title: 'Tiim', data: order.product.workcenters.team }
        ].filter(Boolean)
      },
      getLoad (item) {
        const unit = this.$_get(item, 'product.workcenters.unit', null)
        if (unit === 'min' || unit === 'h') {
          const workTime = this.$_get(item, 'product.workcenters.workTime', [])
          const amount = this.$_get(item, 'product.quantity.amount')
          const divider = 7.5 * (unit === 'min' ? 60 : 1)
          return `${((workTime * amount) / divider).toFixed(1)}`
        }
        return '-'
      },
      close (e) {
        e.preventDefault()
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .bg-blue {
    background-color: aliceblue;
    border-radius: 4px;
    height: 100%;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .bg-light-gray {
    // background-color: #f8f9fa;
    border-radius: 4px;
    height: 100%;
    // border: 0.0625rem solid #e3e3e3;
    // -webkit-box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
    // box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .stats-title {
    & > h5 {
      text-transform: uppercase;
      font-size: 12px;
      display: inline-block;
    }

    button,
    svg {
      font-size: 18px;
      float: right;
      display: inline-block;
    }

    & > svg {
      font-size: 36px;
    }

    & > h2 {
      display: block;
    }
  }

  .divider {
    width: 1px;
    height: 39px;
    margin: 0 2px;
    background: #6c757d;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      #6c757d 20%,
      #6c757d 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
</style>
<style lang="scss">
  .table.stats-table > thead {
    line-height: 28px;
  }

  .table > tr:nth-child(odd) {
    background-color: transparent;
  }

  .table.hide-header > thead {
    opacity: 0;
  }

  .table.no-header > thead {
    display: none !important;
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .input-group.text-dark .form-control + .input-group-append .input-group-text {
    color: #212529 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-bold {
    font-weight: 700;
  }

  @media (max-width: 992px) {
    .faf-mobile-hide {
      display: none;
    }
  }
</style>
