<template>
  <div v-can="['see', 'Ost']" :class="{ 'with-bottom-bar': addNewSale }">
    <div class="row">
      <div class="col-md-5 s">
        <purchase-suggestions-card />
        <inventory-card />
      </div>
      <div class="col-md-7 s">
        <purchase-card />
        <purchase-history-card />
      </div>
    </div>
  </div>
</template>
<script>
  import PurchaseSuggestionsCard from './../../Components/Purchase/PurchaseSuggestionsCard'
  import PurchaseCard from './../../Components/Purchase/PurchaseCard'
  import PurchaseHistoryCard from './../../Components/Purchase/PurchaseHistoryCard'
  import InventoryCard from './../../Components/Inventory/InventoryCard'

  export default {
    name: 'purchase',
    components: {
      PurchaseSuggestionsCard,
      PurchaseCard,
      InventoryCard,
      PurchaseHistoryCard
    },
    props: {
    },
    data () {
      return {
        addNewSale: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .with-bottom-bar {
    margin-bottom: 80px;
  }
</style>
