import get from 'lodash/get'
import { formatTime } from './../../../helperMethods/dateHelpers'

export function getPlannedTime (product) {
  const workcenters = get(product, 'product.workcenters', [])
  const productAmount = get(product, 'product.quantity.amount', 0)
  const units = workcenters.map(w => w.unit)
  const unit = units.length && (units.every(val => val === 'min') || units.every(val => val === 'h')) ? units[0] : null
  return formatTime(workcenters.reduce((a, b) => a + b.workTime, 0) * productAmount, unit)
}

export function getMaterialCalculation (product) {
  const materials = get(product, 'product.materials', [])
  return materials.reduce((a, material) => {
    const sum = get(product, 'product.quantity.amount', 1) * material.amount
    return a + (material.actualSize * get(material, 'specialWeight', 1) * sum * (material.price || 0.00001))
  }, 0).toFixed(2)
}

export function getLaborCosts (product) { // getProductWorkTime
  const workcenters = get(product, 'product.workcenters', [])
  const productQuantity = get(product, 'product.quantity.amount', 0)
  return workcenters.reduce((a, workcenter) => a + ((productQuantity * workcenter.workTime) * (workcenter.price)), 0).toFixed(2)
}

export function getMyPrice (product) {
  const materialCost = +getMaterialCalculation(product)
  const laborCost = +getLaborCosts(product)
  const price = materialCost + laborCost
  return price.toFixed(2)
}

export function getSalePrice (product) {
  const productAmount = get(product, 'product.quantity.amount', 0)
  const productPrice = get(product, 'product.price', 0.00001)
  return (productAmount * productPrice).toFixed(2)
}

export function getMarginal (product) {
  const salePrice = +getSalePrice(product) || 0.0001
  const myPrice = +getMyPrice(product) || 0.0001
  const result = (salePrice - myPrice) / salePrice * 100
  return result.toFixed(2)
}
