import Ajv from 'ajv';
import get from 'lodash/get'
import _getProductNetPrice from './_getProductNetPrice'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['quantity', 'price'],
  properties: {
    price: {
      type: 'number'
    },
    quantity: {
      type: 'object',
      required: ['amount'],
      properties: {
        amount: { type: 'number' }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getProductSalePriceWithDiscount (product, discountPercentage) {
  if (!test(product)) {
    throw { function: '_getProductSalePrice', error: test.errors }
  }

  let productSetupTimePrice = 0
  if (product.workcenters.length > 0) {
    productSetupTimePrice = product.workcenters.map(w => +w.setupTime * +w.price).reduce((a, b) => +a + +b, 0) || 0
  }

  const useNetPrice = typeof product.useNetPrice === "boolean" ? product.useNetPrice : JSON.parse(localStorage.orderSettings).defaultUseNetPrice === true

  if (useNetPrice) {
    const productPrice = +(await _getProductNetPrice(product)) + +productSetupTimePrice
    return (+productPrice - (+productPrice * (+discountPercentage || 0))).toFixed(2)
  } else {
    const productPrice = +product.price * +product.quantity.amount
    return +(+productPrice - (+productPrice * (+discountPercentage || 0))).toFixed(2)
  }
}
