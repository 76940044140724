export default function installAsyncEmit (Vue, settings) {
  if (Vue) {
    Vue.prototype.$emit_p = function (topic, varargs) {
      return new Promise((resolve, reject) => {
        const arr = Array.from(arguments)
        let invoked = false
        const promiseLike = {
          resolve: val => {
            if (!invoked) {
              invoked = true
              resolve(val)
            }
          },
          reject: val => {
            if (!invoked) {
              invoked = true
              reject(val)
            }
          }
        }
        arr.push(promiseLike)
        this.$emit.apply(this, arr)
      });
    }
  } else {
    console.error('Vue not found, cant install asyncEmit')
  }
}
