export function hexToRGB (hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r},${g},${b}, ${alpha})`;
  } else {
    return `rgb(${r},${g},${b})`;
  }
}

export const days = [
  'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev', 'Pühapäev'
]

export const months = [
  'Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'
]

export const extraOptions = {
  responsive: true,
  legend: false,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        min: 0,
        display: false
      }
    }],
    xAxes: [{
      ticks: {
        display: false
      }
    }]
  }
}
