import Ajv from 'ajv';
import _getMaterialTotalWeight from './_getMaterialTotalWeight'
import get from 'lodash/get'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['products'],
  properties: {
    products: {
      type: 'array',
      required: ['materials'],
      properties: {
        materials: {
          type: 'array'
        }
      }
    },
    type: {
      type: 'string',
      enum: [
        'size', 'actualSize'
      ]
    }
  }
}

const test = ajv.compile(schema)

export default async function _getTransportPrice (order, orderSettings) {
  if (!test(order)) {
    throw { function: '_getTransportPrice', error: test.errors }
  }

  const transportPrice = get(order, 'transport.price')

  if (transportPrice || transportPrice === 0) {
    return transportPrice
  }
  if (orderSettings.calculateTransportPrice) {
    const quantity = (await _getMaterialTotalWeight(order.products)) / 700
    return Math.ceil(quantity) * 30
  }
  if (orderSettings.defaultTransportPrice || orderSettings.defaultTransportPrice === 0) {
    return orderSettings.defaultTransportPrice
  }
  return 30
}
