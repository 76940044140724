<template>
  <div class="dataTables_wrapper">
    <faf-filters
      :fields="filterFields"
      :cols="4"
      no-options-label="Laen..."
      @getOptions="getAllSuggestions"
      @filter="filterTable"
    />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :sort-compare="$sortDate"
        :items="items"
        :fields="fields"
        :busy="$loader.val('purchase-suggestions')"
        striped
        show-empty
        stacked="xl"
        hover
        selectable
        select-mode="multi"
        selected-variant="success"
        empty-text="Andmed puuduvad"
        @row-selected="rowSelected"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(product.purchaseSuggestionDeliveryDate)="data">
          <span v-if="data.item.product.purchaseSuggestionDeliveryDate">{{ $dayjs(data.item.product.purchaseSuggestionDeliveryDate).format('DD.MM.YYYY') }}</span>
        </template>
        <template v-slot:cell(selected)="{ rowSelected }">
          <span v-if="rowSelected">✔</span>
        </template>
        <template v-slot:cell(product.materials.name)="data">
          <div class="pl-1">
            <b-button
              v-if="isForceProduction(data.item.product)"
              v-b-tooltip.hover
              :class="'icon-button text-danger'"
              :title="'Materjaliga seotud toode on tootmises'"
              style="font-size: 20px; padding: 0;"
            >
              <font-awesome-icon
                icon="exclamation"
              />
            </b-button>
            <span :class="{'pl-2' : isForceProduction(data.item.product), 'pl-3' : !isForceProduction(data.item.product)}">{{ data.item.product.materials.name }}</span>
          </div>
        </template>
      </b-table>
    </div>
    <faf-add-purchase-modal
      ref="add-purchase-modal"
      v-can="['add', 'Ost']"
      modal-name="modal-add-new-purchase-suggestion"
      @addOrder="addOrder"
      @fromInventory="fromInventory"
      @show="fillOrderMaterials"
    />
  </div>
</template>
<script>
  import FafAddPurchaseModal from './AddPurchaseModal'
  export default {
    name: 'faf-purchase-suggestions',
    components: {
      FafAddPurchaseModal
    },
    data () {
      return {
        filterFields: [
          { type: 'multiselect', name: 'products.materials.name', placeholder: 'Materjal', options: [] },
          { type: 'multiselect', name: 'orderId', placeholder: 'Projekti nr', options: [], can: ['orderFields', 'orderId'] },
          { type: 'multiselect', name: 'salesSystemNumber', placeholder: 'ID', options: [], can: ['orderFields', 'salesSystemNumber'] },
          { type: 'datepicker', name: 'products.purchaseSuggestionDeliveryDate', placeholder: 'Kuupäev' }
        ],
        linkedMaterials: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 30,
        fields: [
          {
            key: 'product.materials.name',
            label: 'Materjal',
            sortable: true
          },
          (this.$can(['orderFields', 'salesSystemNumber']) && {
            key: 'salesSystemNumber',
            label: 'ID',
            sortable: true
          }),
          (this.$can(['orderFields', 'orderId']) && {
            key: 'orderId',
            label: 'Projekti Nr',
            sortable: true
          }),
          {
            key: 'product.purchaseSuggestionDeliveryDate',
            label: 'Kuupäev',
            sortable: true
          },
          {
            key: 'need',
            label: 'Vajadus',
            sortable: true,
            formatter: (value, key, item) => `${value && value.toFixed(2)} ${this.$_get(item, 'product.materials.unit', 'tk')}`
          },
          {
            key: 'sum',
            label: 'Summa',
            formatter: (value, key, item) => `${this.calculateSum(item)} €`,
            sortable: true,
            sortByFormatted: true
          }
        ],
        items: [],
        selected: []
      }
    },
    async created () {
      this.$loader.start('purchase-suggestions')
      await this.$orders.getAllMaterialsWithParent().then(rs => {
        this.totalRows = rs.data.length
        this.items = rs.data
      })
      this.$loader.stop('purchase-suggestions')
    },
    methods: {
      isForceProduction (product) {
        return product.forceProduction || (product.subStatus && product.subStatus.includes('FORCE_PRODUCTION'))
      },
      filterTable (query) {
        this.$orders.getAllMaterialsWithParent(query).then(rs => {
          this.currentPage = 1
          this.totalRows = rs.data.length
          this.items = rs.data
        })
      },
      async getAllSuggestions (event) {
        await this.filterFields.map(async (field) => {
          if (field.type !== 'datepicker' && field.name !== 'products.status') {
            if (field.options && !field.options.length && event === field.name) {
              field.options = (await this.$typeahead.getSuggestions(field.name)).data.filter(n => n)
            }
          }
        })
      },
      calculateSum (item) {
        const need = this.$_get(item, 'need', 1)
        const materialPrice = this.$_get(item, 'product.materials.price', 1)
        const specialWeight = this.$_get(item, 'product.materials.specialWeight', 1)
        const result = need * materialPrice * specialWeight
        return result.toFixed(2)
      },
      rowSelected (items) {
        this.selected = items
        this.$emit('items-selected', !!items.length)
      },
      fillOrderMaterials () {
        this.$refs['add-purchase-modal'].orders = this.selected.map(item => {
          const date = item.product.purchaseSuggestionDeliveryDate
          const prefilledDate = this.$refs['add-purchase-modal'].formData.deliveryDate
          //TODO Next line may not be used
          this.$refs['add-purchase-modal'].formData.linkedMaterials = [...this.$refs['add-purchase-modal'].formData.linkedMaterials, ...item.nestedMaterials].filter(f => f).flat(Infinity);
          this.$refs['add-purchase-modal'].formData.deliveryDate = this.$dayjs(prefilledDate).isBefore(this.$dayjs(date)) ? prefilledDate : date

          return {
            name: item.product.materials.name,
            materialId: item.product.materials._id,
            deleteDisabled: true,
            demandInKg: item.need,
            unit: this.$_get(item, 'product.materials.unit', 'tk'),
            price: this.$_get(item, 'product.materials.price', 0.001),
            specialWeight: this.$_get(item, 'product.materials.specialWeight', 1),
            totalPrice: this.calculateSum(item),
            projectNumber: this.$can(['orderFields', 'salesSystemNumber']) ? item.salesSystemNumber || this.$_get(item, 'orderId', 'Ladu') : this.$_get(item, 'orderId', 'Ladu'),
            notes: '',
            nestedMaterials: item.nestedMaterials
          }
        })
      },
      addOrder (order) {
        this.$loader.start()
        this.$purchase.purchaseRecommendationToOrder(order).then(() => {
          this.$cardReset('purchase-order')
          this.$cardReset('purchase-suggestions')
          this.$bvModal.hide('modal-add-new-purchase-suggestion')
          this.$notify.show('Uus ostutellimus edukalt lisatud!')
          this.$loader.stop()
        }).catch(err => this.$err.show(err))
      },
      fromInventory (order) {
        this.$loader.start()
        this.$purchase.purchaseFromInventory(order).then(rs => {
          if (!rs.data.failed.length) {
            this.$cardReset('purchase-order')
            this.$cardReset('purchase-suggestions')
            this.$cardReset('inventory')
            this.$bvModal.hide('modal-add-new-purchase-suggestion')
            this.$notify.show('Materjalid laost edukalt maha kantud!')
            this.$loader.stop()
          } else {
            this.$refs['add-purchase-modal'].orders = this.$refs['add-purchase-modal'].orders.filter((order) => {
              console.log(rs.data.failed)
              const failedMaterialNames = rs.data.failed.map(m => m.materialId)
              console.log('order', order)
              if (failedMaterialNames.includes(order.materialId)) {
                return order
              }
            })
            this.$err.show('Laos puudusid osad materjalid.')
            this.$loader.stop()
          }
        }).catch(err => {
          this.$loader.stop()
          this.$err.show(err)
        })
      }
    }
  }
</script>
