import dayjs from 'dayjs'
import moment from 'moment'
import get from 'lodash.get'

export function formatDate (date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function formatTime (time, unit, full = false) {
  if (!unit || (unit !== 'min' && unit !== 'h')) {
    return '-'
  }

  const momentUnit = unit === 'min' ? 'minutes' : 'hours'
  const days = String(Math.floor(moment.duration({ [momentUnit]: time }).asDays()))
  const hours = String(moment.duration({ [momentUnit]: time }).hours())
  const min = String(moment.duration({ [momentUnit]: time }).minutes())
  const pad = (s) => s.length === 1 ? '0' + s : s
  if (full) {
    return `${pad(days)} päeva, ${pad(hours)} tundi ja ${pad(min)} minutit`
  }
  return `${pad(days)}:${pad(hours)}:${pad(min)}`
}

export function formatHoursAndMinutes (time, unit) {
  if (!unit || (unit !== 'min' && unit !== 'h')) {
    return '-'
  }

  const momentUnit = unit === 'min' ? 'minutes' : 'hours'

  const hours = String(Math.floor(time / 60))
  const min = String(moment.duration({ [momentUnit]: time }).minutes())
  const pad = (s) => s.length === 1 ? '0' + s : s

  return `${pad(hours)} tundi ja ${pad(min)} minutit`
}

export function installDateHelpers (Vue) {
  if (Vue) {
    Vue.prototype.$getDateColor = function (date) {
      const today = new Date()
      const company = JSON.parse(localStorage.settings)
      const PLASMAPRO = company.name === 'plasmapro'
      if (formatDate(today) > formatDate(date)) {
        return 'danger'
      }
      if (PLASMAPRO && formatDate(dayjs(today).add(1, 'day')) === formatDate(date)) {
        return 'info'
      }
      if (formatDate(today) === formatDate(date)) {
        return 'warning'
      }
      return 'success'
    }

    Vue.prototype.$sortDate = function (a, b, key) {
      if (!['product.workcenters.startingTime', 'product.purchaseSuggestionDeliveryDate', 'product.workcenters.deliveryDate', 'deliveryDate'].includes(key)) {
        return null
      }
      const keyA = get(a, key, '0')
      const keyB = get(b, key, '0')

      if (moment(keyA).isValid() && moment(keyB).isValid()) {
        if (moment(keyA).isBefore(moment(keyB))) {
          return 1
        }
        if (moment(keyA).isAfter(moment(keyB))) {
          return -1
        } else if (moment(keyA).isSame(moment(keyB))) {
          return -1
        }
      }
      return null
    }

    Vue.prototype.$formatTime = formatTime
  } else {
    console.error('Vue not found')
  }
}
