<template>
  <div>
    <h4 class="mb-0 text-info">
      MATERJALID
    </h4>
    <b-table
      v-click-outside="disableEditMode"
      :class="currentTableId"
      :items="materials"
      :fields="materialFields"
      striped
      show-empty
      hover
    >
      <template v-slot:empty>
        <b-button
          :disabled="actionsDisabled"
          class="icon-button text-info"
          @click="addMaterial(null, true)"
        >
          <span class="icon-button-text">Lisa uus materjal</span><font-awesome-icon icon="plus-circle" />
        </b-button>
      </template>

      <template v-slot:cell(diagramNumber)="data">
        <faf-input
          v-model="formData[data.index].diagramNumber"
          :active="editIndex === data.index || allActive"
          :name="'material-diagramNumber-' + data.index + '-' + currentTableId"
          max-width="50"
          number
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(diagram)="data">
        <faf-input
          v-model="formData[data.index].diagram"
          :active="editIndex === data.index || allActive"
          :name="'material-diagram-' + data.index + '-' + currentTableId"
          min-width="140"
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(name)="data">
        <faf-multiselect
          v-model="formData[data.index].name"
          v-validate="'required'"
          :active="editIndex === data.index || allActive"
          :options="nameSuggestions"
          :name="'material-name-' + data.index + '-' + currentTableId"
          min-width="150"
          @input="prefillMaterial($event, data.index); onInput($event)"
          @search-change="searchChange('nameSuggestions', 'getMaterialNames', $event)"
          @add-option="addOption('nameSuggestions', $event)"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(width)="data">
        <faf-input
          v-model="formData[data.index].width"
          v-validate="'decimal:2'"
          :active="editIndex === data.index || allActive"
          :name="'material-width-' + data.index + '-' + currentTableId"
          error-text="Numbril saab olla kuni 2 komakohta!"
          number
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(description)="data">
        <faf-multiselect
          v-model="formData[data.index].description"
          :active="editIndex === data.index || allActive"
          :options="descriptionSuggestions"
          :name="'material-description-' + data.index + '-' + currentTableId"
          min-width="150"
          @search-change="searchChange('descriptionSuggestions', 'getMaterialDescriptionNames', $event)"
          @add-option="addOption('descriptionSuggestions', $event)"
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(size)="data">
        <div style="min-width: 110px;">
          <faf-input
            v-model="formData[data.index].size"
            v-validate="'required'"
            :active="editIndex === data.index || allActive"
            :unit="data.item.unit"
            :name="'material-size-' + data.index + '-' + currentTableId"
            no-text="Neto"
            max-width="50"
            number
            placeholder="Neto"
            @input="fillActualSize($event, data.index); onInput($event)"
            @edit-row="editRow(data.index)"
          />
          <faf-select
            v-model="formData[data.index].unit"
            v-validate="'required'"
            :active="editIndex === data.index || allActive"
            :options="unitOptions"
            :name="'material-unit-' + data.index + '-' + currentTableId"
            max-width="50"
            text-hidden
            @input="onInput"
            @edit-row="editRow(data.index)"
          />
        </div>
      </template>

      <template v-slot:cell(actualSize)="data">
        <faf-input
          v-model="formData[data.index].actualSize"
          v-validate="'required'"
          :active="editIndex === data.index || allActive"
          :unit="data.item.unit"
          :name="'material-actualSize-' + data.index + '-' + currentTableId"
          no-text="Bruto"
          number
          placeholder="Bruto"
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(amount)="data">
        <faf-input
          v-model="formData[data.index].amount"
          v-validate="'required'"
          :active="editIndex === data.index || allActive"
          :name="'material-amount-' + data.index + '-' + currentTableId"
          unit="tk"
          number
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(specialWeight)="data">
        <faf-input
          v-model="formData[data.index].specialWeight"
          v-validate="'required'"
          :active="editIndex === data.index || allActive"
          :name="'material-specialWeight-' + data.index + '-' + currentTableId"
          number
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(price)="data">
        <faf-input
          v-model="formData[data.index].price"
          v-validate="'required'"
          :active="editIndex === data.index || allActive"
          :name="'material-price-' + data.index + '-' + currentTableId"
          number
          unit="€/kg"
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(notes)="data">
        <faf-input
          v-model="formData[data.index].notes"
          v-validate="'max:55'"
          :active="editIndex === data.index || allActive"
          :name="'notes-' + data.index + '-' + currentTableId"
          error-text="Märkmetel saab olla kuni 55 tähemärki!"
          max-width="150"
          @input="onInput"
          @edit-row="editRow(data.index)"
        />
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-b-tooltip.hover
          :disabled="actionsDisabled"
          class="text-info icon-button"
          title="Värskenda materjali"
          @click="prefillMaterial(formData[data.index].name, data.index); onInput()"
        >
          <font-awesome-icon icon="sync" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          :disabled="actionsDisabled"
          class="icon-button text-info"
          title="Lisa materjal"
          @click="addMaterial(data, true)"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          :disabled="actionsDisabled"
          class="icon-button text-info"
          title="Loo duplikaat"
          @click="addMaterial(data)"
        >
          <font-awesome-icon icon="copy" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          :disabled="actionsDisabled"
          class="icon-button text-danger"
          title="Kustuta materjal"
          @click="deleteMaterial(data)"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
  import { emptyMaterial } from './productHelpers'

  export default {
    name: 'faf-materials-edit',
    inject: ['$validator'],
    props: {
      materials: {
        type: Array,
        default: () => []
      },
      editDisabled: Boolean,
      actionsDisabled: Boolean,
      allActive: Boolean
    },
    data () {
      return {
        currentTableId: Math.random().toString(16).slice(-4),
        editIndex: null,
        formData: this.materials,
        nameSuggestions: [],
        descriptionSuggestions: [],
        materialFields: [
          { key: 'diagramNumber', label: 'Nr' },
          { key: 'diagram', label: 'Joonis' },
          { key: 'name', label: 'Materjal' },
          (this.$can(['orderFields', 'material-width']) && { key: 'width', label: 'Paksus' }),
          { key: 'description', label: 'Kirjeldus' },
          { key: 'size', label: 'Kogus', formatter: (value, key, item) => `${value} ${item.unit}` },
          { key: 'actualSize', label: 'Ostuks', formatter: (value, key, item) => `${value} ${item.unit}` },
          { key: 'amount', label: 'Arv', formatter: value => `${value} tk` },
          { key: 'specialWeight', label: 'Erikaal (kg)' },
          { key: 'price', label: 'Hind', formatter: (value, key, item) => `${value} €/${item.unit}` },
          { key: 'notes', label: 'Märkmed' },
          { key: 'action', label: 'Tegevused' }
        ],
        unitOptions: ['kg', 'mm', 'm2', 'tk', 'L', 'm']
      }
    },
    watch: {
      allActive (val) {
        val && this.$validator.validateAll()
      },
      materials (newVal, oldVal) {
        this.finishUpdate(newVal)
      }
    },
    async created () {
      this.validateActualSize = this.$_debounce(this.validateActualSize, 300)
      this.searchChange = this.$_debounce(this.searchChange, 300)
      this.finishUpdate = this.$_debounce(this.finishUpdate, 300)
    },
    methods: {
      finishUpdate (newVal) {
        this.formData = newVal
      },
      // Keep in sync
      onInput () {
        this.$emit('input', this.formData)
      },

      // Multiselect
      async searchChange (suggestions, method, e) {
        this[suggestions] = (await this.$suggestions[method](e)).data
      },
      addOption (suggestions, value) {
        this[suggestions].push(value)
      },

      // Editable logic
      editRow (index) {
        !this.editDisabled && (this.editIndex = index)
      },
      disableEditMode (e) {
        const isCurrentTable = e.path && e.path.find(i => (i.className && typeof i.className === 'string' && i.className.includes(this.currentTableId)))
        !isCurrentTable && (this.editIndex = null)
      },

      // Field specific methods
      async prefillMaterial (event, index) {
        const materialData = (await this.$materials.getMaterials({filters: {name: event}})).data
        if (materialData.docs.length) {
          this.formData[index].description = materialData.docs[0].description
          this.formData[index].price = this.$materialSettings.useCalculatedAveragePrice ? materialData.docs[0].calculatedPrice : materialData.docs[0].price
          this.formData[index].specialWeight = materialData.docs[0].specialWeight
          this.formData[index].width = materialData.docs[0].width
          this.formData[index].unit = materialData.docs[0].unit
          this.formData[index].leftoverPercentage = materialData.docs[0].leftoverPercentage
        }
      },
      async fillActualSize (e, i) {
        if (e) {
          if (this.$can(['action', 'leftoverPercentage'])) {
            await this.$unblockMainThread()
            let leftoverPercentage = 100
            if (this.formData[i].leftoverPercentage) {
              leftoverPercentage = 100 + this.formData[i].leftoverPercentage
            } else if (this.formData[i].name) {
              const materialData = (await this.$materials.getMaterials({filters: {name: this.formData[i].name}})).data
              if (materialData && materialData.docs[0].leftoverPercentage) {
                this.formData[i].leftoverPercentage = materialData.docs[0].leftoverPercentage
                leftoverPercentage = 100 + this.formData[i].leftoverPercentage
              }
            }
            this.formData[i].actualSize = ((+e * leftoverPercentage) / 100).toFixed(2)
          } else {
            this.formData[i].actualSize = e
          }
          await this.$unblockMainThread()
          this.validateActualSize(i)
        }
      },
      validateActualSize (i) {
        this.$validator.validate('material-actualSize-' + i + '-' + this.currentTableId)
      },

      // Add, duplicate, delete
      addMaterial (data, newItem) {
        const index = data ? +data.index + 1 : 0
        const item = newItem ? this.$_cloneDeep({ ...emptyMaterial }) : {
          diagramNumber: data.item.diagramNumber,
          diagram: data.item.diagram,
          name: data.item.name,
          width: data.item.width,
          description: data.item.description,
          size: data.item.size,
          actualSize: data.item.actualSize,
          unit: data.item.unit,
          specialWeight: data.item.specialWeight,
          amount: data.item.amount,
          price: data.item.price
        }
        this.$emit('add-material', { index, item })
      },
      async deleteMaterial (data) {
        if (data.item._id) {
          await this.$confirm.show('Materjal kustutatakse koheselt ära ja toodet pole vaja üle salvestada.', 'Olete kindel, et soovite materjali kustutada?', async answer => {
            if (answer) {
              const update = (await this.$orders.deleteMaterial(data.item._id)).data
              this.$emit('delete-material', { index: +data.index, update })
            }
          })
        } else {
          this.$emit('delete-material', { index: +data.index, update: null })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .table-responsive {
    overflow: initial;
    overflow-x: auto;
  }
  .table-input {
    border: none;
    padding: 0;
    line-height: 1.9;
    font-size: 0.875rem;
    font-weight: 400;
    height: auto;

    .multiselect__tags {
      border: none;
      padding: 0;
      line-height: 1.9;
      font-size: 0.875rem;
      font-weight: 400;
      height: 26px;
    }
  }

  .icon-button {
    font-size: 20px;
    padding: 0;
    margin-right: 5px;
  }
</style>
