<template>
  <div>
    <b-row no-gutters>
      <b-col>
        <faf-pagination-right
          ref="sidePagination"
          :product-name-suggestions="allProducts.map(p => p.name)"
          :total-pages="allProducts.length"
          :total="allProducts.length"
          name="edit-paginate-search"
          @pagechanged="setActive"
          @search="search"
        >
          <form>
            <faf-card
              v-if="formData"
              :key="active"
              :name="'product-' + active"
              :collapse-button-visible="false"
              :scrollable="true"
              :loading="true"
              style="height: calc(100vh - 29rem);"
              class="slide-card mb-0 mt-3"
            >
              <template slot="title">
              </template>

              <template slot="buttons">
                <!-- REFRESH -->
                <b-button
                  v-b-tooltip.hover
                  :disabled="showOverlay"
                  class="text-info icon-button"
                  title="Värskenda toote materjale ja töökeskuseid"
                  @click="syncProduct(formData)"
                >
                  <font-awesome-icon icon="sync" />
                </b-button>

                <!-- FAVOURITE PRODUCT -->
                <faf-favourite-product :disabled="showOverlay" :card-name="'product-' + active" :product="formData" @prefill-product="prefillProduct($event)" />

                <span class="divider" />

                <!-- ADD/DUPLICATE/DELETE PRODUCT -->
                <b-button
                  v-b-tooltip.hover
                  :disabled="dataIsEdited && formData.new || showOverlay"
                  :class="(dataIsEdited && formData.new) ? 'text-gray' : 'text-info'"
                  class="icon-button"
                  title="Lisa toode"
                  @click="addProduct(formData, true)"
                >
                  <font-awesome-icon icon="plus-circle" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  :disabled="dataIsEdited && formData.new || showOverlay"
                  :class="(dataIsEdited && formData.new) ? 'text-gray' : 'text-info'"
                  class="icon-button"
                  title="Loo duplikaat"
                  @click="addProduct(formData)"
                >
                  <font-awesome-icon icon="copy" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  :disabled="showOverlay"
                  class="icon-button text-danger"
                  title="Kustuta"
                  @click="deleteProduct()"
                >
                  <font-awesome-icon icon="trash-alt" />
                </b-button>

                <span class="divider" />

                <!-- LOCK PRODUCT -->
                <b-button
                  v-if="formData.materials && formData.materials.filter(m => m.status === 'PURCHASE_RECOMMENDATION').length"
                  v-b-tooltip.hover
                  :title="isForceProduction(formData) ? 'Lukusta toode' : 'Vabasta toode tootmisse'"
                  class="icon-button text-success"
                  @click="isForceProduction(formData) ? lockProduct() : releaseProductToProduction()"
                >
                  <font-awesome-icon :icon="isForceProduction(formData) ? 'lock' : 'unlock'" />
                </b-button>

                <!-- SAVE PRODUCT -->
                <b-button
                  v-b-tooltip.hover
                  :disabled="(!dataIsEdited && !formData.new) || $loader.val()"
                  :class="(!dataIsEdited && !formData.new) || $loader.val() ? 'text-gray' : 'text-primary'"
                  class="icon-button"
                  title="Salvesta"
                  @click="saveProduct(active)"
                >
                  <font-awesome-icon icon="save" />
                </b-button>
              </template>
              <div v-if="showOverlay">
                <b-skeleton animation height="70px" />
                <b-skeleton animation width="55%" />
                <b-skeleton animation width="70%" />
                <b-skeleton animation />
                <b-skeleton animation />
                <b-skeleton animation height="70px" />
                <b-skeleton animation width="75%" />
                <b-skeleton animation width="80%" />
                <b-skeleton animation />
                <b-skeleton animation />
                <b-skeleton animation height="70px" width="85%" />
                <b-skeleton animation />
                <b-skeleton animation />
                <b-skeleton animation />
                <b-skeleton animation />
              </div>
              <div v-else>
                <b-alert :show="actionsDisabled(formData.status)" variant="danger">
                  Toode on tootmises (Materjale ja töökeskuseid ei ole võimalik enam juurde lisada)
                </b-alert>
                <b-row v-click-outside="disableEditMode" :class="'product-row-'" style="border-top: 1px solid #e9e9e9;" class="ml-1 mb-1 mt-3 pt-3">
                  <b-col xl="3" md="6" sm="12">
                    <h5 class="mb-0 text-info">
                      {{ active }}. TOOTE NIMI
                    </h5>
                    <faf-multiselect
                      :key="'product-name-' + active"
                      v-model="formData.name"
                      v-validate="'required'"
                      :active="editIndex"
                      :options="productSuggestions"
                      :name="'product-name-' + active"
                      @search-change="searchChangeProduct"
                      @add-option="addProductOption($event)"
                      @edit-row="editRow()"
                    />
                  </b-col>
                  <b-col xl="1" md="6" sm="6" style="min-width: 100px;">
                    <h5 class="mb-0 text-info">
                      KOGUS
                    </h5>
                    <faf-input
                      :key="'product-quantity-' + active"
                      v-model="formData.quantity['amount']"
                      v-validate="'required'"
                      :active="editIndex"
                      :name="'product-quantity-' + active"
                      type="number"
                      add-on="tk"
                      unit="tk"
                      max-width="100"
                      @edit-row="editRow()"
                    />
                  </b-col>
                  <b-col v-show="!isChildOrder" xl="1" md="6" sm="6" style="min-width: 100px;">
                    <h5 class="mb-0 text-info">
                      HIND
                    </h5>
                    <faf-input
                      :key="'product-quantity-' + active"
                      v-model="formData.price"
                      v-validate="'required'"
                      :active="editIndex"
                      :name="'product-price-' + active"
                      number
                      add-on="€"
                      max-width="100"
                      @edit-row="editRow()"
                    />
                  </b-col>
                  <b-col xl="3" md="6" sm="12">
                    <h5 class="mb-0 text-info">
                      VIIMISTLUS
                    </h5>
                    <faf-input
                      v-model="formData.surfaceTreatment"
                      :active="editIndex"
                      :name="'product-surfaceTreatment-' + active"
                      @edit-row="editRow()"
                    />
                  </b-col>
                  <b-col>
                    <h5 class="mb-0 text-info">
                      MÄRKMED
                    </h5>
                    <faf-input
                      v-model="formData.notes"
                      :active="editIndex"
                      :name="'product-notes-' + active"
                      @edit-row="editRow()"
                    />
                  </b-col>
                  <b-col v-show="!isChildOrder">
                    <h5 class="mb-0 text-info">
                      KASUTA OMAHINDA
                    </h5>
                    <b-form-checkbox
                      v-model="formData.useNetPrice"
                      :unchecked-value="false"
                      :checked-value="true"
                      :name="'useNetPrice-' + active"
                      @change="dataIsEdited = true"
                    />
                  </b-col>
                </b-row>
                <!-- REPEATER DISABLED -->
                <div style="border-top: 1px solid #e9e9e9;" class="mb-3">
                  <b-row class="ml-1 pt-3 mr-1 mb-3">
                    <b-col :key="active" xl="12">
                      <!-- NEW MATERIALS & WORKCENTERS -->
                      <faf-materials-edit
                        :materials="formData.materials"
                        :edit-disabled="editDisabled"
                        :all-active="allActive"
                        :actions-disabled="actionsDisabled(formData.status)"
                        @input="formData.materials = $event; dataIsEdited = true"
                        @add-material="addMaterial($event)"
                        @delete-material="deleteMaterial($event)"
                      />
                      <faf-workcenters-edit
                        :key="workcentersRepeaterIndex"
                        :workcenters="formData.workcenters"
                        :edit-disabled="editDisabled"
                        :linked-materials-suggestions="formData.linkedMaterialsSuggestions || []"
                        :all-active="allActive"
                        :actions-disabled="actionsDisabled(formData.status)"
                        @input="formData.workcenters = $event; dataIsEdited = true"
                        @add-workcenter="addWorkcenter($event)"
                        @delete-workcenter="deleteWorkcenter($event)"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </faf-card>
          </form>
        </faf-pagination-right>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import FafFavouriteProduct from './FavouriteProduct'
  import FafMaterialsEdit from './MaterialsEdit'
  import FafWorkcentersEdit from './WorkcentersEdit'
  import { productFields, emptyProduct } from './productHelpers'
  import { cleanUpProduct } from './../../../helperMethods/cleanUpOrder'

  export default {
    name: 'faf-products',
    components: {
      FafFavouriteProduct,
      FafMaterialsEdit,
      FafWorkcentersEdit
    },
    props: {
      orderId: String,
      products: Array,
      editDisabled: Boolean,
      showOverlay: Boolean,
      openProducts: Array,
      selectedProductId: {
        type: String,
        value: null
      },
      isChildOrder: Boolean
    },
    data () {
      return {
        workcentersRepeaterIndex: 1,
        allProducts: [],
        initalFormData: null,
        formData: null,
        editIndex: null,
        dataIsEdited: false,
        productFields,
        productSuggestions: [],
        allActive: false,
        active: 1,
        // Materials & workcenters
        // This makes sure the materials and workcenters are updated when something changes
        materialsWorkcentersKey: 0
      }
    },
    computed: {

    },
    mounted () {
      this.allProducts = this.products
      this.searchChangeProduct = this.$_debounce(this.searchChangeProduct, 300)
      this.setActive = this.$_debounce(this.setActive, 250)
      this.preselectProductIfPossible()
    },
    methods: {
      preselectProductIfPossible () {
        if (this.selectedProductId) {
          const searchIndex = this.allProducts.findIndex(p => p._id === this.selectedProductId) + 1
          if (searchIndex !== 0) {
            this.changeProduct(searchIndex)
            this.$refs.sidePagination.currentPage = searchIndex
            this.active = searchIndex
          }
        } else {
          this.changeProduct(this.active)
        }
      },
      search (event) {
        const searchIndex = this.allProducts.findIndex(p => p.name === event) + 1
        if (searchIndex !== 0) {
          this.changeProduct(searchIndex)
          this.$refs.sidePagination.currentPage = searchIndex
          this.active = searchIndex
        }
      },
      setActive (event) {
        if (!this.dataIsEdited) {
          this.active = event
          this.changeProduct(event)
        } else {
          this.$refs.sidePagination.currentPage = this.active
          this.$confirm.show('Kas soovite toote salvestada?', null, async answer => {
            if (answer) {
              await this.saveProduct()
              this.$refs.sidePagination.currentPage = event
              this.active = event
              this.changeProduct(event)
            } else {
              this.allProducts[this.active - 1] = this.initalFormData
              this.changeProduct(event)
              this.$refs.sidePagination.currentPage = event
              this.active = event
              this.dataIsEdited = false
            }
            this.editIndex = null
          })
        }

      },
      changeProduct (event) {
        this.formData = this.allProducts[event - 1]
        this.initalFormData = this.$_cloneDeep(this.allProducts[event - 1])
        this.updateLinkedMaterials(this.formData)
      },
      cleanProductForComparison (item) {
        const data = this.$_cloneDeep({ ...item })
        data.price = +item.price
        data.quantity.amount = +this.$_get(item, 'quantity.amount', 0)
        data.notes = item.notes || null
        delete data.materials
        delete data.workcenters
        return data
      },
      cleanMaterialForComparison (items) {
        const data = this.$_cloneDeep(items).map(item => {
          item.amount = +this.$_get(item, 'amount', 0)
          item.width = +item.width
          item.specialWeight = +item.specialWeight
          item.price = +item.price
          item.diagramNumber = +item.diagramNumber
          item.size = +item.size
          item.actualSize = +item.actualSize
          item.notes = item.notes || null
          return item
        })
        return data
      },
      cleanWorkcenterForComparison (items) {
        const data = this.$_cloneDeep(items).map(item => {
          item.setupTime = +item.setupTime
          item.activeTime = +item.activeTime
          item.price = +item.price
          item.queue = +item.queue
          item.leadTime = +item.leadTime
          item.workTime = +item.workTime
          item.notes = item.notes || null
          return item
        })
        return data
      },

      // Force production
      isForceProduction (product) {
        return product.forceProduction || (product.subStatus && product.subStatus.includes('FORCE_PRODUCTION'))
      },
      releaseProductToProduction () {
        this.$confirm.show(`Hoiatus! Kui te vabastate tooted tootmisesse ennem kui materjalid on kohal
        siis te peate edaspidi jälgima manuaalselt, et vajalikud materjalid oleksid toote tootmiseks olemas.
        Kas Te olete kindel, et soovite tegevusega jätkata?`, null, answer => {
          if (answer) {
            const data = {
              status: 'PRODUCTION_READY',
              subStatus: [ ...this.formData.subStatus, 'FORCE_PRODUCTION' ]
            }
            this.$orders.updateProductStatus(this.formData._id, data).then(rs => {
              this.formData = rs.data
              this.updateLinkedMaterials(this.formData)
              this.$notify.show(rs.data.name + ' on tootmises')
            }).catch(err => this.$err.show('Midagi läks valesti!'))
          }
        })
      },
      lockProduct () {
        const data = {
          status: 'INCOMPLETE',
          subStatus: this.formData.subStatus
        }
        data.subStatus.splice( data.subStatus.indexOf('FORCE_PRODUCTION'), 1 )

        this.$orders.updateProductStatus(this.formData._id, data)
          .then(rs => {
            this.formData = rs.data
            this.$notify.show(rs.data.name + ' on tootmisest eemaldatud!')
          })
          .catch(err => this.$err.show('Midagi läks valesti!'))
      },

      collapse (event) {
        this.$emit('collapse', {event})
      },

      // Product logic
      async searchChangeProduct (e) {
        if (e.length > 1) {
          this.productSuggestions = (await this.$suggestions.getProductNames({ name: e })).data
        }
      },
      addProductOption (e) {
        this.productSuggestions.push(e)
        this.formData.name = e
      },
      async syncProduct (e) {
        this.$loader.start('sync-product')
        for await (const material of this.formData.materials) {
          await this.syncMaterial(material)
        }
        for await (const workcenter of this.formData.workcenters) {
          await this.syncWorkcenter(workcenter)
        }
        this.$loader.stop('sync-product')
      },
      async syncMaterial (material) {
        const materialData = (await this.$materials.getMaterials({filters: {name: material.name}})).data
        if (materialData.docs.length) {
          const newData = materialData.docs[0]
          material.description = newData.description
          material.price = newData.price
          material.specialWeight = newData.specialWeight
          material.width = newData.width
          material.unit = newData.unit
          material.leftoverPercentage = newData.leftoverPercentage
          this.dataIsEdited = true
        }
        return material
      },
      async syncWorkcenter (workcenter) {
        const workcenterData = (await this.$workcenters.getWorkcenters({filters: {name: workcenter.name}})).data
        if (workcenterData.docs.length) {
          const newData = workcenterData.docs[0]
          workcenter.setupTime = newData.setupTime || 0
          workcenter.leadTime = newData.leadTime
          workcenter.price = newData.price
          workcenter.team = newData.team
          this.dataIsEdited = true
        }
        return workcenter
      },
      prefillProduct (e) {
        this.formData = { ...this.formData, ...e }
        this.updateLinkedMaterials(this.formData)
      },
      updateLinkedMaterials (product) {
        product.linkedMaterialsSuggestions = this.getLinkedMaterialOptions(product.materials)
      },
      addProduct (product, newItem) {
        const item = newItem ? this.$_cloneDeep({ ...emptyProduct(), new: true }) : { ...cleanUpProduct(product), new: true }
        this.allProducts.push({ ...item })
        this.active = this.allProducts.length
        this.$refs.sidePagination.currentPage = this.allProducts.length
        this.formData = { ...item }
        this.dataIsEdited = true
      },
      deleteProduct () {
        if (this.formData._id) {
          this.$confirm.show('Olete kindel, et soovite toote koos materjalide ja töökeskustega kustutada?', null, answer => {
            if (answer) {
              this.$orders.deleteProduct(this.formData._id).catch(err => this.$err.show(err))
              this.allProducts.splice(this.active - 1, 1)
              if (this.active > 1) {
                this.active--
                this.$refs.sidePagination.currentPage = this.active
              }
              this.changeProduct(this.active)
              this.$notify.show('Toode kustutatud')
            }
          })
        } else {
          this.$notify.show('Toode kustutatud')
        }
      },
      async saveProduct () {
        this.$cardLoader('product', true)
        this.allActive = true
        this.$validator.reset().then(() => {
          this.$validator.validateAll().then((result) => {

            if (this.$validator.errors.items.length > 0 || !result) {
              this.$cardLoader('product', false)
            } else {
              const currentProduct = this.$_cloneDeep({ ...this.formData})
              if (this.$can(['orderFields', 'linkedMaterials'])) {
                if (currentProduct.workcenters && currentProduct.workcenters.length > 0) {
                  currentProduct.workcenters.map(w => {
                    w.linkedMaterials = (w.linkedMaterials && w.linkedMaterials[0] && w.linkedMaterials.length > 0) ? w.linkedMaterials.map(m => m.value) : []
                  })
                }
              }

              // TODO: Move to backend! Et kõik TT tellimused automaatselt tagastaks hinna 0 ja ei kasutaks omahinna arvutust!
              if (this.isChildOrder) {
                currentProduct.price = 0
                currentProduct.useNetPrice = false
              }

              const newProduct = !currentProduct._id

              const type = newProduct ? 'addProduct' : 'updateProduct'
              this.$loader.start('save-product')
              this.$orders[type](currentProduct._id || this.orderId, currentProduct).then(rs => {
                this.$notify.show(newProduct ? rs.data.name + ' on edukalt lisatud' : rs.data.name + ' on edukalt uuendatud')
                this.dataIsEdited = false
                if (newProduct) {
                  this.allProducts.splice(-1,1)
                  this.allProducts.push(rs.data)
                } else {
                  this.allProducts[this.active - 1] = rs.data
                }
                this.formData = this.$_cloneDeep(rs.data)
                this.$cardLoader('product', false)
                this.updateLinkedMaterials(this.formData)
                this.workcentersRepeaterIndex++
                this.$loader.stop('save-product')
              }).catch(err => {
                this.$loader.stop('save-product')
                this.$err.show(err)
              })
            }

            this.editIndex = null
            this.allActive = false
          })
        })
      },

      // Editable logic
      editRow (index) {
        !this.editDisabled && (this.editIndex = true)
        this.dataIsEdited = true
      },
      disableEditMode (e) {
        const isCurrentRow = e.path && e.path.find(i => (i.className && typeof i.className === 'string' && i.className.includes('product-row-')))
        !isCurrentRow && (this.editIndex = null)
      },

      // Material and workcenters logic
      actionsDisabled (status) {
        return status ? !['INCOMPLETE'].includes(status) : false
      },
      getLinkedMaterialOptions (materials) {
        return materials.filter(f => f._id).map(m => ({text: `${m.diagramNumber}*${m.name}`, value: m._id}))
      },
      addMaterial (event) {
        this.formData.materials.length > 0 ? this.formData.materials.splice(event.index, 0, event.item) : this.formData.materials.push(event.item)
        this.updateLinkedMaterials(this.formData)
        this.$validator.reset()
      },
      async deleteMaterial (event) {
        if (event.update && !event.update.message) {
          this.formData = this.$_cloneDeep(event.update)
        } else {
          const id = this.formData.materials[event.index]._id
          this.formData.materials.splice(event.index, 1)
          this.updateLinkedMaterials(this.formData)
          // Need to hand pick it out
          this.formData.workcenters.map(w => {
            const f = w.linkedMaterials.find(lm => lm.value === id)
            if (f) {
              w.linkedMaterials.splice(w.linkedMaterials.indexOf(f), 1)
            }
          })
        }
      },
      addWorkcenter (event) {
        this.formData.workcenters.length > 0 ? this.formData.workcenters.splice(event.index, 0, event.item) : this.formData.workcenters.push(event.item)
      },
      deleteWorkcenter (event) {
        this.formData.workcenters.splice(event.index, 1)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .tim-icons.disabled {
    cursor: not-allowed;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-leave-active,
  .slide-enter-active {
    transition: 0.2s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }

  .max-90 {
    max-width: 90px;
  }

  .divider {
    display: inline-block;
    width: 1px;
    height: 39px;
    margin: 0 2px -12px;
    background: #6c757d;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      #6c757d 20%,
      #6c757d 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
</style>
