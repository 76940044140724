import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getMaterials (query) {
  return axios({
    url: `/api/material?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getPartialMaterials (query) {
  return axios({
    url: `/api/material?page=${query.page}&limit=${query.limit}&partial=true&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addMaterial (data) {
  return axios({
    url: `/api/material`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateMaterial (id, data) {
  return axios({
    url: `/api/material/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

function getMaterialsWithInventory (filters) {
  return axios({
    url: `/api/material/inventory?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function updateOrAddNewMaterial (data) {
  return axios({
    url: `/api/material-inventory-amount`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

const methods = {
  getMaterials,
  addMaterial,
  updateMaterial,
  updateOrAddNewMaterial,
  getMaterialsWithInventory,
  getPartialMaterials
}

export default function install (Vue, options = {}) {
  Vue.prototype.$materials = {
    ...methods
  }
}

export {
  methods
}
