<template>
  <b-modal
    id="get-custom-pdf"
    title="Salvesta fail"
    title-tag="h4"
    size="lg"
    scrollable
    @hide="$emit('hide')"
  >
    <b-row>
      <b-col cols="12">
        <label for="document-type">Dokumendi tüüp</label>
        <select v-model="documentType" v-validate="'required'" v-bind="$requiredInputProps('document-type')">
          <option v-for="(option, optionIndex) in documentTypeOptions" :key="'option-' + optionIndex" :value="option">
            <span>{{ option.label }}</span>
          </option>
        </select>
      </b-col>

      <b-col cols="12" class="mt-2">
        <label>Tooted</label>
        <div class="table-responsive">
          <b-table
            v-if="formData && formData.length > 0"
            v-click-outside="disableEditMode"
            :fields="fields"
            :items="formData"
            striped
            stacked="xl"
          >
            <template v-slot:cell(product.quantity.amount)="data">
              <faf-input
                v-model="formData[data.index].product.quantity.amount"
                v-validate="'required'"
                :active="editIndex === data.index"
                :name="'product-quantity-' + data.index"
                type="number"
                add-on="tk"
                unit="tk"
                max-width="100"
                @edit-row="editIndex = data.index"
                @input="updateWeight($event, data.index)"
              />
            </template>
            <template v-slot:cell(product.weight)="data">
              <faf-input
                :key="widthKey"
                v-model="formData[data.index].product.weight"
                v-validate="'required'"
                :active="editIndex === data.index"
                :name="'product-weight-' + data.index"
                type="number"
                add-on="kg"
                unit="kg"
                max-width="100"
                @edit-row="editIndex = data.index"
              />
              <!-- <span :key="widthKey">{{ data.value }} kg</span> -->
            </template>
          </b-table>
        </div>
      </b-col>

      <b-col v-if="languageVisible" cols="12">
        <label for="language">Keel</label>
        <select v-model="language" v-validate="'required'" v-bind="$requiredInputProps('language')">
          <option v-for="(option, optionIndex) in languages" :key="'option-' + optionIndex" :value="option.value">
            <span>{{ option.label }}</span>
          </option>
        </select>
      </b-col>
    </b-row>

    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('get-custom-pdf')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('document-download')" class="icon-button text-primary" title="Lae alla" @click="download">
          <font-awesome-icon icon="file-download" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import { downloadBinaryFile } from './../downloadFile'
  import { languages, languageVisibleDocuments } from './../helpers'
  import { _getProductMaterialCost } from './../../../Calculations'

  export default {
    name: 'faf-get-pdf',
    props: {
      visible: Boolean,
      data: Array,
      type: {
        type: String,
        validator: (val) => ['PRODUCTION'].includes(val),
        required: true
      }
    },
    data () {
      return {
        formData: [],
        editIndex: null,
        widthKey: 0,

        documentType: { value: 'PRODUCT_SHEET', label: 'Tooteleht', fileName: 'PRODUCT_SHEET' },
        documentTypeOptions: [ { value: 'PRODUCT_SHEET', label: 'Tooteleht', fileName: 'PRODUCT_SHEET' } ],

        languages,
        language: 'EE',

        fields: [
          { key: 'salesSystemNumber', label: 'ID' },
          (this.$can(['orderFields', 'orderId']) && [{ key: 'orderId', label: 'Projekti nr' }]),
          { key: 'product.workcenters.name', label: 'Töökeskus' },
          { key: 'product.name', label: 'Toote nimetus' },
          { key: 'product.quantity.amount', label: 'Kogus (tk)' },
          { key: 'product.weight', label: 'Kaal (kg)' }
        ]
      }
    },
    computed: {
      languageVisible () {
        return languageVisibleDocuments.includes(this.documentType.value)
      }
    },
    watch: {
      data (val) {
        this.setFormData(val)
      }
    },
    created () {
      this.updateWeight = this.$_debounce(this.updateWeight, 100)
    },
    methods: {
      disableEditMode () {
        this.editIndex = null
      },
      async setFormData (data) {
        this.formData = this.$_cloneDeep([ ...data ])
        this.formData.map(async i => {
          i.product.weight = (await _getProductMaterialCost(i.product, 'size')).amount
        })
      },
      async updateWeight (e, i) {
        if (e) {
          this.formData[i].product.quantity.amount = +this.formData[i].product.quantity.amount
          this.formData[i].product.weight = (await _getProductMaterialCost(this.formData[i].product, 'size')).amount
          this.widthKey++
        }
      },
      async download () {
        this.$validator.validateAll().then(async valid => {
          if (!valid) {
            return
          }
          this.$loader.start('document-download')
          const data = {
            items: this.formData,
            company: this.$company,
            language: this.language
          }
          this.$DocumentController.generateDocument(this.documentType.value, data).then(rs => {
            this.$loader.stop('document-download')
            const headers = {
              'Content-Type': 'application/pdf'
            }
            downloadBinaryFile(rs.data, headers, `${this.documentType.fileName}.pdf`)
            this.$cardReset('production-table')
          }).catch(err => {
            this.$loader.stop('document-download')
            this.$err.show(err)
          })
        })
      }
    }
  }

</script>
<style lang="scss" scoped>
  label {
    margin-top: 10px;
    margin-bottom: 0;
  }
</style>
