<template>
  <b-modal
    :id="modalName"
    :size="myJobs.length && myJobs[0]._id ? 'xl' : 's'"
    :title="getModalTitle()"
    scrollable
    @show="startupCheck"
    @hide="$emit('hide')"
  >
    <div v-if="myJobs.length && myJobs[0]._id">
      <b-row v-if="$can(['add', 'WorksheetCertificate']) && bulkWorksheet" class="pl-2 pb-4">
        <b-col class="justify-content-md-center" lg="4">
          <label for="certificate">Hulgi tööleht</label>
          <input
            v-model.trim="fallbackWorksheet"
            v-bind="$requiredInputProps('worksheet')"
            :disabled="!bulkAddWorksheet"
            placeholder=""
            type="text"
          >
        </b-col>
        <b-col class="justify-content-md-center" align-self="end" lg="4">
          <b-form-checkbox
            v-model="bulkAddWorksheet"
            :unchecked-value="false"
            :value="true"
            name="bulkAddWorksheet"
          >
            Lisa töölehti hulgi
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="$can(['add', 'WorksheetCertificate']) && bulkCert" class="pl-2 pb-4">
        <b-col class="justify-content-md-center" lg="4">
          <label for="linkedWorksheet">Tööleht</label>
          <multiselect
            v-model="linkedWorksheet"
            :options="availableWorkSheets"
            v-bind="$multiselectProps('linkedWorksheet')"
            :disabled="!bulkAddCertificate"
            label="name"
          />
        </b-col>
        <b-col class="justify-content-md-center mt-2" lg="4">
          <label for="certificate">Hulgi sertifikaat</label>
          <input
            v-model.trim="fallbackCertificate"
            v-bind="$requiredInputProps('certificate')"
            :disabled="!bulkAddCertificate"
            placeholder=""
            type="text"
          >
        </b-col>
        <b-col class="justify-content-md-center mt-2" align-self="end" lg="4">
          <b-form-checkbox
            v-model="bulkAddCertificate"
            :unchecked-value="false"
            :value="true"
            name="bulkAddCertificate"
          >
            Lisa sertifikaate hulgi töölehe järgi
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div v-for="(job, index) in myJobs" :key="index" class="separator mt-2">
        <b-container class="pt-4 mb-4 faf-t-border">
          <b-row>
            <b-col class="mt-2" xl="3" cols="6">
              <div class="p-3 bg-blue stats-title">
                <h5 class="text-gray mb-0">
                  <strong>ID</strong>
                </h5>
                <font-awesome-icon icon="project-diagram" />
                <h4 class="mb-2 text-info">
                  <strong>{{ job.salesSystemNumber }}</strong>
                </h4>
              </div>
            </b-col>
            <b-col class="mt-2" xl="3" cols="6">
              <div class="p-3 bg-blue stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Töökeskus</strong>
                </h5>
                <font-awesome-icon icon="cube" />
                <h4 class="mb-2 text-info">
                  <strong>{{ job.product.workcenters.name }}</strong>
                </h4>
              </div>
            </b-col>
            <b-col class="mt-2" xl="3" cols="6">
              <div class="p-3 bg-blue stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Toote nimetus</strong>
                </h5>
                <font-awesome-icon icon="truck" />
                <h4 class="mb-2 text-info">
                  <strong>{{ job.product.name }}</strong>
                </h4>
              </div>
            </b-col>
            <b-col class="mt-2" xl="3" cols="6">
              <div class="p-3 bg-blue stats-title">
                <h5 class="text-gray mb-0">
                  <strong>Tehtud</strong>
                </h5>
                <font-awesome-icon icon="chart-line" />
                <h4 class="mb-2 text-info">
                  <strong>{{ job.product.workcenters.doneProducts + ' / ' + job.product.quantity.amount }}</strong>
                </h4>
              </div>
            </b-col>
          </b-row>

          <faf-repeater
            :items="workcenters[index].proof"
            :delete-disabled="workcenters[index].proof.length <= 1"
            class="mt-4"
            inline-icons
            duplicate-disabled
            @add-row="addNewRow(index, $event)"
            @delete-row="deleteRow(index, $event)"
          >
            <template slot="repeaterSlot" slot-scope="row">
              <b-row class="pl-2">
                <b-col xl="3" cols="10" class="px-xl-1 mt-2">
                  <input
                    v-model="workcenters[index].proof[row.index].producedProductsAmount"
                    v-validate="'required'"
                    v-bind="$requiredInputProps('producedProductsAmount-' + row.index + index)"
                    :readonly="workcenters[index].proof[row.index].operator"
                    placeholder="Kogus"
                    type="number"
                  >
                </b-col>
                <b-col v-if="$can(['add', 'WorksheetCertificate'])" xl="3" cols="10" class="px-xl-1 mt-2">
                  <input
                    v-model="workcenters[index].proof[row.index].workSheet"
                    v-bind="$requiredInputProps('workSheet-' + row.index + index)"
                    :readonly="workcenters[index].proof[row.index].operator || bulkAddWorksheet"
                    placeholder="Tööleht"
                    type="text"
                  >
                </b-col>
                <b-col v-if="$can(['add', 'WorksheetCertificate'])" xl="3" cols="10" class="px-xl-1 mt-2">
                  <input
                    v-model.trim="workcenters[index].proof[row.index].certificate"
                    v-bind="$requiredInputProps('certificate-' + row.index + index)"
                    :readonly="disableCertificate || bulkAddCertificate"
                    placeholder="Sertifikaat"
                    type="text"
                  >
                </b-col>
                <b-col xl="3" cols="10" class="px-xl-1 mt-2">
                  <p v-if="workcenters[index].proof[row.index].operator && workcenters[index].proof[row.index]._id">
                    {{ workcenters[index].proof[row.index].operator }}, {{ getEndDate(index, row.index) }}
                  </p>
                </b-col>
              </b-row>
            </template>
          </faf-repeater>
        </b-container>
      </div>
    </div>
    <div v-else-if="myJobs[0] && myJobs[0].product">
      <h4 class="text-gray">
        Lõpeta töö - {{ myJobs[0].product.workcenters.name }}
      </h4>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide(modalName)">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <ajan-import v-can="['see', 'AjanImport']" :get-proof="true" @importData="prefillProof" />
        <b-button v-b-tooltip.hover :disabled="$loader.val() || disableSubmit" class="icon-button text-success" :title="disableCertificate ? 'Lisa' : 'Kanna kinni'" @click="editProof">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import startupCheck from './../mixins/startupCheck'
  import AjanImport from './../../../components/AjanImport/AjanImport'
  export default {
    name: 'proof-management',
    components:  {
      AjanImport
    },
    mixins: [startupCheck],
    props: {
      myJobs: Array,
      show: Boolean,
      modalName: String,
      bulkCert: {
        type: Boolean,
        default: false
      },
      bulkWorksheet: {
        type: Boolean,
        default: false
      },
      disableCertificate: {
        type: Boolean,
        default: false
      },
      submitAlwaysEnabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        bulkAddCertificate: false,
        bulkAddWorksheet: false,
        fallbackCertificate: '',
        fallbackWorksheet: '',
        linkedWorksheet: '',
        proofEditError: false,
        workcenters: [{proof: [{}]}],
        disableSubmit: false
      }
    },
    computed: {
      availableWorkSheets () {
        return this.workcenters.flatMap(w => {
          return w.proof.flatMap(p => {
            return p.workSheet
          })
        }).filter((v, i, a) => v && a.indexOf(v) === i).map(v => ({name: v, value: v}))
      }
    },
    watch: {
      myJobs: {
        immediate: true,
        handler (value) {
          this.intiProofs(value)
        }
      }
    },
    created () {
      this.checkInProgressJob()
    },
    methods: {
      getModalTitle () {
        const jobsExist = this.myJobs.length && this.myJobs[0]._id
        if (this.disableCertificate) {
          return 'Lisa tööleht'
        }
        if (jobsExist) {
          return 'Kanna töölehed kinni'
        }
        return 'Lõpeta töö'
      },
      getEndDate (index, rowIndex) {
        const endDate = this.$_get(this.workcenters, `[${index}].proof[${rowIndex}].endDate`, null)

        if (endDate) {
          return this.$dayjs(endDate).format('DD.MM.YYYY HH:mm')
        } else {
          const proofId = this.$_get(this.workcenters, `[${index}].proof[${rowIndex}]._id`, null)
          return proofId ? this.$dayjs(new Date( parseInt( ( proofId.substring(0,8)), 16 ) * 1000 )).format('DD.MM.YYYY HH:mm') : ''
        }

      },
      async checkInProgressJob () {
        if (this.submitAlwaysEnabled) {
          this.disableSubmit = false
        } else {
          const myJobs = await this.$work.getJobs({operatorId: this.$user.getId(), status: 'IN_PROGRESS'})
          this.disableSubmit = !myJobs.data.length
        }
      },
      intiProofs (value) {
        if (value && value.length && value[0]._id) {
          this.workcenters = this.$_cloneDeep(value.map(w => {
            return {
              _id: w.product.workcenters._id,
              productName: w.product.name,
              salesSystemNumber: w.salesSystemNumber,
              proofVersion: w.product.workcenters.proofVersion || 1,
              proof: w.product.workcenters.proof && w.product.workcenters.proof.length ? w.product.workcenters.proof.map(p => {
                if (p.status === 'DELETED') p.repeatedRowHidden = true
                else p.repeatedRowHidden = false
                return p
              }) : [{producedProductsAmount: w.product.quantity.amount, workSheet: ''}]
            }
          }))
          this.workcenters.forEach((w, index) => {
            const remainingAmount = this.calculateRemainingProduceAmount(index)
            if (remainingAmount) {
              this.workcenters[index].proof.splice(this.workcenters[index].proof.length, 0, {
                producedProductsAmount: remainingAmount
              })
            }
          })
        }
      },
      async editProof () {
        this.$loader.start('edit-proof')
        this.proofEditError = false
        if (this.myJobs && this.myJobs.length && this.myJobs[0]._id && await this.$validator.validateAll()) {
          const finalWorkcenters = []
          for await (const workcenter of this.workcenters) {
            await this.$unblockMainThread()
            if (this.bulkAddWorksheet) {
              workcenter.proof = workcenter.proof.map(p => {
                if (!p.workSheet) {
                  p.workSheet = this.fallbackWorksheet
                }
                return p
              })
            }

            if (!this.disableCertificate) {
              workcenter.calculateCertificateOnly = this.bulkAddCertificate || false

            }
            finalWorkcenters.push(workcenter)
          }
          await this.$emit_p('editProof',
                             {
                               workcenters: finalWorkcenters,
                               ...this.bulkAddCertificate && { bulkCertificate: {
                                 fallbackCertificate: this.fallbackCertificate,
                                 worksheetNumber: this.linkedWorksheet.value
                               }}
                             })
            .catch(err => {
              this.proofEditError = true
              this.$err.show(err, () => {
                this.$cardReset('production-table')
                this.$cardReset('my-jobs')
              }, 'Osasid tooteid on vahepeal muudetud. Palun pane aken kinni ja proovi mõne hetke pärast uuesti!')
              this.$loader.stop('edit-proof')
            //this.$cardReset('production-table')
            })
          !this.proofEditError && await this.$emit_p('complete', null)
          this.$loader.stop('edit-proof')
        } else {
          !this.proofEditError && await this.$emit_p('complete', null)
          this.$loader.stop('edit-proof')
        }
      },
      notMatchingUser (operator) {
        return this.$user.getUsername() !== operator
      },
      calculateRemainingProduceAmount (index) {
        try {
          return +this.myJobs[index].product.quantity.amount - this.workcenters[index].proof.reduce((a, b) => {
            if (b.status !== 'DELETED') {
              return a + +b.producedProductsAmount
            } else {
              return +a + 0
            }
          }, 0)
        } catch (error) {
          return 0
        }

      },
      addNewRow (productIndex, proofIndex) {
        const remainingAmount = this.calculateRemainingProduceAmount(productIndex)
        this.workcenters[productIndex].proof.splice(proofIndex + 1, 0, {
          producedProductsAmount: remainingAmount > 0 ? remainingAmount : 0
        })
      },
      deleteRow (productIndex, proofIndex) {
        const operator = this.workcenters[productIndex].proof[proofIndex].operator
        if (operator && this.notMatchingUser(operator)) {
          this.$err.show('Ei saa kustutada teise operaatori tööd!')
        } else {
          const jobId = this.$_get(this.workcenters, `[${productIndex}].proof[${proofIndex}].jobId`, null)
          if (jobId) {
            this.$_set(this.workcenters, `[${productIndex}].proof[${proofIndex}].status`, 'DELETED')
            this.$_set(this.workcenters, `[${productIndex}].proof[${proofIndex}].repeatedRowHidden`, true)
          } else {
            this.workcenters[productIndex].proof.splice(proofIndex, 1)
          }
        }
      },
      // Ajan Import only
      prefillProof (event) {
        const index = this.workcenters.findIndex(obj => event.name.trim().includes(this.$_get(obj, 'productName').trim()))
        const lastProof = this.workcenters[index].proof.length - 1

        if (this.workcenters[index].proof[lastProof].workSheet) {
          this.workcenters[index].proof.splice(lastProof + 1, 0, {
            producedProductsAmount: event.producedProductsAmount,
            workSheet: event.workSheet
          })
        } else {
          this.workcenters[index].proof[lastProof].producedProductsAmount = event.producedProductsAmount
          this.workcenters[index].proof[lastProof].workSheet = event.workSheet
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .bg-blue {
    background-color: aliceblue;
    border-radius: 4px;
    height: 100%;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .bg-light-gray {
    // background-color: #f8f9fa;
    border-radius: 4px;
    height: 100%;
    // border: 0.0625rem solid #e3e3e3;
    // -webkit-box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
    // box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    //   0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .stats-title {
    & > h5 {
      text-transform: uppercase;
      font-size: 12px;
      display: inline-block;
    }

    button,
    svg {
      font-size: 18px;
      float: right;
      display: inline-block;
    }

    & > svg {
      font-size: 36px;
    }

    & > h2 {
      display: block;
    }
  }

  .faf-t-border {
    border-top: 0.5px, solid, #6c757d;
  }
</style>
