<template>
  <div>
    <hr />
    <b-row class="pl-3 pr-3">
      <b-col md="6" class="px-1 pb-2">
        <h4>Üldised seaded</h4>
        <b-col md="12" class="px-1 pb-2">
          <label for="transport-price">Transpordi vaikimisi hind</label>
          <b-input-group>
            <b-form-checkbox v-model="formData.orderSettings.calculateTransportPrice" name="check-button" switch class="ml-2 mb-3 mt-1">
              Arvuta materjali kaalu järgi
            </b-form-checkbox>
          </b-input-group>
          <b-input-group>
            <b-form-input
              v-model.trim="formData.orderSettings.defaultTransportPrice"
              v-validate="'required'"
              :disabled="formData.orderSettings.calculateTransportPrice"
              v-bind="$requiredInputProps('transport-name')"
              class="transport-control"
              name="transport-price"
              type="number"
            />
          </b-input-group>
        </b-col>
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <h4>Materjalide seaded</h4>
        <b-row class="pl-3 pr-3">
          <b-col md="12" class="px-1 pb-2">
            <b-form-checkbox
              v-model="formData.materialSettings.useCalculatedAveragePrice"
              :options="$userGroupOptions"
              name="use-calculated-average-price"
            >
              Kasuta eeltäitmisel hinna väärtuseks kaalutud keskmist
            </b-form-checkbox>
          </b-col>
          <b-col md="12" class="px-1 pb-2">
            <label for="remains-price">Jäägi hind</label>
            <b-input-group>
              <b-form-input
                v-model.trim="formData.materialSettings.remainsPrice"
                v-validate="'required'"
                class="form-control"
                name="remains-price"
                type="number"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <h4>Toodete seaded</h4>
        <b-col md="12" class="px-1 pb-2">
          <label for="transport-price">Vaikimisi toote hind</label>
          <b-input-group>
            <b-form-checkbox v-model="formData.orderSettings.defaultUseNetPrice" name="check-button" switch class="ml-2 mb-3 mt-1">
              Eelvali vaikimisi "Kasuta toote omahinda"
            </b-form-checkbox>
          </b-input-group>
        </b-col>
      </b-col>
    </b-row>

    <b-row class="justify-content-end mt-3">
      <b-col cols="5">
        <b-button
          id="add-new-user"
          :disabled="this.$loader.val()"
          class="w-100"
          type="submit"
          variant="primary"
          size="m"
          @click="upsert"
        >
          Salvesta
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    name: 'material-settings',
    data () {
      return {
        formData: {
          orderSettings: {
            defaultTransportPrice: null,
            calculateTransportPrice: false,
            defaultUseNetPrice: false
          },
          materialSettings: {
            useCalculatedAveragePrice: false
          }
        }
      }
    },
    async created () {
      const { data } = await this.$settings.get()
      if (data.materialSettings) this.formData.materialSettings = data.materialSettings
      if (data.orderSettings) this.formData.orderSettings = { ...this.formData.orderSettings, ...data.orderSettings }
      this.$validator.reset()
    },
    methods: {
      async upsert () {
        this.$loader.start('edit-material-settings')
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            this.$loader.stop('edit-material-settings')

          } else {
            this.$settings.upsert(this.formData).then(() => {
              this.$loader.stop('edit-material-settings')
              this.$notify.show('Salvestatud')
            }).catch(err => {
              this.$loader.stop('edit-materialy-settings')
              this.$err.show(err)
            })
          }
        })
      }
    }
  }
</script>
