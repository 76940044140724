// OPTIONS
const documentSubTypes = [
  { label: "Lehelõikus", value: "SHEET_CUT" },
  { label: "Standard", value: "STANDARD" },
]

const vatTypes = [
  "Käibemaks 20%",
  "Käibemaks 20% (Pöördmaksustamine KMS § 41 lg.1)",
]

const languages = [
  { label: "Eesti keel", value: "EE" },
  { label: "Inglise keel", value: "EN" },
]

// VISIBLE
const contactVisibleDocuments = [
  "SALES_PREPAYMENT",
  "SALES_PRICE_INQUIRY",
  "SALES_BILL_OF_DELIVERY",
  "SALES_ORDER_CONFIRMATION",
]

const supplierVisibleDocuments = ["PURCHASE_PRICE_INQUIRY", "PURCHASE_ORDER"]

const productNameVisibleDocuments = [
  "SALES_PREPAYMENT",
  "SALES_PRICE_INQUIRY",
  "SALES_ORDER_CONFIRMATION",
]

const vatTypeVisibleDocuments = ["SALES_PREPAYMENT"]

const documentSubTypeVisibleDocuments = [
  "SALES_PRICE_INQUIRY",
  "SALES_BILL_OF_DELIVERY",
]

const clientOrderNumberVisibleDocuments = ["SALES_ORDER_CONFIRMATION"]

const languageVisibleDocuments = [
  "SALES_ORDER_CONFIRMATION",
  "SALES_BILL_OF_DELIVERY",
  "SALES_PRICE_INQUIRY",
  "SALES_PREPAYMENT",
]

const deliveryTermsVisibleDocuments = [
  "SALES_PRICE_INQUIRY",
  "SALES_ORDER_CONFIRMATION",
]

const deliveryTimeVisibleDocuments = [
  "SALES_PRICE_INQUIRY",
  "SALES_ORDER_CONFIRMATION",
]

const validTimeVisibleDocuments = ["SALES_PRICE_INQUIRY"]

const notesVisibleDocuments = [
  "SALES_PRICE_INQUIRY",
  "SALES_ORDER_CONFIRMATION",
]

const childrenVisibleDocuments = ["WORKORDER", "PRODUCT_WORKORDER"]

export {
  documentSubTypes,
  vatTypes,
  languages,
  contactVisibleDocuments,
  supplierVisibleDocuments,
  productNameVisibleDocuments,
  vatTypeVisibleDocuments,
  documentSubTypeVisibleDocuments,
  clientOrderNumberVisibleDocuments,
  languageVisibleDocuments,
  deliveryTermsVisibleDocuments,
  deliveryTimeVisibleDocuments,
  validTimeVisibleDocuments,
  notesVisibleDocuments,
  childrenVisibleDocuments,
}
