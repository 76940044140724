import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const getQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `${field}=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getQualityReport (query) {
  return axios({
    url: `/api/client?${getQuery(query)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function findQualityReports (query) {
  return axios({
    url: `/api/quality-reports/find?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addQualityReport (data) {
  return axios({
    url: `/api/quality-reports`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateQualityReport (id, data) {
  return axios({
    url: `/api/quality-reports/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

function addDocument (id, data) {
  return axios({
    url: `/api/quality-reports/document/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function deleteDocument (id) {
  return axios({
    url: `/api/quality-reports/document/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function getSuggestions (field) {
  return axios({
    url: `/api/quality-reports/typeahead/${field}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$qualityReports = {
    findQualityReports,
    addQualityReport,
    updateQualityReport,
    addDocument,
    deleteDocument,
    getSuggestions,
    getQualityReport
  }
}

export {
  findQualityReports,
  addQualityReport,
  updateQualityReport,
  deleteDocument,
  addDocument,
  getSuggestions,
  getQualityReport
}
