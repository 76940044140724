import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function findManagementSystemReports (query) {
  return axios({
    url: `/api/management-system-reports/find?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addManagementSystemReport (data) {
  return axios({
    url: `/api/management-system-reports`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateManagementSystemReport (id, data) {
  return axios({
    url: `/api/management-system-reports/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

function addDocument (id, data) {
  return axios({
    url: `/api/management-system-reports/document/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function updateDocument (id, data) {
  return axios({
    url: `/api/management-system-reports/document/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function deleteDocument (id) {
  return axios({
    url: `/api/management-system-reports/document/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function getSuggestions (field) {
  return axios({
    url: `/api/management-system-reports/typeahead/${field}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$managementSystemReports = {
    findManagementSystemReports,
    addManagementSystemReport,
    updateManagementSystemReport,
    addDocument,
    deleteDocument,
    getSuggestions,
    updateDocument
  }
}

export {
  findManagementSystemReports,
  addManagementSystemReport,
  updateManagementSystemReport,
  deleteDocument,
  addDocument,
  getSuggestions,
  updateDocument
}
