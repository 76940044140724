<template>
  <div>
    <faf-filters
      :fields="filterFields"
      :cols="4"
      url-cache
      @search-change="searchChange"
      @addTag="addTag"
      @filter="getMaterials({ filters: $event })"
    />
    <div
      style="max-height: 450px; overflow: scroll;"
      class="table-responsive"
    >
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('materials-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:head(multiplier)="data">
          m<sup>2</sup> kaal
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4"
            title="Vaata/Muuda"
            @click="$refs['add-edit-material'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getMaterials({ page: $event })"
    />
    <add-edit-material ref="add-edit-material" :key="addMaterialModal" @hide="addMaterialModal = !addMaterialModal" @material-added="getMaterials" />
  </div>
</template>
<script>
  import AddEditMaterial from './AddEditMaterial'

  export default {
    name: 'supplies-table',
    components: {
      AddEditMaterial
    },
    data () {
      return {
        addMaterialModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Nimi'
          },
          {
            key: 'description',
            label: 'Kirjeldus'
          },
          (this.$can(['orderFields', 'material-width']) && {
            key: 'width',
            label: 'Paksus'
          }),
          (this.$can(['action', 'leftoverPercentage']) && {
            key: 'leftoverPercentage',
            label: 'Jäägi %'
          }),
          {
            key: 'unit',
            label: 'Ühik'
          },
          {
            key: 'specialWeight',
            label: 'Erikaal (kg)'
          },
          {
            key: 'multiplier',
            label: 'm<sup>2</sup> kaal',
            formatter: (value, key, item) => value || '-'
          },
          {
            key: 'price',
            label: 'Baashind',
            formatter: (value, key, item) => `${ value ? +value.toFixed(5) : '-' } €`
          },
          {
            key: 'calculatedPrice',
            label: 'Kaalutud keskmine',
            formatter: (value, key, item) => `${ value ? +value.toFixed(5) : item.price ? +item.price.toFixed(5) : '-' } €`
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Materjali nimi', options: [], taggable: true }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getMaterials()
    },
    methods: {
      addTag (fieldName, val) {
        this.filterFields[0].options.push(val)
      },
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getMaterialNames({ [e.name]: e.event })).data
      },
      async getMaterials (event) {
        this.$loader.start('suppliers-table')
        const page = (event && event.page) || this.currentPage
        const filters = this.$route.query.filter ? JSON.parse(this.$route.query.filter) : (event && event.filters)
        const { data } = await this.$materials.getPartialMaterials({page, limit: this.perPage, filters})
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('suppliers-table')
      }
    }
  }
</script>
