import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getClientNames (filters) {
  return axios({
    url: `/api/client/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getSupplierNames (filters) {
  return axios({
    url: `/api/supplier/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getMaterialNames (filters) {
  return axios({
    url: `/api/material/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getMaterialDescriptionNames (filters) {
  return axios({
    url: `/api/material-description/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getWorkcenterNames (filters) {
  return axios({
    url: `/api/workcenter/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getTeamNames (filters) {
  return axios({
    url: `/api/team/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getProductNames (filters) {
  return axios({
    url: `/api/product/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getAddress (value) {
  return axios({
    url: `/api/address?value=${value}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$suggestions = {
    getClientNames,
    getSupplierNames,
    getMaterialNames,
    getMaterialDescriptionNames,
    getWorkcenterNames,
    getTeamNames,
    getProductNames,
    getAddress
  }
}

export {
  getClientNames,
  getSupplierNames,
  getMaterialNames,
  getMaterialDescriptionNames,
  getWorkcenterNames,
  getTeamNames,
  getProductNames,
  getAddress
}
