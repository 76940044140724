/* eslint-disable vue/valid-v-bind */
/* eslint-disable vue/no-parsing-error */
<template>
  <div>
    <div v-if="allowEmpty && items.length === 0">
      <b-col cols="12">
        <i class="tim-icons icon-simple-add active" @click="addRow" />
        <p class="ml-2" style="display: inline; cursor: pointer;" @click="addRow(0)">
          Lisa rida
        </p>
      </b-col>
    </div>
    <div v-for="(row, index) in items" :key="'rep' + index">
      <div v-if="!row.repeatedRowHidden" :class="getRepeaterClasses" class="repeater">
        <b-row>
          <b-col>
            <slot :model="row, index" name="repeaterSlot" />
          </b-col>
          <b-col :class="{ 'inline-actions': inlineIcons }" cols="1" class="repeater-actions pl-0">
            <!-- <i v-if="!addDisabled" class="tim-icons icon-simple-add active" @click="addRow(index)" /> -->
            <b-button v-if="!addDisabled" class="icon-button text-info" @click="addRow(index)">
              <font-awesome-icon icon="plus-circle" />
            </b-button>
            <!-- <i v-if="!duplicateDisabled" class="tim-icons icon-single-copy-04 active" @click="duplicateRow(index)" /> -->
            <b-button v-if="!duplicateDisabled" class="icon-button text-info" @click="duplicateRow(index)">
              <font-awesome-icon icon="copy" />
            </b-button>
            <!-- <i v-if="!deleteDisabled" :class="{ active: isTrashActive && !row.deleteDisabled }" class="tim-icons icon-trash-simple" @click="(isTrashActive && !row.deleteDisabled) && deleteRow(index)" /> -->
            <b-button v-if="!deleteDisabled" :disabled="!isTrashActive && row.deleteDisabled" class="icon-button text-danger" @click="(isTrashActive && !row.deleteDisabled) && deleteRow(index)">
              <font-awesome-icon icon="trash-alt" />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'faf-repeater',
    props: {
      inlineIcons: Boolean,
      items: Array,
      allowEmpty: Boolean,
      addDisabled: Boolean,
      duplicateDisabled: Boolean,
      deleteDisabled: Boolean,
      smallPadding: Boolean,
      separator: Boolean
    },
    computed: {
      isTrashActive () {
        return this.items.length > 1 || this.allowEmpty
      },
      getRepeaterClasses () {
        return {
          'inline-repeater': this.inlineIcons,
          'py-1': (this.inlineIcons || this.smallPadding) && !this.separator,
          'py-3': !this.inlineIcons && !this.smallPadding,
          'separator': this.separator
        }
      }
    },
    methods: {
      addRow (i) {
        this.$emit('add-row', i)
      },
      duplicateRow (i) {
        this.$emit('duplicate-row', i)
      },
      deleteRow (i) {
        this.$emit('delete-row', i)
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import './../../assets/sass/black-dashboard/custom/variables';

  .repeater:not(.inline-repeater) + .repeater:not(.inline-repeater) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .repeater.inline-repeater .px-xl-1 {
    margin-top: -3px;
  }

  .repeater.inline-repeater label {
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  .repeater-actions i:not(.active),
  .inline-actions i:not(.active) {
    cursor: not-allowed;
  }

  .repeater-actions:not(.inline-actions) {
    margin-top: 20px;
    max-width: 25px;

    .icon-button {
      margin-bottom: 15px;
      display: block;
    }
  }

  .inline-actions {
    padding-right: 0;
    max-width: 120px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;

    .icon-button {
      margin-right: 5px;
      padding: 0;
    }
  }

  .white-content .inline-actions,
  .white-content .repeater-actions,
  .modal .inline-actions,
  .modal .repeater-actions {
    .active {
      color: $black;
    }
  }

  .separator > .row {
    padding-bottom: 1.5rem;
  }

  .separator:nth-child(2n + 2) > .row {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .separator:not(:first-child) > .row {
    padding-top: 0.5rem;
  }

  // .separator:not(:last-child) > .row {
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  // }
</style>
