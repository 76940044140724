export function downloadFile (content, headers, name) {
  const blob = new Blob([
    content
  ], { type: headers['content-type'] })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = !name ? headers['content-disposition'].split('filename=')[1].replace(/(^")|("$)/g, '') : name + (blob.type === 'application/pdf' ? '.pdf' : '')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadBinaryFile (binaryContent, headers, name) {
  downloadFile(new Uint8Array(binaryContent), headers, name)
}

export function downloadCsvFile (content, name) {
  const headers = []
  headers['content-type'] = 'text/csv;charset=utf-8'
  downloadFile(`\uFEFF${content}`, headers, name)
}
