<template>
  <div :class="{ 'with-bottom-bar': addNewSale }">
    <transition name="fadeHeight">
      <faf-add-edit-sale v-if="addNewSale" v-can="['add', 'Müük']" @hide="addNewSale = false" />
    </transition>
    <faf-card v-can="['see', 'Tellimused']" title="Tellimuste tabel" name="archive-table">
      <template slot="buttons">
        <b-button
          id="add-new-sale"
          v-b-modal.modal-1
          v-can="['add', 'Müük']"
          v-b-tooltip.hover
          class="icon-button text-success"
          title="Lisa uus projekt"
          @click="addNewSale = true"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-button>
      </template>
      <faf-order-table />
    </faf-card>
  </div>
</template>
<script>
  import { FafOrderTable, FafAddEditSale } from '@faf/faf-ui'

  export default {
    name: 'order-table-card',
    components: {
      FafOrderTable,
      FafAddEditSale
    },
    data () {
      return {
        addNewSale: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .with-bottom-bar {
    margin-bottom: 80px;
  }
</style>
