import Ajv from 'ajv'
import get from 'lodash/get'

// Ühe toote töökeskuste arvutus

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['workcenters', 'quantity'],
  properties: {
    workcenters: {
      type: 'array',
      required: ['unit', 'workTime', 'price']
    },
    quantity: {
      type: 'object',
      required: ['amount'],
      properties: {
        amount: { type: 'number' }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getProductWorkTime (product) {
  if (!test(product)) {
    throw { function: '_getProductWorkTime', error: test.errors }
  }
  if (product.workcenters.length <= 0) {
    return {
      amount: '0.00',
      price: '0.00',
      total: '0.00'
    }
  }

  const workcenters = product.workcenters
  const productQuantity = get(product, 'quantity.amount', 0)
  let otherUnits = false

  const calc = workcenters.reduce((a, workcenter) => {
    if (workcenter.unit !== 'h' && workcenter.unit !== 'min') {
      otherUnits = true
    }
    const workTime = workcenter.unit === 'h' ? (+workcenter.workTime * 60) : +workcenter.workTime
    const price = workcenter.unit === 'h' ? (+workcenter.price / 60) : +workcenter.price
    return {
      amount: a.amount + (workTime * productQuantity),
      total: a.total + ((productQuantity * workTime) * +price)
    }
  }, { amount: 0, total: 0 })

  const price = +calc.total / +calc.amount

  return {
    amount: isFinite(calc.amount) ? calc.amount.toFixed(2) : '0.00',
    price: isFinite(price) ? price.toFixed(2) : '0.00',
    total: isFinite(calc.total) ? calc.total.toFixed(2) : '0.00',
    otherUnits
  }
}
