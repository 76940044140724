import { _getTotalSalePrice, _getProductMaterialCost, _getDiscountPercentage, _getProductSalePriceWithDiscount } from '../../Calculations/index'

export default {
  inject: ['$validator'],
  inheritAttrs: false,
  methods: {
    async getAdditionalData (type, order, formData) {
      switch (type) {
      case 'SALES_PREPAYMENT':
        return await this.getSalesPrepaymendAdditionalData(order, formData)
      case 'SALES_BILL_OF_DELIVERY':
        return await this.getSalesBillOfDeliveryAdditionalData(order, formData)
      case 'SALES_PRICE_INQUIRY':
        return await this.getSalesPriceInquiryData(order, formData)
      case 'SALES_ORDER_CONFIRMATION':
        return await this.getSalesOrderConfirmationAdditionalData(order, formData)
      default:
        return {}
      }
    },
    async calculate (order) {
      const calc = {
        salePrice: await _getTotalSalePrice(order.products)
      }
      calc.saleTotal = (+calc.salePrice + (order.transport ? +order.transport.price : 0) - +order.discount).toFixed(2)
      return calc
    },
    async getSalesPrepaymendAdditionalData (order, formData) {
      const calc = await this.calculate(order)
      return {
        productName: formData.productName,
        total: calc.saleTotal,
        vatType: formData.vatType,
        vat: (calc.saleTotal * 0.2).toFixed(2),
        vatTotal: formData.vatType.includes('Pöördmaksustamine') ? calc.saleTotal : (calc.saleTotal * 1.2).toFixed(2)
      }
    },
    async getSalesBillOfDeliveryAdditionalData (order, formData) {
      const calculatedProducts = []
      const discountPercentage = await _getDiscountPercentage(order)
      for await (const product of order.products) {
        calculatedProducts.push({
          ...product,
          price: await _getProductSalePriceWithDiscount(product, discountPercentage),
          weight: (await _getProductMaterialCost(product, 'size')).amount,
          materialWidth: product.materials.length ? product.materials[0].width : '',
          materialName: product.materials.length ? product.materials[0].name : ''
        })
      }
      const totalWeight = calculatedProducts.reduce((a, product) => a + +product.weight, 0)
      return {
        calculatedProducts,
        totalWeight: totalWeight.toFixed(2),
        documentSubType: this.$can(['see', 'documentSubTypes']) ? formData.documentSubType : 'STANDARD'
      }
    },
    async getSalesPriceInquiryData (order, formData) {
      const calc = await this.calculate(order)

      const calculatedProducts = []
      const discountPercentage = await _getDiscountPercentage(order)
      for await (const product of order.products) {
        const price = await _getProductSalePriceWithDiscount(product, discountPercentage)
        calculatedProducts.push({
          ...product,
          price,
          oneProductPrice: (+price / +product.quantity.amount).toFixed(2),
          weight: (await _getProductMaterialCost(product, 'size')).amount,
          materialWidth: product.materials.length ? product.materials[0].width : '',
          materialName: product.materials.length ? product.materials[0].name : ''
        })
      }
      const totalWeight = calculatedProducts.reduce((a, product) => a + +product.weight, 0)
      return {
        productName: formData.productName,
        total: calc.saleTotal,
        totalWeight: totalWeight.toFixed(2),
        calculatedProducts,
        documentSubType: this.$can(['see', 'documentSubTypes']) ? formData.documentSubType : 'STANDARD',
        notes: formData.notes,
        deliveryTerms: formData.deliveryTerms,
        deliveryTime: formData.deliveryTime,
        validTime: formData.validTime
      }
    },
    async getSalesOrderConfirmationAdditionalData (order, formData) {
      const calc = await this.calculate(order)

      const calculatedProducts = []
      const discountPercentage = await _getDiscountPercentage(order)
      for await (const product of order.products) {
        const price = await _getProductSalePriceWithDiscount(product, discountPercentage)
        calculatedProducts.push({
          ...product,
          oneProductPrice: (+price / +product.quantity.amount).toFixed(2),
          price
        })
      }

      return {
        productName: formData.productName,
        total: calc.saleTotal,
        clientOrderNumber: formData.clientOrderNumber,
        calculatedProducts,
        deliveryTerms: formData.deliveryTerms,
        deliveryTime: formData.deliveryTime,
        notes: formData.notes
      }
    }
  }
}
