<template>
  <faf-card name="my-jobs" title="Hetkel töös">
    <faf-my-jobs ref="jobs" />
  </faf-card>
</template>
<script>
  import { FafMyJobs } from '@faf/faf-ui'
  export default {
    name: 'my-jobs-card',
    components: {
      FafMyJobs
    }
  }
</script>
