<template>
  <b-modal
    :visible="!!editModal"
    title="Muuda"
    size="s"
    title-tag="h4"
    @hide="$emit('hide')"
  >
    <form>
      <b-row class="px-2">
        <b-col cols="12" class="mt-2 px-1">
          <label for="add-delivery-date">Tarneaeg</label>
          <datepicker
            v-validate="'required'"
            v-model="date"
            v-bind="$datepickerProps('add-delivery-date')"
            name="add-delivery-date"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-delivery-date')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </form>
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">

        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$emit('hide')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('detailed-sale')" class="icon-button text-success" title="Salvesta projekt" @click="submit">
          <font-awesome-icon icon="save" />
        </b-button>

      </div>
    </div>
  </b-modal>
</template>
<script>

  export default {
    name: 'faf-detailed-edit-modal',
    inject: ['$validator'],
    props: {
      editModal: [String, Boolean],
      deliveryDate: String
    },
    data () {
      return {
        date: null
      }
    },
    watch: {
      editModal () {
        this.date = this.deliveryDate
      }
    },
    methods: {
      submit () {
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            return
          }
          this.$emit('submit', this.date)
        })
      }
    }
  }
</script>
