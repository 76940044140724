<template>
  <div>
    <div v-can="['see', 'Supplier']" class="col-md-12">
      <faf-card name="quality-reports" title="Kvaliteediaruanded">
        <faf-quality-reports-table />
        <template slot="buttons">
          <b-button
            id="add-new-quality-report"
            v-can="['add', 'Supplier']"
            v-b-modal.add-edit-quality-reports
            v-b-tooltip.hover
            class="icon-button text-success"
            title="Lisa uus kvaliteediaruanne"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </template>
      </faf-card>
    </div>
  </div>
</template>
<script>
  import { FafQualityReportsTable } from '@faf/faf-ui'
  export default {
    name: 'quality-reports',
    components: {
      FafQualityReportsTable
    },
    methods: {

    }
  }
</script>
<style lang="scss" scoped>
  .with-bottom-bar {
    margin-bottom: 80px;
  }
</style>
