<template>
  <div>
    <b-container class="px-1">
      <b-row>
        <b-col>
          <label for="add-new-user">Kasutajanimi</label>
          <input
            v-model.trim="form.username"
            v-validate="'required|min:2|validate_username'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-new-user')}"
            name="add-new-user"
            autocomplete="nope"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-new-user')">
            Kasutajanimi peab olema vähemalt 2 tähemärki ning sisaldama ainult tähti ja/või punkti keskel.
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="pr-1">
          <label for="add-first-name">Eesnimi</label>
          <input
            v-model.trim="form.firstName"
            v-validate="'required|min:2|max:15|validate_name'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-first-name')}"
            placeholder="Britney"
            name="add-first-name"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-first-name')">
            Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.
          </b-form-invalid-feedback>
        </b-col>
        <b-col class="pl-1">
          <label for="add-last-name">Perenimi</label>
          <input
            v-model.trim="form.lastName"
            v-validate="'required|min:2|max:15|validate_name'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-last-name')}"
            placeholder="Spears"
            name="add-last-name"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-last-name')">
            Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="pr-1">
          <label for="add-password">Parool</label>
          <input
            v-model="form.password"
            v-validate="'required|min:6'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-password')}"
            type="password"
            name="add-password"
            autocomplete="new-password"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-password')">
            Parool peab olema vähemalt 6 tähemärki.
          </b-form-invalid-feedback>
        </b-col>
        <b-col class="pl-1">
          <label for="add-password-repeat">Korda parooli</label>
          <input
            v-model="form.repeatPassword"
            v-validate="{ required: true, min: 6, is: form.password }"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-password-repeat')}"
            type="password"
            name="add-password-repeat"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-password-repeat')">
            Korda parool peab ühtima parooliga.
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <label for="add-user-group">Kasutaja õigused:</label>
          <b-form-group>
            <b-form-checkbox-group
              v-model.trim="form.userGroups"
              v-validate="'required'"
              :state="form.userGroups && $validateField('add-user-group')"
              :options="$userGroupOptions"
              name="add-user-group"
            />
            <b-form-invalid-feedback>
              Kasutaja õigused on nõutud.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="justify-content-end mt-3">
        <b-col cols="5">
          <b-button
            id="add-new-user"
            class="w-100"
            type="submit"
            variant="primary"
            size="m"
            @click="onSubmit"
          >
            Lisa
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import { registerUser } from './../../views/Login/AuthService'
  export default {
    name: 'add-user',
    data () {
      return {
        form: {
          email: '',
          firstName: ''
        },
        show: true,
        companyList: []
      }
    },
    methods: {
      onSubmit (evt) {
        evt.preventDefault()
        this.$validator.validateAll().then(valid => {
          if (valid) {
            registerUser(this.form).then(rs => {
              this.$notify.show('Kasutaja edukalt lisatud!')
              this.$root.$emit('new-user-added', rs.data);
              this.form = {}
              this.$validator.reset()
            }).catch(err => {
              this.$err.show(err)
            })
          }
        })
      }
    }
  }
</script>
