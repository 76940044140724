<template>
  <b-table
    v-if="data.length"
    id="edit-user-table"
    :busy="isLoading"
    :items="data"
    :fields="fields"
    responsive
    striped
    small
  >
    <template v-slot:cell(show_details)="row">
      <i :class="getEditClasses(row)" @click="toggleEdit(row); row.toggleDetails();"></i>
      <b-button
        :class="getEditClasses(row)"
        class="icon-button text-secondary"
        @click="toggleEdit(row); row.toggleDetails();"
      >
        <font-awesome-icon icon="chevron-circle-right" />
      </b-button>
    </template>
    <template v-slot:cell(name)="row">
      {{ row.item.firstName }} {{ row.item.lastName }}
    </template>
    <template v-slot:cell(userGroups)="row">
      {{ getUserGroups(row.item.userGroups) }}
    </template>
    <template v-slot:cell(delete)="row">
      <!-- <i class="tim-icons icon-trash-simple" @click="deleteUser(row)" /> -->
      <b-button
        class="icon-button text-danger"
        @click="deleteUser(row)"
      >
        <font-awesome-icon icon="trash-alt" />
      </b-button>
    </template>
    <template v-slot:row-details="row">
      <!-- Start edit -->
      <b-container class="px-1">
        <b-row class="mt-3">
          <b-col class="pr-md-1">
            <label for="edit-username">Kasutajanimi</label>
            <b-form-input :placeholder="row.item.username" name="edit-username" disabled />
          </b-col>
          <b-col class="px-md-1">
            <label for="edit-first-name">Eesnimi</label>
            <b-form-input
              v-model="editUserModel.firstName"
              v-validate="'min:2|max:15|validate_name'"
              :placeholder="row.item.firstName"
              :state="$validateField('edit-first-name')"
              name="edit-first-name"
            />
            <b-form-invalid-feedback>Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.</b-form-invalid-feedback>
          </b-col>
          <b-col class="pl-md-1">
            <label for="edit-last-name">Perenimi</label>
            <b-form-input
              v-model="editUserModel.lastName"
              v-validate="'min:2|max:15|validate_name'"
              :placeholder="row.item.lastName"
              :state="$validateField('edit-last-name')"
              name="edit-last-name"
            />
            <b-form-invalid-feedback>Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="pr-md-1">
            <label for="edit-password">Parool</label>
            <b-form-input
              v-model="editUserModel.password"
              v-validate="'min:6'"
              :state="$validateField('edit-password')"
              placeholder="Sisesta uus parool"
              name="edit-password"
              type="password"
            />
            <b-form-invalid-feedback>Parool peab olema vähemalt 6 tähemärki.</b-form-invalid-feedback>
          </b-col>
          <b-col class="pl-md-1">
            <label for="edit-password-repeat">Korda parooli</label>
            <b-form-input
              v-model="editUserModel.repeatPassword"
              v-validate="{ min: 6, is: editUserModel.password }"
              :state="$validateField('edit-password-repeat')"
              placeholder="Korda parooli"
              name="edit-password-repeat"
              type="password"
            />
            <b-form-invalid-feedback>Korda parool peab ühtima parooliga.</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label for="edit-user-group">Kasutaja õigused:</label>
            <b-form-group>
              <b-form-checkbox-group
                v-model="editUserModel.userGroups"
                v-validate="'required'"
                :options="$userGroupOptions"
                :state="$validateField('edit-user-group')"
                name="edit-user-group"
              />
              <b-form-invalid-feedback>Kasutaja õigused on nõutud.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-1 mb-3">
          <b-col cols="4" class="pr-md-1">
            <b-button class="w-100" type="submit" variant="info" size="m" @click="toggleEdit(row); row.toggleDetails();">
              Sulge
            </b-button>
          </b-col>
          <b-col cols="4" class="pl-md-1">
            <b-button class="w-100" type="submit" variant="primary" size="m" @click="update(row.item.id)">
              Salvesta
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-table>
</template>

<script>
  import { getUserList, updateUser, deleteUser } from './../../views/Login/AuthService'
  export default {
    name: 'edit-user-table',
    data () {
      return {
        data: [],
        activeRow: null,
        fields: [
          { key: 'show_details', label: '', width: '10px' },
          { key: 'name', label: 'Nimi' },
          { key: 'userGroups', label: 'Kasutaja õigused', formatter: value => {
            return value.join(', ')
          }},
          { key: 'delete', label: '', width: '10px' }
        ],
        editUserModel: {
          lastName: '',
          firstName: ''
        },
        isLoading: true
      }
    },
    created () {
      getUserList().then(rs => {
        this.data = rs.data
        this.isLoading = false
      })
      this.$root.$on('new-user-added', user => {
        getUserList().then(rs => {
          this.data = rs.data
          this.isLoading = false
        })
      });
    },
    methods: {
      getUserGroups (groups) {
        let groupsText = ''

        groups.map(g => {
          const foundGroup = this.$userGroupOptions.find(userGroup => userGroup.value === g)
          groupsText += groupsText ? ', ' + this.$_get(foundGroup, 'text') : this.$_get(foundGroup, 'text')
        })
        return groupsText
      },
      update (id) {
        this.$validator.validateAll().then(valid => {
          if (!valid) {
            return
          }
          updateUser(id, this.editUserModel).then(() => {
            this.$cardReset('edit-user-table')
            this.$notify.show('Kasutaja edukalt muudetud')
          }).catch(err => this.$err.show(err))
        })
      },
      getEditClasses (row) {
        if (this.activeRow === null || this.activeRow === row.index) {
          return row.detailsShowing ? 'tim-icons icon-minimal-right turn-right' : 'tim-icons icon-double-right'
        }
        return 'tim-icons icon-double-right visually-hidden'
      },
      toggleEdit (row) {
        this.editUserModel = {}
        this.activeRow = this.activeRow != row.index ? row.index : null
        // Add one, so it also works with 0
        this.activeRow + 1 && (this.editUserModel.userGroups = row.item.userGroups)
      },
      deleteUser (row) {
        this.$confirm.show(
          `Oled kindel et soovid ${row.item.firstName} ${row.item.lastName} kasutaja kustutada?`, null, answer => {
            if (answer) {
              deleteUser(row.item.id).then(() => {
                this.$cardReset('edit-user-table')
                this.$notify.show('Kasutaja edukalt kustutatud')
              }).catch(err => this.$err.show(err))
            }
          }
        )
      }
    }
  }
</script>
<style lang="scss">
  .tim-icons {
    cursor: pointer;
    -moz-transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  .turn-right {
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  .visually-hidden {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
</style>
