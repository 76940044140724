<template>
  <b-badge :variant="color" class="faf-tag" pill>{{ text }}<br/><span class="tag--description">{{ description }}</span></b-badge>
</template>
<script>
  export default {
    name: 'faf-tag',
    props: {
      text: String,
      description: String,
      color: {
        type: String,
        default: 'info'
      }
    },
    data () {
      return {
      }
    }
  }
</script>
<style lang="scss" scoped>
  .faf-tag {
    font-size: 12px;

    + .faf-tag {
      margin-left: 5px;
    }
  }
</style>
