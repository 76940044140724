// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSave,
  faTrashAlt,
  faArrowAltCircleLeft,
  faCopy,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faMinusCircle,
  faChevronCircleDown,
  faChevronCircleRight,
  faPlusCircle,
  faPlayCircle,
  faPencilAlt,
  faHeart,
  faFileSignature,
  faFlushed,
  faInfoCircle,
  faSmileWink,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faBoxes,
  faSearch,
  faEye,
  faFileDownload,
  faCommentDots,
  faCommentAlt,
  faCog,
  faShoppingCart,
  faAtom,
  faSuitcase,
  faClipboardList,
  faUnlock,
  faLock,
  faLockOpen,
  faExclamation,
  faExclamationCircle,
  faLink,
  faHandPaper,
  faIdCard,
  faFileAlt,
  faFileInvoice,
  faDownload,
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileExcel,
  faFileCsv,
  faFolderOpen,
  faFolder,
  faEuroSign,
  faMoneyBill,
  faMoneyBillWave,
  faProjectDiagram,
  faChartLine,
  faTruck,
  faFileImport,
  faTasks,
  faEnvelope,
  faCube,
  faTruckPickup,
  faUserShield,
  faArrowUp,
  faArrowDown,
  faPaperPlane,
  faMailBulk,
  faPauseCircle,
  faClock,
  faAngleDoubleDown,
  faSync
} from '@fortawesome/free-solid-svg-icons'
import {
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSave)
library.add(faTrashAlt)
library.add(faArrowAltCircleLeft)
library.add(faCopy)
library.add(faTimes)
library.add(faTimesCircle)
library.add(faCheckCircle)
library.add(faMinusCircle)
library.add(faChevronCircleDown)
library.add(faChevronCircleRight)
library.add(faPlusCircle)
library.add(faPlayCircle)
library.add(faPencilAlt)
library.add(faHeart)
library.add(faFileSignature)
library.add(faFlushed)
library.add(faInfoCircle)
library.add(faSmileWink)
library.add(faAngleLeft)
library.add(faAngleDoubleLeft)
library.add(faAngleRight)
library.add(faAngleDoubleRight)
library.add(faBoxes)
library.add(faSearch)
library.add(faEye)
library.add(faFileDownload)
library.add(faCommentDots)
library.add(faCommentAlt)
library.add(faCog)
library.add(faShoppingCart)
library.add(faAtom)
library.add(faSuitcase)
library.add(faClipboardList)
library.add(faUnlock)
library.add(faLock)
library.add(faLockOpen)
library.add(faExclamation)
library.add(faExclamationCircle)
library.add(faLink)
library.add(faHandPaper)
library.add(faIdCard)
library.add(faFileAlt)
library.add(faFileInvoice)
library.add(faDownload)
library.add(faFilePdf)
library.add(faFileImage)
library.add(faFileWord)
library.add(faFileExcel)
library.add(faFileCsv)
library.add(faFolderOpen)
library.add(faFolder)
library.add(faEuroSign)
library.add(faMoneyBill)
library.add(faMoneyBillWave)
library.add(faProjectDiagram)
library.add(faChartLine)
library.add(faTruck)
library.add(faFileImport)
library.add(faTasks)
library.add(faEnvelope)
library.add(faCube)
library.add(faTruckPickup)
library.add(faUserShield)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faPaperPlane)
library.add(faMailBulk)
library.add(faPauseCircle)
library.add(faClock)
library.add(faAngleDoubleDown)
library.add(faSync)

export default function (Vue) {
  Vue.component('font-awesome-icon', FontAwesomeIcon)
}
