import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function download (id, callback = (progress) => progress) {
  return axios({
    url: `/file-service/v1/download/file/${id}`,
    method: 'GET',
    headers: {
      Authorization: token()
    },
    onDownloadProgress (progress) {
      callback(progress)
    },
    responseType: 'arraybuffer'
  }).then(rs => {
    downloadBinaryFile(rs.data, rs.headers)
  })
}

function deleteFile (id) {
  return axios({
    url: `/file-service/v1/file/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: token(),
      'X-FAF-Organization': `LAPI`
    }
  })
}

function qualityReportPrintoutDownload (company = 'LAPI') {
  return axios({
    url: `/file-service/v1/meta/file/?metadata.qualityReportPrint=${company}`,
    method: 'GET',
    headers: {
      Authorization: token()
    }
  }).then(rs => {
    download(rs.data[0]._id)
  })
}

async function convertImageToBase64 (file, newWidth = 256, newHeight = 256) {
  return await new Promise((resolve, reject) => {
    const blob = new Blob([file], { type: "image/jpeg" });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const context = canvas.getContext("2d");
      const img = document.createElement("img");
      img.src = reader.result;
      img.onload = function () {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min((newWidth / iw),(newHeight / ih));
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img,0,0,iwScaled,ihScaled);
        resolve(canvas.toDataURL("image/jpeg",0.5));
      }
    }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$fileService = {
    download,
    deleteFile,
    qualityReportPrintoutDownload,
    convertImageToBase64
  }
}

export {
  download,
  deleteFile,
  qualityReportPrintoutDownload,
  convertImageToBase64
}

function downloadFile (content, headers, name) {
  const blob = new Blob([
    content
  ], { type: headers['content-type'] })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = !name ? headers['content-disposition'].split('filename=')[1].replace(/(^")|("$)/g, '') : name + (blob.type === 'application/pdf' ? '.pdf' : '')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function downloadBinaryFile (binaryContent, headers, name) {
  downloadFile(new Uint8Array(binaryContent), headers, name)
}
