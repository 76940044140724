export default function installCard (Vue) {
  if (Vue) {
    // this.$cardLoader(['name-of-card', 'name-of-card-2'], true)
    // this.$cardLoader('name-of-card', true)
    Vue.prototype.$cardLoader = function (name, val) {
      if (typeof name === 'string') {
        this.$root.$emit(name + '-loading', val)
      } else {
        name.map(name => this.$root.$emit(name + '-loading', val))
      }
    }

    // this.$cardReset(['name-of-card', 'name-of-card-2'])
    // this.$cardReset('name-of-card')
    Vue.prototype.$cardReset = function (name) {
      if (typeof name === 'string') {
        this.$root.$emit(name + '-reset')
      } else {
        name.map(name => this.$root.$emit(name + '-reset'))
      }
    }

    // this.$cardCollapsed(['name-of-card', 'name-of-card-2'], true)
    // this.$cardCollapsed('name-of-card', false)
    Vue.prototype.$cardCollapsed = function (name, value) {
      if (typeof name === 'string') {
        this.$root.$emit(name + '-collapsed', value)
      } else {
        name.map(name => this.$root.$emit(name + '-collapsed', value))
      }
    }
  } else {
    console.error('Vue not found')
  }
}
