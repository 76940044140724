<template>
  <div>
    <b-row class="pl-3 pr-3">
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">SMTP aadress</label>
        <input
          v-validate="'required'"
          v-model.trim="formData.emailSettings.server.host"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('host') }"
          name="host"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Port</label>
        <input
          v-validate="'required'"
          v-model.trim="formData.emailSettings.server.port"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('port') }"
          type="number"
          name="port"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Kasutajanimi</label>
        <input
          v-validate="'required'"
          v-model.trim="formData.emailSettings.server.auth.user"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('user') }"
          name="user"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Parool</label>
        <input
          v-validate="'required'"
          v-model.trim="formData.emailSettings.server.auth.pass"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('pass') }"
          type="password"
          name="pass"
        />
      </b-col>
      <b-col md="6" class="px-1 pb-2">
        <label for="add-project-name">Kellelt</label>
        <input
          v-validate="'required'"
          v-model.trim="formData.emailSettings.from"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('from') }"
          name="from"
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-end mt-3">
      <b-col cols="5">
        <b-button
          id="add-new-user"
          :disabled="$loader.val()"
          class="w-100"
          type="submit"
          variant="primary"
          size="m"
          @click="upsert"
        >Salvesta</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    name: 'email-settings',
    data () {
      return {
        formData: {
          emailSettings: {
            server: {
              host: null,
              port: null,
              auth: {
                user: null,
                pass: null
              }
            },
            from: null
          }
        }
      }
    },
    async created () {
      const { data } = await this.$settings.get()
      if (data.emailSettings) this.formData.emailSettings = data.emailSettings
      this.$validator.reset()
    },
    methods: {
      async upsert () {
        this.$loader.start('edit-email-settings')
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            this.$loader.stop('edit-email-settings')

          } else {
            this.$settings.upsert(this.formData).then(() => {
              this.$loader.stop('edit-email-settings')
              this.$notify.show('Salvestatud')
            }).catch(err => {
              this.$loader.stop('edit-email-settings')
              this.$err.show(err)
            })
          }
        })
      }
    }
  }
</script>
