import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getPurchaseOrders (filters) {
  return axios({
    url: `/api/purchase-order?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getPurchaseOrdersHistory (filters) {
  return axios({
    url: `/api/purchase-order-history?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addPurchaseOrder (data) {
  return axios({
    url: `/api/purchase-order`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function editPurchaseOrder (id, data) {
  return axios({
    url: `/api/purchase-order/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function purchaseRecommendationToOrder (data) {
  return axios({
    url: `/api/purchase-recommendations-order`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function purchaseFromInventory (data) {
  return axios({
    url: `/api/purchase-from-inventory`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function annullPurchaseOrder (id) {
  return axios({
    url: `/api/annull-purchase-order/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function completePurchaseOrder (id) {
  return axios({
    url: `/api/complete-purchase-order/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function editPurchaseMaterial (id, data) {
  return axios({
    url: `/api/edit-purchase-material/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$purchase = {
    getPurchaseOrders,
    addPurchaseOrder,
    purchaseRecommendationToOrder,
    annullPurchaseOrder,
    completePurchaseOrder,
    purchaseFromInventory,
    editPurchaseOrder,
    getPurchaseOrdersHistory,
    editPurchaseMaterial
  }
}

export {
  getPurchaseOrders,
  addPurchaseOrder,
  purchaseRecommendationToOrder,
  annullPurchaseOrder,
  completePurchaseOrder,
  purchaseFromInventory,
  editPurchaseOrder,
  getPurchaseOrdersHistory,
  editPurchaseMaterial
}
