<template>
  <faf-card name="purchase-order-history" initial-collapsed title="Ostutellimuste ajalugu">
    <faf-purchase-history />
  </faf-card>
</template>
<script>
  import { FafPurchaseHistory } from '@faf/faf-ui'
  export default {
    name: 'purchase-history-card',
    components: {
      FafPurchaseHistory
    }
  }
</script>
