import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function getMistakeCount (period, area) {
  return axios({
    url: `/report-service/v1/get-mistake-count?period=${period}&area=${area}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getProductivity (period) {
  return axios({
    url: `/report-service/v1/get-productivity?period=${period}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getMaterialAvailability (period, area) {
  return axios({
    url: `/report-service/v1/get-material-activity?period=${period}&area=${area}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getPlanExecution (period, area, teamName, workcenterName) {
  return axios({
    url: `/report-service/v1/get-plan-execution?period=${period}&area=${area}&teamName=${teamName}&workcenterName=${workcenterName}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getWorkTime (period, area, page) {
  return axios({
    url: `/report-service/v1/get-work-time?period=${period}&area=${area}&page=${page}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getSalesCapacity (period, area, page) {
  return axios({
    url: `/report-service/v1/get-sales-capacity?period=${period}&area=${area}&page=${page}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getProductivityDetailed (period, area, page, teamName, workcenterName) {
  return axios({
    url: `/report-service/v1/get-productivity-detailed?period=${period}&area=${area}&page=${page}&teamName=${teamName}&workcenterName=${workcenterName}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$statistics = {
    getMaterialAvailability,
    getPlanExecution,
    getMistakeCount,
    getProductivity,

    getWorkTime,
    getSalesCapacity,
    getProductivityDetailed
  }
}

export {
  getMaterialAvailability,
  getPlanExecution,
  getMistakeCount,
  getProductivity,

  getWorkTime,
  getSalesCapacity,
  getProductivityDetailed
}
