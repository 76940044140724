import Ajv from 'ajv'
import { _getProductMaterialCost } from '.'
import { unblockMainThread } from './../plugins/unblockMainThread'

const ajv = new Ajv({ allErrors: true })

// Kõikide toodete materjalid

const schema = {
  type: 'object',
  required: ['products', 'type'],
  properties: {
    products: {
      type: 'array',
      required: ['materials'],
      properties: {
        materials: {
          type: 'array'
        }
      }
    },
    type: {
      type: 'string',
      enum: [
        'size', 'actualSize'
      ]
    }
  }
}

const test = ajv.compile(schema)

export default async function _getMaterialCostTotal (products, type = 'actualSize') {
  if (!test({products, type})) {
    throw { function: '_getMaterialCostTotal', error: test.errors }
  }

  const calculations = []

  for await (const product of products) {
    if (product.materials.length > 0) {
      calculations.push(await _getProductMaterialCost(product, type))
    }
  }

  const calc = calculations.reduce((a, material) => {
    return {
      amount: a.amount + +material.amount,
      total: a.total + +material.total
    }
  }, { amount: 0, total: 0 })

  return {
    amount: calc.amount.toFixed(2),
    total: calc.total.toFixed(2)
  }
}
