<template>
  <div>
    <b-modal
      v-if="item.orderNumber"
      :visible="item && !!item.orderNumber"
      :title="item && item.orderNumber"
      size="xl"
      ok-title="Lisa"
      cancel-title="Tagasi"
      @show="$emit('show')"
      @hide="item = {}, $emit('close')"
    >
      <b-row>
        <b-col xl="8" md="6" cols="12" class="pr-xl-1">
          <h4 class="text-info">
            Tarnija
          </h4>

          <b-row>
            <b-col xl="6" md="6" cols="6" class="pr-xl-1 position-relative">
              <label class="mb-0 mt-2">Tarnija</label>
              <input
                v-if="!$can(['action', 'FilterSuppliers'])"
                v-model="item.supplier.name"
                v-validate="'required'"
                v-bind="$requiredInputProps('supplier-name')"
              >
              <multiselect
                v-if="$can(['action', 'FilterSuppliers'])"
                v-model="item.supplier.name"
                v-validate="'required'"
                v-faf-autocomplete-off
                :options="supplierNameSuggestions.data"
                v-bind="$multiselectProps('supplier-name')"
                name="supplier-name"
                no-options="Alusta trükkimist..."
                @tag="addTag(supplierNameSuggestions.data, 'formData.supplier.name', $event)"
                @search-change="getSupplierNameSuggestions"
              >
                <template slot="noOptions">
                  Alusta trükkimist...
                </template>
              </multiselect>
            </b-col>
            <b-col xl="6" md="6" cols="6" class="pr-xl-1">
              <label class="mb-0 mt-2">Kontakti nimi</label>
              <input
                v-if="!$can(['action', 'FilterSuppliers'])"
                v-model="item.supplier.contact.name"
                v-validate="'required'"
                v-bind="$requiredInputProps('supplier-contact')"
              >
              <multiselect
                v-if="$can(['action', 'FilterSuppliers']) && supplierContacts.data.length > 0"
                v-model="item.supplier.contact.name"
                v-validate="'required'"
                v-faf-autocomplete-off
                :options="supplierContacts.data"
                v-bind="$multiselectProps('supplier-contact')"
                name="supplier-contact"
                @tag="addTag(supplierContacts.data, 'formData.supplier.contact.name', $event)"
              >
                <template slot="noOptions">
                  Alusta trükkimist...
                </template>
              </multiselect>
            </b-col>
            <b-col xl="6" md="6" cols="6" class="pr-xl-1">
              <label class="mb-0 mt-2">Telefon</label>
              <input
                v-model="item.supplier.contact.phone"
                v-validate="'required'"
                v-bind="$requiredInputProps('supplier-phone')"
              >
            </b-col>
            <b-col xl="6" md="6" cols="6" class="pr-xl-1">
              <label class="mb-0 mt-2">E-mail</label>
              <input
                v-model="item.supplier.contact.email"
                v-validate="'required|email'"
                v-bind="$requiredInputProps('supplier-email')"
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="4" md="6" cols="12" class="pr-xl-1 mt-5">
          <label class="mb-0 ml-1">Märkmed</label>
          <b-form-textarea
            id="textarea-no-resize"
            v-model="item.notes"
            class="ml-1"
            rows="11"
            no-resize
            style="max-height: 93px"
          />
        </b-col>
      </b-row>
      <h4 class="mt-3 text-info">
        Materjalid
      </h4>
      <b-table
        v-click-outside="disableEditMode"
        :class="currentTableId"
        :fields="fields"
        :items="item.materials"
        striped
        hover
      >
        <template v-slot:cell(demandInKg)="data">
          <faf-input
            v-model="data.item.demandInKg"
            v-validate="'required'"
            :active="editIndex === data.index || allActive"
            :unit="data.item.unit || 'tk'"
            :add-on="data.item.unit || 'tk'"
            :name="'demandInKg-' + data.index"
            no-text="Vajadus"
            number
            placeholder="Vajadus"
            @input="onInput(data.index)"
            @edit-row="editRow(data.index)"
          />
        </template>
        <template v-slot:cell(price)="data">
          <faf-input
            v-model="data.item.price"
            v-validate="'required'"
            :active="editIndex === data.index || allActive"
            :unit="' €/kg'"
            :add-on="' €/kg'"
            :name="'price-' + data.index"
            no-text="Hind"
            number
            placeholder="Hind"
            @input="onInput(data.index)"
            @edit-row="editRow(data.index)"
          />
        </template>
        <template v-slot:cell(notes)="data">
          <span v-if="history">
            {{ isNoteExcisting(data) ? data.item.notes : '' }}
          </span>
          <b-button
            v-else
            v-b-modal.modal-add-note
            v-b-tooltip.hover
            :class="isNoteExcisting(data) ? 'icon-button text-info' : 'icon-button text-warning'"
            :title="isNoteExcisting(data) ? data.item.notes : 'Lisa märkus'"
            style="font-size: 20px; padding: 0;"
            @click="setNotesToEdit(data.item)"
          >
            <font-awesome-icon
              :icon="isNoteExcisting(data) ? 'comment-dots' : 'comment-alt'"
            />
          </b-button>
        </template>
      </b-table>
      <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
        <div class="row justify-content-center">
          <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="item = {}, $emit('close')">
            <font-awesome-icon icon="arrow-alt-circle-left" />
          </b-button>
          <b-button v-b-tooltip.hover class="icon-button text-primary" title="Salvesta fail" @click="pdfDownloadVisible = !pdfDownloadVisible">
            <font-awesome-icon icon="file-download" />
          </b-button>
          <b-button v-b-tooltip.hover class="icon-button text-success" title="Salvesta" @click="saveOrder">
            <font-awesome-icon icon="check-circle" />
          </b-button>
          <span class="divider" />
          <b-button v-b-tooltip.hover :disabled="$loader.val()" class="icon-button text-dark" title="Saada email" @click="emailSendingModalVisible = true; emailSendingType = 'FILE_DEFAULT'">
            <font-awesome-icon icon="mail-bulk" />
          </b-button>
        </div>
      </div>
    </b-modal>

    <faf-add-note :visible="!!noteToEdit" :note-to-edit="noteToEdit" :loading="noteLoading" @submit="updateNote" @close="noteToEdit = null" />
    <faf-get-pdf
      v-if="pdfDownloadVisible"
      :visible="pdfDownloadVisible"
      :order="item"
      :supplier="item.supplier.name"
      type="PURCHASE"
      @close="pdfDownloadVisible = false"
    />
    <email-sending-modal
      v-if="$can(['see', 'EmailSending'])"
      :modal-visible="emailSendingModalVisible"
      :order="item"
      :type="emailSendingType"
      included-file-type="PURCHASE"
      @hide="emailSendingModalVisible = false; emailSendingType = null"
    />
  </div>
</template>
<script>
  import EmailSendingModal from './../Email/EmailSendingModal'

  export default {
    name: 'faf-detailed-purchase-modal',
    components: {
      EmailSendingModal
    },
    props: {
      id: {
        type: String
      },
      history: Boolean
    },
    data () {
      return {
        editIndex: null,
        pdfDownloadVisible: false,
        currentTableId: Math.random().toString(16).slice(-4),
        allActive: false,
        item: {
          supplier: {
            contact: {}
          }
        },
        fields: [
          {
            key: 'name',
            label: 'Materjali nimi'
          },
          {
            key: 'projectNumber',
            label: this.$can(['orderFields', 'salesSystemNumber']) ? 'ID' : 'Projekti number'
          },
          {
            key: 'demandInKg',
            label: 'Vajadus'
          },
          {
            key: 'price',
            label: 'Hind'
          },
          {
            key: 'specialWeight',
            label: 'Erikaal',
            formatter: (value, key, item) => value || 1
          },
          {
            key: 'totalPrice',
            label: 'Hind kokku',
            formatter: (value, key, item) => `${value.toFixed(2)} €`
          },
          {
            key: 'notes',
            label: 'Märkmed'
          }
        ],
        noteToEdit: null,
        noteLoading: false,

        emailSendingModalVisible: false,
        emailSendingType: null,

        supplierNameSuggestions: { data: [] },
        supplierContacts: { data: [] }
      }
    },
    watch: {
      id (val) {
        this.getCurrentPurchaseOrder(val)
      },
      'item.supplier.name' (newVal, oldVal) {
        if (oldVal && newVal && oldVal !== newVal) {
          this.item.supplier.contact = {}
        }
        this.getSupplierContacts(newVal)
      },
      'item.supplier.contact.name' (val) {
        if (val && this.supplierContacts.contacts) {
          const foundContact = this.supplierContacts.contacts.find(contact => contact.name === val)
          if (foundContact) {
            this.setSupplierContact(foundContact)
          }
        }
      }
    },
    created () {
      this.getCurrentPurchaseOrder(this.id)
      this.getSupplierNameSuggestions = this.$_debounce(this.getSupplierNameSuggestions, 700)
    },
    methods: {
      async getSupplierNameSuggestions (e) {
        if (e.length >= 2) {
          this.supplierNameSuggestions = await this.$suppliers.getSuppliersNames({name: e})
        }
      },
      async getSupplierContacts (val) {
        const supplier = val ? (await this.$suppliers.getSuppliers({ page: 1, limit: 1, filters: { name: val } })).data.docs[0] : null

        if (supplier) {
          this.supplierContacts = supplier
          this.supplierContacts.data = supplier.contacts.map(contact => contact.name)
        } else {
          this.supplierContacts = { data: [] }
        }
        this.supplierContacts.contacts && (this.supplierContacts.contacts.length === 1 && this.setSupplierContact(this.supplierContacts.contacts[0]))
      },
      setSupplierContact (contact) {
        this.item.supplier.contact = this.$_cloneDeep(contact)
      },
      getCurrentPurchaseOrder (_id) {
        const method = this.history ? 'getPurchaseOrdersHistory' : 'getPurchaseOrders'
        this.$purchase[method]({_id}).then(rs => {
          this.item = rs.data.docs ? rs.data.docs[0] : rs.data[0]
          this.item.materials.map(i => i.demandInKg = i.demandInKg.toFixed(2))
          this.getSupplierNameSuggestions(this.item.supplier.name)
          this.getSupplierContacts(this.item.supplier.name)
          this.setSupplierContact(this.item.supplier.contact)
        })
      },
      isNoteExcisting (data) {
        const notes = this.$_get(data, 'item.notes', null)
        return ![' ', '', null, void 0, false].includes(notes)
      },
      setNotesToEdit (item) {
        this.noteToEdit = {
          id: item._id,
          notes: item.notes
        }
      },
      updateNote (notes) {
        this.noteLoading = true
        this.$purchase.editPurchaseMaterial(this.noteToEdit.id, { notes: notes || ' '}).then(() => {
          this.noteToEdit = null
          this.getCurrentPurchaseOrder(this.id)
          this.noteLoading = false
        }).catch(err => {
          this.$err.show(err)
          this.noteLoading = false
        })
      },
      onInput (index) {
        this.item.materials[index].totalPrice = +this.item.materials[index].demandInKg * (+this.item.materials[index].specialWeight || 1) * +this.item.materials[index].price
      },
      editRow (index) {
        this.editIndex = index
      },
      disableEditMode (e) {
        const isCurrentTable = e.path && e.path.find(i => (i.className && typeof i.className === 'string' && i.className.includes(this.currentTableId)))
        !isCurrentTable && (this.editIndex = null)
      },
      saveOrder () {
        this.allActive = true
        this.$validator.validateAll().then(valid => {
          if (!valid) {
            this.allActive = false
          }
          if (valid) {
            this.allActive = false
            this.$purchase.editPurchaseOrder(this.item._id || this.item.id, {
              materials: this.item.materials,
              notes: this.item.notes,
              supplier: this.item.supplier
            }).then(() => {
              this.$notify.show('Tellimus edukalt muudetud!')
              this.$bvModal.hide('modal-detailed-purchase-order')
            }).catch(err => this.$err.show(err))
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .stats-title {
    & > h4 {
      display: inline-block;
    }

    button,
    svg {
      font-size: 18px;
      float: right;
    }

    & > svg {
      font-size: 36px;
    }

    & > h2 {
      display: block;
    }
  }
</style>
