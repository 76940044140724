import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import sidebarRoutes from './router/sidebarRoutes'
import fafui, { validationRules } from './../fafui/src'
import moment from 'moment'

import VueParticles from 'vue-particles'
import BootstrapVue from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'

import VeeValidate from 'vee-validate'

Vue.use(BootstrapVue, {
  BTooltip: {
    delay: {
      show: 2000,
      hide: 100
    },
    triggers: 'hover'
  }
})
Vue.use(VueParticles)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})

Vue.component('multiselect', Multiselect)
Vue.component('datepicker', Datepicker)

validationRules(VeeValidate.Validator)

Vue.prototype.$formatMinutes = (minutes) => {
  const days = String(Math.floor(moment.duration({minutes}).asDays()))
  const hours = String(moment.duration({minutes}).hours())
  const min = String(moment.duration({minutes}).minutes())
  const pad = (s) => s.length === 1 ? '0' + s : s

  return `${pad(days)}:${pad(hours)}:${pad(min)}`
}

Vue.prototype.$company = {}
Vue.use(fafui, {
  App,
  router,
  role: localStorage.user ? JSON.parse(localStorage.user).userGroups : ['PUBLIC'],
  sideMenuLinks: sidebarRoutes,
  topNavigationLinks: [
  ]
});

Vue.config.productionTip = false
