<template>
  <b-card :style="cardHeight" :class="{ 'card-accent': accent, collapsed }" style="postition: relative">
    <div :class="{ sticky: scrollable }" class="px-1 card-title">
      <b-row>
        <b-col v-if="$slots['title']" cols="auto" class="flex-grow-1">
          <slot name="title" />
        </b-col>
        <b-col v-if="!$slots['title'] && title" cols="auto">
          <h5 v-if="small" class="mb-2">{{ title }}</h5>
          <h4 v-else>{{ title }}</h4>
        </b-col>
        <b-col v-if="!$slots['title']" class="align-middle">
          <div v-if="tags && tags.length">
            <faf-tag v-for="(tag, index) in tags" :key="index" :text="tag"/>
          </div>
        </b-col>
        <b-col cols="auto" class="text-right">
          <slot :data="$data" name="buttons"></slot>
          <b-button v-if="collapseButtonVisible" class="icon-button" style="margin:0; padding: 5px" @click="collapse">
            <font-awesome-icon :class="{ turn: collapsed }" icon="chevron-circle-down" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="loading" class="spinner-container">
      <b-spinner label="Loading..." variant="primary" style="margin: auto;" />
    </div>
    <transition v-else name="fadeHeight">
      <div v-if="!collapsed">
        <slot />
      </div>
    </transition>
  </b-card>
</template>
<script>
  export default {
    name: 'faf-card',
    props: {
      name: String,
      title: String,
      accent: Boolean,
      small: Boolean,
      initialLoading: Boolean,
      initialCollapsed: Boolean,
      scrollable: Boolean,
      collapseButtonVisible: {
        type: Boolean,
        default: true
      },
      tags: Array,
      minLoadingHeight: {
        type: [Number, String],
        default: 200
      },
      maxHeight: {
        type: [Number, String],
        default: 'full'
      }
    },
    data () {
      return {
        loading: this.initialLoading,
        heights: {
          full: 'calc(100vh - 110px)',
          half: 'calc(50vh - 70px)'
        },
        collapsed: this.initialCollapsed
      }
    },
    computed: {
      cardHeight () {
        const heights = {}
        if (this.scrollable) {
          heights['overflow-y'] = 'scroll'
          if (typeof this.maxHeight === 'string') {
            heights.maxHeight = this.heights[this.maxHeight]
          } else {
            heights.maxHeight = this.maxHeight + 'px'
          }
        }
        if (this.loading) {
          if (typeof this.minLoadingHeight === 'string') {
            heights.minHeight = this.heights[this.minLoadingHeight]
          } else if (this.minLoadingHeight) {
            heights.minHeight = this.minLoadingHeight + 'px'
          }
        }
        return heights
      }
    },
    created () {
      this.setLoading = this.$_debounce(this.setLoading)
      if (this.name) {
        this.$root.$on(this.name + '-loading', val => {
          this.setLoading(val)
        })
        this.$root.$on(this.name + '-collapsed', val => {
          this.setCollapsed()
        })
      }
    },
    mounted () {
      if (this.name) {
        this.$root.$on(this.name + '-reset', val => {
          this.resetComponent()
        })
      }
    },
    beforeDestroy () {
      if (this.name) {
        this.$root.$off(this.name + '-loading')
        this.$root.$off(this.name + '-reset')
        this.$root.$off(this.name + '-collapsed')
      }
    },
    methods: {
      collapse () {
        this.collapsed = !this.collapsed
        this.$emit('collapse', this.collapsed)
      },
      setLoading (val) {
        this.loading = val
      },
      resetComponent () {
        this.loading = true
        this.$emit('cardReset')
        setTimeout(() => { this.loading = false }, 20);
      },
      setCollapsed (val) {
        this.collapsed = val
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import './../../assets/sass/black-dashboard/custom/variables';

  .white-content ~ div .card:not(.card-white) .card.card-accent {
    background: darken($card-white-background, 2%);

    .card-title {
      background: darken($card-white-background, 2%);
    }
  }

  :not(.white-content) .card-accent {
    background: darken($card-black-background, 2%);

    .card-title {
      background: darken($card-black-background, 2%);
    }
  }

  :not(.white-content) .modal .card,
  .modal ~ div .card {
    background: darken($white, 2%);

    .card-title {
      background: darken($white, 2%);
    }
  }

  :not(.white-content) .modal .card-accent,
  .modal ~ div .card-accent {
    background: darken($white, 6%);
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);

    .card-title {
      background: darken($white, 6%);
    }
  }

  .modal .card-title,
  .modal .card-title h4,
  .modal .card-title h5 {
    color: #1d253b;
  }

  .card-title {
    top: 0px;
    background: $card-black-background;
    padding-top: 15px;
    margin-top: -15px;
    z-index: 1;

    // -webkit-box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
    // -moz-box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
    // box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);

    &.sticky {
      position: sticky;
    }
  }

  .spinner-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    top: 54px;
    bottom: 0;
  }

  .white-content .card-title,
  .modal .card-title {
    background: $card-bg;
  }

  .tim-icons {
    cursor: pointer;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .turn {
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
</style>
<style lang="scss">
  .collapsed.card .card-body {
    padding-bottom: 0px;
  }

  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 100vh;
  }
  .fadeHeight-enter,
  .fadeHeight-leave-to {
    opacity: 0;
    max-height: 0px;
  }
</style>
