<template>
  <b-modal
    :title="title"
    size="xxl"
    content-class="faf-full-modal-height"
    visible
    scrollable
    title-tag="h4"
    @hide.prevent="hide"
  >
    <form v-if="formData">
      <b-row class="row-eq-height display-flex px-3 pb-3">
        <b-col v-if="$can(['orderFields', 'statusOffer']) && !isChildOrder" md="3" class="px-1 pb-2">
          <label for="status">Staatus</label>
          <b-select
            v-model="formData.status"
            v-validate="'required'"
            v-bind="$requiredInputProps('status')"
            placeholder="Staatus"
          >
            <option v-for="(option, optionIndex) in statusOptions" :key="'option-' + optionIndex" :value="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-col>
        <b-col v-if="$can(['orderFields', 'orderIdMultiselect'])" md="3" class="px-1 pb-2">
          <label for="add-order-id">Projekti nr</label>
          <multiselect
            v-model="formData.orderId"
            v-validate="'required'"
            v-faf-autocomplete-off
            :options="orderIdSuggestions"
            v-bind="$multiselectProps('orderId')"
            name="orderId"
            @tag="addOrderId"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
          <b-form-invalid-feedback v-if="!$validateField('add-order-id')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
        <b-col v-else-if="$can(['orderFields', 'orderId'])" md="3" class="px-1 pb-2">
          <label for="add-order-id">Projekti nr</label>
          <input
            v-model.trim="formData.orderId"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-order-id') }"
            :type="!$can(['orderFields', 'salesSystemNumber']) && 'number'"
            name="add-order-id"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-order-id')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
        <b-col v-if="$can(['orderFields', 'projectName'])" md="3" class="px-1 pb-2">
          <label for="add-project-name">Projekti nimi</label>
          <input
            v-model.trim="formData.projectName"
            :class="{ 'form-control': true }"
            name="add-project-name"
          />
        </b-col>

        <b-col :class="{ 'field-with-tooltip': newClient }" md="3" class="px-1 pb-2">
          <label for="add-client">Klient</label>
          <multiselect
            v-model="formData.client"
            v-validate="'required'"
            v-faf-autocomplete-off
            :options="clientSuggestions"
            v-bind="$multiselectProps('add-client')"
            name="add-client"
            placeholder="Vali klient"
            @tag="addNewClient"
            @input="newClient = false"
            @search-change="searchChangeClient"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
          <b-button
            v-if="newClient && $can(['action', 'FilterClients'])"
            v-b-tooltip.hover
            title="Lisa uus klient"
            class="icon-tooltip icon-button text-success"
            style="cursor: pointer"
            @click="$refs['add-edit-client'].formData = $_cloneDeep({ ...clientFormData, name: formData.client })"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
          <div v-if="!$validateField('add-client')" class="invalid-feedback">
            Väli on nõutud
          </div>
        </b-col>

        <b-col v-if="!isChildOrder" md="3" class="px-1 pb-2">
          <label for="add-delivery-date">Tarneaeg</label>
          <datepicker
            v-model="formData.deliveryDate"
            v-validate="'required'"
            :disabled-dates="{to: new Date(disableToDate())}"
            v-bind="$datepickerProps('add-delivery-date')"
            name="add-delivery-date"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-delivery-date')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>

        <b-col v-if="$can(['orderFields', 'catalogue'])" md="3" class="px-1 pb-2">
          <label for="add-catalogue">Kataloog</label>
          <input
            v-model.trim="formData.catalogue"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': !$validateField('add-catalogue') }"
            name="add-catalogue"
          />
          <b-form-invalid-feedback v-if="!$validateField('add-catalogue')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>

        <b-col v-if="$can(['orderFields', 'object'])" md="3" class="px-1 pb-2">
          <label for="add-object">Objekt</label>
          <input
            v-model.trim="formData.object"
            class="form-control"
            name="add-object"
          />
        </b-col>
        <b-col md="6" class="px-1 pb-2">
          <label for="notes">Märkmed</label>
          <input
            v-model.trim="formData.notes"
            :class="{ 'form-control': true }"
            name="notes"
          />
        </b-col>
        <b-col align-self="end" md="3" class="px-1 pb-1">
          <b-button
            :disabled="$loader.val('add-new-project') || noChanges"
            variant="success"
            size="sm"
            style="height: 30px;"
            @click="saveProject"
          >
            Salvesta projekt
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <faf-products
            v-if="formData.products"
            ref="product"
            :key="'products-' + productsKey"
            :selected-product-id="selectedProductId"
            :show-overlay="showOverlay"
            :order-id="formData._id"
            :products="formData.products"
            :edit-disabled="false"
            :open-products="openProducts"
            :is-child-order="isChildOrder"
          />
        </b-col>
      </b-row>
    </form>
    <add-edit-client
      ref="add-edit-client"
      :key="addClientModal"
      @hide="addClientModal = !addClientModal"
      @client-added="newClient = false"
    />
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="hide">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>

        <span class="divider" />

        <ajan-import v-can="['see', 'AjanImport']" :disabled="showOverlay || $loader.val()" :get-proof="false" @importData="importData" />

        <clone-order-modal
          v-if="!isChildOrder"
          :id="formData._id"
          :client="formData.client"
          :object="formData.object || ''"
          :catalogue="formData.catalogue || ''"
          :project-name="formData.projectName || ''"
          :order-id="formData.orderId || ''"
          :disabled="actionsDisabled"
        />

        <b-button v-b-tooltip.hover :disabled="actionsDisabled" class="icon-button text-primary" title="Salvesta fail" @click="init(); pdfDownloadVisible = !pdfDownloadVisible">
          <font-awesome-icon icon="file-download" />
        </b-button>
        <b-button
          v-can="['see', 'FileManager']"
          v-b-tooltip.hover
          v-b-modal.file-manager
          :disabled="actionsDisabled"
          class="icon-button text-info"
          title="Dokumendid"
        >
          <font-awesome-icon icon="folder-open" />
        </b-button>

        <span class="divider" />

        <b-button v-b-tooltip.hover :disabled="!formData._id || $loader.val()" class="icon-button text-danger" title="Tühista projekt" @click="annullOrder">
          <font-awesome-icon icon="times" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="!completeAllowed || $loader.val()" class="icon-button text-success" title="Märgi tellimus tehtuks" @click="completeOrder">
          <font-awesome-icon icon="check-circle" />
        </b-button>

        <faf-get-pdf
          v-if="pdfDownloadVisible"
          :visible="pdfDownloadVisible"
          :order="formData"
          :client="formData.client"
          type="SALES"
          @close="pdfDownloadVisible = false"
        />
        <faf-file-manager v-if="formData" :key="formData.documents.length" v-can="['see', 'FileManager']" :order-object-id="formData._id" :pre-loaded-documents="formData.documents || []" />
        <b-button
          v-b-tooltip.hover
          v-b-tooltip.left
          style="font-size: 20px; padding: 0 0 0 10px;"
          class="icon-button text-info"
          title="Detailne vaade"
          @click="detailedOrderId = formData._id"
        >
          <font-awesome-icon icon="eye" />
        </b-button>
        <faf-detailed-sale v-if="!!detailedOrderId" :id="detailedOrderId" @close="detailedOrderId = null;" />
      </div>
    </div>
  </b-modal>
</template>
<script>
  import FafProducts from './common/Products'
  import AddEditClient from './../ClientManagement/AddEditClient'
  import AjanImport from './../../components/AjanImport/AjanImport'
  import { emptyProduct } from './common/productHelpers'
  import FafDetailedSale from './../DetailedViews/DetailedSale'
  import CloneOrderModal from './../Orders/CloneOrderModal'
  import { _getTransportPrice } from './../../Calculations/index'

  export default {
    name: 'faf-add-edit-sale',
    inject: ['$validator'],
    components: {
      FafProducts,
      AddEditClient,
      AjanImport,
      FafDetailedSale,
      CloneOrderModal
    },
    props: {
      editId: String,
      parentOrder: Object,
      selectedProductId: String
    },
    data () {
      return {
        viewportHeight: window.innerHeight,
        detailedOrderId: null,
        initFormData: {
          client: null,
          discount: 0,
          remainsPrice: 0.2,
          status: this.$can(['orderFields', 'statusOffer']) ? 'OFFER' : 'ORDER',
          subStatus: [],
          documents: []
        },
        formData: {
          client: null,
          discount: 0,
          remainsPrice: 0.2,
          status: this.$can(['orderFields', 'statusOffer']) ? 'OFFER' : 'ORDER',
          subStatus: [],
          documents: [],
          products: [ this.$_cloneDeep({ ...emptyProduct() }) ]
        },
        statusOptions: [
          { label: 'Tellimus', value: 'ORDER'},
          { label: 'Hinnapakkumine', value: 'OFFER' }
        ],
        pdfDownloadVisible: false,
        // Client
        clientFormData: {
          name: '',
          address: '',
          contacts: [{
            name: '',
            email: '',
            phone: ''
          }]
        },
        clientSuggestions: [],
        orderIdSuggestions: [],
        newClient: false,
        addClientModal: false,
        duplicate: false,

        // Products
        openProducts: [ 'product-0' ],
        productsKey: 0,
        productsChanged: false
      }
    },
    computed: {
      isChildOrder () {
        return !!this.parentOrder || (this.formData.subStatus && this.formData.subStatus.includes('PRODUCTION_ORDER'))
      },
      noChanges () {
        this.initFormData.products && delete this.initFormData.products
        const currentFormData = this.$_cloneDeep({ ...this.formData })
        delete currentFormData.products
        return this.$_isEqual(currentFormData, this.initFormData)
      },
      showOverlay () {
        return !this.formData._id
      },
      title () {
        if (this.formData && !this.duplicate && (this.formData.salesSystemNumber || this.formData.orderId)) {
          return this.$can(['orderFields', 'salesSystemNumber'])
            ? `Projekt ${ this.formData.salesSystemNumber || this.formData.orderId }`
            : `Projekt ${ this.formData.orderId }`
        }
        return 'Lisa uus projekt'
      },
      completeAllowed () {
        if (this.formData && this.formData.products) {
          return this.formData.status === 'ORDER' && !this.formData.products.map(p => p.status).every(v => v !== 'COMPLETE')
        }
        return false
      },
      actionsDisabled () {
        return this.$loader.val() || !this.formData._id || !(this.formData.products.length > 0 && this.formData.products[0]._id)
      }
    },
    async created () {
      this.$loader.start('add-new-project')
      this.whenChildOrder()
      this.orderIdSuggestions = (await this.$typeahead.getSuggestions('orderId')).data.filter(n => n)
      this.searchChangeClient = this.$_debounce(this.searchChangeClient, 300)

      if (this.editId) {
        await this.init()
      } else {
        this.$loader.stop('add-new-project')
      }
    },
    methods: {
      whenChildOrder () {
        if (this.parentOrder) {
          const deliveryDate = new Date(this.parentOrder.deliveryDate)
          this.formData.client = this.parentOrder.client
          this.formData.catalogue = this.$_get(this.parentOrder, 'catalogue', '')
          this.formData.object = this.$_get(this.parentOrder, 'object', '')
          this.formData.orderId = this.$_get(this.parentOrder, 'orderId', '')
          this.formData.projectName = this.$_get(this.parentOrder, 'projectName', '')
          this.formData.deliveryDate = deliveryDate.setDate(deliveryDate.getDate() - 1)
          this.formData.status = 'ORDER'
          this.formData.subStatus = ['PRODUCTION_ORDER']
        }
      },
      // Initialize
      disableToDate () {
        const d = new Date()
        return d.setDate(d.getDate() - 1)
      },
      async init (id = null) {
        await this.$orders.getOrderById(id || this.editId || this.formData._id).then(async rs => {
          this.formData = {
            ...rs.data,
            transport: {
              price: await _getTransportPrice(rs.data, JSON.parse(localStorage.orderSettings)),
              address: this.$_get(rs.data, 'transport.address', null) || this.$_get((await this.$clients.getClient({ name: rs.data.client })), 'data.address', null)
            }
          }
          if (!this.formData.products || this.formData.products.length < 1) {
            this.formData.products = [ this.$_cloneDeep({ ...emptyProduct() }) ]
          }

          this.initFormData = this.$_cloneDeep({ ...this.formData })
          this.productsKey++
          this.$loader.stop('add-new-project')
        }).catch(err => {
          this.$loader.stop('add-new-project')
          this.$err.show(err)
        })
      },

      // Client
      async searchChangeClient (e) {
        if (e.length > 1) {
          this.clientSuggestions = (await this.$suggestions.getClientNames(e)).data
        }
      },
      addNewClient (value) {
        this.clientSuggestions.push(value)
        this.formData.client = value
        this.newClient = true
      },

      // OrderId

      addOrderId (value) {
        this.orderIdSuggestions.push(value)
        this.formData.orderId = value
      },

      // Import
      async importData (event) {
        this.$loader.start('import')
        if (this.isChildOrder) {
          event = event.map(product => {
            product.useNetPrice = false
            product.price = false
            return product
          })
        }

        await this.$orders.addProduct(this.formData._id, event).catch(err => this.$err.show(err))

        this.init()
        this.$loader.stop('import')
        this.$notify.show('Import tehtud!')
      },

      // Project stuff
      saveProject () {
        this.$loader.start('add-new-project')
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            this.$loader.stop('add-new-project')
            return
          }

          const data = this.$_cloneDeep({
            orderId: this.formData.orderId,
            projectName: this.formData.projectName,
            status: this.formData.status,
            client: this.formData.client,
            deliveryDate: this.formData.deliveryDate,
            ...this.duplicate && {documents: this.formData.documents},
            catalogue: this.formData.catalogue,
            object: this.formData.object,
            notes: this.formData.notes,
            ...this.parentOrder && {subStatus: this.formData.subStatus},
            ...this.parentOrder && {linkedOrders: {parentOrder: this.parentOrder._id}}
          })

          if (this.isChildOrder) {
            data.transport = { ...data.transport, price: 0 }
          }

          if (this.formData._id) {
            // Edit project
            this.$orders.updateOrder(this.formData._id, data).then(rs => {
              this.finishSaveProject(rs)
            }).catch(err => {
              this.$loader.stop('add-new-project')
              this.$err.show(err)
            })
          } else {
            // Add Project
            this.$orders.postOrder(data).then(rs => {
              this.formData = {
                ...rs.data,
                ...this.formData
              }
              this.$notify.show(`Projekt ${rs.data.salesSystemNumber || rs.data.orderId} loodud!`)
              if (this.duplicate) {
                // Save each product separately for duplicate
                this.saveAllProducts(this.formData.products).then(notSaved => {
                  if (notSaved.length > 0) {
                    const message = notSaved.length === 1
                      ? 'Toodet ' + notSaved[0].product.name + ' ei saanud salvestada. Palun proovi uuesti.'
                      : 'Tooteid ' + notSaved.map(e => e.product.name).join(', ') + ' ei saanud salvestada. Palun proovi uuesti.'
                    this.$err.show(message)
                    this.duplicate = false
                    this.$loader.stop('add-new-project')
                  } else {
                    this.duplicate = false
                    this.formData = { ...rs.data }
                    this.init(rs.data._id)
                    this.$loader.stop('add-new-project')
                  }
                })
              } else {
                this.finishSaveProject(rs)
              }
            }).catch(err => {
              this.$loader.stop('add-new-project')
              this.$err.show(err)
            })
          }
        })
      },
      async finishSaveProject (rs) {
        this.duplicate = false
        this.formData = {
          ...rs.data,
          products: rs.data.products.length ? rs.data.products : [ this.$_cloneDeep({ ...emptyProduct() }) ],
          transport: {
            price: await _getTransportPrice(rs.data, JSON.parse(localStorage.orderSettings)),
            address: this.$_get(rs.data, 'transport.address', null) || this.$_get((await this.$clients.getClient({ name: rs.data.client })), 'data.address', null)
          }
        }
        this.initFormData = this.$_cloneDeep({ ...this.formData })
        this.productsKey++
        this.$notify.show(`Projekt ${rs.data.salesSystemNumber || rs.data.orderId} uuendatud!`)
        this.$loader.stop('add-new-project')

      },
      annullOrder () {
        this.$confirm.show(
          'Kontrolli ka materjali koguseid ja vajadusel võta lattu arvele.',
          'Oled kindel, et soovid projekti tühistada?',
          answer => {
            if (answer) {
              this.$loader.start('annull-order')
              this.$orders.updateOrder(this.formData._id, { status: 'ANNULLED' }).then(() => {
                this.$loader.stop('annull-order')
                this.$notify.show('Projekt edukalt tühistatud')
                this.$emit('hide')
              }).catch(err => {
                this.$loader.stop('annull-order')
                this.$err.show(err)
              })
            }
          }
        )
      },
      completeOrder () {
        this.$loader.start('complete-order')
        this.$orders.updateOrder(this.formData._id, { status: 'COMPLETE' }).then(() => {
          this.$loader.stop('complete-order')
          this.$notify.show('Projekt edukalt täidetud')
          this.$emit('hide')
        }).catch(err => {
          this.$loader.stop('complete-order')
          this.$err.show(err)
        })
      },
      hide () {
        if (this.$refs.product.dataIsEdited || this.productsChanged.length > 0 || !this.noChanges) {
          this.$confirm.show('Oled kindel, et soovid lahkuda?', 'Sul on salvestamata muudatusi.', answer => {
            if (answer) {
              this.$emit('hide')
            }
          })
        } else {
          this.$emit('hide')
        }
      },

      // Products
      async saveAllProducts (products) {

        const result = []

        for await (const p of products) {
          const currentProduct = this.$_cloneDeep({ ...p })
          if (this.$can(['orderFields', 'linkedMaterials'])) {
            if (currentProduct.workcenters && currentProduct.workcenters.length > 0) {
              currentProduct.workcenters.map(w => {
                w.linkedMaterials = (w.linkedMaterials && w.linkedMaterials.length > 0) ? w.linkedMaterials.map(m => m.value || m) : []
              })
            }
          }
          const newProduct = !currentProduct._id
          const type = newProduct ? 'addProduct' : 'updateProduct'
          await this.$orders[type](currentProduct._id || this.formData._id, currentProduct).then(rs => {
            this.$notify.show(rs.data.name + ' on edukalt ' + (newProduct ? 'lisatud.' : 'uuendatud.'))
            result.push({ updated: true, product: currentProduct })
          }).catch(err => {
            result.push({ updated: false, product: currentProduct })
          })
        }
        return result.map(p => !p.updated && p).filter(Boolean)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .max-60 {
    max-width: 60px;
  }

  .h-30 {
    max-height: 30px;
  }

  .tim-icons {
    cursor: pointer;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .turn-down {
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  .bottom-bar {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    // width: calc(100vw - 260px);
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .row.display-flex > [class*='col-'] > .card {
    height: 100%;
  }

  label {
    margin-bottom: 0;
  }

  .divider {
    width: 1px;
    height: 39px;
    margin: 0 2px;
    background: #6c757d;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      #6c757d 20%,
      #6c757d 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
</style>
