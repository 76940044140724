<template>
  <div class="row pl-3 reports">
    <faf-card name="key-indicators" title="Raportid">
      <template slot="buttons">
        <b-button v-b-tooltip.hover title="Otsi" class="icon-button text-danger" @click="clearFilters">
          <font-awesome-icon icon="times-circle" />
        </b-button>
        <b-button v-b-tooltip.hover title="Otsi" class="icon-button text-success" @click="generateReport">
          <font-awesome-icon icon="search" />
        </b-button>
        <b-button v-b-tooltip.hover title="Excel" class="icon-button text-success" @click="generateExcelReport">
          <font-awesome-icon icon="file-excel" />
        </b-button>
      </template>
      <!-- FIRST ROW FILTERS -->
      <b-row>
        <b-col>
          <label>Tüüp</label>
          <select v-model="filters.type" v-validate="'required'" v-bind="$requiredInputProps('type')">
            <option v-for="(option, optionIndex) in typeOptions" :key="'type-option-' + optionIndex" :value="option.value">
              <span>{{ option.label }}</span>
            </option>
          </select>
        </b-col>
        <b-col>
          <label>Staatus</label>
          <multiselect
            v-model="filters.status"
            v-faf-autocomplete-off
            :options="statusOptions"
            :taggable="false"
            label="label"
            track-by="label"
            multiple
            v-bind="$multiselectProps('status')"
            name="status"
          />
        </b-col>
        <b-col>
          <label for="start-date">Alates kuupäev</label>
          <datepicker
            v-model="filters.startDate"
            v-validate="{ required: filters.type === 'OPERATOR' }"
            v-bind="$datepickerProps('start-date')"
            name="start-date"
          />
          <b-form-invalid-feedback v-if="!$validateField('start-date')">
            Väli on nõutud
          </b-form-invalid-feedback>
        </b-col>
        <b-col>
          <label for="end-date">Kuni kuupäev</label>
          <datepicker
            v-model="filters.endDate"
            v-bind="$datepickerProps('end-date')"
            name="end-date"
          />
        </b-col>
      </b-row>

      <!-- SECOND ROW FILTERS -->
      <b-row class="mt-3">
        <b-col>
          <label>ID</label>
          <multiselect
            v-model="filters.salesSystemNumber"
            v-faf-autocomplete-off
            :options="salesSystemNumberSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('salesSystemNumber')"
            name="salesSystemNumber"
            @search-change="getSuggestions($event, 'salesSystemNumber')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col v-if="companyPlasmaPro">
          <label>Kataloog</label>
          <multiselect
            v-model="filters.catalogue"
            v-faf-autocomplete-off
            :options="catalogueSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('catalogue')"
            name="catalogue"
            @search-change="getSuggestions($event, 'catalogue')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col v-else>
          <label>Projekti nr</label>
          <multiselect
            v-model="filters.orderId"
            v-faf-autocomplete-off
            :options="orderIdSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('orderId')"
            name="orderId"
            @search-change="getSuggestions($event, 'orderId')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col v-if="companyPlasmaPro">
          <label>Objekt</label>
          <multiselect
            v-model="filters.object"
            v-faf-autocomplete-off
            :options="objectSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('object')"
            name="object"
            @search-change="getSuggestions($event, 'object')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col v-else>
          <label>Projekti nimi</label>
          <multiselect
            v-model="filters.projectName"
            v-faf-autocomplete-off
            :options="projectNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('projectName')"
            name="projectName"
            @search-change="getSuggestions($event, 'projectName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col v-if="['ORDER', 'PRODUCT', 'MATERIAL', 'WORKCENTER'].includes(filters.type)">
          <label>Klient</label>
          <multiselect
            v-model="filters.client"
            v-faf-autocomplete-off
            :options="clientSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('client')"
            name="client"
            @search-change="getSuggestions($event, 'client')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <!-- Conditional -->
        <b-col v-if="['PRODUCT', 'MATERIAL', 'WORKCENTER', 'OPERATOR'].includes(filters.type)">
          <label>Toote nimetus</label>
          <multiselect
            v-model="filters.productName"
            v-faf-autocomplete-off
            :options="productNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('productName')"
            name="productName"
            @search-change="getSuggestions($event, 'products.name', 'productName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>

        <b-col v-if="filters.type === 'MATERIAL'">
          <label>Materjali nimetus</label>
          <multiselect
            v-model="filters.materialName"
            v-faf-autocomplete-off
            :options="materialNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('materialName')"
            name="materialName"
            @search-change="getSuggestions($event, 'products.materials.name', 'materialName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>

        <b-col v-if="['WORKCENTER', 'OPERATOR'].includes(filters.type)">
          <label>Töökeskuse nimetus</label>
          <multiselect
            v-model="filters.workcenterName"
            v-faf-autocomplete-off
            :options="workcenterNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('workcenterName')"
            name="workcenterName"
            @search-change="getSuggestions($event, 'products.workcenters.name', 'workcenterName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>

        <b-col v-if="['OPERATOR'].includes(filters.type)">
          <label>Tiim</label>
          <multiselect
            v-model="filters.teamName"
            v-faf-autocomplete-off
            :options="teamNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('teamName')"
            name="teamName"
            @search-change="getSuggestions($event, 'jobs.teamName', 'teamName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>

        <b-col v-if="filters.type === 'OPERATOR'">
          <label>Operaator</label>
          <multiselect
            v-model="filters.operatorName"
            v-faf-autocomplete-off
            :options="operatorNameSuggestions"
            :taggable="false"
            v-bind="$multiselectProps('operatorName')"
            name="operatorName"
            @search-change="getSuggestions($event, 'operatorName')"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <order-report-table v-if="startGenerating && filters.type === 'ORDER'" :filters="filters" :company-plasma-pro="companyPlasmaPro" />
          <product-report-table v-if="startGenerating && filters.type === 'PRODUCT'" :filters="filters" :company-plasma-pro="companyPlasmaPro" />
          <material-report-table v-if="startGenerating && filters.type === 'MATERIAL'" :filters="filters" :company-plasma-pro="companyPlasmaPro" />
          <workcenter-report-table v-if="startGenerating && filters.type === 'WORKCENTER'" :filters="filters" :company-plasma-pro="companyPlasmaPro" />
          <operator-report-table v-if="startGenerating && filters.type === 'OPERATOR'" :filters="filters" :company-plasma-pro="companyPlasmaPro" />
          <b-skeleton-table
            v-if="!startGenerating"
            :rows="25"
            :columns="10"
            :table-props="{ striped: true }"
            animation
          />
        </b-col>
      </b-row>
    </faf-card>
  </div>
</template>
<script>
  import OrderReportTable from './Tables/OrderReportTable.vue'
  import ProductReportTable from './Tables/ProductReportTable.vue'
  import MaterialReportTable from './Tables/MaterialReportTable.vue'
  import WorkcenterReportTable from './Tables/WorkcenterReportTable.vue'
  import OperatorReportTable from './Tables/OperatorReportTable.vue'
  import { downloadBinaryFile } from '../../components/GetPdf/downloadFile'

  export default {
    name: 'reports',
    components: {
      OrderReportTable,
      ProductReportTable,
      MaterialReportTable,
      WorkcenterReportTable,
      OperatorReportTable
    },
    data () {
      return {
        filters: {
          // First row fields
          type: 'ORDER',
          status: [
            { value: 'COMPLETE', label: 'Tehtud' },
            { value: 'ORDER', label: 'Tellimus' },
            { value: 'OFFER', label: 'Hinnapakkumine' }
          ],
          startDate: null,
          endDate: null,
          // Second row fields
          salesSystemNumber: null,
          orderId: null,
          projectName: null,
          catalogue: null,
          object: null,
          client: null,
          // Product only
          productName: null,
          materialName: null,
          workcenterName: null,
          operatorName: null,
          teamName: null
        },
        statusOptions: [
          { value: 'COMPLETE', label: 'Tehtud' },
          { value: 'ORDER', label: 'Tellimus' },
          { value: 'OFFER', label: 'Hinnapakkumine' },
          { value: 'ANNULLED', label: 'Tühistatud' }
        ],

        // Suggestions
        typeOptions: [
          { value: 'ORDER', label: 'Tellimus' },
          { value: 'PRODUCT', label: 'Toode' },
          { value: 'MATERIAL', label: 'Materjalid' },
          { value: 'WORKCENTER', label: 'Töökeskused' },
          { value: 'OPERATOR', label: 'Operaatorid' }
        ],
        salesSystemNumberSuggestions: [],
        orderIdSuggestions: [],
        projectNameSuggestions: [],
        clientSuggestions: [],
        productNameSuggestions: [],
        materialNameSuggestions: [],
        workcenterNameSuggestions: [],
        operatorNameSuggestions: [],
        teamNameSuggestions: [],
        catalogueSuggestions: [],
        objectSuggestions: [],
        filterVisibleOperator: null,
        filterVisibleOperatorOptions: [
          { value: 'PRODUCT', text: 'Toode' },
          { value: 'WORKCENTER', text: 'Töökeskus' },
          { value: 'TEAM', text: 'Tiim' }
        ],

        // Button
        buttonDisabled: false,

        // Other
        startGenerating: false
      }
    },
    computed: {
      companyPlasmaPro () {
        return localStorage.company === 'PLASMAPRO'
      }
    },
    watch: {
      filters: {
        deep: true,
        handler () {
          this.startGenerating = false
        }
      }
    },
    async created () {
      this.getSuggestions = this.$_debounce(this.getSuggestions, 500)
    },
    methods: {
      async getSuggestions (e, field, suggestionName) {

        if (['operatorName', 'jobs.teamName'].includes(field)) {
          const option = (suggestionName || field) + 'Suggestions'
          this[option] = (await this.$work.getSuggestions(field, e)).data.filter(n => n)
          console.log('jup', this[option])
        } else {
          const option = (suggestionName || field) + 'Suggestions'
          this[option] = (await this.$typeahead.getArchiveSuggestions(field, e)).data.filter(n => n)
        }

      },
      generateReport () {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return
          }
          if (this.startGenerating) {
            this.startGenerating = false
            setTimeout(() => { this.startGenerating = true }, 300);
          } else {
            this.startGenerating = true
          }
        })
      },
      async generateExcelReport () {
        const filters = {
          ...this.filters.status && { status: this.filters.status },
          ...this.filters.startDate && { startDate: this.filters.startDate },
          ...this.filters.endDate && { endDate: this.filters.endDate },
          ...this.filters.salesSystemNumber && { salesSystemNumber: this.filters.salesSystemNumber },
          ...this.filters.orderId && { orderId: this.filters.orderId },
          ...this.filters.projectName && { projectName: this.filters.projectName },
          ...this.filters.catalogue && { catalogue: this.filters.catalogue },
          ...this.filters.object && { object: this.filters.object },
          ...this.filters.client && { client: this.filters.client },
          ...this.filters.productName && { 'products.name': this.filters.productName },
          ...this.filters.operatorName && { operatorName: this.filters.operatorName },
          ...this.filters.workcenterName && { workcenterName: this.filters.workcenterName },
          ...this.filters.teamName && { teamName: this.filters.teamName },
        }

        this.$loader.start('generateReport')
        this.$reports.getExcelReport(this.filters.type, filters).then(rs => {
          const headers = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          downloadBinaryFile(rs.data, headers, `${this.filters.type}.xlsx`)
          this.$loader.stop('generateReport')
        }).catch(err => {
          this.$loader.stop('generateReport')
          this.$err.show('Serveri error')
        })

      },
      clearFilters () {
        // TODO if type changes
        this.filters = {
          // First row fields
          ...this.filters,
          status: null,
          startDate: null,
          endDate: null,
          // Second row fields
          salesSystemNumber: null,
          orderId: null,
          projectName: null,
          client: null,
          catalogue: null,
          object: null,
          // Product only
          productName: null,
          materialName: null,
          workcenterName: null,
          operatorName: null,
          teamName: null
        }
      }
    }
  }
</script>
<style lang="scss">
.table-sticky-dark .table.b-table > thead > tr > .table-b-table-default,
.table-sticky-dark .table.b-table > tbody > tr > .table-b-table-default,
.table-sticky-dark .table.b-table > tfoot > tr > .table-b-table-default {
  color: #fff;
  background-color: #27293d!important;
  // background-color: #212529!important;
}

.reports-table {
  min-height: 600px;
}
</style>
