<template>
  <div :class="{ 'with-bottom-bar': addNewSale }">
    <transition name="fadeHeight">
      <faf-add-edit-sale v-if="addNewSale" v-can="['add', 'Müük']" @hide="addNewSale = false" />
    </transition>

    <faf-card v-can="['see', 'Müük']" title="Müügi tabel" name="sales-table">
      <template slot="buttons">
        <b-button v-if="showDownloadExcel" v-b-tooltip.hover class="icon-button text-success" title="Lae dokument alla" @click="downloadDocument">
          <font-awesome-icon icon="file-excel" />
        </b-button>
        <b-button
          id="add-new-sale"
          v-b-modal.modal-1
          v-can="['add', 'Müük']"
          v-b-tooltip.hover
          class="icon-button text-success"
          title="Lisa uus projekt"
          @click="addNewSale = true"
        >
          <font-awesome-icon icon="plus-circle" />
        </b-button>
      </template>

      <div>
        <div class="dataTables_wrapper">
          <faf-filters :fields="filterFields" :url-cache="true" no-options-label="Laen..." @getOptions="getAllSuggestions" @filter="filterTable({query: $event, page: 1})" />
          <div class="table-responsive sales-table">
            <b-table
              :per-page="perPage"
              :sort-compare="$sortDate"
              :items="items"
              :fields="fields"
              :busy="$loader.val('sales-table')"
              :tbody-tr-class="rowClass"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              stacked="xl"
              show-empty
              hover
              empty-text="Andmed puuduvad"
              @row-contextmenu="$contextMenu.item = $event"
            >
              <template v-slot:table-busy>
                <div class="text-center text-primary my-3">
                  <b-spinner class="align-middle" />
                </div>
              </template>
              <template v-slot:cell(orderId)="data">
                <b>{{ data.item.orderId }}</b>
              </template>
              <template v-slot:cell(createdDate)="data">
                <faf-tag :text="$dayjs(data.item.createdDate).format('DD.MM.YYYY')" />
              </template>
              <template v-slot:cell(deliveryDate)="data">
                <faf-tag :text="$dayjs(data.item.deliveryDate).format('DD.MM.YYYY')" :color="$getDateColor(data.item.deliveryDate)" />
              </template>
              <template v-slot:cell(product.status)="data">
                <faf-tag v-if="$_get(data, 'item.product.status') === 'COMPLETE'" :text="getStatus($_get(data, 'item.product.status'))" color="success" />
                <span v-else>{{ getStatus($_get(data, 'item.product.status')) || 'Tootmises' }}</span>
              </template>
              <template v-slot:cell(actions)="data">
                <b-button
                  v-b-tooltip.hover
                  v-b-tooltip.left
                  class="icon-button text-info"
                  title="Muuda projekti"
                  style="font-size: 20px; padding: 0;"
                  @click="editId = data.item._id; selectedProductId = data.item.product._id"
                >
                  <font-awesome-icon
                    icon="pencil-alt"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  v-b-tooltip.left
                  style="font-size: 20px; padding: 0 0 0 10px;"
                  class="icon-button text-info"
                  title="Detailne vaade"
                  @click="detailedOrderId = data.item._id;"
                >
                  <font-awesome-icon icon="eye" />
                </b-button>
              </template>
            </b-table>
          </div>
          <faf-pagination
            v-if="totalRows > 20"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="mt-2 mb-3"
            align="center"
            @change="filterTable({page: $event})"
          />
        </div>
        <faf-add-edit-sale v-if="!!editId" :edit-id="editId" :selected-product-id="selectedProductId" @hide="editId = null; initSales(); selectedProductId = null" />
        <faf-detailed-sale v-if="!!detailedOrderId" :id="detailedOrderId" @close="detailedOrderId = null; initSales()" />
      </div>
    </faf-card>
  </div>
</template>
<script>
  import FafAddEditSale from './AddEditSale'
  import FafDetailedSale from './../DetailedViews/DetailedSale'
  import { getPlannedTime, getMaterialCalculation, getLaborCosts, getMyPrice, getSalePrice, getMarginal } from './common/salesTableHelpers'
  import { _getProductsTotalQuantity,
           _getMaterialCostTotal,
           _getWorkTimeTotal,
           _getSetupTime,
           _getNetCostTotal,
           _getTotalSalePrice,
           _getMarginal,
           _getRemainsCost,
           _getProductMaterialCost,
           _getProductWorkTime,
           _getMaterialTotalWeight } from './../../Calculations/index'
  import { downloadBinaryFile } from './../../components/GetPdf/downloadFile'

  const STATUSES = [
    { value: 'INCOMPLETE', label: 'Ostusoovitustes' },
    { value: 'IN_PRODUCTION', label: 'Tootmises' },
    { value: 'COMPLETE', label: 'Tehtud' }
  ]

  export default {
    name: 'faf-sales-table',
    components: {
      FafAddEditSale,
      FafDetailedSale
    },
    props: {
    },
    data () {
      return {
        addNewSale: false,
        editId: null,
        selectedProductId: null,
        detailedOrderId: null,
        addClientModal: false,
        totalRows: 200,
        currentPage: 1,
        perPage: 20,
        cachedQuery: {},
        sortBy: 'deliveryDate',
        sortDesc: true,
        filterFields: [
          { type: 'multiselect', name: 'salesSystemNumber', placeholder: 'ID', options: [], can: ['orderFields', 'salesSystemNumber'] },
          { type: 'multiselect', name: 'catalogue', placeholder: 'Kataloog', options: [], can: ['orderFields', 'catalogue'] },
          { type: 'multiselect', name: 'object', placeholder: 'Objekt', options: [], can: ['orderFields', 'object'] },
          { type: 'multiselect', name: 'orderId', placeholder: 'Projekti nr', options: [], can: ['orderFields', 'orderId'] },
          { type: 'multiselect', name: 'projectName', placeholder: 'Projekti nimi', options: [], can: ['orderFields', 'projectName'] },
          { type: 'multiselect', name: 'client', placeholder: 'Klient', options: [] },
          { type: 'multiselect', name: 'products.name', placeholder: 'Toote nimetus', options: [] },
          { type: 'datepicker', name: 'deliveryDate', placeholder: 'Tarne kp', options: [], col: 1 },
          { type: 'multiselect', name: 'products.status', placeholder: 'Staatus', options: STATUSES, props: { label: 'label' } }
        ],
        fields: [
          (this.$can(['orderFields', 'salesSystemNumber']) && {
            key: 'salesSystemNumber',
            label: 'ID',
            sortable: true
          }), (this.$can(['orderFields', 'orderId']) && {
            key: 'orderId',
            label: 'Projekti Nr',
            sortable: true
          }), (this.$can(['orderFields', 'catalogue']) && {
            key: 'catalogue',
            label: 'Kataloog',
            sortable: true
          }), (this.$can(['orderFields', 'object']) && {
            key: 'object',
            label: 'Objekt',
            sortable: true,
            tdClass: 'faf-max-width-100'
          }), {
            key: 'client',
            label: 'Klient',
            sortable: true
          }, (this.$can(['orderFields', 'projectName']) && {
            key: 'projectName',
            label: 'Projekti nimi',
            sortable: true
          }), {
            key: 'createdDate',
            label: 'Sisestatud kp',
            sortable: true
          }, {
            key: 'deliveryDate',
            label: 'Tarneaeg',
            sortable: true
          }, {
            key: 'product.name',
            label: 'Toote nimetus',
            sortable: true
          }, {
            key: 'product.quantity.amount',
            label: 'Kogus',
            sortable: true
          }, {
            key: 'product.quantity',
            label: 'Tehtud',
            formatter: (value, key, item) => `${this.$_get(item, 'product.quantity.doneAmount', 0)}/${this.$_get(item, 'product.quantity.amount', 0)}`,
            sortable: true,
            sortByFormatted: true
          }, {
            key: 'product.plannedTime',
            label: 'Planeeritud aeg',
            formatter: (value, key, item) => getPlannedTime(item),
            sortable: true,
            sortByFormatted: true
          }, {
            key: 'product.status',
            label: 'Staatus',
            sortable: true
          }, (this.$can(['see', 'OrderCalculationsInSalesTable']) && {
            key: 'product',
            label: 'Materjal €',
            formatter: (value, key, item) => getMaterialCalculation(item),
            sortable: true,
            sortByFormatted: true
          }), (this.$can(['see', 'OrderCalculationsInSalesTable']) && {
            key: 'product.workcenters',
            label: 'Töökulu €',
            formatter: (value, key, item) => getLaborCosts(item),
            sortable: true,
            sortByFormatted: true
          }), (this.$can(['see', 'OrderCalculationsInSalesTable']) && {
            key: 'product.detailName',
            formatter: (value, key, item) => getMyPrice(item),
            label: 'Omahind €',
            sortable: true,
            sortByFormatted: true
          }), (this.$can(['see', 'OrderCalculationsInSalesTable']) && {
            key: 'product.price',
            label: 'Müügihind €',
            formatter: (value, key, item) => getSalePrice(item),
            sortable: true,
            sortByFormatted: true
          }), (this.$can(['see', 'OrderCalculationsInSalesTable']) && {
            key: 'product.marginal',
            formatter: (value, key, item) => getMarginal(item) + ' %',
            label: 'Marginaal %',
            sortable: true,
            sortByFormatted: true
          }),
          {
            key: 'actions',
            label: 'Tegevused'
          }
        ],
        items: []
      }
    },
    computed: {
      showDownloadExcel () {
        return this.$can(['action', 'DownloadSalesExcel']) && ['IN_PRODUCTION', 'COMPLETE'].includes(this.cachedQuery['products.status'])
      }
    },
    watch: {
      addNewSale (val, newVal) {
        if (!val && newVal) {
          this.initSales()
        }
      }
    },
    created () {
      this.initSales()
    },
    methods: {
      rowClass (item, type) {
        if (!item || type !== 'row') return
        if (item.product.subStatus && item.product.subStatus.includes('STOPPED')) return 'table-warning-opacity'
      },
      initSales () {
        try {
          this.filterTable({query: JSON.parse(this.$route.query.filter), page: this.$route.query.page || 1})
        } catch (error) {
          this.filterTable({query: {}, page: this.$route.query.page || 1})
        }
      },
      async getAllSuggestions (event) {
        await this.filterFields.map(async (field) => {
          if (field.type !== 'datepicker' && field.name !== 'products.status') {
            if (field.options && !field.options.length && event === field.name) {
              field.options = (await this.$typeahead.getSuggestions(field.name)).data.filter(n => n)
            }
          }
        })
      },
      getStatus (val) {
        return STATUSES.find(i => i.value === val) && STATUSES.find(i => i.value === val).label
      },
      filterTable ({query = 'empty', page = this.$route.query.page || 1}) {
        this.currentPage = page
        if (query !== 'empty') this.cachedQuery = this.$_cloneDeep(query)
        if (query && query['products.status']) this.cachedQuery['products.status'] = this.cachedQuery['products.status'].value
        this.$loader.start('sales-table')
        this.$orders.getProducts({filter: this.cachedQuery, page: page || this.currentPage, limit: this.perPage}).then(rs => {
          this.$loader.stop('sales-table')
          this.items = rs.data.docs
          this.totalRows = rs.data.totalDocs
          this.$router.replace({ query: { filter: this.$route.query.filter, page: rs.data.page }}).catch(() => {})

        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('sales-table')
        })
      },
      async downloadDocument () {
        this.$loader.start('sales-document-download')
        const type = this.cachedQuery['products.status']
        // this.totalRows
        const docs = await this.$orders.getProducts({filter: this.cachedQuery, page: 1, limit: this.totalRows}).then(rs => {
          return rs.data.docs
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop('sales-document-download')
        })

        const data = []
        for await (const item of docs) {
          data.push(await this['MAP_' + type](item))
        }

        await this.$DocumentController.generateSalesExcel(data, type).then(rs => {
          const headers = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          downloadBinaryFile(rs.data, headers, `${ type === 'IN_PRODUCTION' ? 'TOOTMISES' : 'TEHTUD' }.xlsx`)
          this.$loader.stop('sales-document-download')
        }).catch(err => {
          this.$loader.stop('sales-document-download')
          this.$err.show(err)
        })

        this.$loader.stop('sales-document-download')
      },
      async MAP_IN_PRODUCTION (item) {
        return {
          salesSystemNumber: item.salesSystemNumber,
          name: item.product.name,
          quantity: item.product.quantity.amount,
          amount: (await _getMaterialCostTotal([item.product], 'actualSize')).total
        }
      },
      async MAP_COMPLETE (item) {
        const materialBrutoCostTotal = await _getMaterialCostTotal([item.product])
        const workTimeTotal = await _getWorkTimeTotal([item.product])
        const setupTime = await _getSetupTime([item.product])

        return {
          salesSystemNumber: item.salesSystemNumber,
          name: item.product.name,
          quantity: item.product.quantity.amount,
          amount: await _getNetCostTotal(materialBrutoCostTotal, workTimeTotal, 0, setupTime.total)
        }
      }
    }
  }
</script>
<style lang="scss">
  .sales-table .table > tbody > tr > td:nth-child(1) {
    padding-right: 5px;
  }

  .with-bottom-bar {
    margin-bottom: 80px;
  }
</style>
