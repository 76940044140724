export default {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!event.composedPath().includes(el)) {
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};
