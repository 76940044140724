import Sales from './../views/Sales/Sales.vue'
import Production from './../views/Production/Production.vue'
import Purchase from './../views/Purchase/Purchase.vue'
import MyPage from './../views/MyPage/MyPage.vue'
import Order from './../views/Order/Order.vue'
import QualityReports from './../views/QualityReports/QualityReports'
import ManagementSystemReportsReports from './../views/ManagementSystemReports/ManagementSystemReports'
import { isMobile } from '@faf/faf-ui'

export default
[
  ...!isMobile ? [{
    path: 'order',
    name: 'Tellimused',
    icon: 'clipboard-list',
    components: { default: Order }
  }] : [],
  ...!isMobile ? [{
    path: 'sales',
    name: 'Müük',
    icon: 'suitcase',
    components: { default: Sales }
  }] : [],
  {
    path: 'productions',
    name: 'Tootmine',
    icon: 'atom',
    components: { default: Production }
  },
  ...!isMobile ? [{
    path: 'purchase',
    name: 'Ost',
    icon: 'shopping-cart',
    components: { default: Purchase }
  }] : [],
  ...!isMobile ? [{
    path: 'quality-reports',
    name: 'Kvaliteediaruanded',
    icon: 'file-invoice',
    components: { default: QualityReports }
  }] : [],
  ...!isMobile ? [{
    path: 'management-system-reports',
    name: 'Juhtimissüsteem',
    icon: 'folder',
    components: { default: ManagementSystemReportsReports }
  }] : [],
  ...!isMobile ? [{
    path: 'my-page',
    name: 'Minu asjad',
    icon: 'cog',
    components: { default: MyPage }
  }] : []

]
