<template>
  <b-modal
    id="file-manager"
    title="Dokumendid"
    title-tag="h4"
    scrollable
    size="lg"
    @hide="close"
  >
    <b-row class="pb-4">
      <b-col cols="12">
        <file-pond @uploaded="uploaded" @processfiles="filesDone" />
        <b-progress :value="value" :max="max" :variant="progressBarColor" :animated="value !== 100" show-progress />
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Otsi"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-table
        v-if="items.length"
        :striped="true"
        :small="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        :items="items"
        :fields="fields"
        stacked="xl"
      >
        <template v-slot:cell(fileName)="row">
          <div class="pl-1">
            <b-button
              :class="'icon-button text-dark'"
              style="font-size: 24px; padding: 0;"
              @click="downloadFile(row.item)"
            >
              <font-awesome-icon
                :icon="getFileExtensionIcon(row.item.fileName)"
              />
            </b-button>
            <span :class="'pl-3'">{{ row.item.fileName }}</span>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button class="icon-button text-info" title="Lae alla" @click="downloadFile(row.item)">
            <font-awesome-icon icon="download" />
          </b-button>
          <b-button v-if="!row.item.subOrder" v-b-modal.modal-detailed-purchase-order class="icon-button text-danger" title="Kustuta fail" @click="deleteFile(row)">
            <font-awesome-icon icon="trash-alt" />
          </b-button>
          <b-button v-if="['ORDER', 'MANAGEMENT_SYSTEM_REPORTS'].includes(type) && !row.item.subOrder" v-can="['see', 'documentAccessTypeManagement']" class="icon-button text-danger" title="Privaatne" @click="makeFilePrivate(row)">
            <font-awesome-icon v-if="row.item.accessType === 'PRIVATE'" icon="lock" />
            <font-awesome-icon v-else icon="lock-open" />
          </b-button>
        </template>
      </b-table>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('file-manager')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import FilePond from './FilePond'

  export default {
    name: 'faf-file-manager',
    components: {
      FilePond
    },
    props: {
      orderObjectId: String,
      preLoadedDocuments: Array,
      type: {
        type: String,
        default: 'ORDER'
      }
    },
    data () {
      return {
        value: 0,
        max: 100,
        filter: null,
        filterOn: [],
        options: {
          url: '/upload',
          paramName: 'file'
        },
        fields: [
          {
            key: 'fileName',
            label: 'Nimetus'
          },
          {
            key: 'size',
            label: 'Suurus',
            formatter: (value, key, item) => this.niceBytes(value)
          },
          {
            key: 'actions',
            label: 'Tegevused'
          }
        ],
        items: this.preLoadedDocuments
      }
    },
    computed: {
      progressBarColor () {
        return this.value === this.max ? 'success' : 'info'
      }
    },

    created () {
      if (['ORDER', 'MANAGEMENT_SYSTEM_REPORTS'].includes(this.type) && !this.$user.getUserGroups().includes('admin', 'manager')) {
        // TODO Should filter in backend but its bit bigger development
        this.items = this.preLoadedDocuments.filter(document => document.accessType !== 'PRIVATE')
      }
    },
    methods: {
      filesDone () {
        this.$notify.show('Failid edukalt üles laetud!')
      },
      niceBytes (x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let l = 0, n = parseInt(x, 10) || 0;

        while(n >= 1024 && ++l) {
          n = n / 1024;
        }
        //include a decimal point and a tenths-place digit if presenting
        //less than ten of KB or greater units
        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
      },
      async makeFilePrivate (row) {
        const file = row.item
        const accessType = file.accessType && file.accessType === 'PUBLIC' ? 'PRIVATE' : 'PUBLIC'
        if (this.type === 'MANAGEMENT_SYSTEM_REPORTS') {
          await this.$managementSystemReports.updateDocument(file._id, { accessType })
            .then(() => this.items[row.index].accessType = accessType)
        }
        if (this.type === 'ORDER') {
          this.$orders.updateDocument(file._id, { accessType })
            .then(() => this.items[row.index].accessType = accessType)
        }
      },
      handleFilePondInit () {
        console.log('FilePond has initialized');
      },
      uploaded (event) {
        const documentData = {
          fileserviceId: event._id,
          fileName: event.filename,
          contentType: event.contentType,
          size: event.length,
          accessType: 'PUBLIC'
        }
        if (this.type === 'ORDER') {
          this.$orders.addDocument(this.orderObjectId, documentData)
            .then(rs => {
              documentData._id = rs.data._id
              this.items.push(documentData)
            })
            .catch(error => {
              this.$err.show(error)
            })
        }
        if (this.type === 'QUALITY_REPORTS') {
          this.$qualityReports.addDocument(this.orderObjectId, documentData)
            .then(rs => {
              documentData._id = rs.data._id
              this.items.push(documentData)
            })
            .catch(error => {
              this.$err.show(error)
            })
        }
        if (this.type === 'MANAGEMENT_SYSTEM_REPORTS') {
          this.$managementSystemReports.addDocument(this.orderObjectId, documentData)
            .then(rs => {
              documentData._id = rs.data._id
              this.items.push(documentData)
            })
            .catch(error => {
              this.$err.show(error)
            })
        }
      },
      downloadFile (file) {
        this.value = 2
        this.$fileService.download(file.fileserviceId, progress => {
          this.value = Math.round( (progress.loaded * 100) / file.size )
        }).then(() => {
          this.resetDownloadProgress()
        }).catch(error => {
          this.resetDownloadProgress()
          this.$err.show(error)
        })
      },
      async deleteFile (row) {
        try {
          this.$loader.start('file-delete')
          const file = row.item
          if (this.type === 'ORDER') {
            await this.$orders.deleteDocument(file._id)
          }

          if (this.type === 'QUALITY_REPORTS') {
            await this.$qualityReports.deleteDocument(file._id)
          }

          if (this.type === 'MANAGEMENT_SYSTEM_REPORTS') {
            await this.$managementSystemReports.deleteDocument(file._id)
          }

          await this.$fileService.deleteFile(file.fileserviceId)
          this.items.splice(row.index, 1)
          this.$loader.stop('file-delete')
        } catch (error) {
          this.$loader.stop('file-delete')
          this.$err.show(error)
        }

      },
      resetDownloadProgress () {
        setTimeout(() => {
          this.value = 0
        }, 3000)
      },
      close () {
        this.$emit('close')
      },
      getFileExtensionIcon (fileName) {
        const extension = fileName.split('.').pop().toLowerCase()
        const icons = {
          'pdf': 'file-pdf',
          'png': 'file-image',
          'doc': 'file-word',
          'docx': 'file-word',
          'xls': 'file-excel',
          'xlsx': 'file-excel',
          'jpg': 'file-image',
          'jpeg': 'file-image',
          'csv': 'file-csv'
        }
        return icons[extension] || 'file-invoice'
      }
    }
  }

</script>
<style>
  .progress {
    background-color: #e9ecef;
    font-size: 0.75rem;
  }
  .progress,
  .progress-bar {
    display: flex;
    border: 0;
    box-shadow: none;
    height: 1rem;
    border-radius: 0.25rem;
  }
</style>
