<template>
  <div>
    <faf-card :max-height="300" initial-collapsed title="Kõik aktiivsed tööd" name="all-jobs" @collapse="getJobs">
      <div v-if="jobs.length" style="max-height: 300px; overflow: scroll">
        <b-table
          :fields="fields"
          :items="jobs"
          sort-by="status"
          stacked="xl"
          striped
          hover
        />
      </div>
      <p v-else>
        Pole ühtegi aktiivset tööd
      </p>
      <template v-if="jobs.length && !collapsed" slot="buttons">
        <b-button v-b-tooltip.hover title="Uuenda" class="icon-button text-info" @click="getJobs(false)">
          <font-awesome-icon icon="sync" />
        </b-button>
      </template>
    </faf-card>
  </div>
</template>
<script>
  import otherWorkSuggestions from './helpers/otherWorkSuggestions'

  export default {
    name: 'faf-all-active-jobs-table',
    data () {
      return {
        jobs: [],
        collapsed: true,
        fields: [
          {
            key: 'workcenterName',
            label: 'Töökeskus',
            formatter: (value, key, item) => value || '-',
            sortable: true
          },
          {
            key: 'status',
            label: 'Tüüp',
            formatter: (value, key, item) => this.getJobStatus(value, item) || '-',
            sortable: true
          },
          {
            key: 'operatorName',
            label: 'Operaator',
            sortable: true
          }
        ]
      }
    },
    async created () {
    },
    methods: {
      async getJobs (e) {
        this.collapsed = e
        if (!e) {
          this.$cardLoader('all-jobs', true)
          this.jobs = []
          const works = await this.$work.getJobs({status: 'IN_PROGRESS'})
          if (works.data.length) {

            for await (const work of works.data) {
              let workcenterName = work.jobs[0].workcenterName
              const status = work.jobs[0].status

              if (work.jobs.length > 0) {
                workcenterName = this.$_uniq(work.jobs.map(j => j.workcenterName)).join(', ')
              }

              this.jobs.push({
                workcenterName,
                status,
                operatorName: work.operatorName,
                paused: !!work.paused.find(p => p.status === 'IN_PROGRESS')
              })
            }
          }
          this.$cardLoader('all-jobs', false)
        }
      },
      getOtherWorkText (job) {
        const otherWork = otherWorkSuggestions().find(s => s.value === job.status)
        if (otherWork) {
          return job.workcenterName ? `/(${otherWork.name})` : otherWork.name
        }
        return ''
      },
      getJobStatus (val, item) {
        if (item.paused) {
          return 'Paus'
        }
        // 'ORDER_WORK', 'WORK', 'PREPARATION', 'PENDING_WORK','IMPROVEMENT_ACTIVITY', 'MEETINGS', 'LOGISTICS', 'SERVICE', 'PROOF_CORRECTIONS', 'OTHER', 'DELETED'
        switch (val) {
        case 'ORDER_WORK':
          return 'Tellimustöö'
        case 'WORK':
          return 'Töökeskuse põhine'
        case 'PREPARATION':
          return 'Töö ootel'
        case 'PENDING_WORK':
          return 'Abinõude valmistamine'
        case 'IMPROVEMENT_ACTIVITY':
          return 'Parendustegevused'
        case 'MEETINGS':
          return 'Koosolekud'
        case 'LOGISTICS':
          return 'Logistika'
        case 'SERVICE':
          return 'Hooldus/Remont'
        case 'PROOF_CORRECTIONS':
          return 'Raporteerimise parandused'
        case 'OTHER':
          return 'Muu'
        default:
          return 'Muu'
        }
      }
    }
  }
</script>
<style scoped>
  .separator {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
</style>
