<template>
  <div class="p-3 faf-small-chart stats-title">
    <h5 class="mb-0 text-uppercase">
      <strong>{{ title }}</strong>
    </h5>
    <h2 :class="'mb-2 text-' + color">
      <strong>{{ total }} {{ afterLabel }}</strong>
    </h2>
    <div class="chart-area">
      <line-chart
        v-if="chartData.datasets[0].data && !loading && !toBeUpdated"
        :chart-data="chartData"
        :extra-options="extraOptions"
        style="height: 100%"
      />
      <b-spinner v-if="loading && !toBeUpdated" variant="primary" label="Laeb..." style="margin: auto;" />
      <p v-if="toBeUpdated" class="to-be-updated">
        Varsti tulekul...
      </p>
    </div>
  </div>
</template>

<script>
  import config from '../../config'
  import LineChart from './components/LineChart'
  import { days, extraOptions } from './components/utils'

  export default {
    name: 'faf-small-chart',
    components: {
      LineChart
    },
    props: {
      title: String,
      label: String,
      afterLabel: {
        type: String,
        default: ''
      },
      total: [String, Number],
      data: Array,
      color: {
        type: String,
        default: 'primary',
        validator: (val) => ['default', 'primary', 'success', 'info', 'warning', 'danger', 'black', 'primaryGradient'].includes(val)
      },
      labels: {
        type: Array,
        default: () => days
      },
      additionalLabels: {
        type: Array,
        default: () => []
      },
      loading: Boolean,
      toBeUpdated: Boolean,
      percentage: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        extraOptions: {
          ...extraOptions,
          tooltips: {
            callbacks : {
              label: tooltipItem => {
                return `${ this.label || this.title }: ${tooltipItem.value}${this.afterLabel}`
              },
              footer: tooltipItem => {
                return `${ this.additionalLabels[tooltipItem[0].index] || '' }`
              }
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false
              }
            }]
          }
        },
        chartData: {
          labels: this.labels,
          afterLabels: ['', '', '', '', '', '', '', ''],
          datasets: [{
            label: this.label,
            multiTooltipTemplate: "<%=datasetLabel%> : <%=value%> abc",
            fill: true,
            borderColor: config.colors[this.color],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors[this.color],
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors[this.color],
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.data
          }]
        }
      }
    },
    watch: {
      data (val) {
        this.chartData.datasets[0].data = null
        const numberArray = this.data.map(Number)
        this.updateColor()
        if (Math.max(...numberArray) > 100) {
          this.extraOptions.scales.yAxes[0].ticks.max = Math.max(...numberArray)
        }
        if (Math.max(...numberArray) < 100 && !this.percentage) {
          this.extraOptions.scales.yAxes[0].ticks.max = Math.max(...numberArray) + 1
        }
        this.updateChart()
      }
    },
    created () {
      this.updateChart = this.$_debounce(this.updateChart, 100)
    },
    methods: {
      updateColor () {
        this.chartData.datasets[0].borderColor = config.colors[this.color]
        this.chartData.datasets[0].pointBackgroundColor = config.colors[this.color]
        this.chartData.datasets[0].pointHoverBackgroundColor = config.colors[this.color]
      },
      updateChart () {
        this.chartData.datasets[0].data = this.data
        this.chartData.labels = this.labels
      }
    }
  }
</script>
<style lang="scss" scoped>
  .faf-small-chart {
    border: 1px solid rgba(240, 248, 255, 0.2);
    border-radius: 4px;
    height: 100%;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);

    .chart-area {
      height: 100px;
      margin: 20px 5px 5px;
      position: relative;

      .spinner-border {
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        bottom: 0;
      }

      .to-be-updated {
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        line-height: 100px;
        background-color: rgba(30, 30, 47, 0.3);
      }
    }
  }
</style>
