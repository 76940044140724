import { ee } from "vuejs-datepicker/src/locale"

export function installDatepickerProps (Vue) {
  if (Vue) {
    Vue.prototype.$datepickerProps = function (name, required = true) {
      return {
        language: ee,
        mondayFirst: true,
        bootstrapStyling: true,
        inputClass: {
          'datepicker': true,
          'is-invalid': required && !this.$validateField(name)
        }
      }
    }
  } else {
    console.error('Vue not found')
  }
}

export function installMultiselectProps (Vue) {
  if (Vue) {
    Vue.prototype.$multiselectProps = function (name, required = true) {
      return {
        name,
        showLabels: false,
        searchable: true,
        taggable: true,
        tagPlaceholder: '',
        placeholder: '',
        showNoResults: false,
        class: {
          'is-invalid': required && !this.$validateField(name)
        }
      }
    }
  } else {
    console.error('Vue not found')
  }
}

export function installRequiredInputProps (Vue) {
  if (Vue) {
    Vue.prototype.$requiredInputProps = function (name) {
      return {
        name,
        class: {
          'form-control': true,
          'is-invalid': !this.$validateField(name)
        }
      }
    }
  } else {
    console.error('Vue not found')
  }
}
