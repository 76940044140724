export default function installUser (Vue) {
  if (Vue) {
    Vue.prototype.$user = {
      _vm: new Vue({ data: {
        userData: localStorage.user ? JSON.parse(localStorage.user) : {
          _id: null,
          token: null,
          firstName: null,
          lastName: null,
          company: [],
          theme: 'dark',
          userGroups: []
        }
      }}),
      setData (data) {
        this._vm.$data.userData = Object.assign(this._vm.$data.userData, data)
        localStorage.user = JSON.stringify(this._vm.$data.userData)
      },
      getData () {
        return this._vm.$data.userData
      },
      getUsername () {
        return this._vm.$data.userData.username
      },
      getCompany () {
        return this._vm.$data.userData.company
      },
      getFirstName () {
        return this._vm.$data.userData.firstName
      },
      getLastName () {
        return this._vm.$data.userData.lastName
      },
      getId () {
        return this._vm.$data.userData._id
      },
      getUserGroups () {
        return this._vm.$data.userData.userGroups
      },
      getTheme () {
        return this._vm.$data.userData.theme
      }
    }
  } else {
    console.error('Vue not found')
  }
}
