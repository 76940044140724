<template>
  <div>
    <input ref="fileInput" style="display: none;" type="file" @change="onFileSelected">
    <b-button v-b-tooltip.hover :disabled="disabled" class="icon-button text-dark" title="Import" @click="$refs.fileInput.click()">
      <font-awesome-icon icon="file-import" />
    </b-button>
  </div>
</template>
<script>
  export default {
    name: 'ajan-import',
    props: {
      getProof: Boolean,
      disabled: Boolean
    },
    methods: {
      parseProof (data) {
        const sheets = data.sheets.filter(sheet => sheet.workSheetNumber)
        sheets.map(sheet => {
          sheet.product.map(p => {
            if (!sheet['Repeat(Sheet No)'] && p.amount) {
              this.$emit('importData', {
                name: p.name,
                producedProductsAmount: p.amount,
                workSheet: sheet.workSheetNumber[0]
              })
            } else {
              this.$emit('importData', {
                name: p.name,
                producedProductsAmount: p.quantity * sheet['Repeat(Sheet No)'],
                workSheet: sheet.workSheetNumber
              })
            }
          })
        })
      },
      async onFileSelected (event) {
        this.$loader.start('ajan-import')
        const { data } = await this.$import.upload(event.target.files[0]).catch(err => {
          this.$loader.stop('ajan-import')
          this.$err.show('Import ebaõnnestus!')
        })
        if (this.getProof) {
          this.$loader.stop('ajan-import')
          return this.parseProof(data)
        }
        const machineMeta = (await this.$workcenters.getWorkcenters({filters: {name: data.machineType}})).data
        const xpainMachineMeta = (await this.$workcenters.getWorkcenters({filters: {name: 'Painutus'}})).data
        const materialMeta = (await this.$materials.getMaterials({filters: {name: data.material}})).data
        const scrapMetalTotal = data.sheets.reduce((a, b) => a + (b.scrapMetal * b['Repeat(Sheet No)']), 0);
        const products = data.sheets[data.sheets.length - 1].product.map(p => {
          const painutus = p.name.search("xpain") !== -1
          return {
            name: p.name || '',
            price: 0,
            imported: true,
            quantity: {
              amount: p.amount
            },
            materials: [
              { diagramNumber: 1,
                name: data.material,
                width: data.width,
                unit: this.$_get(materialMeta, 'docs[0].unit', 'kg'),
                specialWeight: this.$_get(materialMeta, 'docs[0].specialWeight', '1'),
                amount: '1',
                price: this.$_get(materialMeta, 'docs[0].price', '0'),
                description: this.$_get(materialMeta, 'docs[0].description', ''),
                size: (p.weight / p.amount).toFixed(2),
                actualSize: (((scrapMetalTotal / data.totalWeight * p.weight) + p.weight) / p.amount).toFixed(2)
              }],
            workcenters: [
              {
                name: data.machineType,
                team: this.$_get(machineMeta, 'docs[0].team', 'Plasma'),
                workTime: ((data.totalTime / data.totalNetTime * p.detailTotalTime) / p.amount).toFixed(2),
                queue: '1',
                setupTime: this.$_get(machineMeta, 'docs[0].setupTime', '0'),
                leadTime: this.$_get(machineMeta, 'docs[0].leadTime', '1'),
                price: this.$_get(machineMeta, 'docs[0].price', '0.42'),
                unit: 'min'
              },
              ...(painutus ? [{
                name: 'Painutus',
                team: this.$_get(xpainMachineMeta, 'docs[0].team', 'Pärnu'),
                workTime: +p.name.split('xpain')[0].slice(-2),
                queue: '2',
                setupTime: this.$_get(xpainMachineMeta, 'docs[0].setupTime', '0'),
                leadTime: this.$_get(xpainMachineMeta, 'docs[0].leadTime', '1'),
                price: this.$_get(xpainMachineMeta, 'docs[0].price', '0.42'),
                unit: 'min'
              }] : [])
            ]
          }
        })
        this.$emit('importData', products)
        this.$loader.stop('ajan-import')
      }
    }
  }
</script>
