import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function get () {
  return axios({
    url: `/api/settings`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function upsert (data) {
  return axios({
    url: `/api/settings`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$settings = {
    get,
    upsert
  }
}

export {
  get,
  upsert
}
