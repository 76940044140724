<template>
  <div :key="$notify.getMessages().toString()" class="notice-wrapper">
    <b-alert
      v-for="(notification, i) in $notify.getMessages()"
      :key="i"
      :class="notification.class"
      show
      variant="success"
      dismissible
      fade
      @dismissed="$notify.hide(notification.id)"
    >
      {{ notification.m }}
    </b-alert>
  </div>
</template>
<script>
  export default {
    name: 'notification-modal'
  }
</script>
<style lang="scss">
  .notice-wrapper {
    width: 300px;
    z-index: 1050;
    position: fixed;
    right: 0px;
    bottom: 0;

    .alert.alert-success {
      background-color: #42b883;
    }

    .alert {
      right: -300px;
      transition: right 0.5s;
    }

    button.close {
      margin-top: -2px;
      padding: 0;
    }

    .alert.enter-active {
      right: 0;
    }

    .alert.leave-active {
      right: -300px;
    }
  }
</style>
