<template>
  <b-modal
    :visible="visible"
    title="Minu profiil"
    size="lg"
    center
    title-tag="h4"
    @hide="close"
  >
    <b-row>
      <b-col class="pr-1">
        <label for="my-username">Kasutajanimi</label>
        <b-form-input v-model.trim="form.username" :placeholder="$user.getUsername()" disabled name="my-username" />
      </b-col>
      <b-col class="px-1">
        <label for="my-first-name">Eesnimi</label>
        <input
          v-model.trim="form.firstName"
          v-validate="'min:2|max:15|validate_name'"
          :placeholder="$user.getFirstName()"
          :state="$validateField('my-first-name')"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('my-first-name')}"
          name="my-first-name"
        />
        <b-form-invalid-feedback v-if="!$validateField('my-first-name')">
          Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.
        </b-form-invalid-feedback>
      </b-col>
      <b-col class="pl-1">
        <label for="my-last-name">Perenimi</label>
        <input
          v-model.trim="form.lastName"
          v-validate="'min:2|max:15|validate_name'"
          :placeholder="$user.getLastName()"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('my-last-name')}"
          name="my-last-name"
          autocomplete="nope"
        />
        <b-form-invalid-feedback v-if="!$validateField('my-last-name')">
          Nimi peab olema vähemalt 2 tähemärki ja sisaldama ainult tähti.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="pr-1">
        <label for="my-new-password">Parool</label>
        <input
          v-model="form.password"
          v-validate="'min:6'"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('my-new-password')}"
          type="password"
          name="my-new-password"
          autocomplete="new-password"
        />
        <b-form-invalid-feedback v-if="!$validateField('my-new-password')">
          Parool peab olema vähemalt 6 tähemärki.
        </b-form-invalid-feedback>
      </b-col>
      <b-col class="pl-1">
        <label for="add-password-repeat">Korda parooli</label>
        <input
          v-model="form.repeatPassword"
          v-validate="{ min: 6, is: form.password }"
          :class="{ 'form-control': true, 'is-invalid': !$validateField('my-new-password-repeat')}"
          type="password"
          name="my-new-password-repeat"
        />
        <b-form-invalid-feedback v-if="!$validateField('my-new-password-repeat')">
          Korda parool peab ühtima parooliga.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label for="add-user-group">Kasutaja õigused:</label>
        <b-form-group>
          <b-form-checkbox-group
            v-model.trim="form.userGroups"
            :options="$userGroupOptions"
            disabled
            name="add-user-group"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-3" />

    <b-row class="mt-3">
      <b-col>
        <b-form-group label="Vali sobiv teema">
          <b-form-radio v-model="form.theme" name="theme" value="light">
            Hele
          </b-form-radio>
          <b-form-radio v-model="form.theme" name="theme" value="dark">
            Tume
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>

    <div slot="modal-footer" class="container">
      <div class="row justify-content-end">
        <div class="col col-md-3 pl-0 pr-1">
          <b-button
            block
            variant="text-danger"
            size="m"
            class="w-100"
            @click="close"
          >
            Tagasi
          </b-button>
        </div>
        <div class="col col-md-3 pr-0 pl-1">
          <b-button
            block
            variant="primary"
            size="m"
            class="w-100"
            @click="update"
          >
            Uuenda profiili
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import { updateUser } from './../../views/Login/AuthService'

  export default {
    name: 'faf-profile',
    props: {
      visible: Boolean
    },
    data () {
      return {
        form: {
          userGroups: this.$user.getUserGroups(),
          theme: this.$user.getTheme()
        }
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      update () {
        this.$validator.validateAll().then(valid => {
          if (!valid) {
            return
          }
          updateUser(this.$user.getId(), this.form).then(() => {
            this.$user.setData({
              firstName: this.form.firstName || this.$user.getFirstName(),
              lastName: this.form.lastName || this.$user.getLastName(),
              theme: this.form.theme || 'dark'
            })
            this.form = {
              userGroups: this.$user.getUserGroups(),
              theme: this.$user.getTheme()
            }
            this.$notify.show('Andmed edukalt uuendatud!')
          }).catch(err => this.$err.show(err))
        })
      }
    }
  }
</script>
