<template>
  <div :class="wrapperClasses" :style="wrapperStyles" class="faf-input-wrapper">
    <div v-if="active">
      <select
        v-model="val"
        v-bind="$attrs"
        :name="name"
        :ref="name"
        class="form-control faf-input faf-select"
        @input.stop="onInput"
      >
        <option v-for="(option, index) in options" :key="'option-' + index" :value="option">
          <span v-if="option === 'm2'">m&sup2;</span>
          <span v-else>{{ option }}</span>
        </option>
      </select>
      <b-button v-b-tooltip.hover v-if="isInValid(name)" :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
    </div>
    <div v-else-if="!textHidden" class="faf-input-text" @click="editRow">
      <b-button v-b-tooltip.hover v-if="isInValid(name)" :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
      <slot name="custom-text" />
      <span v-if="!$slots['custom-text']">{{ val }} {{ unit.replace('m2', 'm&sup2;') }}</span>
    </div>
  </div>
</template>
<script>
  import field from './../mixins/field'

  export default {
    name: 'faf-select',
    mixins: [ field ],
    props: {
      options: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>
