export {
  getOrderStatus,
  convertSubStatusToText
}

function getOrderStatus (order) {

  if (order.status === 'COMPLETE') {
    return { value: 'COMPLETE', label: 'Tehtud' }
  }

  if (order.status === 'ANNULLED') {
    return { value: 'ANNULLED', label: 'Tühistatud' }
  }

  if (order.status === 'ORDER' && order.subStatus) {
    if (order.subStatus.includes('ORDER_SENT')) {
      return { value: 'ORDER_SENT', label: 'Kaup väljastatud' }
    } else if (order.subStatus.includes('ORDER_EMAIL_SENT')) {
      return { value: 'ORDER_EMAIL_SENT', label: 'Klient teavitatud' }
    } else if (order.subStatus.includes('ORDER_PACKED')) {
      return { value: 'ORDER_PACKED', label: 'Komplekteeritud' }
    } else {
      return { value: 'ORDER', label: 'Tellimus' }
    }
  }

  if (order.status === 'OFFER' && order.subStatus) {
    if (order.subStatus.includes('OFFER_EMAIL_SENT')) {
      return { value: 'OFFER_EMAIL_SENT', label: 'Saadetud' }
    } else if (order.subStatus.includes('OFFER_PREPARED')) {
      return { value: 'OFFER_PREPARED', label: 'Ettevalmistatud' }
    } else {
      return { value: 'OFFER', label: 'Hinnapakkumine' }
    }
  }
}

function convertSubStatusToText (status) {
  const statuses = {
    ORDER_SENT: 'Kaup väljastatud',
    ORDER_EMAIL_SENT: 'Klient teavitatud',
    ORDER_PACKED: 'Komplekteeritud',
    OFFER_EMAIL_SENT: 'Saadetud',
    OFFER_PREPARED: 'Ettevalmistatud'
  }
  return statuses[status]
}
