import Ajv from 'ajv'
import { unblockMainThread } from './../plugins/unblockMainThread'
const ajv = new Ajv({ allErrors: true })

// Kõikide toodete seadistusaeg

const schema = {
  type: 'array',
  required: ['products'],
  properties: {
    products: {
      type: 'array',
      required: ['workcenters'],
      properties: {
        workcenters: {
          type: 'array',
          required: ['setupTime', 'price']
        }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getSetupTime (products) {
  if (!test(products)) {
    throw { function: '_getSetupTime', error: test.errors }
  }

  const time = []
  const price = []

  for await (const product of products) {
    if (product.workcenters.length > 0) {
      for await (const workcenter of product.workcenters) {
        await unblockMainThread()
        time.push(+workcenter.setupTime || 0)
        price.push((+workcenter.price * +workcenter.setupTime) || 0)
      }
    }
  }

  const totalTime = time.reduce((a, b) => a + +b, 0)
  const totalPrice = price.reduce((a, b) => a + +b, 0)
  const resultPrice = totalPrice / totalTime

  return {
    time: totalTime,
    price: isFinite(resultPrice) ? resultPrice.toFixed(2) : '0.00',
    total: totalPrice.toFixed(2)
  }
}
