<template>
  <proof-management
    :my-jobs="myJobs"
    :modal-name="modalName"
    :bulk-cert="true"
    @editProof="editProof"
    @complete="completeJob"
  />
</template>
<script>
  import ProofManagement from './templates/ProofManagement'
  export default {
    name: 'end-and-edit-job-modal',
    components: {
      ProofManagement
    },
    props: {
      modalName: {
        type: String,
        default: 'end-job-modal'
      },
      myJobs: Array,
      workId: String,
      editMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        overTime: 0,
        closingModal: {},
        workcenters: [{proof: [{}]}]
      }
    },
    methods: {
      async editProof ({ workcenters, bulkCertificate = null }, promise) {
        await this.$orders.setProof(
          {
            workcenters,
            bulkCertificate,
            calculateDoneAmount: true,
            setEndDate: true
          }
        ).catch(err => {
          promise.reject(err)
        })
        promise.resolve()
      },
      async completeJob (payload, promise) {
        if (await this.$validator.validateAll()) {
          await this.$work.completeJob(this.workId, this.overTime)
            .catch(err => promise.reject(err))
          this.$bvModal.hide('end-job-modal')
          this.$notify.show('Töö on edukalt lõpetatud!')
          this.$cardReset('my-jobs')
          this.$cardReset('production-table')
          promise.resolve()
        }
        promise.resolve()
      }
    }
  }
</script>
