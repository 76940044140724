<template>
  <div @contextmenu="openMenu">
    <slot></slot>
    <div
      v-click-outside="closeMenu"
      v-if="$contextMenu.visible"
      ref="right"
      :style="{ top: top, left: left }"
      class="dropdown-menu right-click-menu"
      tabindex="-1"
    >
      <b-dropdown-text tag="span" style="width: 240px;">
        {{ $contextMenu.item.salesSystemNumber }}
      </b-dropdown-text>
      <span>
        <b-dropdown-divider/>
      </span>

      <span v-for="(item, index) of items" :key="index">
        <b-dropdown-item :disabled="$route.name === item.to" @click="open(item)">
          {{ item.name }}
        </b-dropdown-item>
      </span>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    name: 'context-menu',
    data () {
      return {
        top: '0px',
        left: '0px',
        items: [
          { name: 'Ava Tellimustes', to: 'Tellimused' },
          { name: 'Ava Müügis', to: 'Müük' },
          { name: 'Ava Tootmises', to: 'Tootmine' }
        ]
      };
    },
    watch: {
      '$contextMenu.item' (val) {
        if (this.$can(['orderFields', 'salesSystemNumber']) && val) this.$contextMenu.visible = true
      }
    },
    methods: {
      open (item) {
        this.$router.replace({name: item.to, query: { filter: JSON.stringify({salesSystemNumber: this.$contextMenu.item.salesSystemNumber}) }})
        this.$contextMenu.visible = false
        this.$contextMenu.reset()
      },
      setMenu (top, left) {
        const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 25;
        const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 25;

        if (top > largestHeight) top = largestHeight;

        if (left > largestWidth) left = largestWidth;

        this.top = top + 'px';
        this.left = left + 'px';
      },

      closeMenu () {
        this.$contextMenu.visible = false
        this.$contextMenu.reset()
      },

      openMenu (e) {
        if (this.$can(['orderFields', 'salesSystemNumber']) && this.$contextMenu.item) {
          Vue.nextTick(() => {
            this.setMenu(e.y, e.x)
          })
          e.preventDefault()
        }
      }
    }
  }
</script>
<style scoped>
  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  .dropdown-menu {
    transition: none;
  }
  .dropdown-menu:before {
    display: none !important;
    content: none !important;
  }
  .right-click-menu {
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 250px;
    z-index: 999999;
  }
</style>
