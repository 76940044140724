<template>
  <div>
    <b-modal
      id="email-sending-modal"
      :visible="modalVisible"
      title="Saada email"
      scrollable
      title-tag="h4"
      @hide="$emit('hide')"
    >
      <b-row>
        <b-col v-if="contacts" cols="12">
          <label for="template" class="mb-0">Emaili tüüp</label>
          <select v-model="selectedTemplateType" v-validate="'required'" v-bind="$requiredInputProps('template')" :disabled="templateTypes.length === 1">
            <option v-for="(option, optionIndex) in templateTypes" :key="'template-option-' + optionIndex" :value="option.value">
              <span>{{ option.text }}</span>
            </option>
          </select>
        </b-col>

        <b-col v-if="['OFFER_EMAIL_SENT', 'FILE_DEFAULT'].includes(selectedTemplateType)" cols="12">
          <faf-get-pdf
            :order="order"
            :chosen-one="type === 'OFFER_EMAIL_SENT' && 'SALES_PRICE_INQUIRY'"
            :type="includedFileType"
            for-email
            visible
            @documentType="setFileType"
            @formData="fileFormData = $event"
          />
        </b-col>

        <b-col v-if="contacts" cols="12">
          <label for="to" class="mb-0 mt-2">Saaja</label>
          <select v-model="to" v-validate="'required'" v-bind="$requiredInputProps('to')">
            <option v-for="(option, optionIndex) in contacts" :key="'to-option-' + optionIndex" :value="option.email">
              <span>{{ option.name }} ({{ option.email }})</span>
            </option>
          </select>
        </b-col>

        <b-col v-if="['DEFAULT', 'FILE_DEFAULT', 'OFFER_EMAIL_SENT'].includes(selectedTemplateType)" cols="12">
          <label for="client-contact" class="mb-0 mt-2">Pealkiri</label>
          <input
            v-model="defaultFormData.preHeader"
            v-validate="'required'"
            v-bind="$requiredInputProps('preHeader')"
          />
        </b-col>

        <b-col v-if="['DEFAULT', 'FILE_DEFAULT', 'OFFER_EMAIL_SENT', 'ORDER', 'ORDER_EMAIL_SENT'].includes(selectedTemplateType)" cols="12">
          <label for="client-contact" class="mb-0 mt-2">Sisu</label>
          <textarea
            v-model="defaultFormData.text"
            v-validate="'required'"
            v-bind="$requiredInputProps('text')"
            rows="5"
          />
        </b-col>
      </b-row>
      <div slot="modal-footer" class="container modal-buttons-wrapper">
        <div class="row justify-content-center">
          <b-button v-b-tooltip.hover :disabled="$loader.val()" :title="!!type ? 'Ära saada emaili' : 'Tagasi'" class="icon-button" @click="$emit('hide')">
            <font-awesome-icon :icon="!!type ? 'times-circle' : 'arrow-alt-circle-left'" />
          </b-button>
          <b-button v-b-tooltip.hover class="icon-button text-success" title="Saada" @click="sendEmail">
            <font-awesome-icon icon="paper-plane" />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import getPdfAdditionalDataMixin from './../../components/GetPdf/getPdfAdditionalDataMixin'

  export default {
    name: 'email-sending-modal',
    mixins: [ getPdfAdditionalDataMixin ],
    props: {
      order: Object,
      modalVisible: Boolean,
      type: {
        type: String,
        validator: (val) => ['OFFER_EMAIL_SENT', 'COMPLETE', 'ORDER', 'ORDER_EMAIL_SENT', 'FILE_DEFAULT'].includes(val)
      },
      addPdfDocument: Boolean,
      includedFileType: {
        type: String,
        validator: (val) => ['SALES', 'PRODUCTION', 'PURCHASE'].includes(val),
        default: 'SALES'
      }
    },
    data () {
      return {
        selectedTemplateType: 'TRACKING',
        defaultFormData: {
          text: null,
          preHeader: null
        },
        client: null,
        contacts: null,
        to: null,

        fileType: null,
        fileFormData: {}
      }
    },
    computed: {
      templateTypes () {
        if (this.type === 'OFFER_EMAIL_SENT') {
          return [
            { text: 'Hinnapakkumine', value: this.type }
          ]
        } else if (this.type === 'ORDER' || this.type === 'ORDER_EMAIL_SENT') {
          return [
            { text: 'Jälgimise link', value: this.type }
          ]
        } else if (this.type === 'COMPLETE' ) {
          return [
            { text: 'Tellimus täidetud', value: this.type }
          ]
        } else if (this.type === 'FILE_DEFAULT' ) {
          return [
            { text: 'Universaalne koos failiga', value: 'FILE_DEFAULT' }
          ]
        } else {
          return [
            { text: 'Jälgimise link', value: 'TRACKING' },
            { text: 'Universaalne koos failiga', value: 'FILE_DEFAULT' },
            { text: 'Universaalne', value: 'DEFAULT' }
          ]
        }
      }
    },
    watch: {
      modalVisible (val) {
        if (val && !this.contacts) this.getClientContacts()
        if (this.$company.name === 'LAPI') {
          this.productName = this.order && (this.order.projectName || this.order.object)
        }
      },
      templateTypes (val) {
        this.setDefaultData(val[0].value)
      }
    },
    methods: {
      setDefaultData (type) {
        this.selectedTemplateType = type
        if (type === 'DEFAULT') {
          this.defaultFormData.preHeader = ''
          this.defaultFormData.text = ''
        }
        if (type === 'OFFER_EMAIL_SENT') {
          this.defaultFormData.preHeader = 'Hinnapakkumine'
          this.defaultFormData.text = 'Tere!\n\nKaasa lisatud hinnapakkumine.'
        }
        if (type === 'ORDER') {
          this.defaultFormData.text = 'Tellimus on töösse pandud.'
        }
        if (type === 'ORDER_EMAIL_SENT') {
          this.defaultFormData.text = 'Tellimus on komplekteeritud.'
        }
        if (type === 'COMPLETE') {
          this.defaultFormData.preHeader = 'Tellimus on täidetud!'
          this.defaultFormData.text = 'Tere!\n\nTäitsime rõõmuga Teie tellimuse. Täname, et tellisite meie käest!'
        }
      },
      setFileType (event) {
        this.fileType = event
        this.defaultFormData.preHeader = event.label
        this.defaultFormData.text = 'Tere!\n\nEdastame Teile faili ' + event.label.toLowerCase() + '.'
      },
      async sendEmail () {
        !this.$loader.val('send-email') && this.$validator.validateAll().then(async (result) => {
          if (this.$validator.errors.items.length < 0 || result) {
            this.$loader.start('send-email')
            await this.$email.send(
              await this.getEmailPayload()
            ).then(() => {
              this.$emit('hide')
              this.$notify.show('Saadetud')
            }).catch(err => this.$err.show(err))
            this.$loader.stop('send-email')
          }
        })
      },
      async getClientContacts () {
        const client = !!this.order.client
        const { data } = await this[client ? '$clients' : '$suppliers'][client ? 'getClient' : 'getSupplier']({name: (this.order.client || this.order.supplier.name)})
        this.client = data
        this.contacts = this.$_get(data, 'contacts', [])
        if (this.contacts.length) this.to = this.contacts[0].email
      },
      async getEmailPayload () {
        if (this.selectedTemplateType === 'TRACKING') {
          return {
            template: 'tracking',
            to: this.to,
            lang: 'et',
            parameters: {
              trackingLink: `https://tracking.produxio.io/${ this.$company.name.toLowerCase() }/${this.order._id}`,
              salesSystemNumber: this.order.salesSystemNumber,
              clientName: this.order.client
            }
          }
        }
        if (this.selectedTemplateType === 'DEFAULT') {
          return {
            template: 'universal',
            to: this.to,
            lang: 'et',
            parameters: {
              text: this.defaultFormData.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              salesSystemNumber: this.order.salesSystemNumber,
              preHeader: this.defaultFormData.preHeader
            }
          }
        }

        if (this.selectedTemplateType === 'OFFER_EMAIL_SENT' || this.selectedTemplateType === 'FILE_DEFAULT') {
          // Here is file for email
          const data = {
            company: this.$company,
            order: this.order,
            client: {
              data: this.client,
              contact: this.client.contacts.find(contact => contact.email === this.to)
            },
            supplier: {
              data: this.client,
              contact: this.client.contacts.find(contact => contact.email === this.to)
            },
            additionalData: await this.getAdditionalData(this.fileType.value, this.order, this.fileFormData),
            language: 'EE'
          }

          const file = (await this.$DocumentController.generateDocument(this.fileType.value, data)).data

          const formData = new FormData();
          formData.append('files', new Blob([file]), `${ this.fileType.fileName }.pdf`)
          formData.append('payload', JSON.stringify({
            template: 'universal',
            to: this.to,
            lang: 'et',
            parameters: {
              text: this.defaultFormData.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              salesSystemNumber: this.order.salesSystemNumber || this.order.orderNumber,
              preHeader: this.defaultFormData.preHeader
            }
          }))

          return formData
        }

        if (this.selectedTemplateType === 'ORDER' || this.selectedTemplateType === 'ORDER_EMAIL_SENT') {
          return {
            template: 'tracking',
            to: this.to,
            lang: 'et',
            parameters: {
              text: this.defaultFormData.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              trackingLink: `https://tracking.produxio.io/${ this.$company.name.toLowerCase() }/${this.order._id}`,
              salesSystemNumber: this.order.salesSystemNumber,
              clientName: this.order.client
            }
          }
        }

        if (this.selectedTemplateType === 'COMPLETE') {
          return {
            template: 'universal',
            to: this.to,
            lang: 'et',
            parameters: {
              text: this.defaultFormData.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              salesSystemNumber: this.order.salesSystemNumber,
              preHeader: this.defaultFormData.preHeader
            }
          }
        }

      }
    }
  }
</script>
<style lang="scss" scoped>
  textarea.form-control {
    max-height: 300px;
  }
</style>
