<template>
  <b-col :xl="cols">
    <div class="stats-title">
      <h5 class="mb-0 text-info"><strong>{{ title }}</strong></h5>
      <h5 class="mb-2 text-gray"><strong>{{ text }}</strong></h5>
    </div>
  </b-col>
</template>
<script>
  export default {
    name: 'faf-stats-title',
    props: {
      cols: {
        type: [String, Number],
        default: '2'
      },
      title: {
        type: String,
        required: true
      },
      text: {
        type: [String, Number],
        required: true
      }
    }
  }
</script>
<style lang="scss" scoped>
  .stats-title {
    & > .text-info {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 22px;
    }

    & > .text-gray {
      font-size: 16px;
      line-height: 16px;
    }
  }
</style>
