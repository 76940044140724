<template>
  <div style="overflow: hidden" :class="{ 'white-content': $user.getTheme() === 'light' }">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'app',
    watch: {
      '$versionMismatch.value': {
        handler (val) {
          if (val) {
            this.$bvToast.toast(`Teil on vanem versioon tarkvarast! Proovige teha lehele refreshi`, {
              title: 'Hoiatus',
              noAutoHide: true,
              noCloseButton: true,
              solid: true
            })
          }
        },
        immediate: true

      }
    },
    mounted () {
      document.getElementById("initialLoading").remove();
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
      console.log(this.$versionMismatch)
    },
    methods: {
      toggleNavOpen () {
        const root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
