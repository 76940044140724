<template>
  <div class="dataTables_wrapper">
    <div class="table-responsive">
      <b-table
        :per-page="perPage"
        :page-options="pageOptions"
        :current-page="currentPage"
        :sort-compare="$sortDate"
        :items="items"
        :fields="fields"
        :busy="$loader.val('purchase-orders')"
        striped
        show-empty
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(deliveryDate)="data">
          {{ $dayjs(data.item.deliveryDate).format('DD.MM.YYYY') }}
        </template>
        <template v-slot:cell(actions)="row">
          <b-button v-b-tooltip.hover v-b-tooltip.left class="icon-button text-success" title="Lõpeta tellimus" @click="completePurchaseOrder(row.item._id || row.item.id)">
            <font-awesome-icon icon="check-circle" />
          </b-button>
          <b-button v-b-tooltip.hover v-b-tooltip.left :class="'icon-button ' + partialStatusColor(row.item.status)" title="Tellimus osaliselt kohal?" @click="setOrderPartiallyHere(row.item)">
            <font-awesome-icon icon="boxes" />
          </b-button>
          <b-button v-b-tooltip.hover v-b-tooltip.left class="icon-button text-danger" title="Tühista tellimus" @click="annullPurchaseOrder(row.item._id || row.item._id)">
            <font-awesome-icon icon="minus-circle" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            v-b-modal.modal-detailed-purchase-order
            class="icon-button text-info"
            title="Detailne vaade"
            @click="selectedDetailedItem = row.item"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-if="totalRows > 10"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
    >
      <template v-slot:first-text>
        <font-awesome-icon icon="angle-double-left" />
      </template>
      <template v-slot:prev-text>
        <font-awesome-icon icon="angle-left" />
      </template>
      <template v-slot:next-text>
        <font-awesome-icon icon="angle-right" />
      </template>
      <template v-slot:last-text>
        <font-awesome-icon icon="angle-double-right" />
      </template>
    </b-pagination>
    <faf-add-purchase-modal modal-name="modal-add-new-purchase" @addOrder="addOrder" />
    <faf-detailed-purchase-modal v-if="selectedDetailedItem" :id="selectedDetailedItem._id || selectedDetailedItem.id" @close="selectedDetailedItem = null" />
  </div>
</template>
<script>
  import FafAddPurchaseModal from './AddPurchaseModal'
  import FafDetailedPurchaseModal from './DetailedPurchaseModal'

  export default {
    name: 'faf-purchase',
    components: {
      FafAddPurchaseModal,
      FafDetailedPurchaseModal
    },
    data () {
      return {
        totalRows: 0,
        partialStatusColor: (status) => status === 'ORDER' ? '' : 'text-success',
        currentPage: 1,
        perPage: 10,
        selectedDetailedItem: null,
        pageOptions: [5, 10, 15],
        fields: [
          {
            key: 'orderNumber',
            label: 'Tellimuse Number',
            sortable: true
          },
          {
            key: 'deliveryDate',
            label: 'Tarneaeg',
            sortable: true
          },
          {
            key: 'supplier.name',
            label: 'Tarnija',
            sortable: true
          },
          {
            key: 'supplier.name',
            label: 'Tarnija',
            sortable: true
          },
          {
            key: 'supplier.contact',
            label: 'Kontakt',
            formatter: (value, key, item) => `${value.name}, ${value.email}, ${value.phone}`
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        items: [],
        selected: [],
        tags: []
      }
    },
    async created () {
      await this.getPurchaseOrder()
    },
    methods: {
      rowSelected (items) {
        this.selected = items
      },
      async getPurchaseOrder () {
        this.$loader.start('purchase-orders')
        await this.$purchase.getPurchaseOrders().then(rs => {
          this.totalRows = rs.data.length
          this.items = rs.data
        })
        this.$loader.stop('purchase-orders')
      },
      addOrder (payload) {
        this.$loader.start()
        this.$purchase.addPurchaseOrder(payload).then( async () => {
          await this.getPurchaseOrder()
          this.$bvModal.hide('modal-add-new-purchase')
          this.$notify.show('Uus ostutellimus edukalt lisatud!')
          this.$loader.stop()
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop()
        })
      },
      setOrderPartiallyHere (payload) {
        this.$loader.start()
        const status = payload.status === 'ORDER' ? 'PARTIAL_COMPLETE' : 'ORDER'
        this.$purchase.editPurchaseOrder(payload._id || payload.id, {status}).then(() => {
          payload.status = status
          this.$loader.stop()
        }).catch(err => {
          this.$err.show(err)
          this.$loader.stop()
        })
      },
      annullPurchaseOrder (id) {
        this.$confirm.show('Olete kindel, et soovite ostutellimuse tühistada?', null, answer => {
          if (answer) {
            this.$loader.start()
            this.$purchase.annullPurchaseOrder(id).then( async () => {
              await this.getPurchaseOrder()
              this.$cardReset('purchase-suggestions')
              this.$bvModal.hide('modal-add-new-purchase')
              this.$notify.show('Ostutellimus on tühistatud!')
              this.$loader.stop()
            }).catch(err => {
              this.$err.show(err)
              this.$loader.stop()
            })
          }
        })
      },
      completePurchaseOrder (id) {
        this.$confirm.show('Olete kindel, et tellimus on täidetud?', null, (answer) => {
          if (answer) {
            this.$loader.start()
            this.$purchase.completePurchaseOrder(id).then(async () => {
              await this.getPurchaseOrder()
              this.$cardReset('inventory')
              this.$bvModal.hide('modal-add-new-purchase')
              this.$notify.show('Ostutellimus on täidetud')
              this.$loader.stop()
            }).catch(err => {
              this.$err.show(err)
              this.$loader.stop()
            })
          }
        })
      }
    }
  }
</script>
