<template>
  <faf-inventory />
</template>
<script>
  import { FafInventory } from '@faf/faf-ui'
  export default {
    name: 'inventory-card',
    components: {
      FafInventory
    }
  }
</script>
