<template>
  <div>
    <faf-filters
      :fields="filterFields"
      :cols="2"
      :url-cache="true"
      @search-change="searchChange"
      @getOptions="getAllSuggestions"
      @filter="getAllQualityReports({ filters: $event })"
    />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('quality-reports-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4 mr-2"
            title="Vaata/Muuda"
            @click="$refs['add-edit-quality-reports'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            :disabled="$loader.val('document-download')"
            class="icon-button text-warning mr-4"
            style="padding: 0; font-size: 18px;"
            title="Lae alla"
            @click="download(data.item)"
          >
            <font-awesome-icon icon="file-pdf" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getAllQualityReports({ filters: $route.query.filter ? JSON.parse($route.query.filter) : {}, page: $event }, true)"
    />
    <add-edit-quality-report ref="add-edit-quality-reports" :key="addQualityReportModal" @hide="addQualityReportModal = !addQualityReportModal; getAllQualityReports(false, true)" />
  </div>
</template>
<script>
  import AddEditQualityReport from './AddEditQualityReport'
  import { downloadBinaryFile } from '../../components/GetPdf/downloadFile'

  const statusOptions = [
    { label: 'Avatud', value: 'OPEN'},
    { label: 'Suletud', value: 'CLOSED'},
    { label: 'Ettepanek', value: 'PROPOSAL'}
  ]
  const typeOptions = [
    { label: 'Väline', value: 'EXTERNAL'},
    { label: 'Sisene', value: 'INTERNAL'},
    { label: 'Tarnija', value: 'SUPPLIER'},
    { label: 'Parendus', value: 'IMPROVEMENT'}
  ]

  export default {
    name: 'faf-quality-reports-table',
    components: {
      AddEditQualityReport
    },
    data () {
      return {
        addQualityReportModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        items: [],
        statusOptions,
        typeOptions,
        fields: [
          {
            key: 'entryId',
            label: 'KV'
          },
          {
            key: 'status',
            label: 'Staatus',
            formatter: value => (this.statusOptions.find(s => s.value === value)).label
          },
          {
            key: 'type',
            label: 'Tüüp',
            formatter: value => (this.typeOptions.find(s => s.value === value)).label
          },
          {
            key: 'client',
            label: 'Klient'
          },
          {
            key: 'createdDate',
            label: 'Sisestatud KP.',
            formatter: value => this.$dayjs(value).format('DD.MM.YYYY')
          },
          {
            key: 'deadline',
            label: 'Tähtaeg',
            formatter: value => value ? this.$dayjs(value).format('DD.MM.YYYY') : '-'
          },
          {
            key: 'contacts',
            label: 'Kontakt',
            formatter: value => value ? value.name : '-'
          },
          {
            key: 'salesSystemNumber',
            label: 'Tellimuse/Projekti ID'
          },
          {
            key: 'notes',
            label: 'Probleemi kirjeldus',
            tdClass: 'faf-max-width-100'
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'entryId', placeholder: 'ID', options: [] },
          { type: 'multiselect', name: 'status', placeholder: 'Staatus', options: statusOptions.map(s => s.label) },
          { type: 'multiselect', name: 'type', placeholder: 'Tüüp', options: typeOptions.map(t => t.label) },
          { type: 'multiselect', name: 'client', placeholder: 'Klient', options: [] }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getAllQualityReports(this.$route.query.filter ? {filters: JSON.parse(this.$route.query.filter)} : {})
    },
    methods: {
      async getAllSuggestions (event) {
        await this.filterFields.map(async (field) => {
          if (field.type !== 'status' && field.name !== 'type') {
            if (field.options && !field.options.length && event === field.name) {
              field.options = (await this.$qualityReports.getSuggestions(field.name)).data.filter(n => n)
            }
          }
        })
      },
      async initialize () {
        try {
          this.filterTable({query: JSON.parse(this.$route.query.filter), page: this.$route.query.page || 1})
        } catch (error) {
          this.filterTable({query: {}, page: this.$route.query.page || 1})
        }
      },
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getClientNames({ [e.name]: e.event })).data
      },
      async getAllQualityReports (event, pageChange) {
        const payload = this.$_cloneDeep(event)
        this.$loader.start('quality-reports-table')
        let page = (event && event.page) || this.currentPage
        if (!pageChange) {
          page = 1
        }

        if (this.$_get(payload, 'filters.status')) payload.filters.status = statusOptions.find(s => s.label === event.filters.status).value
        if (this.$_get(payload, 'filters.type')) payload.filters.type = typeOptions.find(s => s.label === event.filters.type).value

        const { data } = await this.$qualityReports.findQualityReports({page, limit: this.perPage, filters: (payload && payload.filters)})
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('quality-reports-table')
      },

      download (item) {
        this.$loader.start('document-download')
        this.$DocumentController.generateDocument('QUALITY_REPORT', { ...item, company: this.$company, language: 'EE' }).then(rs => {
          const headers = {
            'Content-Type': 'application/pdf'
          }
          downloadBinaryFile(rs.data, headers, `KVALITEEDI_ARUANNE_${item.entryId}.pdf`)
          this.$loader.stop('document-download')
        }).catch(err => {
          this.$loader.stop('document-download')
          this.$err.show(err)
        })
      }
    }
  }
</script>
