<template>
  <b-modal
    id="add-edit-material"
    :title="modalTitle"
    size="lg"
    ok-title="Lisa"
    cancel-title="Tagasi"
    scrollable
    @hide="$emit('hide')"
  >
    <div>
      <h4 class="mt-0 text-info">
        Materjal
      </h4>
      <b-row class="px-2">
        <b-col cols="6" class="px-1">
          <label>Nimi</label>
          <input
            v-model="formData.name"
            v-validate="'required'"
            v-bind="$requiredInputProps('name')"
            placeholder="Nimi"
          >
        </b-col>
        <b-col cols="6" class="px-1">
          <label>Kirjeldus</label>
          <multiselect
            v-model="formData.description"
            v-faf-autocomplete-off
            v-bind="$multiselectProps('description', false)"
            :options="descriptionSuggestions.data"
            placeholder="Kirjeldus"
            @tag="addTag('descriptionSuggestions', `formData.description`, $event)"
            @search-change="getDescriptionSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col :cols="colSize" class="px-1">
          <label>Paksus</label>
          <input
            v-model="formData.width"
            :name="'width'"
            class="form-control"
            placeholder="Paksus"
            type="number"
          >
        </b-col>
        <b-col :cols="colSize" class="px-1">
          <label>Jäägi %</label>
          <input
            v-model="formData.leftoverPercentage"
            :name="'leftoverPercentage'"
            class="form-control"
            placeholder="Jäägi %"
            type="number"
          >
        </b-col>
        <b-col :cols="2" class="px-1">
          <label>Ühik</label>
          <select
            v-model="formData.unit"
            v-validate="'required'"
            v-bind="$requiredInputProps('unit')"
            placeholder="Ühik"
          >
            <option v-for="(option, optionIndex) in materialUnitOptions" :key="'option-' + optionIndex" :value="option">
              <span v-if="option === 'm2'">m&sup2;</span>
              <span v-else>{{ option }}</span>
            </option>
          </select>
        </b-col>
        <b-col :cols="colSize" class="px-1">
          <label>Erikaal</label>
          <input
            v-model="formData.specialWeight"
            v-validate="'required'"
            v-bind="$requiredInputProps('specialWeight')"
            :name="'specialWeight'"
            class="form-control"
            placeholder="Erikaal"
            type="number"
          >
        </b-col>
        <b-col :cols="colSize" class="px-1">
          <label>Hind</label>
          <input
            v-model="formData.price"
            :name="'price'"
            class="form-control"
            placeholder="Hind"
            type="number"
          >
        </b-col>
        <b-col :cols="colSize" class="px-1">
          <label>m<sup>2</sup> kaal</label>
          <input
            v-model="formData.multiplier"
            v-bind="$requiredInputProps('multiplier')"
            :name="'multiplier'"
            class="form-control"
            placeholder="m2 kaal"
            type="number"
          >
        </b-col>
      </b-row>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('add-edit-material')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('add-edit-material')" class="icon-button text-success" title="Salvesta" @click="submit">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import { materialUnitOptions } from './../Sales/common/productHelpers'

  export default {
    name: 'add-edit-material',
    data () {
      return {
        formData: {
          unit: 'tk'
        },
        descriptionSuggestions: { data: [] },
        materialUnitOptions,
        colSize: this.$can(['action', 'leftoverPercentage']) ? 2 : 3
      }
    },
    computed: {
      modalTitle () {
        return this.formData._id ? 'Muuda materjali' : 'Lisa uus materjal'
      }
    },
    watch: {
      formData (val) {
        val.name && this.$bvModal.show('add-edit-material')
      }
    },
    created () {
      this.getDescriptionSuggestions = this.$_debounce(this.getDescriptionSuggestions, 700)
    },
    methods: {
      async getDescriptionSuggestions (e) {
        if (e.length >= 2) {
          this.descriptionSuggestions = await this.$suggestions.getMaterialDescriptionNames({name: e})
        }
      },
      addNewRow (index) {
        this.formData.contacts.splice(index + 1, 0, { name: '', email: '', phone: '' })
      },
      deleteRow (index) {
        this.formData.contacts.splice(index, 1)
      },
      addTag (type, model, val) {
        this[type].data.push(val)
        this.$_set(this, model, val)
      },
      async editMaterial () {
        return await this.$materials.updateMaterial(this.formData._id, this.formData)
      },
      async addMaterial () {
        return await this.$materials.addMaterial(this.formData)
      },
      async submit () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('add-edit-material')
          await this[this.formData._id ? 'editMaterial' : 'addMaterial']()
            .then(() => {
              this.$bvModal.hide('add-edit-material')
              this.$emit('material-added')
              // this.$cardReset('materials')
            })
            .catch(err => this.$err.show())
          this.$loader.stop('add-edit-material')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  label {
    font-size: 0.75rem;
    margin-top: 10px;
    margin-bottom: 0px;
  }
</style>
