<template>
  <b-modal
    :visible="!!editModal"
    title="Muuda"
    size="s"
    title-tag="h4"
    @hide="$emit('hide')"
  >
    <form>
      <b-row class="px-2">
        <b-col v-if="editModal === 'CALCULATION'" cols="12" class="mt-2 px-1">
          <label for="transport-price">Transpordi hind</label>
          <b-input-group append="€" class="text-dark">
            <b-form-input
              v-validate="'required'"
              v-model.trim="form.transport.price"
              class="form-control"
              name="transport-price"
              type="number"
            />
          </b-input-group>
        </b-col>
        <b-col v-if="editModal === 'DISCOUNT'" cols="12" class="mt-2 px-1">
          <label for="discount">Allahindlus</label>
          <b-input-group append="€" class="text-dark">
            <b-form-input
              v-validate="'required'"
              v-model.trim="form.discount"
              class="form-control"
              name="discount"
              type="number"
            />
          </b-input-group>
        </b-col>
        <b-col v-if="editModal === 'TRANSPORT'" cols="12" class="mt-2 px-1">

          <label>Tarne aadress</label>
          <multiselect
            v-model="form.transport.address"
            :options="addressOptions"
            :name="'transport-address'"
            v-bind="$multiselectProps('transport-address')"
            active
            @tag="addNewAddress"
            @search-change="searchAddressSuggestions"
          >
            <template slot="noOptions" >
              Alusta trükkimist...
            </template>
            <template slot="noResult" >
              Tulemused puuduvad
            </template>
          </multiselect>
        </b-col>
        <b-col v-if="editModal === 'TRANSPORT'" cols="12" class="mt-2 px-1">
          <label for="invoice">Arve nr</label>
          <input
            v-model="form.invoice"
            :class="{ 'form-control': true }"
            name="invoice"
          />
        </b-col>
        <b-col v-if="editModal === 'TRANSPORT'" cols="12" class="mt-2 px-1">
          <label for="detailedNotes">Sisesed märkmed</label>
          <b-form-textarea
            v-model="form.detailedNotes"
            rows="6"
            name="detailedNotes"
          />
        </b-col>
        <b-col v-if="editModal === 'REMAINS'" cols="12" class="mt-2 px-1">
          <label for="remains-price">Jäägi hind</label>
          <input
            v-validate="'required'"
            v-model="form.remainsPrice"
            :class="{ 'form-control': true }"
            name="remains-price"
            type="number"
          />
        </b-col>
        <b-col v-if="editModal === 'NOTES'" cols="12" class="mt-2 px-1">
          <label for="notes">Märkmed</label>
          <b-form-textarea
            v-model.trim="form.notes"
            rows="6"
            name="notes"
          />
        </b-col>
      </b-row>
    </form>
    <div slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">

        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$emit('hide')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('detailed-sale')" class="icon-button text-success" title="Salvesta projekt" @click="submit">
          <font-awesome-icon icon="save" />
        </b-button>

      </div>
    </div>
  </b-modal>
</template>
<script>

  export default {
    name: 'faf-detailed-edit-modal',
    inject: ['$validator'],
    props: {
      editModal: [String, Boolean],
      formData: Object
    },
    data () {
      return {
        form: { transport: {} },
        addressOptions: []
      }
    },
    watch: {
      editModal () {
        this.form = this.$_cloneDeep(this.formData)
      }
    },
    mounted () {
      this.searchAddressSuggestions = this.$_debounce(this.searchAddressSuggestions, 800)
    },
    methods: {
      async searchAddressSuggestions (value) {
        this.addressOptions = (await this.$suggestions.getAddress(value)).data
      },
      addNewAddress (value) {
        this.addressOptions.push(value)
        this.form.transport.address = value
      },
      submit () {
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            return
          }
          this.$emit('submit', this.form)
        })
      }
    }
  }
</script>
