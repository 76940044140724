<template>
  <div class="content-wrapper">
    <error />
    <confirmation />
    <notice />
    <context-menu>
      <div class="container-fluid">
        <router-view :key="$route.name" />
      </div>
    </context-menu>
  </div>
</template>
<script>
  import Error from './../components/Notifications/Error'
  import Confirmation from './../components/Notifications/Confirmation'
  import Notice from './../components/Notifications/Notice'

  export default {
    components: {
      Error,
      Confirmation,
      Notice
    }
  };
</script>
<style lang="scss" scoped>
</style>
