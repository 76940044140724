import Ajv from 'ajv';

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['products', 'type'],
  properties: {
    products: {
      type: 'array',
      required: ['quantity'],
      properties: {
        quantity: {
          type: 'object',
          required: ['amount'],
          properties: {
            amount: { type: 'number' }
          }
        }
      }
    },
    type: {
      type: 'string',
      enum: [
        'amount', 'doneAmount'
      ]
    }
  }
}

const test = ajv.compile(schema)

export default async function __getAllProductsTotalAmount ({products, type}) {
  if (!test({products, type})) {
    throw { function: '__getAllProductsTotalAmount', error: test.errors }
  }

  return products.map(p => {
    return p.quantity[type]
  }).reduce((a, b) => +a + +b, 0)
}
