export const productFields = [
  { key: 'name', label: 'Toote nimetus' },
  { key: 'quantity.amount', label: 'Kogus' },
  { key: 'price', label: 'Hind', formatter: value => `${value} €` },
  { key: 'surfaceTreatment', label: 'Viimistlus' },
  { key: 'useNetPrice', label: 'Kasuta omahinda' },
  { key: 'notes', label: 'Märkmed' }
]

export const emptyMaterial = {
  diagramNumber: '1',
  name: '',
  specialWeight: '1',
  amount: '1',
  price: '0',
  unit: 'kg',
  size: '',
  actualSize: ''
}

export const emptyWorkcenter = {
  queue: '1',
  name: '',
  specialWeight: '1',
  leadTime: '1',
  setupTime: '0',
  amount: '1',
  price: '0.42',
  unit: 'min',
  linkedMaterials: []
}

export const emptyProduct = () => {
  return {
    quantity: {},
    name: '',
    price: 0,
    useNetPrice: localStorage.orderSettings ? (JSON.parse(localStorage.orderSettings).defaultUseNetPrice || false) : false,
    notes: '',
    materials: [ emptyMaterial ],
    workcenters: [ emptyWorkcenter ]
  }
}

export const productValidationFields = [
  'product-name-',
  'product-quantity-',
  'product-price-'
]

export const materialValidationFields = [
  'material-diagramNumber-',
  'material-name-'
]

export const workcenterFields = [
  { key: 'queue', label: 'Nr' },
  { key: 'name', label: 'Nimi' },
  { key: 'team', label: 'Tiim' },
  { key: 'workTime', label: 'Töömaht', formatter: (value, key, item) => `${value} ${item.unit}` },
  { key: 'leadTime', label: 'Läbilase', formatter: value => `${value} päev` },
  { key: 'setupTime', label: 'Seadistusaeg' },
  { key: 'price', label: 'Hind', formatter: (value, key, item) => `${value} €/${item.unit}` },
  { key: 'notes', label: 'Märkmed' }
]

export const materialUnitOptions = ['kg', 'mm', 'm2', 'tk', 'L', 'm']

export const workcenterUnitOptions = ['h', 'min', 'tk', 'kg', 'm2', 'm']
