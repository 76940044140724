import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function getSuggestions (field) {
  return axios({
    url: `/api/typeahead/${field}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getArchiveSuggestions (field, value) {
  return axios({
    url: `/api/archive-typeahead/${field}?value=${value}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$typeahead = {
    getSuggestions,
    getArchiveSuggestions
  }
}

export {
  getSuggestions,
  getArchiveSuggestions
}
