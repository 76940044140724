import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function upload (file) {
  const data = new FormData()
  data.append('file', file)
  return axios({
    url: `/upload`,
    method: 'POST',
    headers: {
      Authorization: token(),
      'X-FAF-Organization': `LAPI`
    },
    data
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$import = {
    upload
  }
}

export {
  upload
}
