<template>
  <div>
    <faf-filters
      :fields="filterFields"
      :cols="2"
      :url-cache="true"
      @getOptions="getAllSuggestions"
      @filter="getAllManagementSystemReports({ filters: $event })"
    />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('management-system-reports-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4 mr-2"
            title="Vaata/Muuda"
            @click="$refs['add-edit-management-system-reports'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
          <b-button
            v-can="['see', 'FileManager']"
            v-b-tooltip.hover
            v-b-modal.file-manager
            :disabled="!data.item._id"
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info"
            title="Dokumendid"
            @click="fileManager = data.item"
          >
            <font-awesome-icon icon="folder-open" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-file-manager v-if="fileManager" :order-object-id="fileManager._id" :pre-loaded-documents="fileManager.documents" type="MANAGEMENT_SYSTEM_REPORTS" @close="fileManager = null" />
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getAllManagementSystemReports({ filters: $route.query.filter ? JSON.parse($route.query.filter) : {}, page: $event }, true)"
    />
    <add-edit-management-system-report ref="add-edit-management-system-reports" :key="addManagementSystemReportModal" @hide="addManagementSystemReportModal = !addManagementSystemReportModal; getAllManagementSystemReports(false, true)" />
  </div>
</template>
<script>
  import AddEditManagementSystemReport from './AddEditManagementSystemReport'

  const statusOptions = [
    { label: 'Avatud', value: 'OPEN'},
    { label: 'Suletud', value: 'CLOSED'},
    { label: 'Ettepanek', value: 'PROPOSAL'}
  ]
  const typeOptions = [
    { label: 'Väline', value: 'EXTERNAL'},
    { label: 'Sisene', value: 'INTERNAL'},
    { label: 'Tarnija', value: 'SUPPLIER'},
    { label: 'Parendus', value: 'IMPROVEMENT'}
  ]

  export default {
    name: 'faf-management-system-reports-table',
    components: {
      AddEditManagementSystemReport
    },
    data () {
      return {
        addManagementSystemReportModal: false,
        fileManager: null,
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        items: [],
        statusOptions,
        typeOptions,
        fields: [
          {
            key: 'entryId',
            label: 'JS'
          },
          {
            key: 'name',
            label: 'Dokumendi nimi'
          },
          {
            key: 'type',
            label: 'Tüüp'
          },
          {
            key: 'version',
            label: 'Versioon'
          },
          {
            key: 'createdDate',
            label: 'Sisestatud KP.',
            formatter: value => this.$dayjs(value).format('DD.MM.YYYY')
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'entryId', placeholder: 'ID', options: [] },
          { type: 'multiselect', name: 'name', placeholder: 'Dokumendi nimi', options: [] },
          { type: 'multiselect', name: 'type', placeholder: 'Tüüp', options: [] }
        ]
      }
    },
    async created () {
      await this.getAllManagementSystemReports(this.$route.query.filter ? {filters: JSON.parse(this.$route.query.filter)} : {})
    },
    methods: {
      async getAllSuggestions (event) {
        await this.filterFields.map(async (field) => {

          if (field.options && !field.options.length && event === field.name) {
            field.options = (await this.$managementSystemReports.getSuggestions(field.name)).data.filter(n => n)
          }

        })
      },
      async initialize () {
        try {
          this.filterTable({query: JSON.parse(this.$route.query.filter), page: this.$route.query.page || 1})
        } catch (error) {
          this.filterTable({query: {}, page: this.$route.query.page || 1})
        }
      },
      async getAllManagementSystemReports (event, pageChange) {
        const payload = this.$_cloneDeep(event)
        this.$loader.start('management-system-reports-table')
        let page = (event && event.page) || this.currentPage
        if (!pageChange) {
          page = 1
        }

        const { data } = await this.$managementSystemReports.findManagementSystemReports({page, limit: this.perPage, filters: (payload && payload.filters)})
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('management-system-reports-table')
      }
    }
  }
</script>
