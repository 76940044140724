<template>
  <div>
    <b-table
      v-if="items.length"
      small
      responsive
      hover
      striped
      sticky-header="600px"
      :items="items"
      :fields="fields"
      :tbody-tr-class="rowClass"
      class="reports-table"
      :class="{ 'table-sticky-dark': this.$user.getTheme() === 'dark' }"
    >
      <template #head()="data">
        <p class="font-weight-bolder" style="min-height: 18px">
          {{ data.label }}
        </p>
        <p class="font-weight-bolder text-lowercase" style="min-height: 18px">
          {{ data.field.secondLabel }}
        </p>
      </template>
    </b-table>
    <b-skeleton-table
      v-else
      :rows="25"
      :columns="13"
      :table-props="{ striped: true }"
      animation="wave"
    />
  </div>
</template>
<script>
  export default {
    name: 'product-report-table',
    components: {
    },
    props: {
      filters: Object,
    },
    data () {
      return {
        fields: [
          {
            key: 'salesSystemNumber',
            label: 'ID',
            tdClass: "text-nowrap"
          },
          {
            key: 'products.workcenters.name',
            label: 'Töökeskus',
            tdClass: "text-nowrap"
          },
          {
            key: 'products.name',
            label: 'Toote nimi',
            tdClass: "text-nowrap"
          },
          {
            key: 'workTimeMinute',
            label: 'Kogus',
            secondLabel: '[min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' min', 0)
          },
          {
            key: 'workTimeHour',
            label: '',
            secondLabel: '[tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' h', 1)
          },
          {
            key: 'priceMinute',
            label: 'Hind',
            secondLabel: '[€/min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' €/min', 2)
          },
          {
            key: 'priceHour',
            label: '',
            secondLabel: '[€/tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' €/h', 2)
          },
          {
            key: 'totalPrice',
            label: 'Summa',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' €' : '-'
          },
          {
            key: 'workTimeMinuteActual',
            label: 'Tegelik kogus',
            secondLabel: '[min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' min', 0)
          },
          {
            key: 'workTimeHourActual',
            label: '',
            secondLabel: '[tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' h', 0)
          },
          {
            key: 'effectivity',
            label: 'Effektiivsus',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' %', 2)
          },
          {
            key: 'recommendedPrice',
            label: 'Soovitatav hind',
            secondLabel: '[€/min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' €/min', 2)
          },
          {
            key: 'recommendedPriceHour',
            label: '',
            secondLabel: '[€/tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => this.format(value, item.otherUnits, ' €/h', 2)
          }
        ],
        items: []
      }
    },
    async created () {
      await this.generateReport()
    },

    methods: {
      rowClass (item, type) {
        if (!item || type !== 'row') return
        if (item.accent) return 'row-accent'
      },
      format (value, otherUnits, unit, spaces) {
        spaces = spaces || 0
        if (otherUnits) {
          return value ? value.toFixed(spaces) : '-'
        } else {
          return value ? value.toFixed(spaces) + unit : '-'
        }
      },
      async generateReport () {
        const filters = {
          ...this.filters.status && { status: this.filters.status },
          ...this.filters.startDate && { startDate: this.filters.startDate },
          ...this.filters.endDate && { endDate: this.filters.endDate },
          ...this.filters.salesSystemNumber && { salesSystemNumber: this.filters.salesSystemNumber },
          ...this.filters.orderId && { orderId: this.filters.orderId },
          ...this.filters.projectName && { projectName: this.filters.projectName },
          ...this.filters.client && { client: this.filters.client },
          ...this.filters.productName && { 'products.name': this.filters.productName },
          ...this.filters.workcenterName && { 'products.workcenters.name': this.filters.workcenterName },
          ...this.filters.catalogue && { client: this.filters.catalogue },
          ...this.filters.object && { client: this.filters.object },
        }

        this.items = (await this.$reports.getWorkcenterReport(filters)).data
      }
    }
  }
</script>
<style lang="scss">
.row-accent {
  font-weight: 800;
  & + tr td {
    border-color: rgba(255, 255, 255, 0.5)!important;
    padding-top: 10px!important;
  }
}
</style>
