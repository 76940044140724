import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`
const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getAllProducts (query) {
  return axios({
    url: `/api/product?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addProduct (data) {
  return axios({
    url: `/api/product`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function updateProduct (data) {
  return axios({
    url: `/api/product`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function deleteProduct (id) {
  return axios({
    url: `/api/product/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$products = {
    getAllProducts,
    addProduct,
    updateProduct,
    deleteProduct
  }
}

export {
  getAllProducts,
  addProduct,
  updateProduct,
  deleteProduct
}
