<template>
  <b-modal
    :id="modalName"
    size="xl"
    title="Lisa uus ostutellimus"
    ok-title="Lisa"
    cancel-title="Tagasi"
    @show="$emit('show')"
    @hide="close"
  >
    <div>
      <h4 class="mt-0 text-info">
        Tarnija
      </h4>
      <b-row>
        <b-col :class="{ 'field-with-tooltip': newSupplier }" xl="3" md="3" cols="4" class="pr-xl-1 position-relative">
          <label>Tarnija</label>
          <input
            v-if="!$can(['action', 'FilterSuppliers'])"
            v-model="formData.supplier.name"
            v-validate="'required'"
            v-bind="$requiredInputProps('supplier-name')"
          >
          <multiselect
            v-if="$can(['action', 'FilterSuppliers'])"
            v-model="formData.supplier.name"
            v-validate="'required'"
            v-faf-autocomplete-off
            :options="supplierNameSuggestions.data"
            v-bind="$multiselectProps('supplier-name')"
            name="supplier-name"
            no-options="Alusta trükkimist..."
            @tag="addTag(supplierNameSuggestions.data, 'formData.supplier.name', $event)"
            @input="newSupplier = false"
            @search-change="getSupplierNameSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
          <b-button
            v-if="newSupplier && $can(['action', 'FilterSuppliers'])"
            v-b-tooltip.hover
            title="Lisa uus tarnija"
            class="icon-tooltip icon-button text-success"
            style="cursor: pointer"
            @click="$refs['add-edit-supplier'].formData = $_cloneDeep({ ...supplierFormData, ...formData.supplier })"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
        </b-col>
        <b-col xl="2" md="2" cols="4" class="pr-xl-1">
          <label>Kontakti nimi</label>
          <input
            v-if="!$can(['action', 'FilterSuppliers'])"
            v-model="formData.supplier.contact.name"
            v-validate="'required'"
            v-bind="$requiredInputProps('supplier-contact')"
          >
          <multiselect
            v-if="$can(['action', 'FilterSuppliers'])"
            v-model="formData.supplier.contact.name"
            v-validate="'required'"
            v-faf-autocomplete-off
            :options="supplierContacts.data"
            v-bind="$multiselectProps('supplier-contact')"
            name="supplier-contact"
            @tag="addTag(supplierContacts.data, 'formData.supplier.contact.name', $event)"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col xl="2" md="2" cols="4" class="pr-xl-1">
          <label>Telefon</label>
          <input
            v-model="formData.supplier.contact.phone"
            v-validate="'required'"
            v-bind="$requiredInputProps('supplier-phone')"
          >
        </b-col>
        <b-col xl="3" md="3" cols="4" class="pr-xl-1">
          <label>E-mail</label>
          <input
            v-model="formData.supplier.contact.email"
            v-validate="'required|email'"
            v-bind="$requiredInputProps('supplier-email')"
          >
        </b-col>
        <b-col xl="2" md="2" cols="4" class="pr-xl-1">
          <label>Tarneaeg</label>
          <datepicker
            v-model="formData.deliveryDate"
            v-validate="'required'"
            v-bind="$datepickerProps('delivery-date')"
            name="delivery-date"
          />
        </b-col>
      </b-row>
      <hr />
      <h4 class="mt-3 text-info">
        Tellimus
      </h4>
      <b-row style="margin-right: 90px;">
        <b-col xl="3" md="2" cols="4" class="pr-xl-1">
          <label class="mb-0">Materjal</label>
        </b-col>
        <b-col xl="1" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">Vajadus</label>
        </b-col>
        <b-col xl="1" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">Ühik</label>
        </b-col>
        <b-col xl="1" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">Baashind €</label>
        </b-col>
        <b-col xl="1" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">Erikaal</label>
        </b-col>
        <b-col xl="1" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">Summa €</label>
        </b-col>
        <b-col xl="2" md="2" cols="4" class="px-xl-1">
          <label class="mb-0">{{ $can(['orderFields', 'salesSystemNumber']) ? 'ID' : 'Projekti number' }}</label>
        </b-col>
        <b-col class="px-xl-1">
          <label class="mb-0">Märkused</label>
        </b-col>
      </b-row>
      <!-- Repeater -->
      <faf-repeater
        :items="orders"
        inline-icons
        @add-row="addNewRow"
        @duplicate-row="duplicateRow($event)"
        @delete-row="deleteRow"
      >
        <template slot="repeaterSlot" slot-scope="row">
          <b-row>
            <b-col xl="3" md="2" cols="4" class="pr-xl-1">
              <multiselect
                v-model="orders[row.index].name"
                v-validate="'required'"
                v-faf-autocomplete-off
                v-bind="$multiselectProps('material-' + row.index)"
                :options="materialSuggestions.data"
                placeholder="Materjal"
                @input="prefillMaterial($event, row.index)"
                @tag="addTag(materialSuggestions.data, 'orders[row.index].name', $event)"
              />
            </b-col>
            <b-col xl="1" md="2" cols="4" class="px-xl-1">
              <input
                v-model="orders[row.index].demandInKg"
                v-validate="'required'"
                v-bind="$requiredInputProps('demandInKg-' + row.index)"
                placeholder="Vajadus"
                type="number"
                @input="updateTotal(row.index)"
              >
            </b-col>
            <b-col xl="1" md="2" cols="4" class="px-xl-1">
              <select
                v-model="orders[row.index].unit"
                v-validate="'required'"
                v-bind="$requiredInputProps('unit-' + row.index, submitted)"
                placeholder="Ühik"
              >
                <option v-for="(option, optionIndex) in unitOptions" :key="'option-' + optionIndex" :value="option">
                  <span v-if="option === 'm2'">m&sup2;</span>
                  <span v-else>{{ option }}</span>
                </option>
              </select>
            </b-col>
            <b-col xl="1" md="2" cols="4" class="px-xl-1">
              <input
                v-model="orders[row.index].price"
                v-validate="'required'"
                v-bind="$requiredInputProps('price-' + row.index)"
                placeholder="Hind"
                type="number"
                @input="updateTotal(row.index)"
              >
            </b-col>
            <b-col xl="1" md="2" cols="4" class="px-xl-1">
              <input
                v-model="orders[row.index].specialWeight"
                v-bind="$requiredInputProps('specialWeight-' + row.index)"
                placeholder="Erikaal"
                type="number"
                readonly
              >
            </b-col>
            <b-col xl="1" md="2" cols="4" class="px-xl-1">
              <input
                v-model="orders[row.index].totalPrice"
                v-validate="'required'"
                v-bind="$requiredInputProps('totalPrice-' + row.index)"
                placeholder="Summa"
                type="number"
                readonly
              >
            </b-col>
            <b-col xl="2" md="2" cols="4" class="px-xl-1">
              <input
                v-model="orders[row.index].projectNumber"
                v-validate="'required'"
                v-bind="$requiredInputProps('project-' + row.index)"
                :placeholder="$can(['orderFields', 'salesSystemNumber']) ? 'ID' : 'Projekti number'"
                readonly
              >
            </b-col>
            <b-col class="px-xl-1">
              <input
                v-model="orders[row.index].notes"
                v-bind="$requiredInputProps('notes-' + row.index)"
                placeholder="Märkused"
              >
            </b-col>
          </b-row>
        </template>
      </faf-repeater>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide(modalName)">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover class="icon-button text-info" title="Kasuta ladu" @click="fromInventory">
          <font-awesome-icon icon="boxes" />
        </b-button>
        <b-button v-b-tooltip.hover class="icon-button text-success" title="Lisa tellimus" @click="addOrder">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
    <add-edit-supplier
      ref="add-edit-supplier"
      :key="addSupplierModal"
      @hide="addSupplierModal = !addSupplierModal"
      @supplier-added="getSupplierContacts(formData.supplier.name); newSupplier = false"
    />
  </b-modal>
</template>
<script>
  import AddEditSupplier from './../SupplierManagement/AddEditSupplier'

  export default {
    name: 'faf-add-purchase-modal',
    components: {
      AddEditSupplier
    },
    props: {
      modalName: {
        type: String,
        default: 'modal-add-new-purchase'
      }
    },
    data () {
      return {
        materialSuggestions: { data: [] },
        supplierNameSuggestions: { data: [] },
        newSupplier: false,
        addSupplierModal: false,
        supplierFormData: {
          name: '',
          address: '',
          contacts: [{
            name: '',
            email: '',
            phone: ''
          }]
        },
        supplierContacts: { data: [] },
        unitOptions: ['kg', 'mm', 'm2', 'tk', 'L', 'm'],
        submitted: false,
        orders: [{ name: ' ', demandInKg: 0, unit: 'kg', price: 0, totalPrice: 0, projectNumber: 'Ladu', notes: '' }],
        formData: {
          linkedMaterials: [],
          deliveryDate: null,
          supplier: {
            name: '',
            contact: {}
          }
        }
      }
    },
    watch: {
      'formData.supplier.name' (newVal, oldVal) {
        if (oldVal && newVal && oldVal !== newVal) {
          this.formData.supplier.contact = {}
        }
        this.getSupplierContacts(newVal)
      },
      'formData.supplier.contact.name' (val) {
        const foundContact = this.supplierContacts.contacts.find(contact => contact.name === val)
        if (foundContact) {
          this.setSupplierContact(foundContact)
        }
      }
    },
    async created () {
      this.materialSuggestions = await this.$suggestions.getMaterialNames()
      this.getSupplierNameSuggestions = this.$_debounce(this.getSupplierNameSuggestions, 700)
      this.getSupplierContacts = this.$_debounce(this.getSupplierContacts, 400)
    },
    methods: {
      async getSupplierNameSuggestions (e) {
        if (e.length >= 2) {
          this.supplierNameSuggestions = await this.$suppliers.getSuppliersNames({name: e})
        }
      },
      async getSupplierContacts (val) {
        const supplier = val ? (await this.$suppliers.getSuppliers({ page: 1, limit: 1, filters: { name: val } })).data.docs[0] : null

        if (supplier) {
          this.supplierContacts = supplier
          this.supplierContacts.data = supplier.contacts.map(contact => contact.name)
        } else {
          this.supplierContacts = { data: [] }
        }
        this.supplierContacts.contacts && (this.supplierContacts.contacts.length === 1 && this.setSupplierContact(this.supplierContacts.contacts[0]))
      },
      setSupplierContact (contact) {
        this.formData.supplier.contact = this.$_cloneDeep(contact)
      },
      addOrder () {
        this.$validator.validateAll().then((result) => {
          if (this.$validator.errors.items.length > 0 || !result) {
            return
          }
          this.$emit('addOrder', {
            ...this.formData,
            materials: this.orders
          })
        })
      },
      fromInventory () {
        this.$emit('fromInventory', {
          ...this.formData,
          materials: this.orders
        })
      },
      close () {
        this.formData = {
          linkedMaterials: [],
          deliveryDate: null,
          supplier: {
            name: '',
            contact: {}
          }
        }
        this.orders = [{ name: ' ', demandInKg: 0, unit: 'kg', price: 0, totalPrice: 0, projectNumber: 'Ladu', notes: '' }]
        this.$emit('close')
        this.$cardReset('purchase-suggestions')
        this.$cardReset('inventory')
      },
      addTag (options, model, val) {
        options.push(val)
        this.$_set(this, model, val)

        this.newSupplier = model.includes('name')
      },
      addNewRow (index) {
        this.orders.splice(index + 1, 0, { name: ' ', demandInKg: 0, unit: 'kg', price: 0, totalPrice: 0, projectNumber: 'Ladu', notes: '' })
      },
      duplicateRow (index) {
        const newItem = this.$_cloneDeep(this.orders[index])
        newItem.projectNumber = 'Ladu'
        delete newItem.deleteDisabled
        this.orders.splice(index + 1, 0, newItem)
      },
      deleteRow (index) {
        this.orders.splice(index, 1)
      },
      updateTotal (index) {
        this.orders[index].totalPrice = +this.orders[index].demandInKg * (+this.orders[index].specialWeight || 1) * +this.orders[index].price || 0
      },
      // Field specific methods
      async prefillMaterial (event, index) {
        const materialData = (await this.$materials.getMaterials({filters: {name: event}})).data
        if (materialData.docs.length) {
          this.orders[index].materialId = materialData.docs[0]._id
          this.orders[index].description = materialData.docs[0].description
          this.orders[index].price = materialData.docs[0].price
          this.orders[index].specialWeight = materialData.docs[0].specialWeight || 1
          this.orders[index].width = materialData.docs[0].width
          this.orders[index].unit = materialData.docs[0].unit
        } else {
          this.orders[index].specialWeight = 1
        }
      }
    }
  }
</script>
