import BlackDashboard from "./plugins/blackDashboard";
import RouterPrefetch from 'vue-router-prefetch'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import dayjs from 'dayjs'
import VueClipboard from 'vue-clipboard2'
import axios from 'axios'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/sass/black-dashboard.scss"

import { lapiPrivileges, plasmaProPrivileges, adremPrivileges } from './../../src/privileges'

// Produxio
import FafAddEditSale from './produxio/Sales/AddEditSale'
import FafSalesTable from './produxio/Sales/SalesTable'
import FafInventory from './produxio/Inventory/Inventory'
import FafPurchaseHistory from './produxio/Purchase/PurchaseHistory'
import FafPurchase from './produxio/Purchase/Purchase'
import FafAddPurchaseModal from './produxio/Purchase/AddPurchaseModal'
import FafPurchaseSuggestions from './produxio/Purchase/PurchaseSuggestions'
import FafOrderTable from './produxio/Orders/OrderTable'
import FafChildOrderTable from './produxio/Orders/ChildOrderTable'
import FafMyJobs from './produxio/MyJobs/MyJobs'
import FafAllActiveJobsTable from './produxio/MyJobs/AllActiveJobsTable'
import FafProductionTable from './produxio/Production/ProductionTable'
import FafMyStatistics from './produxio/Statistics/MyStatistics/MyStatistics'
import FafReports from './produxio/Reports/Reports'
import FafQualityReportsTable from './produxio/QualityReports/QualityReportsTable'
import WorkTimeTable from './produxio/WorkTimeTable/WorkTimeTable'
import FafManagementSystemReportsTable from './produxio/ManagementSystemReports/ManagementSystemReportsTable'
import FafDetailedSale from './produxio/DetailedViews/DetailedSale'

// Layout & pages & components
import Layout from './layout/Layout'
import Login from './views/Login/Login'
import Settings from './views/Settings/Settings'
import Profile from './views/Profile/Profile'
import FafCard from './components/Card/Card.vue'
import FafRepeater from './components/Repeater/Repeater.vue'
import FafTag from './components/Tag/Tag.vue'
import FafFilters from './components/Filters/Filters.vue'
import FafGetPdf from './components/GetPdf/GetPdf.vue'
import FafAddNote from './components/AddNote/AddNote.vue'
import FafTrackingLink from './components/TrackingLinkCopy/TrackingLinkCopy.vue'
import Pagination from './components/Pagination/Pagination.vue'
import PaginationRight from './components/Pagination/PaginationRight.vue'
import FafFileManager from './components/FileManager/FileManager.vue'
import FafStatsTitle from './components/StatsTitle/StatsTitle.vue'

// Form components
import FafInput from './components/FafForm/FafInput/FafInput.vue'
import FafSelect from './components/FafForm/FafSelect/FafSelect.vue'
import FafMultiselect from './components/FafForm/FafMultiselect/FafMultiselect.vue'

// Router Guards
import loginGuard from './router/loginGuard'
import heartbeat from './router/heartbeat'

// Notification
import installErr from './components/Notifications/Err'
import installConfirm from './components/Notifications/Confirm'
import installNotify from './components/Notifications/Notify'

// Role Privilegs
import installRolePrivileges from './plugins/rolePrivileges'

// Plugins
import installUser from './plugins/user'
import installFafAutocompleteOff from './plugins/fafAutocompleteOff'
import installCard from './components/Card/card'
import installLoader from './layout/loader'
import installValidation from './validation/validate'
import installAsyncEmit from './plugins/asyncEmit'
import installUnblockMainThread from './plugins/unblockMainThread'
import contextMenu from './components/ContextMenu/state'
import isMobilePlugin, { isMobile } from './plugins/isMobile'

// Assets
import fafLogo from './assets/images/faf-logo'
import installIcons from './icons'

//helperMethods
import installLodashHelperMethods from './helperMethods/lodashHelpers'
import { installDatepickerProps, installMultiselectProps, installRequiredInputProps } from './helperMethods/customProps'
import { installDateHelpers } from './helperMethods/dateHelpers'

// Controllers
import DocumentService from './Controllers/DocumentService'
import CalculationService from './Controllers/CalculationService'
import Orders from './Controllers/Orders'
import Purchase from './Controllers/Purchase'
import Materials from './Controllers/Materials'
import Products from './Controllers/Products'
import Suggestions from './Controllers/Suggestions'
import Typeahead from './Controllers/Typeahead'
import Work from './Controllers/Work'
import Suppliers from './Controllers/Suppliers'
import Workcenters from './Controllers/Workcenters'
import Clients from './Controllers/Clients'
import FileService from './Controllers/FileService'
import Import from './Controllers/Import'
import Statistics from './Controllers/Statistics'
import Reports from './Controllers/Reports'
import QualityReports from './Controllers/QualityReports'
import AppSettings from './Controllers/Settings'
import Email from './Controllers/Email'
import ManagementSystemReports from './Controllers/ManagementSystemReports'

// Other
import { migrateProof, migrateTeam, fixPlannedTime } from './produxio/Statistics/migrateProof'

const components = [
  FafCard,
  FafRepeater,
  FafTag,
  FafFilters,
  FafGetPdf,
  FafTrackingLink,
  FafAddNote,
  Pagination,
  PaginationRight,
  FafFileManager,
  FafStatsTitle,
  FafInput,
  FafSelect,
  FafMultiselect,
  WorkTimeTable,
  FafAddEditSale,
  FafChildOrderTable,
  FafDetailedSale
]

// Validation
import { extendVeeValidate as validationRules } from './validation/validationRules'

async function setApplicationInfo () {
  if (!localStorage.company) {
    const { data } = await axios('api/info').catch(err => {
      alert('Ühenduse probleem. Proovi mõne hetke pärast lehte uuesti laadida')
      location.reload();
    })
    localStorage.company = data.company
  }
}

export default async function install (Vue, options = {}) {
  await setApplicationInfo()

  axios.interceptors.request.use(function (config) {
    config.headers['X-FAF-Organization'] = localStorage.company || 'FAF';
    return config;
  });

  Vue.use(BlackDashboard);
  if (!Vue.prototype.$company) Vue.prototype.$company = {}
  Vue.use(contextMenu)
  Vue.use(isMobilePlugin)
  // Controllers
  Vue.use(DocumentService)
  Vue.use(CalculationService)
  Vue.use(Orders)
  Vue.use(Purchase)
  Vue.use(Materials)
  Vue.use(Products)
  Vue.use(Suggestions)
  Vue.use(Typeahead)
  Vue.use(Work)
  Vue.use(Suppliers)
  Vue.use(Workcenters)
  Vue.use(Clients)
  Vue.use(FileService)
  Vue.use(Import)
  Vue.use(Statistics)
  Vue.use(Reports)
  Vue.use(QualityReports)
  Vue.use(AppSettings)
  Vue.use(Email)
  Vue.use(ManagementSystemReports)
  Vue.use(VueClipboard)
  // Global registration
  installFafAutocompleteOff(Vue)
  Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
  installLodashHelperMethods(Vue)

  if (localStorage.company === 'PLASMAPRO') {
    installRolePrivileges(Vue, plasmaProPrivileges, options.role)
  } else if (localStorage.company === 'LAPI') {
    installRolePrivileges(Vue, lapiPrivileges, options.role)
  } else if (localStorage.company === 'ADREM') {
    installRolePrivileges(Vue, adremPrivileges, options.role)
  } else if (localStorage.company === 'FAF') {
    installRolePrivileges(Vue, lapiPrivileges, options.role)
  } else {
    localStorage.clear()
    location.reload();
  }

  const router = options.router
  Vue.use(RouterPrefetch);
  installUnblockMainThread(Vue)
  installAsyncEmit(Vue)
  installNotify(Vue)
  installErr(Vue)
  installConfirm(Vue)
  installUser(Vue)
  installValidation(Vue)
  installCard(Vue)
  installLoader(Vue)
  installDatepickerProps(Vue)
  installMultiselectProps(Vue)
  installRequiredInputProps(Vue)
  installIcons(Vue)
  installDateHelpers(Vue)

  if (localStorage.sessionEndTime) {
    await heartbeat({
      timeout: localStorage.sessionEndTime,
      beatAfter: 15000
    }, () => {
      Vue.prototype.$rolePrivileges.changeRole(['PUBLIC'])
      options.router.replace({ name: 'Login' })
    })
  }
  loginGuard(options.router)
  Vue.prototype.$dayjs = dayjs
  Vue.prototype.$companyLogo = fafLogo
  Vue.prototype.$mode = process.env.VUE_APP_MODE
  Vue.prototype.$homepage = window.location.origin
  Vue.prototype.$sideMenuLinks = options.sideMenuLinks
  Vue.prototype.$topNavigationLinks = options.topNavigationLinks || []
  Vue.prototype.$userGroupOptions = options.userGroupOptions || [
    { text: 'Administraator', value: 'admin' },
    { text: 'Manager', value: 'manager' },
    { text: 'Kasutaja', value: 'employee' }
  ]

  components.map(component => Vue.component(component.name, component))

  new Vue({
    router,
    render: h => h(options.App)
  }).$mount("#app");
}

export {
  validationRules,
  Layout,
  Login,
  Settings,
  Profile,
  FafCard,
  FafRepeater,
  FafTag,
  FafFilters,
  FafGetPdf,
  FafTrackingLink,
  FafAddNote,
  FafAddEditSale,
  FafSalesTable,
  FafInventory,
  FafPurchaseHistory,
  FafPurchase,
  FafAddPurchaseModal,
  FafPurchaseSuggestions,
  FafOrderTable,
  FafMyJobs,
  FafAllActiveJobsTable,
  FafProductionTable,
  FafFileManager,
  FafStatsTitle,
  FafMyStatistics,
  FafReports,
  FafQualityReportsTable,
  WorkTimeTable,
  FafManagementSystemReportsTable,
  isMobile,

  FafInput,
  FafSelect,
  FafMultiselect,
  migrateProof,
  migrateTeam,
  fixPlannedTime
}
