import Ajv from 'ajv'
import _getProductSalePrice from './_getProductSalePrice'
import _getProductNetPrice from './_getProductNetPrice'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object'
}

const test = ajv.compile(schema)

export default async function _getProductMarginal (product) {
  if (!test(product)) {
    throw { function: '_getProductMarginal', error: test.errors }
  }

  const salePrice = await _getProductSalePrice(product) || 0.0001
  const netPrice = await _getProductNetPrice(product)

  const result = (+salePrice - +netPrice) / +salePrice * 100
  return Number.isFinite(result) ? result.toFixed(2) : 0
}
