<template>
  <faf-card name="purchase-suggestions" title="Ostusoovitused">
    <faf-purchase-suggestions ref="purchase-suggestions" @items-selected="selected = $event" />
    <template slot="buttons">
      <b-button v-b-tooltip.hover v-b-modal.modal-add-new-purchase-suggestion :disabled="!selected" class="icon-button text-success" title="Lisa uus ostutellimus">
        <font-awesome-icon icon="plus-circle" />
      </b-button>
    </template>
  </faf-card>
</template>
<script>
  import { FafPurchaseSuggestions } from '@faf/faf-ui'
  export default {
    name: 'purchase-suggestions-card',
    components: {
      FafPurchaseSuggestions
    },
    data () {
      return {
        selected: false
      }
    }
  }
</script>
