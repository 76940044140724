import axios from 'axios'

const companyHeader = {
  'X-FAF-Organization': localStorage.company
}

function login (credentials) {
  return axios({
    url: '/auth-service/authenticate',
    method: 'POST',
    headers: {
      ...companyHeader
    },
    data: credentials
  })
}

function getUserList () {
  return axios({
    url: '/auth-service',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.user).token}`,
      ...companyHeader
    }
  })
}

function getListOfAllCompanies () {
  return axios({
    url: '/auth-service/company-list',
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.user).token}`,
      ...companyHeader
    }
  })
}

function registerUser (data) {
  !data.firstName && delete data.firstName
  !data.lastName && delete data.lastName
  !data.password && delete data.password
  delete data.repeatPassword
  return axios({
    url: '/auth-service/register',
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.user).token}`,
      ...companyHeader
    },
    data
  })
}

function updateUser (id, data) {
  !data.firstName && delete data.firstName
  !data.lastName && delete data.lastName
  !data.password && delete data.password
  delete data.repeatPassword
  return axios({
    url: `/auth-service/${id}`,
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.user).token}`,
      ...companyHeader
    },
    data
  })
}

function deleteUser (id) {
  return axios({
    url: `/auth-service/${id}`,
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.user).token}`,
      ...companyHeader
    }
  })
}

export {
  login,
  getUserList,
  updateUser,
  registerUser,
  deleteUser,
  getListOfAllCompanies
}
