<template>
  <div class="row pl-3 statistics">
    <faf-card name="key-indicators" title="Võtmenäitajad">
      <b-row>
        <b-col cols="5">
          <h4>STATISTIKA</h4>
          <b-form-group class="button-checkboxes mb-4">
            <b-form-radio-group
              v-model="statisticTime"
              :options="timeOptions"
              label="label"
              name="statistic-time"
              buttons
            />
          </b-form-group>
          <b-row>
            <b-col cols="6" class="mb-3">
              <faf-small-chart
                :data="mistakeCount.data"
                :total="mistakeCount.total"
                :labels="mistakeCount.labels"
                :additional-labels="mistakeCount.additionalLabels"
                :color="getColor(mistakeCount.total, true)"
                :loading="mistakeCount.loading"
                :percentage="false"
                title="Vigade arv"
                after-label="tk"
              />
            </b-col>
            <b-col cols="6" class="mb-3">
              <faf-small-chart
                :data="productivity.data"
                :total="productivity.total"
                :labels="productivity.labels"
                :additional-labels="productivity.additionalLabels"
                :color="getColor(productivity.total)"
                :loading="productivity.loading"
                :to-be-updated="!!productivity.toBeUpdated"
                title="Tootlikkus"
                after-label="%"
              />
            </b-col>
            <b-col cols="6" class="mb-3">
              <faf-small-chart
                :data="planExecution.data"
                :total="planExecution.total"
                :labels="planExecution.labels"
                :additional-labels="planExecution.additionalLabels"
                :color="getColor(planExecution.total)"
                :loading="planExecution.loading"
                title="Plaani täitmine"
                after-label="%"
              />
            </b-col>
            <b-col cols="6" class="mb-3">
              <faf-small-chart
                :data="materialAvailability.data"
                :total="materialAvailability.total"
                :labels="materialAvailability.labels"
                :additional-labels="materialAvailability.additionalLabels"
                :color="getColor(materialAvailability.total)"
                :loading="materialAvailability.loading"
                title="Materjali saadavus"
                after-label="%"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-row>
            <b-col>
              <h4>ARUANDED</h4>
            </b-col>
            <b-col v-if="futureAllowedReports.includes(reportType)" cols="auto">
              <b-form-group class="button-checkboxes mb-2">
                <b-button :disabled="page <= 0" size="sm" class="mb-0 h-30" @click="page--; getBigChart()">
                  Eelmine
                </b-button>
                <b-button :disabled="page >= 3" size="sm" class="mb-0 h-30" @click="page++; getBigChart()">
                  Järgmine
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <select v-model="reportType" v-bind="$requiredInputProps('report-type')">
                <option v-for="(option, optionIndex) in reportTypeOptions" :key="'option-' + optionIndex" :value="option.value">
                  <span>{{ option.label }}</span>
                </option>
              </select>
            </b-col>
            <b-col cols="3">
              <select v-model="reportArea" v-bind="$requiredInputProps('report-area')">
                <option v-for="(option, optionIndex) in reportAreaOptions" :key="'option-' + option.value + optionIndex" :value="option.value">
                  <span>{{ option.label }}</span>
                </option>
              </select>
            </b-col>
            <b-col cols="3">
              <select v-if="teamAllowedReports.includes(reportType) && teamWorkcenterSelecionAllowedReports.includes(reportType) && reportArea === 'TEAM'" v-model="reportTeam" v-bind="$requiredInputProps('report-team')">
                <option v-for="(option, optionIndex) in reportTeamOptions" :key="'option-' + optionIndex" :value="option">
                  <span>{{ option }}</span>
                </option>
              </select>
              <select v-if="workcenterAllowedReports.includes(reportType) && teamWorkcenterSelecionAllowedReports.includes(reportType) && reportArea === 'WORKCENTER'" v-model="reportWorkcenter" v-bind="$requiredInputProps('report-workcenter')">
                <option v-for="(option, optionIndex) in reportWorkcenterOptions" :key="'option-' + optionIndex" :value="option">
                  <span>{{ option }}</span>
                </option>
              </select>
            </b-col>
            <b-col cols="3">
              <b-form-group class="button-checkboxes mb-4 text-right">
                <b-form-radio-group
                  v-model="reportTime"
                  :options="timeOptions"
                  name="report-time"
                  buttons
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- BIG CHART -->
              <faf-big-chart
                :data="bigChart.data"
                :title="reportTitle"
                :sub-title="reportSubTitle"
                :total="bigChart.total"
                :secondary-total="bigChart.secondaryTotal"
                :labels="bigChart.labels"
                :additional-labels="bigChart.additionalLabels"
                :loading="bigChart.loading"
                :after-label="reportAfterLabel"
                :to-be-updated="bigChart.toBeUpdated"
                :type="bigChart.type"
                :label="bigChart.label"
                :color="bigChart.color || 'warning'"
                :secondary-color="bigChart.secondaryColor || 'primary'"
                :error="error"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template slot="buttons">
        <!-- DO WE NEED THIS?-->
      </template>
    </faf-card>
  </div>
</template>
<script>
  import FafSmallChart from './../../../components/Charts/FafSmallChart'
  import FafBigChart from './../../../components/Charts/FafBigChart'

  import { timeOptions,
           reportTypeOptions,
           factoryAllowedReports,
           teamAllowedReports,
           workcenterAllowedReports,
           futureAllowedReports,
           teamWorkcenterSelecionAllowedReports } from './helpers'

  const emptyChart = {
    total: 0,
    data: null,
    labels: [],
    additionalLabels: [],
    loading: true,
    toBeUpdated: false,
    type: 'default',
    label: null
  }

  export default {
    name: 'faf-my-statistics',
    components: {
      FafSmallChart,
      FafBigChart
    },
    data () {
      return {
        statisticTime: 'WEEK',
        reportTime: 'WEEK',
        timeOptions,

        reportType: null,
        reportArea: 'FACTORY',
        page: 0,

        reportTeam: null,
        reportTeamOptions: [],
        reportWorkcenter: null,
        reportWorkcenterOptions: [],

        mistakeCount: this.$_cloneDeep(emptyChart),
        productivity: this.$_cloneDeep(emptyChart),
        planExecution: this.$_cloneDeep(emptyChart),
        materialAvailability: this.$_cloneDeep(emptyChart),

        bigChart: this.$_cloneDeep(emptyChart),

        factoryAllowedReports,
        teamAllowedReports,
        workcenterAllowedReports,
        teamWorkcenterSelecionAllowedReports,
        futureAllowedReports,

        error: false
      }
    },
    computed: {
      reportTypeOptions () {
        const options = []
        reportTypeOptions.map(o => this.$can(['statistics', o.value]) && options.push(o))
        return options
      },
      reportAreaOptions () {
        return [
          factoryAllowedReports.includes(this.reportType) && { value: 'FACTORY', label: 'Tehas' },
          teamAllowedReports.includes(this.reportType) && { value: 'TEAM', label: 'Tiim' },
          workcenterAllowedReports.includes(this.reportType) && { value: 'WORKCENTER', label: 'Töökeskus' }
        ].filter(Boolean)
      },
      reportTitle () {
        const option = reportTypeOptions.find(i => i.value === this.reportType)
        return option ? option.label : ''
      },
      reportSubTitle () {
        switch (this.reportType) {
        case 'WORK_TIME':
          return this.reportArea === 'WORKCENTER' ? ' töökeskuste lõikes' : this.reportArea === 'TEAM' ? ' tiimide lõikes' : ''
        case 'SALES_CAPACITY':
          return ''
        default:
          return ''
        }
      },
      reportAfterLabel () {
        switch (this.reportType) {
        case 'WORK_TIME':
          return ' minutit'
        case 'SALES_CAPACITY':
          return ' €'
        case 'PRODUCTIVITY_DETAILED':
          return ' %'
        case 'PLAN_EXECUTION':
          return ' %'
        default:
          return ''
        }
      }
    },
    watch: {
      statisticTime () {
        this.getSmallCharts()
      },
      reportTime () {
        this.resetPage()
        this.getBigChart()
      },
      reportType () {
        this.resetPage()
        this.reportArea = this.reportAreaOptions[0].value
        this.reportTeam = this.reportTeamOptions[0]
        this.getBigChart()
      },
      reportArea (val) {
        this.resetPage()
        if (val === 'TEAM') {
          this.reportTeam = this.reportTeamOptions[0]
          this.reportWorkcenter = null
        } else {
          this.reportTeam = null
          this.reportWorkcenter = this.reportWorkcenterOptions[0]
        }
        this.getBigChart()
      },
      reportTeam () {
        this.resetPage()
        this.getBigChart()
      },
      reportWorkcenter () {
        this.resetPage()
        this.getBigChart()
      }
    },
    async created () {
      this.reportTeamOptions = (await this.$suggestions.getTeamNames()).data
      this.reportWorkcenterOptions = (await this.$suggestions.getWorkcenterNames()).data
      this.reportType = this.reportTypeOptions[0].value
      this.getSmallCharts()
    },
    methods: {
      getSmallCharts () {
        this.setLoading(true)
        // Vigade arv
        this.getMistakeCount()
        // Tootlikkus
        this.getProductivity()
        // Plaani täitmine
        this.getPlanExecutionSmall()
        // Materjali saadavus
        this.getMaterialAvailability()
      },
      async getMistakeCount () {
        this.mistakeCount = {
          ...(await this.$statistics.getMistakeCount(this.statisticTime, 'OTHER').then(rs => rs.data).catch(err => this.$_cloneDeep(emptyChart))),
          loading: false
        }
      },
      async getProductivity () {
        const productivity = (await this.$statistics.getProductivity(this.statisticTime).then(rs => rs.data).catch(err => this.$_cloneDeep(emptyChart)))
        this.productivity = {
          ...this.$_cloneDeep(emptyChart),
          ...productivity,
          toBeUpdated: productivity.noCache,
          loading: false
        }
      },
      async getPlanExecutionSmall () {
        this.planExecution = {
          ...(await this.$statistics.getPlanExecution(this.statisticTime, 'OTHER').then(rs => rs.data).catch(err => this.$_cloneDeep(emptyChart))),
          loading: false
        }
      },
      async getMaterialAvailability () {
        this.materialAvailability = {
          ...(await this.$statistics.getMaterialAvailability(this.statisticTime).then(rs => rs.data).catch(err => this.$_cloneDeep(emptyChart))),
          loading: false
        }
      },

      getBigChart () {
        this.bigChart.loading = true
        this.error = false
        switch (this.reportType) {
        case 'WORK_TIME':
          return this.getWorkTime()
        case 'PRODUCTIVITY_DETAILED':
          return this.getProductivityDetailed()
        case 'SALES_CAPACITY':
          return this.getSalesCapacity()
        case 'PLAN_EXECUTION':
          return this.getPlanExecution()
        default:
          return ''
        }
      },
      async getWorkTime () {
        const workTime = (await this.$statistics.getWorkTime(this.reportTime, this.reportArea, this.page).then(rs => rs.data).catch(err => {
          this.error = true
          return this.$_cloneDeep(emptyChart)
        }))
        this.bigChart = {
          ...this.$_cloneDeep(emptyChart),
          ...workTime,
          label: ['Tegelik aeg', 'Aeg kokku'],
          toBeUpdated: workTime.noCache,
          loading: false,
          type: 'time',
          color: 'success',
          secondaryColor: 'primary'
        }
      },
      async getProductivityDetailed () {
        const productivity = (await this.$statistics.getProductivityDetailed(this.reportTime, this.reportArea, this.page, this.reportTeam, this.reportWorkcenter).then(rs => rs.data).catch(err => {
          this.error = true
          return this.$_cloneDeep(emptyChart)
        }))
        this.bigChart = {
          ...this.$_cloneDeep(emptyChart),
          ...productivity,
          toBeUpdated: productivity.noCache,
          loading: false,
          color: 'primary'
        }
      },
      async getSalesCapacity () {
        const salesCapacity = (await this.$statistics.getSalesCapacity(this.reportTime, this.reportArea, this.page).then(rs => rs.data).catch(err => {
          this.error = true
          return this.$_cloneDeep(emptyChart)
        }))
        let label = ['Müügimaht', 'Hinnapakkumiste müügimaht']
        if (!this.$can(['orderFields', 'statusOffer'])) {
          label = 'Müügimaht'
          salesCapacity.data = salesCapacity.data[0]
        }
        this.bigChart = {
          ...this.$_cloneDeep(emptyChart),
          ...salesCapacity,
          label,
          loading: false,
          type: 'money'
        }
      },
      async getPlanExecution () {
        const planExecution = (await this.$statistics.getPlanExecution(this.reportTime, this.reportArea, this.reportTeam, this.reportWorkcenter).then(rs => rs.data).catch(err => {
          this.error = true
          return this.$_cloneDeep(emptyChart)
        }))
        this.bigChart = {
          ...this.$_cloneDeep(emptyChart),
          ...planExecution,
          loading: false,
          color: this.getColor(planExecution.total)
        }
      },

      setLoading (val) {
        this.mistakeCount.loading = val
        this.productivity.loading = val
        this.planExecution.loading = val
        this.materialAvailability.loading = val
      },
      getColor (val, smallIsBetter) {
        if (smallIsBetter) {
          return +val <= 10 ? 'success' : +val <= 30 ? 'warning' : 'danger'
        } else {
          if (val === '-') return 'success'
          return +val >= 90 ? 'success' : +val >= 50 ? 'warning' : 'danger'
        }
      },
      resetPage () {
        this.page = 0
      }
    }
  }
</script>
<style lang="scss">
  .statistics {
    .button-checkboxes .btn {
      margin-top: 0;
    }

    .h-30 {
      height: 30px;
      line-height: 16px;
      font-size: 0.75rem;
    }
  }
</style>
