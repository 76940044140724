<template>
  <div>
    <faf-card :collapse-button-visible="false">
      <b-table
        v-if="myJobs.length"
        :fields="fields"
        :items="myJobs"
        stacked="xl"
        striped
        hover
      />
      <p v-else>
        Pole ühtegi aktiivset tööd
      </p>
      <end-and-edit-job-modal
        :key="endAndEditJobModal"
        :work-id="workId"
        :my-jobs="myJobs"
        @hide="endAndEditJobModal = !endAndEditJobModal"
      />
      <zoom-center-transition :duration="400" :delay="30">
        <start-single-workcenter-job
          :key="startSingleWorkcenterJobIndex"
          @hide="startSingleWorkcenterJobIndex++"
        />
      </zoom-center-transition>
      <template v-if="myJobs.length" slot="buttons">
        <b-button v-b-tooltip.hover :title="paused ? 'Jätka' : 'Paus'" class="icon-button text-warning" @click="togglePauseJob">
          <font-awesome-icon :icon="paused ? 'play-circle' : 'pause-circle'" />
        </b-button>
        <b-button v-if="!paused" v-b-tooltip.hover class="icon-button text-danger" title="Tühista töö" @click="annullJob">
          <font-awesome-icon icon="minus-circle" />
        </b-button>
        <b-button v-if="!paused" v-b-tooltip.hover class="icon-button text-success" title="Lõpeta töö" @click="finishJob">
          <font-awesome-icon icon="check-circle" />
        </b-button>
        <b-button v-if="!paused" v-b-tooltip.hover class="icon-button text-warning" title="Kanna kinni ilma kogusteta" @click="calculateWorkTimeOnly">
          <font-awesome-icon icon="clock" />
        </b-button>
      </template>
    </faf-card>
  </div>
</template>
<script>
  import EndAndEditJobModal from './EndAndEditJobModal'
  import StartSingleWorkcenterJob from './StartSingleWorkcenterJob'
  import otherWorkSuggestions from './helpers/otherWorkSuggestions'
  import { ZoomCenterTransition } from 'vue2-transitions'

  export default {
    name: 'faf-my-jobs',
    components: {
      EndAndEditJobModal,
      StartSingleWorkcenterJob,
      ZoomCenterTransition
    },
    data () {
      return {
        endAndEditJobModal: false,
        startSingleWorkcenterJobIndex: 0,
        workId: null,
        paused: null,
        myJobs: [],
        fields: [
          {
            key: 'product.workcenters.name',
            label: 'Töökeskus',
            sortable: true
          },
          {
            key: 'orderId',
            label: 'Tellimuse nr',
            formatter: (value, key, item) => item.orderId || item.salesSystemNumber || '-',
            sortable: true
          },
          {
            key: 'product.name',
            label: 'Toote nimetus',
            formatter: (value, key, item) => value ? value : '-',
            sortable: true
          },
          {
            key: 'product.workcenters.deliveryDate',
            label: 'Tarneaeg',
            formatter: (value, key, item) => value ? this.$dayjs(value).format('DD-MM-YYYY') : '-',
            sortable: true,
            sortByFormatted: true
          }
        ]
      }
    },
    beforeDestroy () {
      this.$root.$off('annull-job')
    },
    async created () {
      this.$loader.start()
      this.$root.$on('annull-job', () => this.annullJob())
      await this.getMyJobs()
      this.$loader.stop()
    },
    methods: {
      async getMyJobs () {
        const myJobs = await this.$work.getJobs({operatorId: this.$user.getId(), status: 'IN_PROGRESS'})
        if (myJobs.data.length) {
          this.workId = myJobs.data[0]._id
          this.paused = !!this.$_get(myJobs, 'data[0].paused', []).find(p => p.status === 'IN_PROGRESS')
          for await (const job of myJobs.data[0].jobs) {
            if (job.workcenterId) {
              const jobDetails = await this.$orders.getAllWorkcenterWithParent({filter: {'products.workcenters._id': job.workcenterId, doneProducts: true}, page: 1, limit: 50})
              this.myJobs.push(jobDetails.data.docs[0])
            } else {
              this.myJobs = [{
                product: {
                  workcenters: {
                    name: `${job.workcenterName || ''}${this.getOtherWorkText(job)}`
                  }}
              }]
            }
          }
        }
      },
      getOtherWorkText (job) {
        const otherWork = otherWorkSuggestions().find(s => s.value === job.status)
        if (otherWork) {
          return job.workcenterName ? `/(${otherWork.name})` : otherWork.name
        }
        return ''
      },
      annullJob () {
        this.$confirm.show('Olete kindel, et soovite antud töö ära tühistada?', null, answer => {
          if (answer) {
            this.$work.annullJob(this.workId).then(() => {
              this.$cardReset('my-jobs')
              this.$cardReset('production-table')
            })
          }
        })
      },
      async togglePauseJob () {
        try {
          if (this.paused) {
            await this.$work.unPauseJob(this.workId)
            this.paused = false
            this.$notify.show('Töö jätkus!')
          } else {
            this.$work.pauseJob(this.workId)
            this.paused = true
            this.$notify.show('Töö on pausil!')
          }
        } catch (error) {
          this.$err.show(error)
        }
      },
      async calculateWorkTimeOnly () {
        await this.$work.completeJob(this.workId, this.overTime, true)
          .catch(err => this.$err.show(err))
        this.$notify.show('Töö on edukalt lõpetatud!')
        this.$cardReset('my-jobs')
        this.$cardReset('production-table')
      },
      finishJob () {
        this.myJobs.length > 0 ? this.$bvModal.show('end-job-modal') : this.$err.show('Pole ühtegi tööd lõpetada!')
      }
    }
  }
</script>
<style scoped>
  .separator {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
</style>
