<template>
  <div :class="wrapperClasses" :style="wrapperStyles">
    <div v-if="active" class="position-relative">
      <input
        :ref="name"
        v-model="val"
        v-bind="$attrs"
        :name="name"
        class="form-control faf-input"
        @input.stop="onInput"
      />
      <b-button v-if="isInValid(name)" v-b-tooltip.hover :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
      <span v-if="addOn" class="faf-input-addon">
        {{ addOn }}
      </span>
    </div>
    <div v-else-if="!textHidden" class="faf-input-text" @click="editRow">
      <b-button v-if="isInValid(name)" v-b-tooltip.hover :title="errorText" class="icon-button text-danger">
        <font-awesome-icon icon="exclamation-circle" />
      </b-button>
      <slot name="custom-text" />
      <span v-if="!$slots['custom-text'] && (val || val === 0)" class="overflow-ellipsis">{{ val }} {{ unit.replace('m2', 'm&sup2;') }}</span>
      <span v-else-if="!$slots['custom-text']">{{ noText || '' }}  {{ unit.replace('m2', 'm&sup2;') }}</span>
    </div>
  </div>
</template>
<script>
  import field from './../mixins/field'

  export default {
    name: 'faf-input',
    mixins: [ field ],
    props: {
    },
    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss">
  .overflow-ellipsis {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    width: 100%;
    display: inline-flex;
  }

  .faf-input-text .overflow-ellipsis {
    display: inline-block;
  }

  .faf-input-wrapper {
    width: 100%;
    cursor: text;

    &.active {
      display: inline-block;
    }

    .position-relative {
      width: 100%;
    }

    &:not(.active) {
      width: 100%;
    }

    + .faf-input-wrapper {
      margin-left: 5px;
    }

    .faf-input-text {
      line-height: 20px;
      height: 20px;
      position: relative;
    }

    &.faf-input-with-addon .faf-input {
      padding-right: 20px;
    }

    .faf-input-addon {
      color: #222a42;
      position: absolute;
      right: 5px;
      top: 0;
    }

    &.faf-invalid .position-relative,
    &.faf-invalid .faf-input-text {
      padding-left: 20px;
      .icon-button {
        position: absolute;
        left: 0;
        top: 8px;
        padding: 0;
        margin: 0;
        font-size: 16px;
        cursor: auto;
      }
    }
    &.faf-invalid .faf-input-text .icon-button {
      top: 0;
    }
  }

  .faf-input {
    border: none;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    height: 30px;

    &.multiselect {
      .multiselect__tags {
        border: none;
        line-height: 30px;
        font-size: 14px;
        font-weight: 400;
        min-height: 30px;
        border-bottom: none !important;
      }

      .multiselect__content-wrapper {
        width: auto;
      }

      .multiselect__option--selected {
        padding-right: 30px;
      }

      .multiselect__option--selected:after {
        content: 'x' !important;
        padding-right: 10px;
        padding-left: 10px;
      }

      input {
        position: absolute;
        left: 0;
        font-size: 14px;
      }

      .multiselect__input,
      .multiselect__option:after {
        line-height: 30px;
      }

      .multiselect__tag {
        padding-right: 1rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        padding-left: 2px;
        margin-right: 3px;

        .multiselect__tag-icon {
          width: 1rem;
          font-size: 14px;

          &::after {
            font-size: inherit;
          }
        }
      }
    }

    &:not(select) {
      border-bottom: 1px solid #2b3553 !important;
      margin-bottom: -16px;
      margin-top: -20px;
    }
  }

  .faf-invalid {
  }
</style>
