<template>
  <proof-management
    :my-jobs="myJobs"
    :bulk-cert="true"
    :modal-name="modalName"
    @editProof="editProof"
    @complete="complete"
  />
</template>
<script>
  import startupCheck from './mixins/startupCheck'
  import ProofManagement from './templates/ProofManagement'
  export default {
    name: 'complete-workcenter',
    components: {
      ProofManagement
    },
    mixins: [startupCheck],
    props: {
      modalName: {
        type: String,
        default: 'complete-workcenter-modal'
      },
      myJobs: Array,
      editMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        bulkAdd: false,
        overTime: 0,
        closingModal: {},
        workcenters: [{proof: [{}]}],
        fallbackCertificate: '',
        linkedWorksheet: ''
      }
    },
    mounted () {
      this.checkIfNewestversionOfProofs(this.myJobs)
    },
    methods: {
      checkIfNewestversionOfProofs (currentProofs) {
      },
      async editProof ({ workcenters, bulkCertificate = null }, promise) {
        await this.$orders.setProof(
          {
            workcenters,
            bulkCertificate,
            calculateDoneAmount: true,
            setEndDate: true
          }
        ).catch(err => {
          promise.reject(err)
        })
        promise.resolve()

        //this.$cardReset('my-jobs')
        //this.$cardReset('production-table')
      },
      complete (payload, promise) {
        this.$cardReset('my-jobs')
        this.$cardReset('production-table')
        promise.resolve()
      }
    }
  }
</script>
