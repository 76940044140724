import Vue from 'vue'
import Router from 'vue-router'
import sidebarRoutes from './sidebarRoutes'
import { Layout, Login, Settings, isMobile } from '@faf/faf-ui'

Vue.use(Router)
const routes = [
  {
    path: '/',
    name: 'Base',
    redirect: { name: isMobile ? 'Tootmine' : 'Minu asjad' },
    component: Layout,
    children: [
      ...sidebarRoutes,
      {
        path: 'settings',
        name: 'Sätted',
        components: { default: Settings }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: Login
    }
  }
]

const router = new Router({
  routes,
  linkActiveClass: 'active',
  base: '/',
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router
