<template>
  <div>
    <div v-if="totalPages" class="iconBar" @wheel="scroll">
      <a :id="name" variant="primary" href="#" @click="show = !show"><font-awesome-icon :icon="show ? 'times' : 'search'" />
        <b-popover
          :show.sync="show"
          :target="name"
          title="Otsi toote nime järgi"
        >
          <multiselect
            v-model="searchInput"
            v-faf-autocomplete-off
            :options="productNameSuggestions"
            v-bind="$multiselectProps('name')"
            name="name"
            @input="search"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-popover>
      </a>
      <a href="#" @click="onClickPreviousPage"><font-awesome-icon icon="arrow-up" /></a>
      <a
        v-for="(item, index) in pages"
        :key="index"
        :class="{active: isPageActive(item.name)}"
        href="#"
        @click="onClickPage(item.name)"
      >{{ item.name }}</a>
      <a href="#" @click="onClickNextPage"><font-awesome-icon icon="arrow-down" /></a>
    </div>
    <div class="slot-content">
      <div v-if="!contentVisible" class="spinner-container">
        <b-spinner label="Loading..." />
      </div>
      <slot v-if="contentVisible"></slot>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'faf-pagination-right',
    props: {
      maxVisibleButtons: {
        type: Number,
        required: false,
        default: 5
      },
      totalPages: {
        type: Number,
        required: true
      },
      setCurrentPage: {
        type: Number,
        required: false
      },
      total: {
        type: Number,
        required: true
      },
      productNameSuggestions: {
        type: Array,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        show: false,
        contentVisible: true,
        searchInput: null,
        currentPage: 1
      }
    },
    computed: {
      isInFirstPage () {
        return this.currentPage === 1
      },
      isInLastPage () {
        return this.currentPage === this.totalPages
      },
      startPage () {
        const start = this.currentPage - Math.round(this.maxVisibleButtons / 2) - 1;
        return start < 1 ? 1 : start;

      },
      endPage () {
        const end = this.startPage + this.maxVisibleButtons
        return end > this.totalPages ? this.totalPages : end
      },
      pages () {
        const range = []
        for (let i = this.startPage; i <= this.endPage; i += 1) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage
          })
        }
        return range
      }
    },
    watch: {
      setCurrentPage (val) {
        this.contentVisible = false
        this.setCurrent(val)
      },
      currentPage () {
        this.contentVisible = false
        this.setContentVisible()
      }
    },
    mounted () {
      this.setContentVisible = this.$_debounce(this.setContentVisible, 400)
      this.$el.addEventListener('keydown', this.onKeyPress);
      this.scroll = this.$_throttle(this.scroll, 100)

    },
    destroyed () {
      this.$el.removeEventListener('keydown', this.onKeyPress)
    },
    methods: {
      setContentVisible () {
        this.contentVisible = true
      },
      search () {
        this.$emit('search', this.searchInput)
      },
      scroll (event) {
        event.preventDefault();
        if (event.deltaY < -1 || event.deltaY < -2) {
          this.onClickPreviousPage()
        }
        if (event.deltaY > 1 || event.deltaY > 2) {
          this.onClickNextPage()
        }
      },
      onClickFirstPage () {
        this.currentPage = 1
        this.$emit('pagechanged', 1)
      },
      onClickPreviousPage () {
        if (this.currentPage >= 2) {
          this.currentPage--
          this.$emit('pagechanged', this.currentPage)
        }

      },
      onClickPage (page) {
        this.currentPage = page
        this.$emit('pagechanged', page)
      },
      onKeyPress (event) {
        if (event.keyCode == 38) {
          this.onClickPreviousPage()

        } else if (event.keyCode == 40) {
          this.onClickNextPage()
        }
      },
      onClickNextPage () {
        if (this.currentPage <= this.totalPages - 1) {
          this.currentPage++
          this.$emit('pagechanged', this.currentPage)
        }
      },
      onClickLastPage () {
        this.currentPage = this.totalPages
        this.$emit('pagechanged', this.totalPages)
      },
      isPageActive (page) {
        return this.currentPage === page;
      }
    }

  }
</script>
<style lang="scss" scoped>
  .slot-content {
    float: left;
    width: 95%;
  }
  .spinner-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    top: 54px;
    bottom: 0;
  }
  .iconBar {
    float: right;
    width: 35px;
    background-color: #303030;
    margin-top: 15px;
    opacity: 0.4;
    border-radius: 15px;
  }

  .iconBar a {
    display: block;
    text-align: center;
    padding: 5px;
    transition: all 0.5s ease;
    color: white;
    font-size: 15px;
  }

  .iconBar a:hover {
    background-color: #006346;
    border-radius: 15px;
  }

  .iconBar .active {
    background-color: #008c62;
    border-radius: 15px;
  }
  .badge-round {
    font-size: 15px;
    margin-left: -40px;
    width: 40px;
    height: 40px;
    line-height: 30px;
    border-radius: 50%;
  }
  .faf-pagination-right {
    .pagination {
      display: block;
      padding-left: 0;
      list-style: none;
      border-radius: 0;
      position: absolute;
      top: 70px;
      right: 1px;
    }
  }
</style>
