export const days = [
  'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev', 'Pühapäev'
]

export const timeOptions = [ { text: 'Nädal', value: 'WEEK' }, { text: 'Kuu', value: 'MONTH' }, { text: 'Aasta', value: 'YEAR' } ]

export const reportTypeOptions = [
  { value: 'WORK_TIME', label: 'Tegelik aeg' },
  { value: 'PRODUCTIVITY_DETAILED', label: 'Efektiivsus' },
  { value: 'SALES_CAPACITY', label: 'Müügimaht' },
  { value: 'PLAN_EXECUTION', label: 'Plaani täitmine' }
]

export const factoryAllowedReports = [ 'WORK_TIME', 'SALES_CAPACITY' ]

export const teamAllowedReports = [ 'WORK_TIME', 'PRODUCTIVITY_DETAILED', 'PLAN_EXECUTION' ]

export const workcenterAllowedReports = [ 'WORK_TIME', 'PRODUCTIVITY_DETAILED', 'PLAN_EXECUTION' ]

export const teamWorkcenterSelecionAllowedReports = [ 'PRODUCTIVITY_DETAILED', 'PLAN_EXECUTION' ]

export const futureAllowedReports = [ 'SALES_CAPACITY', 'WORK_TIME' ]
