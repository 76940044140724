import Ajv from 'ajv'
import { unblockMainThread } from './../plugins/unblockMainThread'

const ajv = new Ajv({ allErrors: true })

// This is used to get the total weight for products. Used to calculate transport price.

const schema = {
  type: 'object',
  required: ['products', 'type'],
  properties: {
    products: {
      type: 'array',
      required: ['materials'],
      properties: {
        materials: {
          type: 'array'
        }
      }
    },
    type: {
      type: 'string',
      enum: [
        'size', 'actualSize'
      ]
    }
  }
}

const test = ajv.compile(schema)

export default async function _getMaterialTotalWeight (products, type = 'size') {
  if (!test({products, type})) {
    throw { function: '_getMaterialTotalWeight', error: test.errors }
  }

  const calculations = []

  for await (const product of products) {
    for await (const material of product.materials) {
      await unblockMainThread()
      calculations.push((material[type] * material.specialWeight) * product.quantity.amount)
    }
  }

  const calc = calculations.reduce((a, b) => +a + +b, 0)

  return calc
}
