import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getWorkcenters (query) {
  return axios({
    url: `/api/workcenter?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getWorkcentersNames (filters) {
  return axios({
    url: `/api/workcenter/name?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addWorkcenter (data) {
  return axios({
    url: `/api/workcenter`,
    method: 'POST',
    data,
    headers: { Authorization: token() }
  })
}

function updateWorkcenter (id, data) {
  return axios({
    url: `/api/workcenter/${id}`,
    method: 'PUT',
    data,
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$workcenters = {
    getWorkcenters,
    getWorkcentersNames,
    addWorkcenter,
    updateWorkcenter
  }
}

export {
  getWorkcenters,
  getWorkcentersNames,
  addWorkcenter,
  updateWorkcenter
}
