import Ajv from 'ajv';
import _getProductMaterialCost from './_getProductMaterialCost'
import _getProductWorkTime from './_getProductWorkTime'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['quantity', 'price'],
  properties: {
    price: {
      type: 'number'
    },
    quantity: {
      type: 'object',
      required: ['amount'],
      properties: {
        amount: { type: 'number' }
      }
    }
  }
}

const test = ajv.compile(schema)

export default async function _getProductNetPrice (product) {
  if (!test(product)) {
    throw { function: '_getProductNetPrice', error: test.errors }
  }

  const materialCost = await _getProductMaterialCost(product)
  const laborCost = await _getProductWorkTime(product)
  const price = +materialCost.total + +laborCost.total
  return price.toFixed(2)
}
