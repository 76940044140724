export default () => [
  { name: 'Töö ootel', value: 'PREPARATION'},
  { name: 'Abinõude valmistamine', value: 'PENDING_WORK'},
  { name: 'Parendustegevused', value: 'IMPROVEMENT_ACTIVITY'},
  { name: 'Koosolekud', value: 'MEETINGS'},
  { name: 'Logistika', value: 'LOGISTICS'},
  { name: 'Hooldus/Remont', value: 'SERVICE'},
  { name: 'Raporteerimise parandused', value: 'PROOF_CORRECTIONS' },
  { name: 'Muu', value: 'OTHER'}
]
