<template>
  <b-modal
    id="add-edit-quality-reports"
    :title="modalTitle"
    size="lg"
    ok-title="Lisa"
    cancel-title="Tagasi"
    scrollable
    @hide="$emit('hide')"
  >
    <div>
      <b-row class="px-xl-3">
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="status">Staatus</label>
          <b-select
            v-model="formData.status"
            v-validate="'required'"
            v-bind="$requiredInputProps('status')"
            placeholder="Staatus"
          >
            <option v-for="(option, optionIndex) in statusOptions" :key="'statusoption-' + optionIndex" :value="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="status">Tüüp</label>
          <b-select
            v-model="formData.type"
            v-validate="'required'"
            v-bind="$requiredInputProps('status')"
            placeholder="Staatus"
          >
            <option v-for="(option, optionIndex) in typeOptions" :key="'typeoption-' + optionIndex" :value="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Tellimuse/Projekti ID</label>
          <input
            v-model="formData.salesSystemNumber"
            v-bind="$requiredInputProps('salesSystemNumber')"
          >
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="add-delivery-date">Tähtaeg</label>
          <datepicker
            v-model="formData.deadline"
            v-bind="$datepickerProps('deadline')"
            name="deadline"
          />
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="status">Klient</label>
          <multiselect
            v-model="formData.client"
            v-faf-autocomplete-off
            :options="clientNameSuggestions.data"
            v-bind="$multiselectProps('client')"
            name="client"
            no-options="Alusta trükkimist..."
            @search-change="getClientNameSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
            <template slot="noResult">
              Tulemused puuduvad
            </template>
          </multiselect>
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="status">Kontakt</label>
          <b-select
            v-model="formData.contacts"
            v-bind="$requiredInputProps('contacts')"
            placeholder="Kontakt"
          >
            <option v-for="(option, optionIndex) in getClientContactsOptions" :key="'contactsoption-' + optionIndex" :value="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-col>

        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Töökeskus</label>
          <multiselect
            v-model="formData.workcenter"
            v-faf-autocomplete-off
            v-bind="$multiselectProps('workcenter')"
            :options="workcenterSuggestions.data"
            name="workcenter"
            no-options="Alusta trükkimist..."
            @search-change="getWorkcenterSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
            <template slot="noResult">
              Tulemused puuduvad
            </template>
          </multiselect>
        </b-col>

        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Tiim</label>
          <multiselect
            v-model="formData.team"
            v-faf-autocomplete-off
            v-bind="$multiselectProps('team')"
            :options="teamSuggestions.data"
            name="team"
            no-options="Alusta trükkimist..."
            @search-change="getTeamSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
            <template slot="noResult">
              Tulemused puuduvad
            </template>
          </multiselect>
        </b-col>

        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Toode</label>
          <input
            v-model="formData.product"
            v-bind="$requiredInputProps('product')"
          >
        </b-col>

        <b-col xl="12" md="12" cols="12" class="p-xl-1 mb-2">
          <label class="mb-0">Probleemi kirjeldus</label>
          <b-form-textarea
            v-model="formData.notes"
            placeholder="Kirjuta..."
            class="form-control"
            rows="3"
            max-rows="6"
          />
        </b-col>

        <b-col xl="12" md="12" cols="12" class="p-xl-1 mb-2">
          <label class="mb-0">Põhjuste analüüs</label>
          <b-form-textarea
            v-model="formData.analysisOfCauses"
            placeholder="5 miksi, Pareto või Ishikawa diagramm e. kalaluu diagramm"
            class="form-control"
            rows="3"
            max-rows="6"
          />
        </b-col>

        <b-col xl="12" md="12" cols="12" class="p-xl-1 mb-2">
          <label class="mb-0">Korrigeerivad tegevused</label>
          <b-form-textarea
            v-model="formData.correctiveActions"
            placeholder="Mis peab olema tehtud probleemi kõrvaldamiseks, kas parandatakse või tehakse uus?"
            class="form-control"
            rows="3"
            max-rows="6"
          />
        </b-col>
        <b-col xl="12" md="12" cols="12" class="p-xl-1 mb-2">
          <label class="mb-0">Parendus või ennetavad tegevused</label>
          <b-form-textarea
            v-model="formData.preventableActions"
            placeholder="Mida tehakse antud vea/probleemi välistamiseks edasipidi?"
            class="form-control"
            rows="3"
            max-rows="6"
          />
        </b-col>
        <b-col xl="12" md="12" cols="12" class="p-xl-1">
          <h4 class="mt-3 mb-0 text-info">
            Kuluarvestus
          </h4>
          <hr class="m-0 p-xl-1" />
        </b-col>
      </b-row>
      <b-row v-for="(item, i) in formData.costAccounting" :key="'cost-accounting-' + i" class="px-xl-3">
        <b-col>
          <b-row>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Kululiik / Nimetus</label>
              <input
                v-model="item.name"
                v-bind="$requiredInputProps('cost-accounting-name')"
              >
            </b-col>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Summa</label>
              <input
                v-model="item.price"
                v-bind="$requiredInputProps('cost-accounting-price')"
              >
            </b-col>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Vastutaja nimi</label>
              <input
                v-model="item.responsiblePerson"
                v-bind="$requiredInputProps('cost-accounting-responsible-person')"
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" align-self="end" class="px-0">
          <b-button
            class="icon-button text-info m-0"
            @click="addCostAccounting(i)"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
          <b-button
            :disabled="costAccountingDeleteDisabled"
            class="icon-button text-danger m-0"
            @click="deleteCostAccounting(i)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="px-xl-3">
        <b-col xl="12" md="12" cols="12" class="p-xl-1">
          <h4 class="mt-3 mb-0 text-info">
            Etapid ja otsused
          </h4>
          <hr class="m-0 p-xl-1" />
        </b-col>
      </b-row>

      <b-row v-for="(item, i) in formData.actions" :key="'actions-' + i" class="px-xl-3">
        <b-col>
          <b-row>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Kuupäev</label>
              <datepicker
                v-model="item.date"
                v-bind="$datepickerProps('actions-date')"
                name="actions-date"
              />
            </b-col>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Läbitud etapid ja otsused</label>
              <input
                v-model="item.completedStages"
                v-bind="$requiredInputProps('actions-completed-stages')"
              >
            </b-col>
            <b-col xl="4" md="2" cols="3" class="p-xl-1">
              <label v-show="i === 0" class="mb-0">Vastutaja nimi</label>
              <input
                v-model="item.responsiblePerson"
                v-bind="$requiredInputProps('actions-responsible-person')"
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" align-self="end" class="px-0">
          <b-button
            class="icon-button text-info m-0"
            @click="addActions(i)"
          >
            <font-awesome-icon icon="plus-circle" />
          </b-button>
          <b-button
            :disabled="actionsDeleteDisabled"
            class="icon-button text-danger m-0"
            @click="deleteActions(i)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('add-edit-quality-reports')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button
          v-can="['see', 'FileManager']"
          v-b-tooltip.hover
          v-b-modal.file-manager
          :disabled="!formData._id"
          class="icon-button text-info"
          title="Dokumendid"
        >
          <font-awesome-icon icon="folder-open" />
        </b-button>
        <faf-file-manager v-if="formData._id" :order-object-id="formData._id" :pre-loaded-documents="formData.documents" type="QUALITY_REPORTS" />
        <b-button v-b-tooltip.hover :disabled="$loader.val('add-edit-quality-reports')" class="icon-button text-success" title="Salvesta" @click="submit">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  const emptyCostAccounting = {
    name: null,
    price: null,
    responsiblePerson: null
  }

  const emptyActions = {
    date: null,
    completedStages: null,
    responsiblePerson: null
  }

  export default {
    name: 'add-edit-quality-reports',
    data () {
      return {
        formData: {
          _id: null,
          status: 'OPEN',
          type: 'INTERNAL',
          client: '',
          address: '',
          documents: [],
          contacts: {
            name: null,
            email: '',
            phone: ''
          },
          costAccounting: [{
            ...this.$_cloneDeep(emptyCostAccounting)
          }],
          actions: [{
            ...this.$_cloneDeep(emptyActions)
          }]
        },
        statusOptions: [
          { label: 'Avatud', value: 'OPEN'},
          { label: 'Suletud', value: 'CLOSED'},
          { label: 'Ettepanek', value: 'PROPOSAL'}
        ],
        typeOptions: [
          { label: 'Väline', value: 'EXTERNAL'},
          { label: 'Sisene', value: 'INTERNAL'},
          { label: 'Tarnija', value: 'SUPPLIER'},
          { label: 'Parendus', value: 'IMPROVEMENT'}
        ],
        workcenterSuggestions: { data: [] },
        teamSuggestions: { data: [] },
        clientNameSuggestions: { data: [] },
        clientContacts: { contacts: [] }
      }
    },
    computed: {
      modalTitle () {
        return this.formData._id ? 'Muuda kvaliteediaruannet' : 'Lisa uus kvaliteediaruanne'
      },
      getClientContactsOptions () {
        return this.clientContacts.contacts && this.clientContacts.contacts.map(c => {
          return {
            label: `${c.name || ''} ${c.email || ''} ${c.phone || ''}`,
            value: c
          }
        })
      },
      costAccountingDeleteDisabled () {
        return this.formData.costAccounting.length <= 1
      },
      actionsDeleteDisabled () {
        return this.formData.actions.length <= 1
      }
    },
    watch: {
      formData (val) {
        val.status && this.$bvModal.show('add-edit-quality-reports')
        if (!this.formData.costAccounting || this.formData.costAccounting.length === 0) {
          this.formData.costAccounting = [{
            ...this.$_cloneDeep(emptyCostAccounting)
          }]
        }
        if (!this.formData.actions || this.formData.actions.length === 0) {
          this.formData.actions = [{
            ...this.$_cloneDeep(emptyCostAccounting)
          }]
        }
      },
      'formData.client' (newVal, oldVal) {
        if (oldVal && newVal && oldVal !== newVal) {
          this.formData.contacts = {}
        }
        this.getClientContacts(newVal)
      },
      'formData.supplier.contact.name' (val) {
        const foundContact = this.ClientContacts.contacts.find(contact => contact.name === val)
        if (foundContact) {
          this.setClientContact(foundContact)
        }
      }

    },
    created () {
      this.getClientNameSuggestions = this.$_debounce(this.getClientNameSuggestions, 700)
      this.getClientContacts = this.$_debounce(this.getClientContacts, 400)
      this.getWorkcenterSuggestions = this.$_debounce(this.getWorkcenterSuggestions, 400)
    },
    methods: {
      addCostAccounting (i) {
        this.formData.costAccounting.splice(i + 1, 0, { ...this.$_cloneDeep(emptyCostAccounting)})
      },
      deleteCostAccounting (i) {
        this.formData.costAccounting.splice(i, 1)
      },
      addActions (i) {
        this.formData.actions.splice(i + 1, 0, { ...this.$_cloneDeep(emptyActions)})
      },
      deleteActions (i) {
        this.formData.actions.splice(i, 1)
      },

      async getWorkcenterSuggestions (e) {
        if (e.length >= 2) {
          this.workcenterSuggestions = await this.$workcenters.getWorkcentersNames({name: e})
        }
      },
      async getTeamSuggestions (e) {
        if (e.length >= 2) {
          this.teamSuggestions = await this.$suggestions.getTeamNames({name: e})
        }
      },
      async getClientNameSuggestions (e) {
        if (e.length >= 2) {
          this.clientNameSuggestions = await this.$clients.getClientsNames({name: e})
        }
      },
      async getClientContacts (val) {
        const client = val ? (await this.$clients.getAllClients({ page: 1, limit: 1, filters: { name: val } })).data.docs[0] : null
        if (client) {
          this.clientContacts = client
          this.clientContacts.data = client.contacts.map(contact => contact.name)
        } else {
          this.clientContacts = { contacts: [] }
        }

        (this.clientContacts.contacts && !this.formData.contacts.name) && this.setClientContact(this.clientContacts.contacts[0])
      },
      setClientContact (contact) {
        this.formData.contacts = this.$_cloneDeep(contact)
      },
      async edit () {
        return await this.$qualityReports.updateQualityReport(this.formData._id, this.formData)
      },
      async add () {
        return await this.$qualityReports.addQualityReport({ ...this.formData, author: `${ this.$user.getFirstName() } ${ this.$user.getLastName() }` })
      },
      async submit () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('add-edit-quality-reports')
          await this[this.formData._id ? 'edit' : 'add']()
            .then((rs) => {
              if (!this.formData._id) this.formData._id = rs.data._id
              //this.$bvModal.hide('add-edit-quality-reports')
              //this.$emit('quality-report-added')
              //this.$cardReset('quality-reports')
              this.$notify.show(this.formData._id ? 'Aruanne edukalt uuendatud!' : 'Aruanne edukalt lisatud!')
            })
            .catch(err => this.$err.show())
          this.$loader.stop('add-edit-quality-reports')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  textarea.form-control {
    padding: 0.25rem 0.5rem;
    border: 1px solid #2b3553 !important;
  }
  textarea.form-control:active,
  textarea.form-control:focus {
    border: 1px solid #e14eca !important;
  }
</style>
