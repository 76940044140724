function extendVeeValidate (VeeValidate) {
  VeeValidate.extend('validate_username', {
    validate: value => validationRules.usernameValidator(value)
  })

  VeeValidate.extend('validate_name', {
    validate: value => validationRules.nameValidator(value)
  })
}

const validationRules = {
  usernameValidator (value) {
    const rule = /^[a-zA-Z]+[.a-zA-Z]?[a-zA-Z]+$/

    return rule.exec(value) != null
  },
  nameValidator (value) {
    const rule = /^[a-zA-Z]+[-a-zA-Z]?[a-zA-Z]+$/

    return rule.exec(value) != null
  }
}

export {
  extendVeeValidate,
  validationRules
}
