const heartbeats = require('heartbeats')
import { get as getSettings } from './../Controllers/Settings'
import Vue from 'vue'
import isEqual from 'lodash/isEqual'

async function heartbeat (options, callback) {
  let heart = heartbeats.createHeart(options.beatAfter)
  await _syncSettings()
  await _isNewestVersion()
  const currentTime = new Date()
  let currentTimeInMilliseconds = currentTime.getTime()
  if (options.timeout > currentTimeInMilliseconds) {
    heart.createEvent(1, async (count, last) => {
      await _isNewestVersion()
      await _syncSettings()
      const currentTime = new Date()
      currentTimeInMilliseconds = currentTime.getTime()
      if ((options.timeout < currentTimeInMilliseconds) || !localStorage.user) {
        localStorage.removeItem('user');
        localStorage.removeItem('sessionEndTime');
        heart.kill()
        heart = null
        callback()
      }
    })
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem('sessionEndTime');
    heart.kill()
    heart = null
    callback()
  }
}

export default heartbeat

async function _syncSettings () {
  try {
    const { data } = await getSettings()
    const { global, orderSettings, materialSettings } = data
    if (global && !isEqual(global, Vue.prototype.$company)) {
      Vue.prototype.$company = { ...global }
      localStorage.settings = JSON.stringify({ ...global })
    }
    if (orderSettings && !isEqual(orderSettings, Vue.prototype.$orderSettings)) {
      Vue.prototype.$orderSettings = { ...orderSettings }
      localStorage.orderSettings = JSON.stringify({ ...orderSettings })
    }
    if (materialSettings && !isEqual(materialSettings, Vue.prototype.$materialSettings)) {
      Vue.prototype.$materialSettings = { ...materialSettings }
      localStorage.materialSettings = JSON.stringify({ ...materialSettings })
    }
  } catch (error) {
    console.error(error)
  }

}

import axios from 'axios'
Vue.prototype.$versionMismatch = Vue.observable({ value: false });

async function _isNewestVersion () {
  const newestVerson = (await axios.get('/build-version')).data
  const currentVersion = process.env.VUE_APP_BUILD_VERSION

  if (newestVerson != currentVersion) {
    Vue.prototype.$versionMismatch.value = true
  }
}
