<template>
  <faf-card :tags="tags" title="Filtrid">
    <b-row class="px-2">
      <b-col
        v-for="(field, index) in fields"
        :key="index"
        v-can="field.can ? field.can : ['action', 'Filter']"
        :lg="cols || 2"
        class="px-1 py-1"
      >
        <div :class="{ 'field-with-tooltip': field.info }" class="position-relative">
          <label v-if="field.label" :for="field.name">{{ field.label }}</label>
          <component
            :is="field.type"
            v-model="query[field.name]"
            v-faf-autocomplete-off
            :options="field.options"
            v-bind="{ ...$multiselectProps(field.name), ...$datepickerProps(field.name), ...field.props }"
            :value="field.value"
            :unchecked-value="field.unchecked"
            :placeholder="field.placeholder || ''"
            :taggable="!!field.taggable"
            clear-button
            @input="filter"
            @open="getOptions(field.name)"
            @search-change="searchChange(field.name, $event)"
            @tag="addTag(field.name, $event)"
          >
            <template slot="noOptions">
              {{ noOptionsLabel }}
            </template>
            <span>{{ field.placeholder }}</span>
          </component>
          <b-button v-if="field.info" v-b-tooltip.hover :title="field.info" class="icon-tooltip icon-button text-info">
            <font-awesome-icon icon="info-circle" />
          </b-button>
        </div>
      </b-col>

      <slot name="slotFields" />

      <b-col align-self="end" />
    </b-row>
    <template slot="buttons">
      <b-button
        v-if="tags && tags.length"
        v-b-tooltip.hover
        class="icon-button text-warning"
        title="Eemalda filtrid"
        @click="clearFilters"
      >
        <font-awesome-icon icon="times-circle" />
      </b-button>
      <b-button v-b-tooltip.hover class="icon-button text-info" size="small" title="Otsi" @click="filter">
        <font-awesome-icon icon="search" />
      </b-button>
    </template>
  </faf-card>
</template>
<script>
  export default {
    name: 'faf-filters',
    props: {
      fields: {
        type: Array,
        required: true
      },
      noOptionsLabel: {
        type: String,
        default: 'Alusta trükkimist...'
      },
      urlCache: {
        type: Boolean,
        default: false
      },
      cols: [String, Number],
      filterOnMounted: {
        type: Boolean,
        default: false
      },
      initialQuery: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        tags: [],
        query: (this.$route.query.filter && this.urlCache) ? JSON.parse(this.$route.query.filter) : this.$_cloneDeep(this.initialQuery)
      }
    },
    created () {
      this.searchChange = this.$_debounce(this.searchChange, 700)
      //this.filter()
    },
    mounted () {
      if (this.urlCache) {
        this.updateTags()
      }
    },
    methods: {
      addTag (fieldName, val) {
        this.query[fieldName] = val
        this.$emit('addTag', fieldName, val)
        this.filter()
      },
      searchChange (name, event) {
        if (event.length >= 2) {
          this.$emit('search-change', { name, event })
        }
      },
      filter () {
        if (this.urlCache) {
          this.$router.replace({query: {
            ...Object.keys(this.query).length && {filter: JSON.stringify(this.$_cloneDeep(this.query))},
            ...this.$route.query.page && { page: this.$route.query.page }
          }}).catch(() => {})
        }
        this.updateTags()
        this.$emit('filter', this.query)

        delete this.query.page
      },
      clearFilters () {
        this.query = this.$_cloneDeep(this.initialQuery) || {}
        this.tags = []
        this.$router.replace({query:  this.initialQuery }).catch(() => {})
        this.$emit('filter', this.query)
      },
      getOptions (name) {
        this.$emit('getOptions', name)
      },
      updateTags () {
        this.tags = []
        Object.keys(this.query).map(key => {
          const text = this.fields.find(field => field.name === key)
          if (text) {
            if (this.query[key] instanceof Date) {
              this.tags.push((text.label || text.placeholder) + ': ' + this.$dayjs(this.query[key]).format('DD.MM.YYYY'))
            } else if ((this.query[key] instanceof Object)) {
              this.tags.push((text.label || text.placeholder) + ': ' + this.query[key].label)
            } else if ((this.query[key] === true)) {
              this.tags.push((text.label || text.placeholder))
            } else if ((this.query[key] === null)) {
              //this.tags.push((text.label || text.placeholder))
            } else {
              (this.query[key] !== false) && this.tags.push((text.label || text.placeholder) + ': ' + this.query[key])
            }
          }
        });
      },
      paddingClasses (i) {
        switch (i) {
        case 0:
          return 'pr-1'
        case this.fields.length - 1:
          return 'pl-1'
        default:
          return 'px-1'
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .custom-checkbox {
    padding-top: 4px;
  }
</style>
