import Ajv from 'ajv'

const ajv = new Ajv({ allErrors: true })

// Kõikide toodete seadistusaeg

const schema = {
  type: 'object',
  required: ['materialCostTotal', 'workTimeTotal'],
  properties: {
    materialCostTotal: {
      type: 'object',
      required: ['total']
    },
    workTimeTotal: {
      type: 'object',
      required: ['total']
    },
    setupTimeTotal: {
      type: 'string'
    }
  }
}

const test = ajv.compile(schema)

export default async function _getNetCostTotal (materialCostTotal, workTimeTotal, transport, setupTimeTotal) {
  if (!test({materialCostTotal, workTimeTotal, setupTimeTotal})) {
    throw { function: '_getNetCostTotal', error: test.errors }
  }

  return (+materialCostTotal.total + +workTimeTotal.total + +transport + +setupTimeTotal).toFixed(2)
}
