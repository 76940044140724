<template>
  <b-modal
    id="add-edit-workcenter"
    :title="modalTitle"
    size="lg"
    ok-title="Lisa"
    cancel-title="Tagasi"
    scrollable
    @hide="$emit('hide')"
  >
    <div>
      <h4 class="mt-0 text-info">
        Töökeskus
      </h4>
      <b-row class="px-2">
        <b-col cols="6" class="px-1">
          <label>Nimi</label>
          <input
            v-model="formData.name"
            v-validate="'required'"
            v-bind="$requiredInputProps('name')"
            placeholder="Nimi"
          >
        </b-col>
        <b-col cols="6" class="px-1">
          <label>Tiim</label>
          <multiselect
            v-model="formData.team"
            v-faf-autocomplete-off
            v-bind="$multiselectProps('team', false)"
            :options="teamSuggestions.data"
            placeholder="Tiim"
            @tag="addTag('teamSuggestions', `formData.team`, $event)"
            @search-change="getTeamSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
          </multiselect>
        </b-col>
        <b-col cols="4" class="px-1">
          <label>Seadistusaeg (min)</label>
          <input
            v-model="formData.setupTime"
            v-validate="'required'"
            v-bind="$requiredInputProps('setupTime', true)"
            :name="'setupTime'"
            placeholder="Seadistusaeg (min)"
            type="number"
          >
        </b-col>
        <b-col cols="4" class="px-1">
          <label>Läbilase</label>
          <input
            v-model="formData.leadTime"
            v-validate="'required'"
            v-bind="$requiredInputProps('leadTime', true)"
            :name="'leadTime'"
            placeholder="Läbilase"
            type="number"
          >
        </b-col>
        <b-col cols="4" class="px-1">
          <label>Hind</label>
          <input
            v-model="formData.price"
            v-validate="'required'"
            v-bind="$requiredInputProps('price')"
            :name="'price'"
            class="form-control"
            placeholder="Hind"
            type="number"
          >
        </b-col>
      </b-row>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('add-edit-workcenter')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('add-edit-workcenter')" class="icon-button text-success" title="Salvesta" @click="submit">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'add-edit-workcenter',
    data () {
      return {
        formData: {
          setupTime: 0,
          leadTime: 0,
          price: 0.42
        },
        teamSuggestions: { data: [] }
      }
    },
    computed: {
      modalTitle () {
        return this.formData._id ? 'Muuda töökeskust' : 'Lisa uus töökeskus'
      }
    },
    watch: {
      formData (val) {
        val.name && this.$bvModal.show('add-edit-workcenter')
      }
    },
    created () {
      this.getTeamSuggestions = this.$_debounce(this.getTeamSuggestions, 700)
    },
    methods: {
      addTag (type, model, val) {
        this[type].data.push(val)
        this.$_set(this, model, val)
      },
      async getTeamSuggestions (e) {
        if (e.length >= 2) {
          this.teamSuggestions = await this.$suggestions.getTeamNames({name: e})
        }
      },
      async editWorkcenter () {
        return await this.$workcenters.updateWorkcenter(this.formData._id, {
          name: this.formData.name,
          team: this.formData.team,
          setupTime: this.formData.setupTime,
          leadTime: this.formData.leadTime,
          price: this.formData.price
        })
      },
      async addWorkcenter () {
        return await this.$workcenters.addWorkcenter(this.formData)
      },
      async submit () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('add-edit-workcenter')
          await this[this.formData._id ? 'editWorkcenter' : 'addWorkcenter']()
            .then(() => {
              this.$bvModal.hide('add-edit-workcenter')
              this.$emit('workcenter-added')
            })
            .catch(err => {
              if (err.response) {
                err.response.data.code === 11000 ? this.$err.show('Töökeskus eksisteerib juba!') : this.$err.show()
              } else {
                this.$err.show()
              }

            })
          this.$loader.stop('add-edit-workcenter')
        }
      }
    }
  }
</script>
