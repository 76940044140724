import Ajv from 'ajv';
import _getTotalSalePrice from './_getTotalSalePrice'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['products'],
  properties: {
  }
}

const test = ajv.compile(schema)

export default async function _getDiscountPercentage (order) {

  if (!test(order)) {
    throw { function: '_getDiscountPercentage', error: test.errors }
  }

  const totalSalePrice = await _getTotalSalePrice(order.products)
  return +order.discount / +totalSalePrice

}
