<template>
  <div>
    <faf-filters :fields="filterFields" :cols="4" @search-change="searchChange" @filter="getWorkcenters({ filters: $event })" />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('workcenters-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4"
            title="Vaata/Muuda"
            @click="$refs['add-edit-workcenter'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getWorkcenters({ page: $event })"
    />
    <add-edit-workcenter ref="add-edit-workcenter" :key="addWorkcenterModal" @workcenter-added="getWorkcenters()" @hide="addWorkcenterModal = !addWorkcenterModal" />
  </div>
</template>
<script>
  import AddEditWorkcenter from './AddEditWorkcenter'

  export default {
    name: 'workcenters-table',
    components: {
      AddEditWorkcenter
    },
    data () {
      return {
        addWorkcenterModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Nimi'
          },
          {
            key: 'team',
            label: 'Tiim'
          },
          {
            key: 'setupTime',
            label: 'Seadistus aeg',
            formatter: value => `${value} min`
          },
          {
            key: 'leadTime',
            label: 'Läbilase',
            formatter: value => `${value} päev`
          },
          {
            key: 'price',
            label: 'Hind',
            formatter: (value, key, item) => `${value} €`
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Töökeskuse nimi', options: [] }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getWorkcenters()
    },
    methods: {
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getWorkcenterNames({ [e.name]: e.event })).data
      },
      async getWorkcenters (event) {
        this.$loader.start('workcenters-table')
        const page = (event && event.page) || this.currentPage
        const { data } = await this.$workcenters.getWorkcenters({ page, limit: this.perPage, filters: (event && event.filters) })
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('workcenters-table')
      }
    }
  }
</script>
