const documents = [
  // SALES
  'WORKORDER', 'PRODUCT_WORKORDER', 'SALES_PREPAYMENT', 'SALES_PRICE_INQUIRY', 'SALES_BILL_OF_DELIVERY', 'SALES_ORDER_CONFIRMATION',
  // PURCHASE
  'PURCHASE_PRICE_INQUIRY', 'PURCHASE_ORDER',
  // PRODUCTION
  'PRODUCT_SHEET'
]

const orderFields = [
  'material-width', 'salesSystemNumber', 'orderId', 'projectName', 'setup-time', 'linkedMaterials', 'statusOffer', 'readyProducts', 'linkedMaterialsInProduction', 'orderIdMultiselect'
]

const action = [
  'ProductStopped', 'Filter', 'FilterDoneProducts', 'FilterSuppliers', 'FilterWorksheet', 'StartSingleJob', 'FilterClients', 'FilterSubStatus', 'FilterWorkcenterWithLinkedMaterialsParameters',
  'CopyTrackingLink', 'FilterPerPage', 'DownloadSalesExcel', 'leftoverPercentage'
]

const orderSubStatus = [
  'OFFER_PREPARED', 'OFFER_EMAIL_SENT', 'ORDER_PACKED', 'ORDER_EMAIL_SENT', 'ORDER_SENT', 'PRODUCTION_ORDER'
]

const orderStatus = [
  'PRODUCTION_ORDER'
]

const statistics = [
  'WORK_TIME', 'PRODUCTIVITY_DETAILED', 'SALES_CAPACITY', 'PLAN_EXECUTION'
]

export const lapiPrivileges = {
  'admin': {
    see: [
      'Tootmine', 'Müük', 'Sätted', 'Kasutajad', 'Minu asjad', 'Ost', 'Tellimused',
      'Supplier', 'Client', 'FileManager', 'Material', 'Workcenter', 'Statistics',
      'Kvaliteediaruanded', 'documentAccessTypeManagement', 'EmailSending', 'Juhtimissüsteem',
      'detailedProject', 'Reports'
    ],
    add: [
      'Kasutaja', 'Müük', 'Ost', 'Supplier', 'WorksheetCertificate', 'Client', 'Material', 'Workcenter'
    ],
    action,
    orderFields,
    documents,
    orderSubStatus,
    orderStatus,
    statistics
  },
  'manager': {
    see: [
      'Tootmine', 'Müük', 'Minu asjad', 'Ost', 'Tellimused', 'Supplier', 'Sätted',
      'Client', 'FileManager', 'Material', 'Workcenter', 'Statistics', 'Kvaliteediaruanded',
      'documentAccessTypeManagement', 'EmailSending', 'Juhtimissüsteem',
      'detailedProject', 'Reports'
    ],
    add: [
      'Müük', 'Ost', 'Supplier', 'WorksheetCertificate', 'Client', 'Material', 'Workcenter'
    ],
    action,
    orderFields,
    documents,
    orderSubStatus,
    orderStatus,
    statistics
  },
  'employee': {
    see: [
      'Tootmine', 'Minu asjad', 'FileManager', 'Juhtimissüsteem', 'Statistics'
    ],
    add: [
      'WorksheetCertificate'
    ],
    action,
    orderFields,
    documents,
    orderSubStatus,
    orderStatus,
    statistics: [
      'PRODUCTIVITY_DETAILED', 'PLAN_EXECUTION'
    ]
  },
  'BASIC': {
  }
}
