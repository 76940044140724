export default function installFafAutocompleteOff (Vue = false) {
  if (Vue) {
    Vue.directive('faf-autocomplete-off', {
      update (el) {
        const input = el.getElementsByTagName("input")
        if (input[0].hasAttribute('autocomplete') !== 'off') {
          input[0].removeAttribute('autocomplete')
          input[0].setAttribute('autocomplete', 'off')
        }

      },
      unbind (el, binding, vnode) {
        const input = el.getElementsByTagName("input")
        input[0].removeAttribute('autocomplete')
      }
    })
  } else {
    const isVueSet = !Vue && 'Vue not found!'
    const dependencies = [
      isVueSet
    ]
    dependencies.map(dependency => {
      if (dependency) {
        throw new Error(dependency)
      }
    })
  }
}
