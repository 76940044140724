<template>
  <b-modal
    id="confirmation-modal"
    :visible="$confirm.isActive()"
    :title="$confirm.getTitle()"
    no-close-on-backdrop
    size="sm"
    title-tag="h4"
    @close="$confirm.hide()"
  >
    <p class="error-wrapper">
      <font-awesome-icon class="text-warning" icon="info-circle" />
      <span>{{ $confirm.getMessage() }}</span>
    </p>
    <div slot="modal-footer" class="container">
      <div class="row justify-content-end">
        <div class="col col-md-3 pl-0 pr-1">
          <b-button
            block
            variant="text-danger"
            size="m"
            class="w-100"
            @click="$confirm.hide()"
          >
            Ei
          </b-button>
        </div>
        <div class="col col-md-3 pr-0 pl-1">
          <b-button
            block
            variant="primary"
            size="m"
            class="w-100"
            @click="$confirm.agree()"
          >
            Jah
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'confirmation-modal',
    data () {
      return {
        visible: false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .error-wrapper {
    display: flex;
    align-items: center;
    svg {
      font-size: 3.5em;
    }
    span {
      padding-left: 10px;
      align-self: center;
    }
  }
</style>
