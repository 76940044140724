import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${filters[field]}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

function getJobs (filters) {
  return axios({
    url: `/api/get-jobs?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function startJob (data) {
  return axios({
    url: `/api/start-job`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function updateJob (data) {
  return axios({
    url: `/api/update-job`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function completeJob (id, overTime, calculateWorkTimeOnly) {
  return axios({
    url: `/api/complete-job/${id}?overTime=${overTime}&calculateWorkTimeOnly=${calculateWorkTimeOnly}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function pauseJob (id) {
  return axios({
    url: `/api/pause-job/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function unPauseJob (id) {
  return axios({
    url: `/api/unpause-job/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function annullJob (id) {
  return axios({
    url: `/api/annull-job/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function workTimeTable (operatorId) {
  return axios({
    url: `/api/worktime-table/${operatorId}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getSuggestions (field, value) {
  return axios({
    url: `/api/work-typeahead/${field}?value=${value}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$work = {
    startJob,
    updateJob,
    getJobs,
    completeJob,
    annullJob,
    workTimeTable,
    pauseJob,
    unPauseJob,
    getSuggestions
  }
}

export {
  startJob,
  updateJob,
  getJobs,
  completeJob,
  annullJob,
  workTimeTable,
  pauseJob,
  unPauseJob,
  getSuggestions
}
