<template>
  <div>
    <faf-filters :fields="filterFields" :cols="4" @search-change="searchChange" @filter="getSuppliers({ filters: $event })" />
    <div style="max-height: 450px; overflow: scroll;" class="table-responsive">
      <b-table
        :fields="fields"
        :per-page="perPage"
        :items="items"
        :busy="$loader.val('suppliers-table')"
        striped
        stacked="xl"
        hover
        empty-text="Andmed puuduvad"
      >
        <template v-slot:table-busy>
          <div class="text-center text-primary my-3">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            v-b-tooltip.left
            style="padding: 0; font-size: 18px;"
            class="icon-button text-info ml-4"
            title="Vaata/Muuda"
            @click="$refs['add-edit-supplier'].formData = $_cloneDeep(data.item)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>
        </template>
      </b-table>
    </div>
    <faf-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="my-2"
      align="center"
      @change="getSuppliers({ page: $event })"
    />
    <add-edit-supplier ref="add-edit-supplier" :key="addSupplierModal" @supplier-added="getSuppliers()" @hide="addSupplierModal = !addSupplierModal" />
  </div>
</template>
<script>
  import AddEditSupplier from './AddEditSupplier'

  export default {
    name: 'supplies-table',
    components: {
      AddEditSupplier
    },
    data () {
      return {
        addSupplierModal: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Firma'
          },
          {
            key: 'address',
            label: 'Aadress'
          },
          { key: 'actions', label: 'Tegevused' }
        ],
        filterFields: [
          { type: 'multiselect', name: 'name', placeholder: 'Tarnija nimi', options: [] }
        ]
      }
    },
    async created () {
      this.searchChange = this.$_debounce(this.searchChange, 500)
      await this.getSuppliers()
    },
    methods: {
      async searchChange (e) {
        this.filterFields[0].options = (await this.$suggestions.getSupplierNames({ [e.name]: e.event })).data
      },
      async getSuppliers (event) {
        this.$loader.start('suppliers-table')
        const page = (event && event.page) || this.currentPage
        const { data } = await this.$suppliers.getSuppliers({page, limit: this.perPage, filters: (event && event.filters)})
        this.items = data.docs || []
        this.totalRows = data.totalDocs
        this.$loader.stop('suppliers-table')
      }
    }
  }
</script>
