<template>
  <div class="row pl-3">
    <b-col md="4" class="px-1">
      <label for="start-date">Algusaeg</label>
      <datepicker
        v-validate="'required'"
        v-model="startDate"
        v-bind="$datepickerProps('start-date')"
        name="start-date"
      />
      <b-form-invalid-feedback v-if="!$validateField('start-date')">
        Väli on nõutud
      </b-form-invalid-feedback>
    </b-col>
    <b-col md="4" class="px-1">
      <label for="end-date">Lõppaeg</label>
      <datepicker
        v-validate="'required'"
        v-model="endDate"
        v-bind="$datepickerProps('end-date')"
        name="end-date"
      />
      <b-form-invalid-feedback v-if="!$validateField('start-date')">
        Väli on nõutud
      </b-form-invalid-feedback>
    </b-col>
    <b-button v-b-tooltip.hover class="icon-button text-info mt-3" @click="generateReport">
      <font-awesome-icon icon="file-excel" />
    </b-button>
  </div>
</template>
<script>
  import { downloadBinaryFile } from './../../../components/GetPdf/downloadFile'
  export default {
    name: 'work-time-excel-report',
    data () {
      return {
        startDate: null,
        endDate: null,
        twoMonthsInMilliseconds: 5184000000
      }
    },
    methods: {
      generateReport () {
        this.$validator.validateAll().then(valid => {
          if (valid) {
            if ((this.endDate.getTime() - this.startDate.getTime()) < this.twoMonthsInMilliseconds) {
              this.$loader.start('generateReport')
              this.$DocumentController.generateWorkTimeReportExcel(this.startDate, this.endDate).then(rs => {
                const headers = {
                  'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                downloadBinaryFile(rs.data, headers, `Tööajaraport-${this.$dayjs(this.startDate).format('DD.MM.YYYY')}-${this.$dayjs(this.endDate).format('DD.MM.YYYY')}.xlsx`)
                this.$loader.stop('generateReport')
              }).catch(err => {
                this.$loader.stop('generateReport')
                this.$err.show('Serveri error')
              })
            } else {
              this.$loader.stop('generateReport')
              this.$err.show('Vale kuupäevade vahemik! (Maksimaalne vahemik on 2 kuud)')
            }
          }
        })
      }
    }
  }
</script>
