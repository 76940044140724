<template>
  <component
    :is="forEmail ? 'div' : 'b-modal'"
    :visible="visible"
    title="Salvesta fail"
    title-tag="h4"
    @hide="close"
  >
    <b-row>
      <b-col v-if="documentTypeOptions.length > 0" v-show="!chosenOne" cols="12">
        <label for="document-type">Dokumendi tüüp</label>
        <select v-model="documentType" v-validate="'required'" v-bind="$requiredInputProps('document-type')">
          <option v-for="(option, optionIndex) in documentTypeOptions" :key="'document-type-option-' + optionIndex" :value="option">
            <span>{{ option.label }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="contactVisible && client" cols="12">
        <label for="client-contact">Kontakt</label>
        <select v-model="clientContact" v-validate="'required'" v-bind="$requiredInputProps('client-contact')">
          <option v-for="(option, optionIndex) in clientData.contacts" :key="'client-contact-option-' + optionIndex" :value="option">
            <span>{{ option.name }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="supplierVisible && supplier" cols="12">
        <label for="supplier-contact">Tarnija kontakt</label>
        <select v-model="supplierContact" v-validate="'required'" v-bind="$requiredInputProps('supplier-contact')">
          <option v-for="(option, optionIndex) in supplierData.contacts" :key="'supplier-contact-option-' + optionIndex" :value="option">
            <span>{{ option.name }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="productNameVisible" cols="12">
        <label for="product-name">Kauba nimetus <i v-if="chosenOne">(PDF failil)</i></label>
        <input
          v-model="formData.productName"
          v-bind="$requiredInputProps('product-name')"
        />
      </b-col>

      <b-col v-if="vatTypeVisible" cols="12">
        <label for="vat-type">KM tüüp <i v-if="chosenOne">(PDF failil)</i></label>
        <select v-model="formData.vatType" v-validate="'required'" v-bind="$requiredInputProps('vat-type')">
          <option v-for="(option, optionIndex) in vatTypes" :key="'vat-type-option-' + optionIndex" :value="option">
            <span>{{ option }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="documentSubTypeVisible" cols="12">
        <label for="document-sub-type">Tüüp <i v-if="chosenOne">(PDF failil)</i></label>
        <select v-model="formData.documentSubType" v-validate="'required'" v-bind="$requiredInputProps('document-sub-type')">
          <option v-for="(option, optionIndex) in documentSubTypes" :key="'document-sub-type-option-' + optionIndex" :value="option.value">
            <span>{{ option.label }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="clientOrderNumberVisible" cols="12">
        <label for="client-contract-number">Kliendi tellimuse nr <i v-if="chosenOne">(PDF failil)</i></label>
        <input v-model="formData.clientOrderNumber" v-validate="'required'" v-bind="$requiredInputProps('client-contract-number')" />
      </b-col>

      <b-col v-if="deliveryTermsVisible" cols="12">
        <label for="delivery-terms">Tarnetingimus <i v-if="chosenOne">(PDF failil)</i></label>
        <input v-model="formData.deliveryTerms" v-bind="$requiredInputProps('deliveryTerms')" />
      </b-col>

      <b-col v-if="deliveryTimeVisible && this.$can(['action', 'chooseDeliveryTimeInDays'])" cols="12">
        <label for="delivery-time">Tarneaeg tellimuse kinnitamisest (päevades) <i v-if="chosenOne">(PDF failil)</i></label>
        <input v-model="formData.deliveryTime" v-validate="'required'" v-bind="$requiredInputProps('deliveryTime')" />
      </b-col>

      <b-col v-if="validTimeVisible && this.$can(['action', 'chooseValidTimeInDays'])" cols="12">
        <label for="delivery-time">Hinnapakkumine kehtib (päevades) <i v-if="chosenOne">(PDF failil)</i></label>
        <input v-model="formData.validTime" v-validate="'required'" v-bind="$requiredInputProps('validTime')" />
      </b-col>

      <b-col v-if="notesVisible" cols="12">
        <label for="notes">Märkmed <i v-if="chosenOne">(PDF failil)</i></label>
        <input v-model="formData.notes" v-bind="$requiredInputProps('notes')" />
      </b-col>

      <b-col v-if="languageVisible" cols="12">
        <label for="language">Keel <i v-if="chosenOne">(PDF failil)</i></label>
        <select v-model="language" v-validate="'required'" v-bind="$requiredInputProps('language')">
          <option v-for="(option, optionIndex) in languages" :key="'language-option-' + optionIndex" :value="option.value">
            <span>{{ option.label }}</span>
          </option>
        </select>
      </b-col>

      <b-col v-if="childrenVisible && !childOrder && hasChildren" cols="12" class="mt-3">
        <b-form-checkbox
          v-model="addChildren"
          :unchecked-value="false"
          name="addChildren"
        >
          Kuva ka alamtellimusi <i v-if="chosenOne">(PDF failil)</i>
        </b-form-checkbox>
      </b-col>

      <b-col v-if="addChildren && documentType.value === 'PRODUCT_WORKORDER'" cols="12" class="mt-3">
        <b-form-checkbox
          v-model="mainOrderOnOnePage"
          :unchecked-value="false"
          name="mainOrderOnOnePage"
        >
          Kuva peatellimuse tooted ühel lehel
        </b-form-checkbox>
      </b-col>
    </b-row>

    <div v-if="!forEmail" slot="modal-footer" class="container modal-buttons-wrapper">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="close">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('document-download')" class="icon-button text-primary" title="Lae alla" @click="download">
          <font-awesome-icon icon="file-download" />
        </b-button>
      </div>
    </div>
  </component>
</template>
<script>
  import { downloadBinaryFile } from './downloadFile'
  import getPdfAdditionalDataMixin from './getPdfAdditionalDataMixin'
  import { documentSubTypes,
           vatTypes,
           languages,
           contactVisibleDocuments,
           supplierVisibleDocuments,
           productNameVisibleDocuments,
           vatTypeVisibleDocuments,
           documentSubTypeVisibleDocuments,
           clientOrderNumberVisibleDocuments,
           languageVisibleDocuments,
           deliveryTermsVisibleDocuments,
           deliveryTimeVisibleDocuments,
           validTimeVisibleDocuments,
           notesVisibleDocuments,
           childrenVisibleDocuments } from './helpers'

  export default {
    name: 'faf-get-pdf',
    mixins: [ getPdfAdditionalDataMixin ],
    props: {
      visible: Boolean,
      order: Object,
      client: String,
      supplier: String,
      type: {
        type: String,
        validator: (val) => ['SALES', 'PRODUCTION', 'PURCHASE'].includes(val),
        required: true
      },
      forEmail: {
        type: Boolean
      },
      chosenOne: {
        type: [String, Boolean],
        validator: (val) => ['WORKORDER', 'PRODUCT_WORKORDER', 'SALES_PREPAYMENT', 'SALES_PRICE_INQUIRY', 'SALES_BILL_OF_DELIVERY', 'SALES_ORDER_CONFIRMATION', 'PURCHASE_PRICE_INQUIRY', 'PURCHASE_ORDER', false].includes(val)
      }
    },
    data () {
      return {
        documentType: { value: null },
        clientData: {},
        clientContact: null,

        supplierData: {},
        supplierContact: null,

        formData: {
          clientOrderNumber: this.order && (this.order.projectName || this.order.object),
          documentSubType: 'SHEET_CUT',

          productName: 'Metallplaadid',
          vatType: 'Käibemaks 20% (Pöördmaksustamine KMS § 41 lg.1)',

          deliveryTerms: '',
          deliveryTime: this.$can(['action', 'chooseDeliveryTimeInDays']) ? '5' : '',

          validTime: this.$can(['action', 'chooseValidTimeInDays']) ? '7' : '',

          notes: ''
        },

        documentSubTypes,
        vatTypes,

        languages,
        language: 'EE',

        addChildren: false,
        mainOrderOnOnePage: true,

        documentTypeOptions: [ { value: null } ]
      }
    },
    computed: {
      contactVisible () {
        return contactVisibleDocuments.includes(this.documentType.value) && this.$can(['add', 'Client'])
      },
      supplierVisible () {
        return supplierVisibleDocuments.includes(this.documentType.value) && this.$can(['action', 'FilterSuppliers'])
      },
      productNameVisible () {
        return productNameVisibleDocuments.includes(this.documentType.value)
      },
      vatTypeVisible () {
        return vatTypeVisibleDocuments.includes(this.documentType.value)
      },
      documentSubTypeVisible () {
        return documentSubTypeVisibleDocuments.includes(this.documentType.value) && this.$can(['see', 'documentSubTypes'])
      },
      clientOrderNumberVisible () {
        return clientOrderNumberVisibleDocuments.includes(this.documentType.value)
      },
      languageVisible () {
        return languageVisibleDocuments.includes(this.documentType.value)
      },
      deliveryTermsVisible () {
        return deliveryTermsVisibleDocuments.includes(this.documentType.value)
      },
      deliveryTimeVisible () {
        return deliveryTimeVisibleDocuments.includes(this.documentType.value)
      },
      validTimeVisible () {
        return validTimeVisibleDocuments.includes(this.documentType.value)
      },
      notesVisible () {
        return notesVisibleDocuments.includes(this.documentType.value)
      },
      childrenVisible () {
        return childrenVisibleDocuments.includes(this.documentType.value)
      },

      childOrder () {
        return this.$_get(this.order, 'subStatus', []).includes('PRODUCTION_ORDER')
      },
      hasChildren () {
        return this.$_get(this.order, 'linkedOrders.childOrders', []).length > 0
      }
    },
    watch: {
      documentType (val) {
        this.addChildren = (this.childrenVisible && !this.childOrder && this.hasChildren)
        this.mainOrderOnOnePage = this.addChildren
        this.$emit('documentType', val)
      },
      formData: {
        deep: true,
        handler () {
          this.formDataChange()
        }
      }
    },
    async created () {
      this.formDataChange = this.$_debounce(this.formDataChange, 300)
      this.getDocumentTypeOptions(this.type)
      if (this.client) {
        this.clientData = (await this.$clients.getClient({ name: this.client })).data
        this.clientContact = this.clientData.contacts[0]
      }
      if (this.supplier && this.type === 'PURCHASE') {
        this.supplierData = (await this.$suppliers.getSupplier({ name: this.supplier })).data
        this.supplierContact = this.supplierData.contacts.find(s => s.name === this.order.supplier.contact.name) || this.supplierData.contacts[0]
      }
      if (this.$company.name === 'LAPI') {
        this.formData.productName = this.formData.clientOrderNumber
      }
      this.formData.notes = this.$_get(this.order, 'notes', '')
      this.formDataChange()
    },
    methods: {
      formDataChange () {
        this.$emit('formData', this.formData)
      },

      async getDocumentTypeOptions (type) {
        if (type === 'SALES') {
          const id = this.$company.name === 'ADREM' ? this.order.orderId : (this.order.salesSystemNumber || this.order.orderId)
          this.documentTypeOptions = [
            { value: 'WORKORDER', label: 'Töökäsk', fileName: 'TOOKASK_' + id, fileNameEN: 'WORKORDER_' + id },
            { value: 'PRODUCT_WORKORDER', label: 'Tootepõhine töökäsk', fileName: 'TOOTE_TOOKASK_' + id, fileNameEN: 'PRODUCT_WORKORDER_' + id },
            { value: 'SALES_PREPAYMENT', label: 'Ettemaksuarve', fileName: 'ETTEMAKSUARVE_' + id, fileNameEN: 'PREPAYMENT_' + id },
            { value: 'SALES_PRICE_INQUIRY', label: 'Hinnapakkumine', fileName: 'HINNAPAKKUMINE_' + id, fileNameEN: 'PRICE_INQUIRY_' + id },
            { value: 'SALES_BILL_OF_DELIVERY', label: 'Saateleht', fileName: 'SAATELEHT_' + id, fileNameEN: 'BILL_OF_DELIVERY_' + id },
            { value: 'SALES_ORDER_CONFIRMATION', label: 'Tellimuse kinnitus', fileName: 'TELLIMUSE_KINNITUS_' + id, fileNameEN: 'ORDER_CONFIRMATION_' + id }
          ].filter(type => this.$can(['documents', type.value]))
        }
        if (type === 'PRODUCTION') {
          const id = this.order.salesSystemNumber || this.order.orderId
          this.documentTypeOptions = [
            { value: 'WORKORDER', label: 'Töökäsk', fileName: 'TOOKASK_' + id, fileNameEN: 'WORKORDER_' + id },
            { value: 'PRODUCT_WORKORDER', label: 'Tootepõhine töökäsk', fileName: 'TOOTE_TOOKASK_' + id, fileNameEN: 'PRODUCT_WORKORDER_' + id }
          ].filter(type => this.$can(['documents', type.value]))
        }
        if (type === 'PURCHASE') {
          const id = this.order.orderNumber
          this.documentTypeOptions = [
            { value: 'PURCHASE_PRICE_INQUIRY', label: 'Hinnapäring', fileName: 'HINNAPARING_' + id, fileNameEN: 'PURCHASE_PRICE_INQUIRY_' + id },
            { value: 'PURCHASE_ORDER', label: 'Ostutellimus', fileName: 'OSTUTELLIMUS_' + id, fileNameEN: 'PURCHASE_ORDER_' + id }
          ].filter(type => this.$can(['documents', type.value]))
        }
        this.documentType = this.chosenOne ? this.documentTypeOptions.find(o => o.value === this.chosenOne) : this.documentTypeOptions[0]
      },

      async download () {
        this.$validator.validateAll().then(async valid => {
          if (!valid) {
            return
          }
          this.$loader.start('document-download')
          const data = {
            order: this.order,
            client: {
              data: this.clientData,
              contact: this.clientContact
            },
            supplier: {
              data: this.supplierData,
              contact: this.supplierContact
            },
            addChildren: this.addChildren,
            mainOrderOnOnePage: this.mainOrderOnOnePage,
            additionalData: await this.getAdditionalData(this.documentType.value, this.order, this.formData),
            language: this.language
          }
          this.$DocumentController.generateDocument(this.documentType.value, data).then(rs => {
            this.$loader.stop('document-download')
            const headers = {
              'Content-Type': 'application/pdf'
            }
            const fileName = this.language === 'EE' ? this.documentType.fileName : this.documentType.fileNameEN
            downloadBinaryFile(rs.data, headers, `${fileName}.pdf`)
          }).catch(err => {
            this.$loader.stop('document-download')
            this.$err.show(err)
          })
        })
      },

      close () {
        this.$emit('close')
      }
    }
  }

</script>
<style lang="scss" scoped>
  label {
    margin-top: 10px;
    margin-bottom: 0;
  }
</style>
