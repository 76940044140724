import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`
const andFilterQuery = (filters) => filters ? Object.keys(filters).map(field => {
  if (filters[field]) {
    return `filter[${field}]=${encodeURIComponent(filters[field])}`
  } else {
    return null
  }
}).filter(f => f).join('&') : ''

const getDate = (date => {
  if (date) {
    let dates = date.startDate ? '&startDate=' + date.startDate : ''
    dates += date.endDate ? '&endDate=' + date.endDate : ''
    return dates
  } else {
    return ''
  }
})

function getOrders (filters, pagination, date, status = [], subStatus = [], sort = '') {
  let optionalFilters = andFilterQuery(filters) ? '&' + andFilterQuery(filters) : ''
  optionalFilters += getDate(date)
  status = status.length ? JSON.stringify(status) : ''
  subStatus = (subStatus && subStatus.length) ? JSON.stringify(subStatus) : ''
  sort = sort ? `&sort[${Object.keys(sort)[0]}]=${Object.values(sort)[0]}` : ''

  return axios({
    url: `/api/order?page=${ pagination.page }&limit=${ pagination.limit }${ optionalFilters }&status=${status}&subStatus=${subStatus}${sort}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getOrderById (id) {
  return axios({
    url: `/api/order/${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function postOrder (data) {
  return axios({
    url: `/api/add-order`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function updateOrder (id, data) {
  return axios({
    url: `/api/update-order/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function updateWorkcenter (id, data) {
  return axios({
    url: `/api/update-workcenter/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function setProof (data) {
  return axios({
    url: `/api/set-proof`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function upsertProof (id, data) {
  return axios({
    url: `/api/upsert-proof/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function updateProduct (id, data) {
  return axios({
    url: `/api/update-product/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function updateProductStatus (id, data) {
  return axios({
    url: `/api/update-product-status/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function addProduct (id, data) {
  return axios({
    url: `/api/add-product/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function deleteProduct (id) {
  return axios({
    url: `/api/delete-product/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function getProducts (query) {
  return axios({
    url: `/api/order-product?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filter)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getAllWorkcenterWithParent (query) {
  delete query.filter.page
  return axios({
    url: `/api/get-all-work-center-with-parent?page=${query.page}&limit=${query.limit}&${andFilterQuery(query.filter)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getAllMaterialsWithParent (filters) {
  return axios({
    url: `/api/get-all-materials-with-parent?${andFilterQuery(filters)}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function addDocument (id, data) {
  return axios({
    url: `/api/order/document/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function updateDocument (id, data) {
  return axios({
    url: `/api/order/document/${id}`,
    method: 'PATCH',
    headers: { Authorization: token() },
    data
  })
}

function deleteDocument (id) {
  return axios({
    url: `/api/order/document/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function addSubStatus (id, data) {
  return axios({
    url: `/api/order/add-sub-status/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function deleteSubStatus (id, data) {
  return axios({
    url: `/api/order/delete-sub-status/${id}`,
    method: 'PUT',
    headers: { Authorization: token() },
    data
  })
}

function deleteMaterial (id) {
  return axios({
    url: `/api/order/material/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function deleteWorkcenter (id) {
  return axios({
    url: `/api/order/workcenter/${id}`,
    method: 'DELETE',
    headers: { Authorization: token() }
  })
}

function getWorkcenter (id) {
  return axios({
    url: `/api/get-workcenter?filter[products.workcenters._id]=${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function getWorkcenterByJobId (id) {
  return axios({
    url: `/api/get-workcenter?filter[products.workcenters.proof.jobId]=${id}`,
    method: 'GET',
    headers: { Authorization: token() }
  })
}

function toggleStopped (id, data) {
  return axios({
    url: `/api/order/toggle-stopped/${id}`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

function cloneOrder (id, data) {
  return axios({
    url: `/api/order/clone-order/${id}`,
    method: 'POST',
    headers: { Authorization: token() },
    data
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$orders = {
    getOrders,
    postOrder,
    updateOrder,
    getProducts,
    getAllWorkcenterWithParent,
    updateWorkcenter,
    updateProduct,
    updateProductStatus,
    addProduct,
    deleteProduct,
    getAllMaterialsWithParent,
    getOrderById,
    addDocument,
    deleteDocument,
    addSubStatus,
    deleteSubStatus,
    deleteMaterial,
    deleteWorkcenter,
    getWorkcenter,
    updateDocument,
    getWorkcenterByJobId,
    upsertProof,
    toggleStopped,
    cloneOrder,
    setProof
  }
}

export {
  getOrders,
  postOrder,
  updateOrder,
  getProducts,
  getAllWorkcenterWithParent,
  updateWorkcenter,
  updateProduct,
  updateProductStatus,
  addProduct,
  deleteProduct,
  getAllMaterialsWithParent,
  getOrderById,
  addDocument,
  deleteDocument,
  addSubStatus,
  deleteSubStatus,
  deleteMaterial,
  deleteWorkcenter,
  getWorkcenter,
  updateDocument,
  getWorkcenterByJobId,
  upsertProof,
  toggleStopped,
  cloneOrder,
  setProof
}
