<template>
  <div style="display: inline-block;">
    <b-button
      v-b-tooltip.hover
      :class="{
        'text-dark disabled': !product.name,
        'text-info': !prefilled && product.name,
        'text-success': prefilled
      }"
      class="icon-button"
      title="Eeltäida toode"
      @click="prefillProduct"
    >
      <font-awesome-icon :icon="prefilled ? 'check-circle' : 'file-signature'" />
    </b-button>
    <b-button
      v-b-tooltip.hover
      :class="{
        'text-info': !favourite,
        'text-primary': favourite
      }"
      :disabled="disabled"
      class="icon-button"
      title="Salvesta toode"
      @click="saveFavouriteProduct"
    >
      <font-awesome-icon icon="heart" />
    </b-button>
  </div>
</template>
<script>
  import { cleanUpWorkcenters, cleanUpProduct } from './../../../helperMethods/cleanUpOrder'

  export default {
    name: 'faf-favourite-product',
    props: {
      product: {
        type: Object,
        required: true
      },
      cardName: String,
      disabled: {
        type: Boolean,
        required: false
      }
    },
    data () {
      return {
        prefilled: false,
        favourite: false,
        loading: false
      }
    },
    methods: {
      prefillProduct () {
        this.cardName && this.$cardLoader(this.cardName, true)
        this.product.name && this.$products.getAllProducts({page: 1, limit: 1, filters: { name: this.product.name }}).then(rs => {
          if (rs.data.docs.length) {
            const requestedProduct = rs.data.docs[0]
            requestedProduct.workcenters = cleanUpWorkcenters(requestedProduct.workcenters)
            delete requestedProduct.id
            delete requestedProduct._id

            this.$emit('prefill-product', { ...this.product, ...requestedProduct })
            this.prefilled = true
            this.cardName && this.$cardLoader(this.cardName, false)
          } else {
            this.$err.show('Toote infot ei leitud')
            this.cardName && this.$cardLoader(this.cardName, false)
          }
        }).catch(err => {
          this.$err.show('Toote infot ei leitud')
          this.cardName && this.$cardLoader(this.cardName, false)
        })
      },
      saveFavouriteProduct () {
        this.product = cleanUpProduct(this.product)
        this.$products.addProduct(this.product).then(rs => {
          this.favourite = !this.favourite
          this.$notify.show('Toode edukalt lisatud!')
        }).catch(err => {
          if (err.response.data.message.includes('name_1 dup key')) {
            this.$confirm.show('Kas uuendan toote?', 'Toode on juba olemas', answer => {
              if (answer) {
                this.$products.updateProduct(this.product)
                  .then(rs => this.$notify.show('Toode edukalt uuendatud!'))
                  .catch(err => this.$err.show(err))
              }
            })
          } else {
            this.$err.show(err)
          }
        })
      }
    }
  }
</script>
