import Ajv from 'ajv'

const ajv = new Ajv({ allErrors: true })

const schema = {
  type: 'object',
  required: ['salePrice', 'netCostTotal'],
  properties: {
    salePrice: {
      type: 'string'
    }
  }
}

const test = ajv.compile(schema)

export default async function _getMarginal (salePrice, netCostTotal) {
  if (!test({salePrice, netCostTotal})) {
    throw { function: '_getMarginal', error: test.errors }
  }

  const result = (+salePrice - +netCostTotal) / +salePrice * 100
  return Number.isFinite(result) ? result.toFixed(2) : 0
}
