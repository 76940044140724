<template>
  <component
    :is="'router-link'"
    v-can="['see', name]"
    v-bind="$attrs"
    class="nav-item"
    tag="li"
  >
    <a class="nav-link" style="min-height: 50px;">
      <b-button
        v-b-tooltip.hover
        :title="name"
        class="icon-button text-white"
      >
        <font-awesome-icon :icon="icon" />
      </b-button>
      <!-- <i v-if="icon" :class="icon"></i> -->
      <p>{{ name }}</p>
    </a>
  </component>
</template>
<script>
  export default {
    name: "sidebar-link",
    props: {
      name: String,
      icon: String
    },
    mounted () {
      if (this.addLink) {
        this.addLink(this);
      }
    },
    beforeDestroy () {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      if (this.removeLink) {
        this.removeLink(this);
      }
    },
    methods: {
      hideSidebar () {
        if (this.autoClose) {
          this.$sidebar.displaySidebar(false);
        }
      },
      isActive () {
        return this.$el.classList.contains("active");
      }
    }
  };
</script>
<style lang="scss">
  .sidebar .nav-item .nav-link {
    p {
      display: inline-block;
      padding-left: 10px;
      max-width: 100px;
      -webkit-transition: max-width 2s;
      transition: max-width 2s;
    }
    .icon-button {
      display: inline-block;
      width: 30px;
      margin: 0;
      padding: 0;
    }
  }

  .sidebar.sidebar-collapsed .nav-item .nav-link p {
    opacity: 0;
    max-width: 0px;
    position: absolute;
  }
</style>
