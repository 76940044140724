<template>
  <div>
    <b-table
      v-if="items.length"
      small
      responsive
      hover
      striped
      sticky-header="600px"
      :items="items"
      :fields="fields"
      :tbody-tr-class="rowClass"
      class="reports-table"
      :class="{ 'table-sticky-dark': this.$user.getTheme() === 'dark' }"
    >
      <template #head()="data">
        <p class="font-weight-bolder" style="min-height: 18px">
          {{ data.label }}
        </p>
        <p class="font-weight-bolder text-lowercase" style="min-height: 18px">
          {{ data.field.secondLabel }}
        </p>
      </template>
    </b-table>
    <b-skeleton-table
      v-else
      :rows="25"
      :columns="10"
      :table-props="{ striped: true }"
      animation="wave"
    />
  </div>
</template>
<script>
  export default {
    name: 'product-report-table',
    components: {
    },
    props: {
      filters: Object,
      companyPlasmaPro: Boolean
    },
    data () {
      return {
        fields: [
          {
            key: 'salesSystemNumber[0]',
            label: 'ID',
            tdClass: "text-nowrap",
            secondLabel: ''
          },
          {
            key: 'operatorName',
            label: 'Operaator',
            tdClass: "text-nowrap"
          },
          {
            key: 'workcenterName',
            label: 'Töökeskus',
            tdClass: "text-nowrap"
          },
          {
            key: 'teamName',
            label: 'Tiim',
            tdClass: "text-nowrap"
          },
          {
            key: 'productName[0]',
            label: 'Toode',
            tdClass: "text-nowrap"
          },
          {
            key: 'plannedTime',
            label: 'Planeeritud aeg',
            secondLabel: '[min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed() + ' min' : '0 min'
          },
          {
            key: 'plannedTimeHours',
            label: '',
            secondLabel: '[tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' h' : '0 h'
          },
          {
            key: 'actualTime',
            label: 'Tegelik aeg',
            secondLabel: '[min]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed() + ' min' : '0 min'
          },
          {
            key: 'actualTimeHours',
            label: '',
            secondLabel: '[tund]',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' h' : '0 h'
          },
          {
            key: 'effectivity',
            label: 'Effektiivsus',
            tdClass: "text-nowrap",
            formatter: (value, key, item) => value ? value.toFixed(2) + ' %' : '-'
          }
        ],
        items: []
      }
    },
    async created () {
      await this.generateReport()
    },

    methods: {
      rowClass (item, type) {
        if (!item || type !== 'row') return
        if (item.accent) return 'row-accent'
      },
      async generateReport () {
        const filters = {
          ...this.filters.status && { status: this.filters.status },
          ...this.filters.startDate && { startDate: this.filters.startDate },
          ...this.filters.endDate && { endDate: this.filters.endDate },
          ...this.filters.salesSystemNumber && { salesSystemNumber: this.filters.salesSystemNumber },
          ...this.filters.orderId && { orderId: this.filters.orderId },
          ...this.filters.projectName && { projectName: this.filters.projectName },
          ...this.filters.client && { client: this.filters.client },
          ...this.filters.productName && { 'products.name': this.filters.productName },
          ...this.filters.operatorName && { operatorName: this.filters.operatorName },
          ...this.filters.workcenterName && { workcenterName: this.filters.workcenterName },
          ...this.filters.teamName && { teamName: this.filters.teamName },
          ...this.filters.catalogue && { client: this.filters.catalogue },
          ...this.filters.object && { client: this.filters.object },
        }

        console.log('this.filters.teamName', this.filters.teamName)

        this.items = (await this.$reports.getOperatorReport(filters)).data
      }
    }
  }
</script>
<style lang="scss">
.row-accent {
  font-weight: 800;
  & + tr td {
    border-color: rgba(255, 255, 255, 0.5)!important;
    padding-top: 10px!important;
  }
}
</style>
