export default {
  methods: {
    startupCheck () {
      if (this.myJobs && !this.myJobs.length) {
        this.$err.show('Vali tootmistabelist töid', () => {
          this.$bvModal.hide(this.modalName)
        })
      }
    }
  }
}
