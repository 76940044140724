<template>
  <faf-card name="production-table" title="Tootmise tabel">
    <faf-production-table @rows-selected="rowsSelected = $event" />
    <template slot="buttons">
      <b-button v-b-tooltip.hover v-b-modal.get-custom-pdf :disabled="!rowsSelected" class="icon-button text-success" title="Lae dokument alla">
        <font-awesome-icon icon="file-download" />
      </b-button>
      <b-button v-b-tooltip.hover v-b-modal.prepare-proof-modal :disabled="!rowsSelected" class="icon-button text-info" title="Lisa tööleht">
        <font-awesome-icon icon="file-alt" />
      </b-button>
      <b-button v-b-tooltip.hover v-b-modal.complete-workcenter-modal :disabled="!rowsSelected" class="icon-button text-info" title="Kanna töölehed kinni">
        <font-awesome-icon icon="file-invoice" />
      </b-button>
      <b-button v-b-tooltip.hover class="icon-button text-success" title="Alusta tööd" @click="$root.$emit('start-workcenter-job', {})">
        <font-awesome-icon icon="play-circle" />
      </b-button>
    </template>
  </faf-card>
</template>
<script>
  import { FafProductionTable } from '@faf/faf-ui'
  export default {
    name: 'production-table-card',
    components: {
      FafProductionTable
    },
    data () {
      return {
        rowsSelected: false
      }
    }
  }
</script>
