<template>
  <b-modal
    id="add-edit-management-system-reports"
    :title="modalTitle"
    size="lg"
    ok-title="Lisa"
    cancel-title="Tagasi"
    @hide="$emit('hide')"
  >
    <div>
      <b-row class="px-xl-3">
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Dokumendi nimetus</label>
          <input
            v-model="formData.name"
            v-bind="$requiredInputProps('name')"
          >
        </b-col>
        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0">Versioon</label>
          <input
            v-model="formData.version"
            v-bind="$requiredInputProps('version')"
          >
        </b-col>

        <b-col xl="4" md="2" cols="3" class="p-xl-1 mb-2">
          <label class="mb-0" for="status">Dokumendi tüüp</label>
          <multiselect
            v-model="formData.type"
            v-faf-autocomplete-off
            :options="typeSuggestions"
            v-bind="$multiselectProps('type')"
            name="type"
            @tag="addType"
          />
        </b-col>
      </b-row>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('add-edit-management-system-reports')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button
          v-can="['see', 'FileManager']"
          v-b-tooltip.hover
          v-b-modal.file-manager
          :disabled="!formData._id"
          class="icon-button text-info"
          title="Dokumendid"
        >
          <font-awesome-icon icon="folder-open" />
        </b-button>
        <faf-file-manager v-if="formData._id" :order-object-id="formData._id" :pre-loaded-documents="formData.documents" type="MANAGEMENT_SYSTEM_REPORTS" />
        <b-button v-b-tooltip.hover :disabled="$loader.val('add-edit-management-system-reports')" class="icon-button text-success" title="Salvesta" @click="submit">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'add-edit-management-system-reports',
    data () {
      return {
        formData: {
          _id: null,
          type: '',
          name: '',
          documents: [],
          contacts: {
            name: null,
            email: '',
            phone: ''
          }
        },
        typeSuggestions: []
      }
    },
    computed: {
      modalTitle () {
        return this.formData._id ? 'Muuda dokumenti' : 'Lisa uus dokument'
      }
    },
    watch: {
      formData (val) {
        val.entryId && this.$bvModal.show('add-edit-management-system-reports')
      }
    },
    created () {
      this.getTypeSuggestions()
    },
    methods: {
      addType (e) {
        this.typeSuggestions.push(e)
        this.formData.type = e
      },
      async getTypeSuggestions (e) {
        this.typeSuggestions = (await this.$managementSystemReports.getSuggestions('type')).data.filter(n => n)
      },
      async edit () {
        return await this.$managementSystemReports.updateManagementSystemReport(this.formData._id, this.formData)
      },
      async add () {
        return await this.$managementSystemReports.addManagementSystemReport({ ...this.formData, author: `${ this.$user.getFirstName() } ${ this.$user.getLastName() }` })
      },
      async submit () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('add-edit-management-system-reports')
          await this[this.formData._id ? 'edit' : 'add']()
            .then((rs) => {
              if (!this.formData._id) this.formData._id = rs.data._id
              this.$notify.show(this.formData._id ? 'Aruanne edukalt uuendatud!' : 'Aruanne edukalt lisatud!')
            })
            .catch(err => this.$err.show())
          this.$loader.stop('add-edit-management-system-reports')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  textarea.form-control {
    padding: 0.25rem 0.5rem;
    border: 1px solid #2b3553 !important;
  }
  textarea.form-control:active,
  textarea.form-control:focus {
    border: 1px solid #e14eca !important;
  }
</style>
