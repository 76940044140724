<template>
  <b-modal
    id="add-edit-client"
    :title="modalTitle"
    size="lg"
    ok-title="Lisa"
    cancel-title="Tagasi"
    scrollable
    @hide="$emit('hide')"
  >
    <div>
      <h4 class="mt-0 text-info">
        Klient
      </h4>
      <b-row>
        <b-col xl="5" md="3" cols="4" class="pr-xl-1">
          <label>Firma nimi</label>
          <input
            v-model="formData.name"
            v-validate="'required'"
            v-bind="$requiredInputProps('client-name')"
          >
        </b-col>
        <b-col xl="6" md="2" cols="4" class="pr-xl-1">
          <label>Aadress</label>
          <multiselect
            v-model="formData.address"
            v-validate="'required'"
            :options="addressOptions"
            :name="'address'"
            v-bind="$multiselectProps('address')"
            active
            @tag="addNewAddress"
            @search-change="searchAddressSuggestions"
          >
            <template slot="noOptions">
              Alusta trükkimist...
            </template>
            <template slot="noResult">
              Tulemused puuduvad
            </template>
          </multiselect>
        </b-col>
      </b-row>
      <hr />
      <h4 class="mt-3 text-info">
        Kontaktid
      </h4>
      <!-- Repeater -->
      <faf-repeater
        :items="formData.contacts"
        :delete-disabled="formData.contacts.length <= 1"
        inline-icons
        duplicate-disabled
        @add-row="addNewRow"
        @delete-row="deleteRow"
      >
        <template slot="repeaterSlot" slot-scope="row">
          <b-row class="pl-2">
            <b-col xl="4" md="3" cols="4" class="px-xl-1">
              <input
                v-model="formData.contacts[row.index].name"
                v-validate="'required'"
                v-bind="$requiredInputProps('name-' + row.index)"
                placeholder="Nimi"
                type="text"
              >
            </b-col>
            <b-col xl="4" md="2" cols="4" class="px-xl-1">
              <input
                v-model="formData.contacts[row.index].email"
                v-validate="'required|email'"
                v-bind="$requiredInputProps('email-' + row.index)"
                placeholder="Email"
                type="text"
              >
              <b-form-invalid-feedback v-if="!$validateField('email-' + row.index)">
                Email ei ole korrektne
              </b-form-invalid-feedback>
            </b-col>
            <b-col xl="4" md="2" cols="4" class="px-xl-1">
              <input
                v-model.trim="formData.contacts[row.index].phone"
                v-validate="{ required: true, regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ }"
                v-bind="$requiredInputProps('phone-' + row.index)"
                placeholder="Telefon"
                type="text"
              >
              <b-form-invalid-feedback v-if="!$validateField('phone-' + row.index)">
                Telefoninumber ei ole korrektne
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </template>
      </faf-repeater>
    </div>
    <div slot="modal-footer" class="container modal-buttons-wrapper mt-3">
      <div class="row justify-content-center">
        <b-button v-b-tooltip.hover class="icon-button" title="Tagasi" @click="$bvModal.hide('add-edit-client')">
          <font-awesome-icon icon="arrow-alt-circle-left" />
        </b-button>
        <b-button v-b-tooltip.hover :disabled="$loader.val('add-edit-client')" class="icon-button text-success" title="Salvesta" @click="submit">
          <font-awesome-icon icon="check-circle" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'add-edit-client',
    data () {
      return {
        formData: {
          name: '',
          address: '',
          contacts: [{
            name: '',
            email: '',
            phone: ''
          }]
        },
        addressOptions: []
      }
    },
    computed: {
      modalTitle () {
        return this.formData._id ? 'Muuda klienti' : 'Lisa uus klient'
      }
    },
    watch: {
      formData (val) {
        val.contacts.length === 0 && val.contacts.push({ name: '', email: '', phone: '' })
        val.name && this.$bvModal.show('add-edit-client')
      }
    },
    mounted () {
      this.searchAddressSuggestions = this.$_debounce(this.searchAddressSuggestions, 800)
    },
    methods: {
      async searchAddressSuggestions (value) {
        this.addressOptions = (await this.$suggestions.getAddress(value)).data
      },
      addNewAddress (value) {
        this.addressOptions.push(value)
        this.formData.address = value
      },
      addNewRow (index) {
        this.formData.contacts.splice(index + 1, 0, { name: '', email: '', phone: '' })
      },
      deleteRow (index) {
        this.formData.contacts.splice(index, 1)
      },
      async editClient () {
        return await this.$clients.updateClient(this.formData._id, {
          name: this.formData.name,
          address: this.formData.address,
          contacts: this.formData.contacts
        })
      },
      async addClient () {
        return await this.$clients.addClient(this.formData)
      },
      async submit () {
        if (await this.$validator.validateAll()) {
          this.$loader.start('add-edit-client')
          await this[this.formData._id ? 'editClient' : 'addClient']()
            .then(() => {
              this.$bvModal.hide('add-edit-client')
              this.$emit('client-added')
            })
            .catch(err => this.$err.show())
          this.$loader.stop('add-edit-client')
        }
      }
    }
  }
</script>
