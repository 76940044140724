export default function installPrivileges (Vue = false, privileges = false, role = false) {
  let _privateRole = role
  if (Vue && privileges && role) {
    Vue.prototype.$can = (binding) => {
      const rolePrivilege = binding[0]
      const elementPrivilege = binding[1]
      const canSee = _privateRole.map(role => {
        return privileges[role] ? privileges[role][rolePrivilege] ? privileges[role][rolePrivilege].includes(elementPrivilege) : false : false
      }).filter(f => f)
      return canSee.length
    }
    Vue.prototype.$rolePrivileges = {
      _vm: new Vue({
        data: {
          role,
          privileges
        },
        watch: {
          role () {
            this.rolePrivileges()
          }
        },
        created () {
          this.rolePrivileges()
        },
        methods: {
          rolePrivileges () {
            const roles = this.role
            Vue.directive('can', {
              inserted (el, binding, vnode) {
                const rolePrivilege = binding.value[0]
                const elementPrivilege = binding.value[1]
                const canSee = roles.map(role => {
                  return privileges[role] ? privileges[role][rolePrivilege] ? privileges[role][rolePrivilege].includes(elementPrivilege) : false : false
                }).filter(f => f)
                if (!canSee.length) {
                  el.remove()
                }
              }
            })
          }
        }
      }),
      changeRole (role) {
        this._vm.$data.role = role
        _privateRole = role
      }
    }
  } else {
    const isVueSet = !Vue && 'Vue not found!'
    const isPrivilegesSet = !privileges && 'Privileges not found!'
    const role = !role && 'Role not found!'
    const dependencies = [
      isVueSet, isPrivilegesSet, role
    ]
    dependencies.map(dependency => {
      if (dependency) {
        throw new Error(dependency)
      }
    })
  }
}
