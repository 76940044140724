import axios from 'axios'

const token = () => `Bearer ${JSON.parse(localStorage.user).token}`

function calculateOrder (id) {
  return axios({
    url: `/calculation-service/v1/calculation/order/${ id }`,
    method: 'GET',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    }
  })
}

function calculateAfterOrder (id) {
  return axios({
    url: `/calculation-service/v1/calculation/after-order/${ id }`,
    method: 'GET',
    headers: {
      Authorization: token(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    }
  })
}

export default function install (Vue, options = {}) {
  Vue.prototype.$CalculationController = {
    calculateOrder,
    calculateAfterOrder
  }
}

export {
  calculateOrder,
  calculateAfterOrder
}
