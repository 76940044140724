import Vue from 'vue'

const MOBILE_WIDTH = 1000

const isMobile = Vue.observable( window.innerWidth < MOBILE_WIDTH)

export default function install (Vue, options = {}) {
  Vue.prototype.$isMobile = isMobile

  window.addEventListener('resize', () => {
    if (!Vue.prototype.$isMobile && window.innerWidth < MOBILE_WIDTH) {
      Vue.prototype.$isMobile = true
    } else if (Vue.prototype.$isMobile && window.innerWidth > MOBILE_WIDTH) {
      Vue.prototype.$isMobile = false
    }
  }, { passive: true })

}

export {
  isMobile
}
