export default {
  colors: {
    default: "#344675",
    primary: "#e14eca",
    secondary: "£f4f5f7",
    success: "#42b883",
    info: "#1d8cf8",
    warning: "#ff8d72",
    danger: "#fd5d93",
    teal: "#00d6b4",
    black: "#222a42",
    primaryGradient: ['rgba(76, 211, 150, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)']
  }
}
